import React, { useState, useEffect } from "react";
import {
  FormControlListaDeInputs,
  SpanStyledInfo,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../components/Grid/CCol";

import { Icon } from "@iconify/react/dist/iconify.js";

const Section = ({
  title,
  values,
  setValues,
  tempTitulo,
  showActionTitle,
  position = 0,
  widths,
  onChange,
  deleteButtonActivate,
  disabledIndexes = [],
  md = [],
  titleMd = "",
}) => {
  const [editMode, setEditMode] = useState(true);
  const [tempValues, setTempValues] = useState([...values]);

  useEffect(() => {
    setTempValues([...values]);
  }, [values]);

  const handleChange = (index, newValue) => {
    const newTempValues = [...tempValues];
    newTempValues[index] = { ...newTempValues[index], value: newValue };
    setTempValues(newTempValues);
  };

  const handleSave = () => {
    setValues(tempValues);
    setEditMode(true);
  };

  const handleCancel = () => {
    setTempValues([...values]);
    setEditMode(true);
  };

  return (
    <RowAninhada
      className="justify-content-center align-items-center"
      style={{
        marginTop: showActionTitle ? "10px" : "0px",
        flexWrap: "nowrap",
      }}
    >
      {title && (
        <CCol
          md={titleMd}
          className="d-flex align-items-center"
          style={{ marginTop: showActionTitle ? "24px" : "5px" }}
        >
          <SpanStyledInfo
            style={{
              flexGrow: 1,
              display: "block",
              whiteSpace: "nowrap",
              textAlign: "left",
              overflow: "visible",
            }}
          >
            {title}
          </SpanStyledInfo>
        </CCol>
      )}
      {tempValues.map((item, index) => (
        <CCol
          md={md[index] ? md[index] : (index + 1) % 2 === 0 ? 1 : 2}
          key={index}
          style={{
            width: widths && widths[index] ? widths[index] : "",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flexShrink: 1,
            minWidth: "50px", // Ajuste conforme necessário
          }}
        >
          {tempTitulo && tempTitulo[index] && (
            <SpanStyledInfo
              style={{
                display: "flex",
                justifyContent: "center",
                whiteSpace: "nowrap",
                position: "relative",
                overflow: "visible",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              {tempTitulo[index].titulo}
            </SpanStyledInfo>
          )}
          <FormControlListaDeInputs
            disabled={editMode || disabledIndexes.includes(index)}
            value={item.value}
            onChange={(e) => handleChange(index, e.target.value)}
            radiusborder={
              position === 1
                ? "5px 5px 0px 0px"
                : position === 2
                ? "0px 0px 5px 5px"
                : position === 3
                ? "5px 5px 5px 5px"
                : "0px 0px 0px 0px"
            }
          />
        </CCol>
      ))}
      <CColButtonsAcoes2Icons md={1}>
        {showActionTitle && (
          <SpanStyledInfo
            style={{
              display: "flex",
              justifyContent: "center",
              whiteSpace: "nowrap",
              overflow: "visible",
              fontSize: "12px",
              marginBottom: "5px",
            }}
          >
            Ação
          </SpanStyledInfo>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "right",
          }}
        >
          {!editMode ? (
            <>
              <Icon
                icon="fluent:save-24-filled"
                color="#008CFF"
                height="26"
                onClick={handleSave}
                style={{
                  cursor: "pointer",
                  marginLeft: "5px",
                  marginTop: "3px",
                }}
              />
              <Icon
                icon="ic:baseline-cancel"
                height="26"
                color="#F24E1E"
                onClick={handleCancel}
                style={{
                  cursor: "pointer",
                  marginLeft: "2px",
                  marginRight: "-5px",
                  marginTop: "3px",
                }}
              />
            </>
          ) : (
            <>
              <Icon
                icon="bx:edit"
                color="#5971C8"
                height="26"
                onClick={() => setEditMode(false)}
                style={{
                  cursor: "pointer",
                  marginLeft: "5px",
                  marginTop: "3px",
                }}
              />
              {deleteButtonActivate && (
                <Icon
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="28"
                  onClick={() => console.log("Delete clicked")}
                  style={{
                    cursor: "pointer",
                    marginLeft: "2px",
                    marginTop: "3px",
                  }}
                />
              )}
            </>
          )}
        </div>
      </CColButtonsAcoes2Icons>
    </RowAninhada>
  );
};

export default Section;
