import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import InputSvg from "../../../../components/Grid/InputSvg";
import { ToastContainer, toast } from "react-toastify";
import ButtonsAcoesListas from "../../../../styles/StyledComponents/ButtonsAcoesListas";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import api from "../../../../utils/api";

function ConsultaTipoDePrograma() {
  const [itens, setItens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [itensFiltrados, setItensFiltrados] = useState([]);
  const [loadingLista, setLoadingLista] = useState(true);
  const { currentClient } = useContext(CurrentClientContext);
  const checkClientPrefeitura =
    currentClient.naturezaJuridica.toLowerCase().includes("município") &&
    currentClient.razaoSocial.toLowerCase().includes("municipio");

  axios.defaults.headers.common["Authorization"] = null;

  const [modalOpened, setModalOpened] = useState(false);
  const [dataDelete, setDataDelete] = useState({});
  const [codigosTipoDeProgramaPadrao, setCodigosTipoDeProgramaPadrao] =
    useState([]);

  const fetchData = () => {
    setLoadingLista(true);
    api
      .get(`tipoDePrograma/por-regiao/${currentClient.endereco.municipio}`)
      .then((response) => {
        if (response.data.length > 1) {
          response.data.sort((itemA, itemB) => {
            if (itemA.codigo < itemB.codigo) return -1;
            if (itemA.codigo > itemB.codigo) return 1;
            return 0;
          });
        }
        setItens(response.data);
        setLoadingLista(false);
      })
      .catch((error) =>
        toast.error(
          "Algo deu errado ao carregar a lista. Por favor, tente mais tarde"
        )
      );
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [currentClient]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = itens.filter((item) => {
      return (
        item.descricao.toLowerCase().includes(value.toLowerCase()) ||
        item.codigo.toString().includes(value)
      );
    });
    setItensFiltrados(filteredItems);
    setCurrentPage(0);
  };

  const deleteTipoDePrograma = () => {
    api
      .delete(
        `tipoDePrograma/custom/${currentClient.clienteId}/delete/${dataDelete.codigo}`
      )
      .then(() => {
        toast.success("Tipo de Programa deletado com sucesso!");
        fetchData();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Algo deu errado. Por favor, tente mais tarde.");
        }
      });
  };

  const getCodigoTipoDeProgramaPadrao = () => {
    api
      .get("tipoDePrograma/padrao")
      .then((resp) => {
        const codigoPadrao = resp.data.map(
          (tipoProgramaPadrao) => tipoProgramaPadrao.codigo
        );
        setCodigosTipoDeProgramaPadrao(codigoPadrao);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCodigoTipoDeProgramaPadrao();
  }, []);

  function openModal() {
    setModalOpened(true);
  }

  function closeModal() {
    setModalOpened(false);
  }

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = itens.slice(startIndex, endIndex);
  const currentFilteredItens = itensFiltrados.slice(startIndex, endIndex);

  const pages = Math.ceil(itens.length / itensPorPagina);
  const filteredPages = Math.ceil(itensFiltrados.length / itensPorPagina);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <ModalDeleteData
        modalOpened={modalOpened}
        closeModal={closeModal}
        setDataDelete={setDataDelete}
        deleteDataFunction={deleteTipoDePrograma}
      />
      <ToastContainer />
      <HeaderOptions
        withRow={true}
        TituloPagina={"Tipo de Programa"}
        SubTituloPagina={"Consulta"}
        botaoNovo={checkClientPrefeitura}
        ToPag={"/planejamento/cadastro/tipo-de-programa"}
        DashboardPage={"/planejamento/cadastro/tipo-de-programa/dashboard"}
        PaginaRelatorio={"/planejamento/cadastro/tipo-de-programa/relatorio"}
      />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loadingLista ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        <Table
          responsive
          style={loadingLista ? { display: "none" } : { marginTop: "-10px" }}
        >
          <thead>
            <tr>
              <TituloTable>Código</TituloTable>
              <TituloTable>Descrição</TituloTable>
              <TituloTable>Indicador</TituloTable>
              <TituloTableAction>Ação</TituloTableAction>
            </tr>
          </thead>
          <BodyTable>
            {searchTerm
              ? currentFilteredItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }}>{item.codigo}</td>
                    <td style={{ whiteSpace: "nowrap" }}>{item.descricao}</td>
                    <td style={{ whiteSpace: "nowrap" }}>{item.indicador}</td>
                    <td>
                      <ButtonsAcoesListas
                        FuncaoIconDelete={() => {
                          openModal();
                          setDataDelete({ codigo: item.codigo });
                        }}
                        CaminhoPagUpdate={`/planejamento/cadastro/tipo-de-programa/visualizar/${item.codigo}`}
                        IconDelete={
                          !codigosTipoDeProgramaPadrao.includes(item.codigo)
                        }
                      />
                    </td>
                  </tr>
                ))
              : currentItens.map((item, index) => (
                  <tr key={index}>
                    <td style={{ whiteSpace: "nowrap" }}>{item.codigo}</td>
                    <td style={{ whiteSpace: "nowrap" }}>{item.descricao}</td>
                    <td style={{ whiteSpace: "nowrap" }}>{item.indicador}</td>

                    <td>
                      <ButtonsAcoesListas
                        FuncaoIconDelete={() => {
                          openModal();
                          setDataDelete({ codigo: item.codigo });
                        }}
                        CaminhoPagUpdate={`/planejamento/cadastro/tipo-de-programa/visualizar/${item.codigo}`}
                        IconDelete={
                          !codigosTipoDeProgramaPadrao.includes(item.codigo)
                        }
                      />
                    </td>
                  </tr>
                ))}
          </BodyTable>
        </Table>
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? itensFiltrados.length : itens.length} registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default ConsultaTipoDePrograma;
