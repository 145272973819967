export const ReturnDataAtual = () => {
  const data = new Date();
  const dia =
    data.getDate() < 9 ? `0${data.getDate()}` : data.getDate().toString();
  const mes =
    data.getMonth() < 9
      ? `0${data.getMonth() + 1}`
      : (data.getMonth() + 1).toString();
  const ano = data.getFullYear().toString();

  return `${dia}/${mes}/${ano}`;
};
