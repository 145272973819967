import { useContext, useEffect, useState } from "react";
import { CurrentCityContext } from "../../contexts/CurrentCityContext";
import { CurrentClientContext } from "../../contexts/CurrentClientContext";
import {
  ButtonItem,
  ContainerItems,
  LocalSelectContainer,
  PTitulo,
} from "./StylesBox";

const MAX_CIDADES_SEM_SCROLL = 7;
const LocalSelectBox = ({ userAuthData }) => {
  const { currentCity, setCurrentCity } = useContext(CurrentCityContext);
  const { setCurrentClient } = useContext(CurrentClientContext);
  const [arrayCidadesUnicas] = useState([]);

  useEffect(() => {
    const arrayCidades = userAuthData.clientes.map(
      (cliente) => cliente.endereco
    );
    arrayCidades.forEach((endereco) => {
      if (arrayCidadesUnicas.length === 0) {
        arrayCidadesUnicas.push(endereco);
      } else {
        const indiceRepeticao = arrayCidadesUnicas.findIndex(
          (item) =>
            item.municipio === endereco.municipio &&
            item.estado === endereco.estado
        );

        if (indiceRepeticao === -1) {
          arrayCidadesUnicas.push(endereco);
        }
      }
    });
  }, [arrayCidadesUnicas, userAuthData]);

  const handleChangeRegion = (region) => {
    const userHistory = JSON.parse(localStorage.getItem("userHistory"));
    userHistory.regiao = region;
    localStorage.setItem("userHistory", JSON.stringify(userHistory));
    setCurrentCity(region);
    console.log(region);
    const clientesPorRegiao = userAuthData.clientes.filter(
      (cliente) =>
        cliente.endereco.municipio === region.municipio &&
        cliente.endereco.estado === region.estado
    );

    console.log(clientesPorRegiao);
    setCurrentClient({ ...clientesPorRegiao[0] });
  };

  return (
    <LocalSelectContainer>
      <PTitulo>Município</PTitulo>
      {/* <button onClick={() => console.log(arrayCidadesUnicas)}>console</button> */}
      <ContainerItems
        hasScroll={arrayCidadesUnicas.length > MAX_CIDADES_SEM_SCROLL}
      >
        {Object.keys(userAuthData).length > 0
          ? arrayCidadesUnicas.map((item, index) => (
              <ButtonItem
                selectedButton={
                  item.municipio === currentCity.municipio &&
                  item.estado === currentCity.estado
                }
                key={index}
                onClick={() => handleChangeRegion(item)}
              >{`${item.municipio} - ${item.estado}`}</ButtonItem>
            ))
          : null}
      </ContainerItems>
    </LocalSelectContainer>
  );
};

export default LocalSelectBox;
