import React, { useContext, useEffect, useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import HeaderCadastros from "../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, CColButtonsAcoes2Icons } from "../../../components/Grid/CCol";
import FloatingLabelInput from "../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../components/Inputs/MyAutoComplete";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../styles/StyledComponents/ListaDeInputs";
import { Icon } from "@iconify/react";
import { useFetchData } from "../../../hooks/useFetchData";
import InputFormatRealBrasileiro from "../../../components/Grid/InputFormatRealBrasileiro";
import { SpanTotalPorFonte } from "../LOA_QDD/StyledComponentsQDD";
import { CurrentClientContext } from "../../../contexts/CurrentClientContext";
import { ToastContainer, toast } from "react-toastify";
import { FormatValueToLocaleString } from "../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import ModalDeleteData from "../../../components/ModalDeleteData/ModalDeleteData";
import { useParams } from "react-router-dom";
import { ReturnDataAtual } from "../../../utils/Gerais/ReturnDataAtual";
import api from "../../../utils/api";
import LoadingComponent from "../../../components/AssetsComponents.jsx/LoadingComponent";
import { OpenedModalsContext } from "../../../contexts/OpenedModalsContext";
import ModalAutoComplete from "../../../components/Modais/ModalAutoComplete";
import FonteDeRecursos from "../Cadastros/FonteDeRecursos/FonteDeRecursos";
// import styled from "styled-components";

// const InfoBoxReceita = styled.div`
//   background-color: #ebeaea;
//   padding: 10px;
//   border-radius: 5px;
//   margin-top: 13px;

//   p {
//     margin: 0px;
//     color: #515c70;
//     font-weight: 600;
//     font-size: 14px;
//   }
// `;

const QDRCadastro = () => {
  const [todasInfosCarregadas, setTodasInfosCarregadas] = useState(false);
  const { currentClient } = useContext(CurrentClientContext);
  const { id: idParams } = useParams();
  const dataAtual = ReturnDataAtual();
  const ano = parseInt(dataAtual.split("/")[2]);
  const inputFormatRealBrasileiroRef = useRef();

  const { data: nextFichaData } = useFetchData(
    `/qdr/next-ficha/${currentClient.clienteId}`
  );

  const [
    inputVizualizacaoNaturezaDaReceita,
    setInputVizualizacaoNaturezaDaReceita,
  ] = useState("");
  const [valuesQDR, setValuesQDR] = useState({
    ano,
    clienteId: currentClient.clienteId,
    unidadeOrcamentariaId: 0,
    naturezaDaReceitaId: 0,
    naturezaCustom: false,
    fontesDeRecursos: [],
  });
  const [inputNatRecValue, setInputNatRecValue] = useState("");

  const [idQDRParaAtualizacao, setIdQDRParaAtualizacao] = useState(0);
  // const { data: dataFontesDeRecursos } = useFetchData(
  //   `fonteDeRecurso/por-ano/2024`
  // );
  const { data: dataUnidadeOrcamentaria } = useFetchData(
    `/unidadeOrcamentaria/cliente/${currentClient.clienteId}`
  );
  // const { data: dataNaturezasDaReceita } = useFetchData(
  //   `/naturezaDaReceita/por-cliente/${currentClient.clienteId}/${ano}/filtro?codigo=`
  // );
  const [optionsFonteDeRecursos, setOptionsFonteDeRecursos] = useState([]);
  const [optionsUnidadeOrcamentaria, setOptionsUnidadeOrcamentaria] = useState(
    []
  );
  const [optionsNaturezaDaReceita, setOptionsNaturezaDaReceita] = useState([]);

  const [fonteDeRecursoAdicionar, setFonteDeRecursoAdicionar] = useState({
    fonteDeRecursoId: 0,
    esfera: "Fiscal",
    valorPrevisto: 0,
    disabled: true,
    tipo: 1,
  });

  const [valorPrevistoReais, setValorPrevistoReais] = useState(0);
  const [modalOpened, setModalOpened] = useState(false);
  const [dataDelete, setDataDelete] = useState({});
  const [loadingOptionsNatRec, setLoadingOptionsNatRec] = useState(false);
  const [
    inputUnidadeOrcamentariaDisabledValue,
    setInputUnidadeOrcamentariaDisabledValue,
  ] = useState("");
  const [unidadeOrcamentariaPreenchida, setUnidadeOrcamentariaPreenchida] =
    useState(false);
  const [naturezaDaReceitaPreenchida, setNaturezaDaReceitaPreenchida] =
    useState(false);
  const [requisicaoPostConcluida, setRequisicaoPostConcluida] = useState(false);
  const [nextFichaState, setNextFichaState] = useState(null);
  const nextFichaPutRef = useRef();

  const { setOpenedModals } = useContext(OpenedModalsContext);

  const openModal = () => {
    setModalOpened(true);
  };

  const closeModal = () => {
    setModalOpened(false);
  };

  const [inputFonteDeRecursos, setInputFonteDeRecursos] = useState("");

  const [valorFonteAntesDaAlteracao, setValorFonteAntesDaAlteracao] = useState([
    "",
    0,
  ]);
  const [arrayFontesRequisicaoDelete, setArrayFontesRequisicaoDelete] =
    useState([]);

  const preencheInfosPagVizualizar = () => {
    api
      .get(`qdr/por-id/${idParams}/${ano}`)
      .then((resp) => {
        console.log(resp.data[0]);
        const data = resp.data[0];
        const arrayFontesDeRecursos = data.fontesDeRecursos.map((item) => {
          const object = {
            ficha: item.ficha,
            disabled: true,
            tipo: item.tipo,
            esfera: item.esfera,
            fonteDeRecursoId: item.id,
            valorPrevisto: parseFloat(item.valorPrevisto),
          };

          return object;
        });

        setValuesQDR((prevValues) => ({
          ...prevValues,
          unidadeOrcamentariaId: data.unidadeOrcamentariaId.id,
          naturezaDaReceitaId: data.naturezaDaReceita.id,
          fontesDeRecursos: arrayFontesDeRecursos,
        }));

        setIdQDRParaAtualizacao(data.id);

        setArrayFontesRequisicaoDelete(arrayFontesDeRecursos);

        setInputVizualizacaoNaturezaDaReceita(
          `${data.naturezaDaReceita.codigo} - ${data.naturezaDaReceita.especificacao}`
        );

        setInputNatRecValue(
          `${data.naturezaDaReceita.codigo} - ${data.naturezaDaReceita.especificacao}`
        );
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Algo deu errado ao encontrar os dados desse cadastro. Por favor, tente mais tarde."
        );
      });
  };

  useEffect(() => {
    if (
      idParams &&
      optionsNaturezaDaReceita.length > 0 &&
      valuesQDR.naturezaDaReceitaId !== 0
    ) {
      const natRec = optionsNaturezaDaReceita.find(
        (item) => item.id === valuesQDR.naturezaDaReceitaId
      );
      console.log(optionsNaturezaDaReceita);
      console.log("nat rec encontrada");
      console.log(natRec);

      setOptionsFonteDeRecursos(
        natRec.fontesVinculadas
          .sort((itemA, itemB) => {
            if (itemA.conta < itemB.conta) return -1;
            if (itemA.conta > itemB.conta) return 1;
            return 0;
          })
          .map((fonte) => ({
            codigo: fonte.conta,
            titulo: fonte.titulo,
            id: fonte.id,
          }))
      );
    }
  }, [idParams, optionsNaturezaDaReceita, valuesQDR.naturezaDaReceitaId]);

  useEffect(() => {
    if (nextFichaData) {
      setNextFichaState(nextFichaData.ficha);
    }
  }, [nextFichaData]);

  useEffect(() => {
    if (nextFichaData && idParams) {
      nextFichaPutRef.current = nextFichaData.ficha;
    }
  }, [nextFichaData, idParams]);

  useEffect(() => {
    if (idParams) {
      preencheInfosPagVizualizar();
    }
    //eslint-disable-next-line
  }, [idParams]);

  useEffect(() => {
    console.log("valor da unidade orcamentaria:");
    console.log(valuesQDR.unidadeOrcamentariaId);
  }, [valuesQDR.unidadeOrcamentariaId]);

  useEffect(() => {
    if (
      idParams &&
      optionsUnidadeOrcamentaria.length > 0 &&
      valuesQDR.unidadeOrcamentariaId !== 0 &&
      !unidadeOrcamentariaPreenchida
    ) {
      const objetoUnidade = optionsUnidadeOrcamentaria.find(
        (item) => item.id === valuesQDR.unidadeOrcamentariaId
      );
      console.log(optionsUnidadeOrcamentaria);
      setInputUnidadeOrcamentariaDisabledValue(
        `${objetoUnidade?.codigo} - ${objetoUnidade?.titulo}`
      );
      console.log("TANTO DE VEZES QUE PASSOU 1");
      setUnidadeOrcamentariaPreenchida(true);
    }
  }, [
    idParams,
    optionsUnidadeOrcamentaria,
    valuesQDR,
    unidadeOrcamentariaPreenchida,
  ]);

  useEffect(() => {
    if (
      idParams &&
      optionsNaturezaDaReceita.length > 0 &&
      valuesQDR.naturezaDaReceitaId !== 0 &&
      !naturezaDaReceitaPreenchida
    ) {
      // const naturezaDaReceita = optionsNaturezaDaReceita.find(
      //   (item) => item.id === valuesQDR.naturezaDaReceitaId
      // );
      // setInputNaturezaDaReceitaDisabledValue(
      //   `${naturezaDaReceita.codigo} - ${naturezaDaReceita.titulo}`
      // );
      console.log("TANTO DE VEZES QUE PASSOU 2");
      setNaturezaDaReceitaPreenchida(true);
    }
  }, [
    idParams,
    optionsNaturezaDaReceita,
    valuesQDR,
    naturezaDaReceitaPreenchida,
  ]);

  const handleAutoCompleteChange = (item) => {
    console.log(item.id);

    setFonteDeRecursoAdicionar((prevValues) => ({
      ...prevValues,
      fonteDeRecursoId: item.id,
    }));
  };

  useEffect(() => {
    // if (dataFontesDeRecursos) {
    //   setOptionsFonteDeRecursos(
    //     dataFontesDeRecursos.map((fonte) => ({
    //       codigo: fonte.conta,
    //       titulo: fonte.titulo,
    //       id: fonte.id,
    //     }))
    //   );
    // }

    if (dataUnidadeOrcamentaria) {
      console.log(dataUnidadeOrcamentaria);
      const unidadesHabilitadas = dataUnidadeOrcamentaria.filter(
        (item) =>
          item.unidade.responsavelOrcamento === 1 &&
          item.unidade.naturezaJuridica !== "1 - Câmara Municipal"
      );

      console.log(unidadesHabilitadas);

      setOptionsUnidadeOrcamentaria(
        unidadesHabilitadas.map((item) => ({
          codigo: item.unidade.codigo,
          titulo: item.unidade.titulo,
          id: item.unidade.id,
        }))
      );
    }

    // if (dataNaturezasDaReceita) {
    //   console.log(dataNaturezasDaReceita);
    //   setOptionsNaturezaDaReceita(
    //     dataNaturezasDaReceita
    //       .filter((natRec) => natRec.naturezaDaReceita.valorizavel === "SIM")
    //       .map((item) => ({
    //         titulo: item.naturezaDaReceita.especificacao,
    //         codigo: item.naturezaDaReceita.nr,
    //         descricao: item.naturezaDaReceita.descricao,
    //         id: item.naturezaDaReceita.id,
    //         fontesVinculadas: item.fontesDeRecursos,
    //       }))
    //   );
    // }
  }, [dataUnidadeOrcamentaria]);

  const formatValueNatRec = () => {
    const cleanedValue = inputNatRecValue.replace(/[^0-9]/g, "");
    let formatedValue = "";
    const mask = "x.x.x.x.xx.x.x.xx";
    let stringIndex = 0;

    for (let i = 0; i < mask.length && stringIndex < cleanedValue.length; i++) {
      if (mask[i] === "x") {
        formatedValue += cleanedValue[stringIndex];
        stringIndex++;
      } else {
        formatedValue += ".";
      }
    }

    console.log(formatedValue);

    return formatedValue;
  };

  const fetchOptionsNatRec = () => {
    const natRecFormated = formatValueNatRec();

    setLoadingOptionsNatRec(true);

    api
      .get(
        `naturezaDaReceita/por-cliente/${currentClient.clienteId}/2024/filtro?codigo=${natRecFormated}`
      )
      .then((resp) => {
        const data = resp.data;
        console.log(data);
        const optionsFiltered = data
          .filter((natRec) => natRec.naturezaDaReceita.valorizavel === "SIM")
          .map((item) => ({
            titulo: item.naturezaDaReceita.especificacao,
            codigo: item.naturezaDaReceita.nr,
            descricao: item.naturezaDaReceita.descricao,
            id: item.naturezaDaReceita.id,
            fontesVinculadas: item.fontesDeRecursos,
          }));
        setOptionsNaturezaDaReceita(optionsFiltered);
        setLoadingOptionsNatRec(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Algo deu errado, por favor, tente mais tarde");
        setLoadingOptionsNatRec(false);
      });
  };

  useEffect(() => {
    if (inputNatRecValue.length > 0) {
      fetchOptionsNatRec();
    } else {
      console.log("entrou no else");
      setOptionsNaturezaDaReceita([]);
    }
    console.log("mudou");

    //eslint-disable-next-line
  }, [inputNatRecValue]);

  useEffect(() => {
    if (dataUnidadeOrcamentaria) {
      const unidadesHabilitadas = dataUnidadeOrcamentaria.filter(
        (item) =>
          item.unidade.responsavelOrcamento === 1 &&
          item.unidade.naturezaJuridica !== "1 - Câmara Municipal"
      );

      if (valuesQDR.unidadeOrcamentariaId === 0) {
        setValuesQDR((prev) => ({
          ...prev,
          unidadeOrcamentariaId: unidadesHabilitadas[0].unidade.id,
        }));
      }
    }
  }, [dataUnidadeOrcamentaria, valuesQDR]);

  const adicionarFonteDeRecursos = () => {
    // NATUREZAS ESPECIFICAS - COM DEDUCOES

    // 1.7.1.1.51.1.1 - Cota-Parte do Fundo de Participação dos Municípios - Cota Mensal - Pri
    // 1.7.2.1.52.0.1 - Cota-Parte do IPI - Municípios - Principal
    // 1.7.2.1.50.0.1 - Cota-Parte do ICMS - Principal
    // 1.7.2.1.51.0.1 - Cota-Parte do IPVA - Principal
    // 1.7.1.1.52.0.1 - Cota-Parte do Imposto Sobre a Propriedade Territorial Rural - Principa.

    const naturezasComDeducoes = [
      "1.7.1.1.51.1.1",
      "1.7.2.1.52.0.1",
      "1.7.2.1.50.0.1",
      "1.7.2.1.51.0.1",
      "1.7.1.1.52.0.1",
    ];

    const naturezaTemDeducao = naturezasComDeducoes.includes(
      optionsNaturezaDaReceita.find(
        (natRec) => natRec.id === valuesQDR.naturezaDaReceitaId
      ).codigo
    );
    console.log(naturezaTemDeducao);

    const newArray = naturezaTemDeducao
      ? [
          ...valuesQDR.fontesDeRecursos,
          {
            ...fonteDeRecursoAdicionar,
            ficha: idParams ? nextFichaPutRef.current : null,
          },
          {
            ...fonteDeRecursoAdicionar,
            ficha: idParams ? nextFichaPutRef.current + 1 : null,
            valorPrevisto: (20 / 100) * fonteDeRecursoAdicionar.valorPrevisto,
            tipo: 3,
          },
        ]
      : [
          ...valuesQDR.fontesDeRecursos,
          {
            ...fonteDeRecursoAdicionar,
            ficha: idParams ? nextFichaPutRef.current : null,
          },
        ];

    let nenhumCampoVazio = true;

    let fonteDeRecursoAindaNaoAdicionada = true;

    Object.keys(fonteDeRecursoAdicionar).forEach((chave) => {
      if ([0, "", "0,00"].includes(fonteDeRecursoAdicionar[chave])) {
        nenhumCampoVazio = false;
      }
    });

    valuesQDR.fontesDeRecursos.forEach((fonte, index) => {
      if (fonte.fonteDeRecursoId === fonteDeRecursoAdicionar.fonteDeRecursoId) {
        fonteDeRecursoAindaNaoAdicionada = false;
      }
    });

    if (nenhumCampoVazio && fonteDeRecursoAindaNaoAdicionada) {
      console.log("ENTROU NESSE IF");
      setValuesQDR((prevValues) => ({
        ...prevValues,
        fontesDeRecursos: newArray,
      }));

      setFonteDeRecursoAdicionar({
        fonteDeRecursoId: 0,
        esfera: "Fiscal",
        valorPrevisto: "0,00",
        disabled: true,
        tipo: 1,
      });

      setInputFonteDeRecursos("");
      nextFichaPutRef.current += 1;
    } else if (!nenhumCampoVazio) {
      toast.error(
        "Preencha todos os campos corretamente para adicionar uma nova fonte de recursos."
      );
    } else {
      toast.error("Essa fonte de recursos ja foi adicionada.");
    }
  };

  useEffect(() => {
    if (valuesQDR.naturezaDaReceitaId === 0) {
      setOptionsFonteDeRecursos([]);
      setValuesQDR((prev) => ({
        ...prev,
        fontesDeRecursos: [],
      }));
    }
  }, [valuesQDR.naturezaDaReceitaId]);

  const acionaEdicaoInputFonteRecursos = (indexDaFonte) => {
    let newArray = [...valuesQDR.fontesDeRecursos];

    let nenhumaOutraEdicaoAtiva = true;

    if (!newArray[indexDaFonte].disabled) {
      console.log("desabilitado");

      if (newArray[indexDaFonte].tipo === 3) {
        if (
          valuesQDR.fontesDeRecursos[indexDaFonte].valorPrevisto >
            valuesQDR.fontesDeRecursos[indexDaFonte - 1].valorPrevisto ||
          valuesQDR.fontesDeRecursos[indexDaFonte].valorPrevisto === 0
        ) {
          newArray[indexDaFonte].disabled = true;
          newArray[indexDaFonte].valorPrevisto = valorFonteAntesDaAlteracao[1];
          newArray[indexDaFonte].esfera = valorFonteAntesDaAlteracao[0];
        } else {
          newArray[indexDaFonte].disabled = !newArray[indexDaFonte].disabled;
        }
      } else {
        newArray[indexDaFonte].disabled = !newArray[indexDaFonte].disabled;
      }
    } else {
      newArray.forEach((item) => {
        if (!item.disabled) {
          nenhumaOutraEdicaoAtiva = false;
        }
      });

      if (nenhumaOutraEdicaoAtiva) {
        setValorFonteAntesDaAlteracao([
          newArray[indexDaFonte].esfera,
          newArray[indexDaFonte].valorPrevisto,
        ]);
        newArray[indexDaFonte].disabled = !newArray[indexDaFonte].disabled;
      } else {
        toast.error(
          "Conclua as edições pendentes para poder editar outro registro."
        );
      }
    }

    console.log(newArray);

    setValuesQDR((prevValues) => ({
      ...prevValues,
      fontesDeRecursos: [...newArray],
    }));
  };

  const editValorInput = (indexDoObjeto, valor, esfera) => {
    let newArray = [...valuesQDR.fontesDeRecursos];

    newArray[indexDoObjeto].valorPrevisto = parseFloat(
      valor.replace(/,/g, ".")
    );
    newArray[indexDoObjeto].esfera = esfera;

    setValuesQDR((prevValues) => ({
      ...prevValues,
      fontesDeRecursos: newArray,
    }));
  };

  const cancelaAlteracaoFonteRecurso = (indexDoObjeto) => {
    console.log(valorFonteAntesDaAlteracao);
    console.log("cancelouuu");

    let newArray = [...valuesQDR.fontesDeRecursos];
    newArray[indexDoObjeto].disabled = true;
    newArray[indexDoObjeto].valorPrevisto = valorFonteAntesDaAlteracao[1];
    newArray[indexDoObjeto].esfera = valorFonteAntesDaAlteracao[0];

    console.log(newArray);

    setValuesQDR((prevValues) => ({
      ...prevValues,
      fontesDeRecursos: newArray,
    }));
  };

  const calculaValorPrevisto = () => {
    console.log("calculando");
    let previstoReais = 0;

    valuesQDR.fontesDeRecursos
      .filter((item) => item.tipo !== 3)
      .forEach((fonte) => {
        previstoReais += fonte.valorPrevisto;
      });

    valuesQDR.fontesDeRecursos
      .filter((item) => item.tipo === 3)
      .forEach((fonte) => {
        previstoReais -= fonte.valorPrevisto;
      });

    // valuesQDR.fontesDeRecursos.forEach((fonte) => {
    //   previstoReais += fonte.valorPrevisto;
    // });

    setValorPrevistoReais(previstoReais);
  };

  useEffect(() => {
    if (valuesQDR.fontesDeRecursos) {
      calculaValorPrevisto();
    }
    //eslint-disable-next-line
  }, [valuesQDR.fontesDeRecursos]);

  useEffect(() => {
    if (nextFichaState && !idParams && valuesQDR.fontesDeRecursos.length > 0) {
      console.log("atualizando ficha");
      const arrayFontesComFicha = valuesQDR.fontesDeRecursos.map(
        (item, index) => ({
          ...item,
          ficha: nextFichaState + index,
        })
      );

      if (!arraysSaoIguais(arrayFontesComFicha, valuesQDR.fontesDeRecursos))
        setValuesQDR((prev) => ({
          ...prev,
          fontesDeRecursos: arrayFontesComFicha,
        }));
    }
  }, [nextFichaState, idParams, valuesQDR.fontesDeRecursos]);

  const arraysSaoIguais = (array1, array2) => {
    if (Object.keys(array1[0]).length !== Object.keys(array2[0]).length) {
      return false;
    }

    const arrayOrganizado1 = [...array1].slice().sort();
    const arrayOrganizado2 = [...array2].slice().sort();

    console.log(JSON.stringify(arrayOrganizado1));
    console.log(JSON.stringify(arrayOrganizado2));
    return (
      JSON.stringify(arrayOrganizado1) === JSON.stringify(arrayOrganizado2)
    );
  };

  const limpaValoresQDR = () => {
    setValuesQDR((prev) => ({
      ano,
      clienteId: currentClient.clienteId,
      unidadeOrcamentariaId: prev.unidadeOrcamentariaId,
      naturezaDaReceitaId: 0,
      fontesDeRecursos: [],
    }));

    setInputNatRecValue("");
  };

  const postQDR = () => {
    let arrayFontesRefatorado = [...valuesQDR.fontesDeRecursos].map((item) => {
      const { disabled, ...resto } = item;

      return resto;
    });

    let valuesToPost = {
      ...valuesQDR,
      fontesDeRecursos: arrayFontesRefatorado,
    };

    console.log(valuesToPost);
    api
      .post(`qdr`, valuesToPost)
      .then((resp) => {
        console.log(resp.data);
        toast.success("Dados cadastrados com sucesso!");
        setRequisicaoPostConcluida(true);
        limpaValoresQDR();
      })
      .then(() =>
        api.get(`/qdr/next-ficha/${currentClient.clienteId}`).then((resp) => {
          setNextFichaState(resp.data.ficha);
        })
      )
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          toast.error(error.response.data.error);
        } else {
          toast.error(
            "Ocorreu um erro ao cadastrar os dados, por favor, tente mais tarde."
          );
        }
      });
  };

  const deletaFonteDeRecurso = (idDaFonte) => {
    let newArray = [...valuesQDR.fontesDeRecursos].filter(
      (item) => item.fonteDeRecursoId !== idDaFonte
    );

    if (
      arrayFontesRequisicaoDelete.some(
        (item) => item.fonteDeRecursoId === idDaFonte
      )
    ) {
      api
        .delete(`qdr/deleteFonte-QDR/${idParams}/fonte/${idDaFonte}`)
        .then((resp) => {
          console.log(resp);

          setArrayFontesRequisicaoDelete(newArray);
          setValuesQDR((prevValues) => ({
            ...prevValues,
            fontesDeRecursos: newArray,
          }));
          toast.success("Fonte deletada com sucesso!");
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            "Algo deu errado ao deletar essa fonte. Por favor, tente mais tarde."
          );
        });
    } else {
      setValuesQDR((prevValues) => ({
        ...prevValues,
        fontesDeRecursos: newArray,
      }));
      toast.success("Fonte deletada com sucesso!");
    }
  };

  const atualizaCadastroQDR = () => {
    if (idQDRParaAtualizacao !== 0) {
      let arrayFontesRefatorado = [...valuesQDR.fontesDeRecursos].map(
        (item) => {
          const { disabled, ...resto } = item;

          return resto;
        }
      );

      let valuesToPut = {
        fontesDeRecursos: arrayFontesRefatorado,
      };

      console.log("Values to send: ", valuesToPut);

      api
        .put(`/qdr/${idParams}/${currentClient.clienteId}/${ano}`, valuesToPut)
        .then((resp) => {
          console.log(resp);
          toast.success("Dados do cadastro atualizados com sucesso!");
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            "Algo deu errado ao tentar atualizar os dados. Por favor, tente mais tarde"
          );
        });
    } else {
      toast.error(
        "Ocorreu um erro inesperado ao tentar atualizar esse cadastro. Por favor, entre em contato com o suporte."
      );
    }
  };

  useEffect(() => {
    if (requisicaoPostConcluida) {
      api
        .get(`qdr/next-ficha/${currentClient.clienteId}`)
        .then((resp) => {
          const data = resp.data;

          console.log(data);

          setRequisicaoPostConcluida(false);
        })
        .catch((error) => console.log(error));
    }
  }, [requisicaoPostConcluida, currentClient.clienteId]);

  const handleOptionNatRec = (item) => {
    console.log(item);
    console.log(item.id);
    console.log(item.codigo.replace(/[^0-9]/g, "").length);

    // 1111010102
    console.log(item.fontesVinculadas);

    setValuesQDR((prev) => ({
      ...prev,
      naturezaDaReceitaId: item.id,
      naturezaCustom:
        item.codigo.replace(/[^0-9]/g, "").length === 10 ? true : false,
    }));

    if (item.fontesVinculadas && item.fontesVinculadas.length > 0) {
      setOptionsFonteDeRecursos(
        item.fontesVinculadas
          .sort((itemA, itemB) => {
            if (itemA.conta < itemB.conta) return -1;
            if (itemA.conta > itemB.conta) return 1;
            return 0;
          })
          .map((fonte) => ({
            codigo: fonte.conta,
            titulo: fonte.titulo,
            id: fonte.id,
          }))
      );
    } else {
      setOptionsFonteDeRecursos([]);
    }

    if (item.fontesVinculadas.length === 1) {
      console.log("so tem uma fonte");
      const fonte = item.fontesVinculadas[0];

      setFonteDeRecursoAdicionar((prev) => ({
        ...prev,
        fonteDeRecursoId: fonte.id,
      }));

      setInputFonteDeRecursos(`${fonte.conta} - ${fonte.titulo}`);

      inputFormatRealBrasileiroRef.current.focus();
    }
  };

  useEffect(() => {
    if (
      (optionsFonteDeRecursos.length !== 0 || !idParams) &&
      optionsUnidadeOrcamentaria.length !== 0
    ) {
      setTodasInfosCarregadas(true);
    }
  }, [optionsFonteDeRecursos, optionsUnidadeOrcamentaria, idParams]);

  // FONTES ESPECIFICAS

  // 1.7.1.1.51.1.1 - Cota-Parte do Fundo de Participação dos Municípios - Cota Mensal - Pri
  // 1.7.2.1.52.0.1 - Cota-Parte do IPI - Municípios - Principal
  // 1.7.2.1.50.0.1 - Cota-Parte do ICMS - Principal
  // 1.7.2.1.51.0.1 - Cota-Parte do IPVA - Principal
  // 1.7.1.1.52.0.1 - Cota-Parte do Imposto Sobre a Propriedade Territorial Rural - Principa.

  const handleAddModal = (titulo) => {
    setOpenedModals((prev) => [
      ...prev,
      { tituloModal: titulo, requisicaoDoModalConcluida: false },
    ]);
  };

  if (!todasInfosCarregadas) {
    return (
      <Container
        id="pags_responsividade_padding_geral"
        fluid
        className="container_conteudo conteudo_pagina"
      >
        <LoadingComponent size={100} containerHeight={"calc(100vh - 62px)"} />
      </Container>
    );
  }

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <ModalAutoComplete
        tituloModal={"Fonte de Recursos"}
        Component={<FonteDeRecursos isAModalPage={true} />}
      />
      <ModalDeleteData
        modalOpened={modalOpened}
        closeModal={closeModal}
        setDataDelete={setDataDelete}
        deleteDataFunction={() => deletaFonteDeRecurso(dataDelete.id)}
      />
      <ToastContainer />
      <HeaderCadastros
        NomePaginaTitulo={"QDR - Quadro Detalhado da Receita"}
        PaginaSubtitulo={"Cadastro"}
        PaginaConsulta={"/planejamento/loa/qdr/consulta"}
        PaginaRelatorio={"/planejamento/loa-qdr-relatorio"}
        ButtonSaveFunction={idParams ? atualizaCadastroQDR : postQDR}
      />

      <Row className={"row_form_2 mt-1"}>
        {/* <button onClick={() => console.log(valuesQDR)}>console</button> */}
        {/* <button onClick={() => console.log(fonteDeRecursoAdicionar)}>
          fonteAdicionar
        </button> */}
        {/* <button onClick={() => console.log(inputNatRecValue)}>console</button> */}
        {/* <button onClick={() => console.log(nextFichaPutRef)}>nextFicha</button> */}
        {/* <button onClick={() => console.log(idParams)}>params</button> */}
        {/* <button onClick={() => console.log(optionsNaturezaDaReceita)}>
          options natureza
        </button> */}
        {(!idParams || optionsUnidadeOrcamentaria.length > 1) && (
          <CCol>
            <FloatingLabelInput
              new={true}
              value={valuesQDR.unidadeOrcamentariaId}
              tipo="input_select"
              placeholder={"Unidade Orçamentária Gestora"}
              label="Unidade Orçamentária Gestora"
              onSelect={(option) =>
                setValuesQDR((prevValues) => ({
                  ...prevValues,
                  unidadeOrcamentariaId: parseInt(option.value),
                }))
              }
              onChange={(e) => {
                setValuesQDR((prevValues) => ({
                  ...prevValues,
                  unidadeOrcamentariaId: parseInt(e.target.value),
                }));

                console.log(e.target.value);
              }}
              options={[
                { label: "Unidade Orçamentária Gestora", value: null },
                ...optionsUnidadeOrcamentaria.map((item) => ({
                  label: `${item.codigo} - ${item.titulo}`,
                  value: item.id,
                })),
              ]}
            />
          </CCol>
        )}
        {idParams && optionsUnidadeOrcamentaria.length === 1 && (
          <CCol>
            <FloatingLabelInput
              disabled
              value={inputUnidadeOrcamentariaDisabledValue}
              label={"Unidade Orçamentária"}
            />
          </CCol>
        )}
      </Row>

      <Row className="mt-3">
        <CCol
          className="d-flex align-items-center p-2 header_body_conteudo"
          md={12}
        >
          <span
            style={{
              fontSize: "16.5px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            Receita Prevista
          </span>
        </CCol>
      </Row>

      <Row className={"row_form_2 mt-1"}>
        {/* <CCol md={1}>
          <FloatingLabelInput
            value={valuesQDR.ficha}
            label="Ficha"
            placeholder="Ficha"
            disabled
          />
        </CCol> */}
        <CCol>
          {!idParams && (
            <MyAutoComplete
              loadingOptions={loadingOptionsNatRec}
              inputValue={inputNatRecValue}
              setInputValue={setInputNatRecValue}
              style={{ height: "37px" }}
              labelInput="Natureza Da Receita"
              placeholder="Natureza da Receita"
              options={optionsNaturezaDaReceita}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  setValuesQDR((prev) => ({
                    ...prev,
                    naturezaDaReceitaId: 0,
                  }));
                }
              }}
              iconClearFunction={() =>
                setValuesQDR((prev) => ({
                  ...prev,
                  naturezaDaReceitaId: 0,
                }))
              }
              onOptionSelect={handleOptionNatRec}
              labelFormat={(option) => `${option.codigo} - ${option.titulo}`}
            />
          )}

          {idParams && (
            <FloatingLabelInput
              disabled
              label="Natureza Da Receita"
              placeholder="Natureza da Receita"
              value={inputVizualizacaoNaturezaDaReceita}
            />
          )}
        </CCol>

        {valuesQDR.naturezaDaReceitaId !== 0 && (
          <CCol md={12} style={{ marginTop: "13px" }}>
            <FloatingLabelInput
              as="textarea"
              tipo="text_area"
              readOnly
              disabled
              style={{ height: "38px", minHeight: "38px" }}
              label="Função"
              value={
                optionsNaturezaDaReceita.find(
                  (item) => item.id === valuesQDR.naturezaDaReceitaId
                )?.descricao
              }
            />
          </CCol>
        )}

        {/* {valuesQDR.naturezaDaReceitaId !== 0 && (
          <CCol md={12}>
            <InfoBoxReceita>
              <p>
                {
                  optionsNaturezaDaReceita.find(
                    (item) => item.id === valuesQDR.naturezaDaReceitaId
                  )?.descricao
                }
              </p>
            </InfoBoxReceita>
          </CCol>
        )} */}
      </Row>

      <>
        <Row
          className="row_form"
          style={{
            marginTop: "16px",
            borderRadius: "5px",
            paddingTop: "30px",
          }}
        >
          <CCol md={1}>
            <PTitulosInputs>Ficha</PTitulosInputs>
            <FormControlListaDeInputs
              disabled
              radiusborder={
                valuesQDR.fontesDeRecursos.length > 0
                  ? "5px 5px 0px 0px"
                  : "5px"
              }
            />
          </CCol>
          <CCol>
            <PTitulosInputs>Fonte de Recursos</PTitulosInputs>
            <MyAutoComplete
              addNewDataFunction={() => handleAddModal("Fonte de Recursos")}
              inputValue={inputFonteDeRecursos}
              setInputValue={setInputFonteDeRecursos}
              options={
                optionsFonteDeRecursos.length > 0
                  ? optionsFonteDeRecursos
                  : [
                      "Selecione uma natureza da receita para ver as fontes que podem ser vinculadas a ela.",
                    ]
              }
              onOptionSelect={
                optionsFonteDeRecursos.length > 0
                  ? handleAutoCompleteChange
                  : null
              }
              labelFormat={
                optionsFonteDeRecursos.length > 0
                  ? (option) => `${option.codigo} - ${option.titulo}`
                  : (msg) => `${msg}`
              }
              radiusborder={
                valuesQDR.fontesDeRecursos.length > 0
                  ? "5px 5px 0px 0px"
                  : "5px"
              }
            />
          </CCol>
          <CCol md={2}>
            <PTitulosInputs>Esfera</PTitulosInputs>
            <FloatingLabelInput
              style={{ color: "#515c70" }}
              height={"30px"}
              tipo="input_select"
              new={true}
              value={fonteDeRecursoAdicionar.esfera}
              radiusborder={
                valuesQDR.fontesDeRecursos.length > 0
                  ? "5px 5px 0px 0px"
                  : "5px"
              }
              options={[
                { label: "Fiscal", value: "Fiscal" },
                { label: "Seguridade", value: "Seguridade" },
              ]}
              onSelect={(option) =>
                setFonteDeRecursoAdicionar((prevValues) => ({
                  ...prevValues,
                  esfera: option.value,
                }))
              }
              onChange={(e) =>
                setFonteDeRecursoAdicionar((prevValues) => ({
                  ...prevValues,
                  esfera: e.target.value,
                }))
              }
            />
          </CCol>
          <CCol md={2}>
            <PTitulosInputs>Valor</PTitulosInputs>
            <InputFormatRealBrasileiro
              ref={inputFormatRealBrasileiroRef}
              radiusborder={
                valuesQDR.fontesDeRecursos.length > 0
                  ? "5px 5px 0px 0px"
                  : "5px"
              }
              externalValue={
                fonteDeRecursoAdicionar.valorPrevisto > 0
                  ? fonteDeRecursoAdicionar.valorPrevisto
                  : "0,00"
              }
              listaDeInputs={true}
              onChange={(e, valor) => {
                const valorNumerico = parseFloat(valor.replace(/,/g, "."));
                setFonteDeRecursoAdicionar((prevValues) => ({
                  ...prevValues,
                  valorPrevisto: valorNumerico,
                }));
              }}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
            <Icon
              onClick={() => {
                adicionarFonteDeRecursos();
              }}
              icon="ic:baseline-add-box"
              color="#105200"
              height="28"
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                marginTop: "1px",
              }}
            />
          </CColButtonsAcoes2Icons>

          {valuesQDR.fontesDeRecursos.map((item, index, selfArray) => (
            <Row
              key={index}
              style={{
                padding: "0px",
                margin: "0px",
                marginTop: "-1px",
              }}
            >
              <CCol md={1}>
                <FormControlListaDeInputs
                  value={item.ficha}
                  disabled
                  style={item.tipo === 3 ? { color: "#F14646" } : {}}
                  radiusborder={
                    selfArray.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                />
              </CCol>
              <CCol>
                <FormControlListaDeInputs
                  radiusborder={
                    selfArray.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  style={item.tipo === 3 ? { color: "#F14646" } : {}}
                  value={`${
                    optionsFonteDeRecursos.find(
                      (fonte) => fonte.id === item.fonteDeRecursoId
                    )?.codigo
                  } - ${
                    optionsFonteDeRecursos.find(
                      (fonte) => fonte.id === item.fonteDeRecursoId
                    )?.titulo
                  }`}
                  disabled
                />
              </CCol>
              <CCol md={2}>
                {item.disabled ? (
                  <FormControlListaDeInputs
                    radiusborder={
                      selfArray.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                    }
                    style={item.tipo === 3 ? { color: "#F14646" } : {}}
                    value={item.esfera}
                    disabled
                  />
                ) : (
                  <FloatingLabelInput
                    height={"30px"}
                    tipo="input_select"
                    new={true}
                    value={item.esfera}
                    radiusborder={
                      selfArray.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                    }
                    options={[
                      { label: "Fiscal", value: "Fiscal" },
                      { label: "Seguridade", value: "Seguridade" },
                    ]}
                    onSelect={(option) => {
                      item.esfera = option.value;
                      editValorInput(index, item.valorPrevisto, option.value);
                    }}
                  />
                )}
              </CCol>
              <CCol md={2}>
                <InputFormatRealBrasileiro
                  style={
                    item.tipo === 3
                      ? { color: "#F14646", textAlign: "end" }
                      : { textAlign: "end" }
                  }
                  radiusborder={
                    selfArray.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                  }
                  onChange={(e, valorFormatado) => {
                    item.valorPrevisto = e.target.value;
                    editValorInput(index, valorFormatado, item.esfera);
                  }}
                  listaDeInputs={true}
                  externalValue={item.valorPrevisto}
                  disabled={item.disabled}
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                {item.disabled ? (
                  <>
                    <Icon
                      onClick={() => acionaEdicaoInputFonteRecursos(index)}
                      icon="bx:edit"
                      color="#5971C8"
                      height="26"
                      style={{
                        cursor: "pointer",
                        marginLeft: "5px",
                        marginTop: "3px",
                      }}
                    />
                    <Icon
                      onClick={() => {
                        openModal();
                        setDataDelete((prevValues) => ({
                          ...prevValues,
                          id: item.fonteDeRecursoId,
                        }));
                      }}
                      icon="ic:baseline-delete"
                      color="#E79900"
                      height="26"
                      style={{
                        cursor: "pointer",
                        marginLeft: "5px",
                        marginTop: "1px",
                      }}
                    />
                  </>
                ) : (
                  <Icon
                    onClick={() => acionaEdicaoInputFonteRecursos(index)}
                    icon="fluent:save-24-filled"
                    color="#008CFF"
                    height="26"
                    style={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      marginTop: "3px",
                    }}
                  />
                )}

                <Icon
                  onClick={() => {
                    if (item.disabled) {
                      return;
                    } else {
                      cancelaAlteracaoFonteRecurso(index);
                    }
                  }}
                  icon="ic:baseline-cancel"
                  height="26"
                  color="#F24E1E"
                  style={{
                    display: item.disabled ? "none" : "inline-block",
                    pointerEvents: item.disabled ? "none" : "auto",
                    cursor: item.disabled ? "not-allowed" : "pointer",
                    marginLeft: "2px",
                    marginRight: "-5px",
                    marginTop: "3px",
                  }}
                />
              </CColButtonsAcoes2Icons>
            </Row>
          ))}

          <Row
            className="row_form_2"
            style={{
              padding: "0px",
              margin: "0px",
              marginTop: "9px",
            }}
          >
            <CCol></CCol>
            <CCol
              md={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SpanTotalPorFonte>Total da Receita</SpanTotalPorFonte>
            </CCol>
            <CCol md={2}>
              <FormControlListaDeInputs
                style={{
                  borderRadius: "10px",
                  padding: "15px",
                  border: "none",
                  textAlign: "end",
                }}
                value={`R$ ${FormatValueToLocaleString(
                  parseFloat(valorPrevistoReais)
                )}`}
                disabled
              />
            </CCol>
            <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
          </Row>
        </Row>
        <div className="p-3"></div>
      </>
    </Container>
  );
};

export default QDRCadastro;
