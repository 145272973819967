import { FormatValueToLocaleString } from "../../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { calcularTotalReceitas } from "../../../../LOA_QDR/RELATORIO_QDR/calcularReceitas";
import api from "../../../../../../utils/api";

export const getReceitasEsfera = async (clienteId) => {
  try {
    const response = await api.get(`qdr/relatorio/${clienteId}/2024`);
    return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
  }
};

export const processAllData = (dataQdrRelatorio) => {
  const codigosSectionRelatorio = ["1.0.0.0.00.0.0", "2.0.0.0.00.0.0"];
  const fonteComDeducao = 3;
  const fonteSemDeducao = 1;

  // const getTotalReceitaSectionEsfera = (
  //   esfera,
  //   deducao,
  //   dataNaturezasQdr,
  //   codigo
  // ) => {
  //   const receitasStartWithCodigo = dataNaturezasQdr.filter((natureza) =>
  //     natureza.nr.startsWith(codigo[0])
  //   );

  //   const receitasWithQdr = receitasStartWithCodigo.filter(
  //     (receita) => receita.qdr !== "vazio"
  //   );

  //   const fontesPorEsfera = receitasWithQdr
  //     .map((receita) =>
  //       receita.qdr.fontesDeRecursos.filter(
  //         (fonte) =>
  //           fonte.esfera.toLowerCase() === esfera && fonte.tipo === deducao
  //       )
  //     )
  //     .filter((item) => item.length)
  //     .flat();

  //   const totalReceitaEsfera = fontesPorEsfera.reduce((acc, fonte) => {
  //     return (acc += parseFloat(fonte.valorPrevisto));
  //   }, 0);

  //   return totalReceitaEsfera;
  // };

  const getTotalEsfera = (dadosFiltrados) => {
    const dataSection = dadosFiltrados.filter((data) =>
      codigosSectionRelatorio.includes(data.codigo)
    );

    const total = dataSection.reduce(
      (acc, item) => {
        acc.fiscal += item.fiscal;
        acc.seguridade += item.seguridade;
        return acc;
      },
      { fiscal: 0, seguridade: 0 }
    );

    return total;
  };

  const processItem = (item, deducao) => {
    const resultado = [
      {
        codigo: item.nr,
        especificacao: item.especificacao,
        fiscal: item.totalFiscal,
        seguridade: item.totalSeguridade,
        // fiscal: codigosSectionRelatorio.includes(item.nr)
        //   ? getTotalReceitaSectionEsfera(
        //       "fiscal",
        //       deducao,
        //       dataNaturezaQdr,
        //       item.nr
        //     )
        //   : item.totalFiscal,
        // seguridade: codigosSectionRelatorio.includes(item.nr)
        //   ? getTotalReceitaSectionEsfera(
        //       "seguridade",
        //       deducao,
        //       dataNaturezaQdr,
        //       item.nr
        //     )
        //   : item.totalSeguridade,
      },
    ];

    if (
      item.qdr &&
      item.qdr.fontesDeRecursos &&
      item.qdr.fontesDeRecursos.length > 0
    ) {
      const recursos = item.qdr.fontesDeRecursos
        .map((recurso) => {
          if (recurso.tipo === deducao) {
            return {
              codigo: recurso.conta,
              especificacao: recurso.titulo,
              fiscal:
                recurso.esfera.toLowerCase() === "fiscal"
                  ? recurso.valorPrevisto
                  : null,
              seguridade:
                recurso.esfera.toLowerCase() === "seguridade"
                  ? recurso.valorPrevisto
                  : null,
            };
          }
          return null;
        })
        .filter((recurso) => recurso !== null);
      resultado.push(...recursos);
    }

    return resultado;
  };

  const naturezas = dataQdrRelatorio.naturezas
    .map((natureza) =>
      processItem(natureza, fonteSemDeducao, dataQdrRelatorio.naturezas)
    )
    .flat();

  const naturezasDeducao = dataQdrRelatorio.naturezasDeducao
    .map((natureza) =>
      processItem(natureza, fonteComDeducao, dataQdrRelatorio.naturezasDeducao)
    )
    .flat();
  const dadosFiltrados = { naturezas, naturezasDeducao };

  const totalReceitas = calcularTotalReceitas(
    dataQdrRelatorio,
    codigosSectionRelatorio
  );

  const totalEsferaSemDeducao = getTotalEsfera(dadosFiltrados.naturezas);

  const totalReceitasDeducao = getTotalEsfera(dadosFiltrados.naturezasDeducao);

  const totalGeralReceitaEsfera = {
    totalGeralFiscal:
      totalEsferaSemDeducao.fiscal - totalReceitasDeducao.fiscal,
    totalGeralSeguridade:
      totalEsferaSemDeducao.seguridade - totalReceitasDeducao.seguridade,
  };

  return {
    data: dadosFiltrados,
    sum: FormatValueToLocaleString(totalReceitas),
    totalReceitasDeducao,
    totalGeralReceitaEsfera,
  };
};
