import React from "react";
import { Container, Row } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CColImgUsuarioPagConta } from "../../PagContaPerfil/PagCadastroDeUsuario";
import {
  CCol,
  CColButtonsAcoes,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import { FormatCnpjPaste } from "../../../../utils/FormatacaoDeDados/FormatCnpjPaste";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import { RowTituloAba } from "../../PagContaPerfil/StyledComponentsContaPerfil";
import ComplementacaoClientes from "./ComplementacaoClientes";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputData from "../../../../components/Grid/InputData";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import { ReturnColorSistemasLista } from "../../../../utils/Gerais/ReturnColorSistemasLista";
import { FormatDateToBR } from "../../../../utils/FormatacaoDeDados/FormatDateToBR";
import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import { BoxSistemas, ButtonModal, DivModalContent } from "./StyledComponents";
import CheckBoxSistemas from "../../../../components/Inputs/CheckBoxSistemas";
import { SplitArrayIntoChunks } from "../../../../utils/Gerais/SplitArrayIntoChunks";
import useAbas from "./hooks/useAbas";
import useAutoCompletes from "./hooks/useAutoCompletes";
import useCliente from "./hooks/useCliente";
import useGestao from "./hooks/useGestao";
import useSistemas from "./hooks/useSistemas";
import useApi from "./hooks/useApi";
import useFormatData from "./hooks/useFormatData";
import useImagemBrasao from "./hooks/useImagemBrasao";
import useUtils from "./hooks/useUtils";
import IconDelete from "../../../../components/ListaDeInputs/IconDelete";
import ModalComponent from "../../../../components/Modais/ModalComponent/ModalComponent";
import { IconAlertModal } from "../../../../styles/StyledComponents/ModalStyled";
import DivCentralizeIcon from "../../../../components/ListaDeInputs/DivCentralizeIcon";

const selectTipoNatJurValues = [
  { value: "", label: "Natureza Juridica" },
  { value: "1 - Câmara Municipal" },
  { value: "2 - Prefeitura Municipal/Secretarias" },
  { value: "3 - Autarquia" },
  { value: "4 - Fundação" },
  { value: "5 - Sociedade de Economia Mista" },
  { value: "6 - Fundos" },
  { value: "7 - Empresas Públicas" },
  { value: "8 - Autarquia Previdenciária" },
  { value: "9 - Fundo Previdenciário" },
];

// const GestaoAtual = ({
//   verificaGestaoAtual,
//   optionsCpfs,
//   handleCheckSystems,
//   gestoes,
// }) => {
//   const gestaoAtual = verificaGestaoAtual(gestoes);

//   return (
//     <>
//       <RowAninhada>
//         <CCol>
//           <PTitulosInputs>Gestão Atual</PTitulosInputs>
//         </CCol>
//       </RowAninhada>

//       <RowAninhada>
//         {gestaoAtual && (
//           <React.Fragment>
//             <RowAninhada>
//               <CCol md={7}>
//                 <FormControlListaDeInputs
//                   disabled
//                   $border="none"
//                   value={`${
//                     optionsCpfs.find((cpf) => cpf.id === gestaoAtual.pessoaId)
//                       ?.nome
//                   } - CPF ${
//                     optionsCpfs.find((cpf) => cpf.id === gestaoAtual.pessoaId)
//                       ?.cpf
//                   } [${FormatDateToBR(
//                     gestaoAtual.dataInicial
//                   )} - ${FormatDateToBR(gestaoAtual.dataFim)}]`}
//                 />
//               </CCol>
//               <CCol>
//                 <InputData
//                   disabled
//                   height="30px"
//                   value={gestaoAtual.dataInicial}
//                 />
//               </CCol>
//               <CCol>
//                 <InputData height="30px" value={gestaoAtual.dataFim} />
//               </CCol>
//               <CColButtonsAcoes md={1}></CColButtonsAcoes>
//             </RowAninhada>

//             <RowAninhada style={{ marginTop: "15px" }}>
//               <CCol md={12}>
//                 <BoxSistemas>
//                   <RowAninhada>
//                     {SplitArrayIntoChunks(gestaoAtual.modulos, 3).map(
//                       (item, index) => (
//                         <CCol md={2} key={index} style={{ paddingLeft: "0px" }}>
//                           {item.map((subItem, indexSubItem) => (
//                             <CheckBoxSistemas
//                               key={indexSubItem}
//                               $backgroundColor={ReturnColorSistemasLista(
//                                 subItem.sistema
//                               )}
//                               value={subItem.sistema}
//                               checked={subItem.checked}
//                               onChange={(e) =>
//                                 handleCheckSystems(e.target.value)
//                               }
//                               style={
//                                 indexSubItem > 0
//                                   ? { marginTop: "5px" }
//                                   : undefined
//                               }
//                               $opacity={subItem.checked ? "1" : "0.7"}
//                               label={subItem.sistema}
//                             />
//                           ))}
//                         </CCol>
//                       )
//                     )}
//                   </RowAninhada>
//                 </BoxSistemas>
//               </CCol>
//             </RowAninhada>
//           </React.Fragment>
//         )}
//       </RowAninhada>
//     </>
//   );
// };

// const GestoesAnteriores = ({
//   verificaGestaoAtual,
//   gestoes,
//   optionsCpfs,
//   handleCheckSystems,
//   returnGestoesAnteriores,
// }) => {
//   const gestaoAtual = verificaGestaoAtual(gestoes);
//   const gestoesAnteriores = returnGestoesAnteriores(gestaoAtual, gestoes);
//   const [showGestoes, setShowGestoes] = useState(true);

//   const handleShowGestoes = () => {
//     setShowGestoes((prev) => !prev);
//   };

//   return (
//     <>
//       {gestoesAnteriores.length > 0 && (
//         <RowAninhada style={{ marginTop: "15px" }}>
//           <CCol>
//             <PInfosWithArrow
//               $backgroundColor={"#515c70"}
//               style={{ cursor: "pointer" }}
//               onClick={handleShowGestoes}
//             >
//               Gestões anteriores
//             </PInfosWithArrow>
//           </CCol>
//         </RowAninhada>
//       )}

//       {showGestoes &&
//         gestoesAnteriores.map((itemGestao, indexGestao) => (
//           <React.Fragment key={indexGestao}>
//             {/* <button onClick={() => console.log(arraySistemas)}>
//         arraysist
//       </button> */}
//             <RowAninhada style={{ marginTop: "15px" }}>
//               <CCol>
//                 <PTitulosInputs>Gestão</PTitulosInputs>
//                 <FormControlListaDeInputs
//                   disabled
//                   $border="none"
//                   value={`${
//                     optionsCpfs.find((cpf) => cpf.id === itemGestao.pessoaId)
//                       ?.nome
//                   } - CPF ${
//                     optionsCpfs.find((cpf) => cpf.id === itemGestao.pessoaId)
//                       ?.cpf
//                   } [${FormatDateToBR(
//                     itemGestao.dataInicial
//                   )} - ${FormatDateToBR(itemGestao.dataFim)}]`}
//                 />
//               </CCol>
//             </RowAninhada>

//             <RowAninhada style={{ marginTop: "15px" }}>
//               <CCol md={12}>
//                 <BoxSistemas>
//                   <RowAninhada>
//                     {SplitArrayIntoChunks(itemGestao.modulos, 3).map(
//                       (item, index) => (
//                         <CCol md={2} key={index} style={{ paddingLeft: "0px" }}>
//                           {item.map((subItem, indexSubItem) => (
//                             <CheckBoxSistemas
//                               key={indexSubItem}
//                               $backgroundColor={ReturnColorSistemasLista(
//                                 subItem.sistema
//                               )}
//                               value={subItem.sistema}
//                               checked={subItem.checked}
//                               onChange={(e) =>
//                                 handleCheckSystems(e.target.value)
//                               }
//                               style={
//                                 indexSubItem > 0
//                                   ? { marginTop: "5px" }
//                                   : undefined
//                               }
//                               $opacity={subItem.checked ? "1" : "0.7"}
//                               label={subItem.sistema}
//                             />
//                           ))}
//                         </CCol>
//                       )
//                     )}
//                   </RowAninhada>
//                 </BoxSistemas>
//               </CCol>
//             </RowAninhada>
//           </React.Fragment>
//         ))}
//     </>
//   );
// };

const Gestoes = ({
  gestoes,
  optionsCpfs,
  handleCheckSystems,
  setGestaoToDelete,
  openModalDeleteGestao,
  handleChangeGestaoDate,
  setModalDesativaGestaoOpened,
  setGestaoToDeactivate,
}) => {
  return (
    <>
      {gestoes.length > 0 && (
        <Row className="row_form_2 mt-1">
          {gestoes.map((itemGestao, indexGestao) => (
            <React.Fragment key={indexGestao}>
              {/* <button onClick={() => console.log(arraySistemas)}>
        arraysist
      </button> */}
              <RowAninhada
                style={indexGestao !== 0 ? { marginTop: "15px" } : undefined}
              >
                <CCol>
                  <FormControlListaDeInputs
                    disabled
                    $border="none"
                    value={`${
                      optionsCpfs.find((cpf) => cpf.id === itemGestao.pessoaId)
                        ?.nome
                    } - CPF ${
                      optionsCpfs.find((cpf) => cpf.id === itemGestao.pessoaId)
                        ?.cpf
                    } [${FormatDateToBR(
                      itemGestao.dataInicial
                    )} - ${FormatDateToBR(itemGestao.dataFim)}]`}
                  />
                </CCol>

                <CColButtonsAcoes md={1}>
                  <IconDelete
                    onClick={
                      itemGestao.gestaoId
                        ? () => {
                            setModalDesativaGestaoOpened(true);
                            setGestaoToDeactivate({ id: itemGestao.gestaoId });
                          }
                        : () => {
                            openModalDeleteGestao();
                            setGestaoToDelete({ index: indexGestao });
                          }
                    }
                  />
                </CColButtonsAcoes>
              </RowAninhada>

              <RowAninhada style={{ marginTop: "15px" }}>
                <CCol md={12}>
                  <BoxSistemas>
                    <RowAninhada>
                      {SplitArrayIntoChunks(itemGestao.modulos, 3).map(
                        (itemParticao, indexParticao) => (
                          <CCol
                            md={2}
                            key={indexParticao}
                            style={{ paddingLeft: "0px" }}
                          >
                            {itemParticao.map((subItem, indexSubItem) => (
                              <CheckBoxSistemas
                                key={indexSubItem}
                                $backgroundColor={
                                  subItem.checked
                                    ? ReturnColorSistemasLista(subItem.sistema)
                                    : "#B1B0B6"
                                }
                                value={subItem.sistema}
                                checked={subItem.checked}
                                onChange={(e) =>
                                  handleCheckSystems(
                                    indexGestao,
                                    e.target.value
                                  )
                                }
                                style={
                                  indexSubItem > 0
                                    ? { marginTop: "5px" }
                                    : undefined
                                }
                                // $opacity={subItem.checked ? "1" : "0.7"}
                                label={subItem.sistema}
                              />
                            ))}
                          </CCol>
                        )
                      )}
                    </RowAninhada>
                  </BoxSistemas>
                </CCol>
              </RowAninhada>
            </React.Fragment>
          ))}
        </Row>
      )}
    </>
  );
};

const ClientesCadastro = ({ sistema }) => {
  const { id: clienteIdParams } = useParams();

  const { verificaGestaoAtual, verificaSistemaAtivo } = useUtils();

  const { arrayDeAbas, abaSelecionada, setAbaSelecionada } = useAbas({
    sistema,
  });

  const { valuesCliente, tipoNatJur, setTipoNatJur, setValuesCliente } =
    useCliente();

  const { arraySistemas, handleCheckSystems } = useSistemas({
    setValuesCliente,
  });

  const {
    arrayGestoes,
    setGestaoAdicionar,
    adicionarNovaGestao,
    setGestaoToDelete,
    deleteGestao,
    adicionarPessoaNaGestao,
    closeModalDeleteGestao,
    openModalDeleteGestao,
    modalDeleteGestaoOpened,
    setArrayGestoes,
    handleChangeGestaoDate,
    modalDesativaGestaoOpened,
    setModalDesativaGestaoOpened,
    gestaoToDeactivate,
    setGestaoToDeactivate,
    gestaoAdicionar,
  } = useGestao(arraySistemas, setValuesCliente, valuesCliente);

  const { adicionaImagemDoBrasao } = useImagemBrasao(setValuesCliente);

  const { formatArraySistemas, formatObjectToPost } = useFormatData(
    arraySistemas,
    arrayGestoes,
    valuesCliente,
    verificaSistemaAtivo
  );

  const {
    postValuesCliente,
    resgataDadosCnpj,
    putValuesCliente,
    desativaGestao,
  } = useApi({
    setValuesCliente,
    valuesCliente,
    setArrayGestoes,
    formatArraySistemas,
    formatObjectToPost,
    verificaGestaoAtual,
  });

  const {
    optionsUnidadesGestoras,
    optionsClientes,
    optionsCpfs,
    inputAutoCompleteCpfs,
    setInputAutoCompleteCpfs,
    inputAutoCompleteCliente,
    setInputAutoCompleteCliente,
    inputAutoCompleteUnidadeGestora,
    setInputAutoCompleteUnidadeGestora,
    handleSelectCliente,
    handleSelectUnidadeGestora,
  } = useAutoCompletes(resgataDadosCnpj);

  // const debug = () => {
  //   console.log(arrayGestoes);
  //   console.log(arraySistemas);
  //   console.log(valuesCliente);
  //   console.log(tipoNatJur);
  // };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Conta do Cliente"}
        PaginaSubtitulo={"Cadastro de Novo Cliente"}
        ButtonSaveFunction={
          clienteIdParams ? putValuesCliente : postValuesCliente
        }
        PaginaConsulta={"/acesso-interno/gerenciador-clientes/consulta"}
      />

      <ModalDeleteData
        closeModal={closeModalDeleteGestao}
        modalOpened={modalDeleteGestaoOpened}
        setDataDelete={setGestaoToDelete}
        deleteDataFunction={deleteGestao}
      />
      <ModalComponent modalOpened={modalDesativaGestaoOpened}>
        <DivModalContent>
          <IconAlertModal
            width={100}
            icon="fluent:error-circle-20-regular"
            color="red"
            hexcolor="#ff0000"
          />
          <p style={{ color: "#000", fontWeight: "600", fontSize: "23px" }}>
            Tem certeza que deseja desativar essa Gestão?
          </p>
          <p>Está ação não poderá ser desfeita.</p>

          <div>
            <ButtonModal
              $bgColor="#238c59"
              onClick={() => {
                desativaGestao(gestaoToDeactivate.id);
                setModalDesativaGestaoOpened(false);
              }}
            >
              Sim
            </ButtonModal>
            <ButtonModal
              $bgColor="red"
              onClick={() => setModalDesativaGestaoOpened(false)}
            >
              Não
            </ButtonModal>
          </div>
        </DivModalContent>
      </ModalComponent>

      <ToastContainer />
      <Row className="row_form mt-1">
        {/* <button onClick={debug}>console</button> */}
        {/* <button onClick={postImage}>post img</button> */}
        <CColImgUsuarioPagConta
          style={{
            maxHeight: "93px",
            display: "flex",
            justifyContent: "center",
          }}
          md={1}
        >
          <label style={{ cursor: "pointer" }}>
            {valuesCliente.brasao === "" && (
              <Icon
                icon="majesticons:image-line"
                height={"107px"}
                style={{ color: "#888A8E", marginTop: "-8px" }}
              />
            )}

            {valuesCliente.brasao !== "" && (
              <img
                src={`data:image/jpeg;base64,${valuesCliente.brasao}`}
                alt="pré-visualização da imagem"
                style={{ width: "100%", height: "100%" }}
              />
            )}
            <input
              type="file"
              style={{ display: "none" }}
              onChange={adicionaImagemDoBrasao}
            />
          </label>
        </CColImgUsuarioPagConta>

        <CCol
          style={{
            margin: "0px",
            padding: "0px 12px 0px 12px",
          }}
        >
          <Row>
            <CCol md={12}>
              {clienteIdParams &&
                optionsClientes.find(
                  (cliente) => cliente.id === valuesCliente.pessoaJuridicaId
                ) && (
                  <FloatingLabelInput
                    label={"Cliente"}
                    value={`${
                      optionsClientes.find(
                        (cliente) =>
                          cliente.id === valuesCliente.pessoaJuridicaId
                      ).nomeFantasia
                    } - ${
                      optionsClientes.find(
                        (cliente) =>
                          cliente.id === valuesCliente.pessoaJuridicaId
                      ).cnpj
                    }`}
                    disabled
                  />
                )}

              {clienteIdParams &&
                optionsClientes.find(
                  (cliente) => cliente.id === valuesCliente.pessoaJuridicaId
                ) === undefined && (
                  <FloatingLabelInput
                    disabled
                    value={"carregando..."}
                    label={"Cliente"}
                  />
                )}

              {!clienteIdParams && (
                <MyAutoComplete
                  inputValue={inputAutoCompleteCliente}
                  setInputValue={setInputAutoCompleteCliente}
                  labelInput="Cliente"
                  options={optionsClientes}
                  labelFormat={(option) =>
                    `${option.nomeFantasia} - ${option.cnpj}`
                  }
                  onOptionSelect={(option) => handleSelectCliente(option)}
                  style={{ height: "38px" }}
                  iconClearFunction={() => setInputAutoCompleteCliente("")}
                />
              )}
            </CCol>
          </Row>

          <Row style={{ marginTop: "16px" }}>
            <CCol md={4}>
              <FloatingLabelInput
                tipo="input_select"
                value={tipoNatJur || null}
                title={tipoNatJur}
                options={selectTipoNatJurValues}
                label="Natureza Juridica"
                onChange={(e) => {
                  setTipoNatJur(e.target.value);
                  console.log(valuesCliente);
                }}
              />
            </CCol>

            <CCol md={2}>
              <FloatingLabelInput
                disabled
                value={valuesCliente.poder}
                label="Poder*"
                placeholder="Poder*"
              />
            </CCol>
            <CCol>
              {clienteIdParams &&
                optionsUnidadesGestoras.find(
                  (unidade) => unidade.id === valuesCliente.unidadeGestoraId
                ) && (
                  <FloatingLabelInput
                    disabled
                    label={"Unidade Gestora Principal"}
                    value={`${
                      optionsUnidadesGestoras.find(
                        (unidade) =>
                          unidade.id === valuesCliente.unidadeGestoraId
                      ).nomeFantasia
                    } - ${FormatCnpjPaste(
                      optionsUnidadesGestoras.find(
                        (unidade) =>
                          unidade.id === valuesCliente.unidadeGestoraId
                      ).cnpj
                    )}`}
                  />
                )}

              {clienteIdParams &&
                !optionsUnidadesGestoras.find(
                  (unidade) => unidade.id === valuesCliente.unidadeGestoraId
                ) && (
                  <FloatingLabelInput
                    disabled
                    label={"Unidade Gestora Principal"}
                    value={"carregando..."}
                  />
                )}
              {!clienteIdParams && (
                <MyAutoComplete
                  inputValue={inputAutoCompleteUnidadeGestora}
                  setInputValue={setInputAutoCompleteUnidadeGestora}
                  labelInput="Unidade Gestora"
                  onOptionSelect={(option) =>
                    handleSelectUnidadeGestora(option)
                  }
                  options={optionsUnidadesGestoras}
                  labelFormat={(option) =>
                    `${option.nomeFantasia} - ${option.cnpj}`
                  }
                  style={{ height: "37.5px" }}
                  iconClearFunction={() =>
                    setInputAutoCompleteUnidadeGestora("")
                  }
                />
              )}
            </CCol>
          </Row>
        </CCol>
      </Row>

      <RowSelecaoAbasInferior
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
        abas={arrayDeAbas}
      />

      {abaSelecionada.nomeAba === "Sistemas" && (
        <>
          <RowTituloAba>
            <CCol>
              <span>Sistemas</span>
            </CCol>
          </RowTituloAba>

          <Row
            className={`${
              valuesCliente.gestao.length > 0 ? "row_form" : "row_form_2"
            } mt-1`}
          >
            <CCol md={7}>
              <PTitulosInputs>Gestão</PTitulosInputs>
              <MyAutoComplete
                inputValue={inputAutoCompleteCpfs}
                setInputValue={setInputAutoCompleteCpfs}
                onOptionSelect={adicionarPessoaNaGestao}
                options={optionsCpfs}
                labelFormat={(option) => `${option.nome} - ${option.cpf}`}
                style={{ height: "30px" }}
                iconClearFunction={() => setInputAutoCompleteCpfs("")}
              />
            </CCol>
            <CCol>
              <PTitulosInputs>Data início</PTitulosInputs>
              <InputData
                value={gestaoAdicionar.dataInicial}
                style={{ height: "30px" }}
                onChange={(e) =>
                  setGestaoAdicionar((prev) => ({
                    ...prev,
                    dataInicial: e.target.value,
                  }))
                }
              />
            </CCol>
            <CCol>
              <PTitulosInputs>Data Final</PTitulosInputs>
              <InputData
                style={{ height: "30px" }}
                onChange={(e) =>
                  setGestaoAdicionar((prev) => ({
                    ...prev,
                    dataFim: e.target.value,
                  }))
                }
              />
            </CCol>
            <CColButtonsAcoes md={1}>
              <PTitulosInputs>Ação</PTitulosInputs>
              <DivCentralizeIcon>
                <IconAdicionar onClick={adicionarNovaGestao} />
              </DivCentralizeIcon>
            </CColButtonsAcoes>
          </Row>
          <Gestoes
            setModalDesativaGestaoOpened={setModalDesativaGestaoOpened}
            gestoes={valuesCliente.gestao}
            openModalDeleteGestao={openModalDeleteGestao}
            setGestaoToDelete={setGestaoToDelete}
            optionsCpfs={optionsCpfs}
            handleCheckSystems={handleCheckSystems}
            handleChangeGestaoDate={handleChangeGestaoDate}
            setGestaoToDeactivate={setGestaoToDeactivate}
          />
          {/* <GestaoAtual
              verificaGestaoAtual={verificaGestaoAtual}
              optionsCpfs={optionsCpfs}
              handleCheckSystems={handleCheckSystems}
              gestoes={valuesCliente.gestao}
            /> */}

          {/* <GestoesAnteriores
              verificaGestaoAtual={verificaGestaoAtual}
              optionsCpfs={optionsCpfs}
              handleCheckSystems={handleCheckSystems}
              gestoes={valuesCliente.gestao}
              returnGestoesAnteriores={returnGestoesAnteriores}
            /> */}
        </>
      )}

      {abaSelecionada.nomeAba === "Configurações" && (
        <ComplementacaoClientes
          valuesCliente={valuesCliente}
          setValuesCliente={setValuesCliente}
        />
      )}
      <div className="p-3"></div>
    </Container>
  );
};

export default ClientesCadastro;
