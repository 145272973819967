import React, { useEffect, useContext, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import {
  StyledIcon,
  ButtonIcon,
  HeaderBodyNav,
  NavLinkButtonsPags,
} from "../../components/Header/StylesHeader";
import { Icon } from "@iconify/react";
import { StyledHeaderPag } from "../../components/Header/StylesHeader";
import { useNavigate } from "react-router-dom";
import { OpenedModalsContext } from "../../contexts/OpenedModalsContext";
import PopupInstructionsModal from "../../utils/Loading/HelpModal";

function HeaderCadastros({
  location,
  ButtonPrint,
  ButtonPDF,
  ButtonDocx,
  ButtonXls,
  ButtonSaveFunction,
  BotaoSaveAtivo,
  BotaoNovoAtivo,
  BotaoNovoFunction,
  withRow,
  optionsButton,
  OpcoesDeArquivo,
  PaginaRelatorio,
  NomePaginaTitulo,
  PaginaSubtitulo,
  PaginaConsulta,
  DashboardPage,
  ButtonVisualizar,
  RowTitle,
  PdfData = null,
  hideNavButtons = false,
}) {
  const navigate = useNavigate();
  const { openedModals } = useContext(OpenedModalsContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    console.log("OpenedModals: ", openedModals, openedModals.length);
  }, [openedModals]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.altKey &&
        openedModals.length === 0 &&
        (event.key === "s" || event.key === "S")
      ) {
        if (
          ButtonSaveFunction &&
          (BotaoSaveAtivo === undefined || BotaoSaveAtivo)
        ) {
          ButtonSaveFunction();
        }
      }
      if (
        event.altKey &&
        openedModals.length === 0 &&
        (event.key === "c" || event.key === "C")
      ) {
        if (PaginaConsulta) {
          console.log("partiu consulta");
          navigate(PaginaConsulta);
        }
      }
      if (
        event.altKey &&
        openedModals.length === 0 &&
        (event.key === "r" || event.key === "R")
      ) {
        if (PaginaRelatorio) {
          console.log("partiu relatório");
          navigate(PaginaRelatorio);
        }
      }
      if (
        event.altKey &&
        openedModals.length === 0 &&
        (event.key === "g" || event.key === "G")
      ) {
        if (DashboardPage) {
          console.log("partiu dashboard");
          navigate(DashboardPage);
        }
      }
      if (
        event.altKey &&
        openedModals.length === 0 &&
        (event.key === "n" || event.key === "N")
      ) {
        if (BotaoNovoFunction) {
          console.log("partiu novo");
          BotaoNovoFunction();
        }
      }
      if ((event.ctrlKey || event.metaKey) && event.key === "p") {
        event.preventDefault();
        ButtonVisualizar();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    ButtonSaveFunction,
    BotaoNovoFunction,
    BotaoSaveAtivo,
    BotaoNovoAtivo,
    DashboardPage,
    PaginaConsulta,
    PaginaRelatorio,
    navigate,
    openedModals.length,
    ButtonVisualizar,
  ]);

  return (
    <div>
      <PopupInstructionsModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
      />

      <Row>
        <StyledHeaderPag md={12}>
          <div className="d-flex space_between titulo_conteudo">
            <div>
              <span className="cor_letra_primaria fw-bold">
                {NomePaginaTitulo}
              </span>
              {typeof PaginaSubtitulo === "string" ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                  >
                    <g transform="rotate(180 12 12)">
                      <path
                        fill="currentColor"
                        d="M15.125 21.1L6.7 12.7q-.15-.15-.213-.325T6.425 12q0-.2.062-.375T6.7 11.3l8.425-8.425q.35-.35.875-.35t.9.375q.375.375.375.875t-.375.875L9.55 12l7.35 7.35q.35.35.35.863t-.375.887q-.375.375-.875.375t-.875-.375Z"
                      />
                    </g>
                  </svg>
                  <span className="cor_letra_secundaria">
                    {PaginaSubtitulo}
                  </span>
                </>
              ) : (
                <>
                  {PaginaSubtitulo.map((item, index) => (
                    <React.Fragment>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 24 24"
                      >
                        <g transform="rotate(180 12 12)">
                          <path
                            fill={index !== 0 ? "#9398ad" : "currentColor"}
                            d="M15.125 21.1L6.7 12.7q-.15-.15-.213-.325T6.425 12q0-.2.062-.375T6.7 11.3l8.425-8.425q.35-.35.875-.35t.9.375q.375.375.375.875t-.375.875L9.55 12l7.35 7.35q.35.35.35.863t-.375.887q-.375.375-.875.375t-.875-.375Z"
                          />
                        </g>
                      </svg>
                      <span className="cor_letra_secundaria">
                        {PaginaSubtitulo[index]}
                      </span>
                    </React.Fragment>
                  ))}
                </>
              )}
            </div>

            {!hideNavButtons && (
              <div className="header_conteudo">
                <NavLinkButtonsPags
                  $active={window.location.pathname.includes("/consulta")}
                  to={PaginaConsulta}
                  title="Alt + C"
                >
                  <StyledIcon
                    $active={window.location.pathname.includes("/consulta")}
                    width={35}
                    icon="simple-line-icons:magnifier"
                  />
                </NavLinkButtonsPags>

                <NavLinkButtonsPags
                  $active={window.location.pathname.includes("/relatorio")}
                  to={PaginaRelatorio ? PaginaRelatorio : ""}
                  title="Alt + R"
                >
                  <StyledIcon width={35} icon="simple-line-icons:notebook" />
                </NavLinkButtonsPags>

                <NavLinkButtonsPags
                  to={DashboardPage}
                  $active={window.location.pathname.includes("/dashboard")}
                  title="Alt + G"
                >
                  <StyledIcon width={35} icon="cil:chart" />
                </NavLinkButtonsPags>

                <Button
                  disabled={
                    BotaoNovoAtivo === undefined ? false : !BotaoNovoAtivo
                  }
                  onClick={BotaoNovoFunction}
                  variant="success"
                  title="Alt + N"
                >
                  Novo
                </Button>

                <Button
                  disabled={
                    BotaoSaveAtivo === undefined ? false : !BotaoSaveAtivo
                  }
                  onClick={ButtonSaveFunction}
                  variant="primary"
                  title="Alt + S"
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </StyledHeaderPag>
      </Row>

      {(withRow === undefined || withRow) && (
        <Row>
          <Col
            className="d-flex align-items-center p-2 header_body_conteudo"
            md={12}
          >
            <span
              style={{
                fontSize: "16.5px",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              {RowTitle ? RowTitle : NomePaginaTitulo}
            </span>
            <HeaderBodyNav>
              {OpcoesDeArquivo && (
                <>
                  <ButtonIcon
                    /*onClick={ButtonVisualizar}*/
                    onClick={() => {
                      ButtonPDF("newTab");
                    }}
                  >
                    <Icon width={28} icon="fluent:document-search-16-regular" />
                  </ButtonIcon>
                  <ButtonIcon
                    onClick={() => {
                      ButtonPDF("printView");
                    }}
                  >
                    <Icon width={24} icon="fa-solid:print" />
                  </ButtonIcon>
                  <ButtonIcon
                    onClick={() => {
                      ButtonPDF("download");
                    }}
                  >
                    <Icon
                      style={{ color: "red" }}
                      width={24}
                      icon="teenyicons:pdf-outline"
                    />
                  </ButtonIcon>

                  {/* {!ButtonPDF ? (
                    <PDFDownloadLink
                      document={
                        PdfData && (
                          <PdfData.PdfComponent
                            pdfContent={PdfData.pdfContent}
                          />
                        )
                      }
                      fileName={PdfData?.pdfName}
                    >
                      {({ blob, url, loading, error }) => (
                        <ButtonIcon
                          id="buttonPdf"
                          disabled={
                            loading
                          }
                        >
                          <Icon
                            style={{ color: "red" }}
                            width={24}
                            icon="teenyicons:pdf-outline"
                          />
                        </ButtonIcon>
                      )}
                    </PDFDownloadLink>
                  ) : (
                    <>
                      <PDFDownloadLink
                        style={{ display: "none" }}
                        document={
                          PdfData && (
                            <PdfData.PdfComponent
                              pdfContent={PdfData.pdfContent}
                            />
                          )
                        }
                        fileName={PdfData?.pdfName}
                      >
                        {({ blob, url, loading, error }) => (
                          <ButtonIcon
                            id="buttonPdf"
                            disabled={
                              loading 
                            }
                          >
                            <Icon
                              style={{ color: "red" }}
                              width={24}
                              icon="teenyicons:pdf-outline"
                            />
                          </ButtonIcon>
                        )}
                      </PDFDownloadLink>
                      <ButtonIcon onClick={ButtonPDF}>
                        <Icon
                          style={{ color: "red" }}
                          width={24}
                          icon="teenyicons:pdf-outline"
                        />
                      </ButtonIcon>
                    </>
                  )} */}
                  <ButtonIcon onClick={ButtonDocx}>
                    <Icon
                      style={{ color: "blue" }}
                      width={24}
                      icon="teenyicons:doc-outline"
                    />
                  </ButtonIcon>
                  <ButtonIcon onClick={ButtonXls}>
                    <Icon
                      style={{ color: "green" }}
                      width={24}
                      icon="teenyicons:xls-outline"
                    />
                  </ButtonIcon>
                </>
              )}

              {optionsButton && (
                <ButtonIcon>
                  <Icon width={24} icon="material-symbols:settings" />
                </ButtonIcon>
              )}
            </HeaderBodyNav>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default HeaderCadastros;
