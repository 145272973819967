import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import InputSvg from "../../../../components/Grid/InputSvg";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { useFetchData } from "../../../../hooks/useFetchData";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import { Link } from "react-router-dom";
import LoadingComponent from "../../../../components/AssetsComponents.jsx/LoadingComponent";
// import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

function ConsultaReceita() {
  const dataAtual = ReturnDataAtual();
  const { currentClient } = useContext(CurrentClientContext);
  const { data: dataListaNatRec, loading: loadingFetch } = useFetchData(
    `/naturezaDaReceita/por-cliente/${currentClient.clienteId}/${
      dataAtual.split("/")[2]
    }/filtro`
  );
  const [itens, setItens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [itensFiltrados, setItensFiltrados] = useState([]);

  axios.defaults.headers.common["Authorization"] = null;

  const sortArray = (a, b) => {
    const partsA = a.naturezaDaReceita.nr.split(".").map(Number);
    const partsB = b.naturezaDaReceita.nr.split(".").map(Number);

    for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
      const partA = partsA[i] || 0;
      const partB = partsB[i] || 0;

      if (partA < partB) return -1;
      if (partA > partB) return 1;
    }

    return 0;
  };

  useEffect(() => {
    if (dataListaNatRec) {
      setItens(dataListaNatRec.sort(sortArray));
      console.log(dataListaNatRec);
    }
  }, [dataListaNatRec]);

  const reverseMask = (value) => {
    let noDots = value.replace(/\./g, "");
    return noDots;
  };

  /*
  useEffect(() => {
    console.log("filtrados: ", currentFilteredItens);
    console.log("não filtrados: ", currentItens);
  });
  */

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = itens.filter((item) => {
      const itemCodigo = item.naturezaDaReceita.nr;
      return (
        item.naturezaDaReceita.especificacao
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        itemCodigo.includes(value) ||
        reverseMask(itemCodigo).includes(value)
      );
    });
    setItensFiltrados(filteredItems);
    setCurrentPage(0);
  };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = itens.slice(startIndex, endIndex);
  const currentFilteredItens = itensFiltrados.slice(startIndex, endIndex);

  const pages = Math.ceil(itens.length / itensPorPagina);
  const filteredPages = Math.ceil(itensFiltrados.length / itensPorPagina);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderOptions
        withRow={true}
        TituloPagina={"Natureza da Receita"}
        SubTituloPagina={"Consulta"}
        ToPag={"/planejamento/cadastro/natureza-da-receita"}
        DashboardPage={"/planejamento/cadastro/natureza-da-receita/dashboard"}
        PaginaRelatorio={"/planejamento/cadastro/natureza-da-receita/relatorio"}
      />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loadingFetch && (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingComponent size={50} />
          </Col>
        )}
        {!loadingFetch && (
          <Table responsive style={{ marginTop: "-10px" }}>
            <thead>
              <tr>
                <TituloTable>Código</TituloTable>
                <TituloTable>Descrição</TituloTable>
                <TituloTableAction>Ação</TituloTableAction>
              </tr>
            </thead>
            <BodyTable>
              {searchTerm
                ? currentFilteredItens.map((item, index) => (
                    <tr key={index}>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {item.naturezaDaReceita.nr}
                      </td>
                      <td
                        style={{ whiteSpace: "nowrap" }}
                        title={item.naturezaDaReceita.especificacao}
                      >
                        {item.naturezaDaReceita.especificacao.length > 150
                          ? item.naturezaDaReceita.especificacao.substring(
                              0,
                              150
                            ) + "..."
                          : item.naturezaDaReceita.especificacao}
                      </td>
                      {/* <td style={{ whiteSpace: "nowrap" }}>{"--"}</td> */}
                      {/* <td
                      title={FormatValueToLocaleString(
                        parseFloat(item.valores.valorEmpenhado)
                      )}
                    >
                      {`R$ ${FormatValueToLocaleString(
                        parseFloat(item.valores.valorEmpenhado)
                      )}`}
                    </td> */}
                      <td>
                        <Link
                          title={"editar"}
                          to={`/planejamento/cadastro/natureza-da-receita/visualizar/${item.naturezaDaReceita.nr}`}
                        >
                          <Icon icon="bx:edit" color="#5971C8" height="22" />
                        </Link>
                      </td>
                    </tr>
                  ))
                : currentItens.map((item, index) => (
                    <tr key={index}>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {item.naturezaDaReceita.nr}
                      </td>
                      <td
                        style={{ whiteSpace: "nowrap" }}
                        title={item.naturezaDaReceita.especificacao}
                      >
                        {item.naturezaDaReceita.especificacao.length > 150
                          ? item.naturezaDaReceita.especificacao.substring(
                              0,
                              150
                            ) + "..."
                          : item.naturezaDaReceita.especificacao}
                      </td>
                      {/* <td style={{ whiteSpace: "nowrap" }}>{"--"}</td> */}
                      {/* <td
                      title={FormatValueToLocaleString(
                        parseFloat(item.valores.valorEmpenhado)
                      )}
                    >
                      {`R$ ${FormatValueToLocaleString(
                        parseFloat(item.valores.valorEmpenhado)
                      )}`}
                    </td> */}
                      <td>
                        <Link
                          title={"editar"}
                          to={`/planejamento/cadastro/natureza-da-receita/visualizar/${item.naturezaDaReceita.nr}`}
                        >
                          <Icon icon="bx:edit" color="#5971C8" height="22" />
                        </Link>
                      </td>
                    </tr>
                  ))}
            </BodyTable>
          </Table>
        )}
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? itensFiltrados.length : itens.length} registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
    </Container>
  );
}

export default ConsultaReceita;
