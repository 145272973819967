import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { TituloAbaSolo } from "../../../../styles/StyledComponents/LayoutGerais";
import { CCol, CColButtonsAcoes2Icons } from "../../../../components/Grid/CCol";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Icon } from "@iconify/react/dist/iconify.js";
import { especificacoes } from "../Riscos_Fiscais/Especificacoes";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import { useFetchData } from "../../../../hooks/useFetchData";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { SpanStyledInfo } from "../../../../styles/StyledComponents/ListaDeInputs";
import { RowAninhada } from "../../../../components/Grid/CCol";

const Renuncia = ({
  arrayEspecificacoesNominal,
  setArrayEspecificacoesNominal,
  valueEspecificacao,
  setValueEspecificacao,
}) => {
  const dataAtual = ReturnDataAtual();
  const { data: dataListaNatRec } = useFetchData(
    `/naturezaDaReceita/${dataAtual.split("/")[2]}`
  );

  const [values, setValues] = useState([
    {
      1: {
        key: "1",
        data: Array(7).fill({ value: "" }),
      },
      2: {
        key: "2",
        data: Array(7).fill({ value: "" }),
      },
    },
  ]);

  const [especificacao, setEspecificacao] = useState(["Dívida Consolidada"]);
  const [optionsNatRec, setOptionsNatRec] = useState([]);
  const [inputNatRec, setInputNatRec] = useState("");
  const [editModes, setEditModes] = useState([false]);
  const [inputs, setInputs] = useState(values.map(() => ["", "", "", "", ""]));
  const [originalInputs, setOriginalInputs] = useState([]);
  const [valueSelect, setValueSelect] = useState(values.map(() => ""));

  const handleSave = (index) => {
    setEditModes((prevModes) => {
      const newModes = [...prevModes];
      newModes[index] = false;
      return newModes;
    });
  };

  const handleCancel = (index) => {
    setInputs((prevInputs) => {
      const newInputs = [...prevInputs];
      newInputs[index][0] = originalInputs[index][0];
      newInputs[index][1] = originalInputs[index][1];
      newInputs[index][2] = originalInputs[index][2];
      newInputs[index][3] = originalInputs[index][3];
      newInputs[index][4] = originalInputs[index][4];
      return newInputs;
    });
    setEditModes((prevModes) => {
      const newModes = [...prevModes];
      newModes[index] = false;
      return newModes;
    });
  };

  const adicionarEspecificacao = () => {
    if (valueEspecificacao) {
      setEspecificacao((prevValues) => [...prevValues, valueEspecificacao]);
      setValues((prevValues) => [
        ...prevValues,
        {
          1: {
            key: "1",
            data: Array(7).fill({ value: "" }),
          },
          2: {
            key: "2",
            data: Array(7).fill({ value: "" }),
          },
        },
      ]);
      setEditModes((prevModes) => [...prevModes, false]);
      setInputs((prevInputs) => [...prevInputs, ["", "", "", "", ""]]);
      setOriginalInputs((prevOriginalInputs) => [
        ...prevOriginalInputs,
        ["", "", "", "", ""],
      ]);
    }
  };

  const deleteEspecificacao = (index) => {
    setValues((prevValues) => {
      const newValue = [...prevValues];
      newValue.splice(index, 1);
      return newValue;
    });
    setEspecificacao((prevValues) => {
      const newValue = [...prevValues];
      newValue.splice(index, 1);
      return newValue;
    });
    setEditModes((prevModes) => {
      const newModes = [...prevModes];
      newModes.splice(index, 1);
      return newModes;
    });
    setInputs((prevInputs) => {
      const newInputs = [...prevInputs];
      newInputs.splice(index, 1);
      return newInputs;
    });
    setOriginalInputs((prevOriginalInputs) => {
      const newOriginalInputs = [...prevOriginalInputs];
      newOriginalInputs.splice(index, 1);
      return newOriginalInputs;
    });
  };

  useEffect(() => {
    if (dataListaNatRec) {
      const options = dataListaNatRec.map((item) => ({
        codigo: item.nr,
        titulo: item.especificacao,
        id: item.id,
      }));
      setOptionsNatRec(options);
    }
  }, [dataListaNatRec]);

  return (
    <>
      <Row className="row_form mt-1">
        <TituloAbaSolo>
          Estimativa e Compensação da Renúncia de Receita
        </TituloAbaSolo>
      </Row>
      <Row className="row_form mt-1">
        <CCol>
          <MyAutoComplete
            labelInput="Natureza da Receita"
            inputValue={inputNatRec}
            setInputValue={setInputNatRec}
            style={{ height: "37px" }}
            placeholder="Nome ou Código da natureza da receita"
            options={optionsNatRec}
            onOptionSelect={(option) => setValueEspecificacao(option.label)}
            labelFormat={(option) => `${option.codigo} - ${option.titulo}`}
            iconClearFunction={() => setValueEspecificacao("")}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAdicionar onClick={adicionarEspecificacao} />
        </CColButtonsAcoes2Icons>
      </Row>
      {values.map((value, index) => {
        const isEditing = editModes[index];

        return (
          <React.Fragment key={index}>
            <Row className="row_form mt-1">
              <CCol>
                <PTitulosInputs style={{ marginTop: "0px" }}>
                  Especificação
                </PTitulosInputs>
                <FloatingLabelInput
                  disabled
                  value={especificacao[index]}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setEspecificacao((prevValues) => {
                      const newValues = [...prevValues];
                      newValues[index] = newValue;
                      return newValues;
                    });
                  }}
                  style={{ border: "none" }}
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                <Icon
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="28"
                  onClick={() => deleteEspecificacao(index)}
                  style={{
                    cursor: "pointer",
                    marginLeft: "2px",
                    marginTop: "22px",
                  }}
                />
              </CColButtonsAcoes2Icons>
              <RowAninhada
                className="row_form mt-1"
                style={{ paddingTop: "14px" }}
              >
                <CCol>
                  <PTitulosInputs>Modalidade</PTitulosInputs>
                  <FloatingLabelInput
                    height={"30px"}
                    placeholder="Concessão de isenção em caráter não geral"
                    new={true}
                    tipo="input_select"
                    options={[...especificacoes]}
                    onSelect={(option) => {
                      setValueSelect(index, option.value);
                      console.log(valueSelect);
                    }}
                    value={valueSelect[index]}
                  />
                </CCol>
                <CCol style={{ width: "14.5%", marginTop: "-10px" }} md={1}>
                  <SpanStyledInfo
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      whiteSpace: "nowrap",
                      position: "relative",
                      overflow: "visible",
                      fontSize: "12px",
                    }}
                  >
                    2021
                  </SpanStyledInfo>
                  <FormControlListaDeInputs
                    disabled={!isEditing}
                    value={inputs[index][0]}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setInputs((prevInputs) => {
                        const newInputs = [...prevInputs];
                        newInputs[index][0] = newValue;
                        return newInputs;
                      });
                    }}
                  />
                </CCol>
                <CCol style={{ width: "14.5%", marginTop: "-10px" }} md={1}>
                  <SpanStyledInfo
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      whiteSpace: "nowrap",
                      position: "relative",
                      overflow: "visible",
                      fontSize: "12px",
                    }}
                  >
                    2022
                  </SpanStyledInfo>
                  <FormControlListaDeInputs
                    disabled={!isEditing}
                    value={inputs[index][1]}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setInputs((prevInputs) => {
                        const newInputs = [...prevInputs];
                        newInputs[index][1] = newValue;
                        return newInputs;
                      });
                    }}
                  />
                </CCol>
                <CCol style={{ width: "14.5%", marginTop: "-10px" }} md={1}>
                  <SpanStyledInfo
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      whiteSpace: "nowrap",
                      position: "relative",
                      overflow: "visible",
                      fontSize: "12px",
                    }}
                  >
                    2023
                  </SpanStyledInfo>
                  <FormControlListaDeInputs
                    disabled={!isEditing}
                    value={inputs[index][2]}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setInputs((prevInputs) => {
                        const newInputs = [...prevInputs];
                        newInputs[index][2] = newValue;
                        return newInputs;
                      });
                    }}
                  />
                </CCol>
                <CColButtonsAcoes2Icons md={1}>
                  <PTitulosInputs>Ação</PTitulosInputs>
                  <div>
                    {isEditing ? (
                      <>
                        <Icon
                          icon="fluent:save-24-filled"
                          color="#008CFF"
                          height="26"
                          onClick={() => {
                            handleSave(index);
                            setOriginalInputs((prevOriginalInputs) => {
                              const newOriginalInputs = [...prevOriginalInputs];
                              newOriginalInputs[index] = [...inputs[index]];
                              return newOriginalInputs;
                            });
                          }}
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            marginTop: "3px",
                          }}
                        />
                        <Icon
                          icon="ic:baseline-cancel"
                          height="26"
                          color="#F24E1E"
                          onClick={() => handleCancel(index)}
                          style={{
                            cursor: "pointer",
                            marginLeft: "2px",
                            marginRight: "-5px",
                            marginTop: "3px",
                          }}
                        />
                      </>
                    ) : (
                      <Icon
                        icon="bx:edit"
                        color="#5971C8"
                        height="26"
                        onClick={() => {
                          setOriginalInputs((prevOriginalInputs) => {
                            const newOriginalInputs = [...prevOriginalInputs];
                            newOriginalInputs[index] = [...inputs[index]];
                            return newOriginalInputs;
                          });
                          setEditModes((prevModes) => {
                            const newModes = [...prevModes];
                            newModes[index] = true;
                            return newModes;
                          });
                        }}
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginTop: "3px",
                        }}
                      />
                    )}
                  </div>
                </CColButtonsAcoes2Icons>
              </RowAninhada>
              <RowAninhada style={{ paddingTop: "14px" }}>
                <CCol style={{ marginTop: "-5px" }}>
                  <SpanStyledInfo
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      whiteSpace: "nowrap",
                      position: "relative",
                      overflow: "visible",
                      fontSize: "12px",
                    }}
                  >
                    Setores / Programas / Beneficiário
                  </SpanStyledInfo>
                  <FormControlListaDeInputs
                    disabled={!isEditing}
                    value={inputs[index][3]}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setInputs((prevInputs) => {
                        const newInputs = [...prevInputs];
                        newInputs[index][3] = newValue;
                        return newInputs;
                      });
                    }}
                  />
                </CCol>
                <CCol style={{ marginTop: "-5px" }}>
                  <SpanStyledInfo
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      whiteSpace: "nowrap",
                      position: "relative",
                      overflow: "visible",
                      fontSize: "12px",
                    }}
                  >
                    Compensação
                  </SpanStyledInfo>
                  <FormControlListaDeInputs
                    disabled={!isEditing}
                    value={inputs[index][4]}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setInputs((prevInputs) => {
                        const newInputs = [...prevInputs];
                        newInputs[index][4] = newValue;
                        return newInputs;
                      });
                    }}
                  />
                </CCol>
                <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
              </RowAninhada>
            </Row>
          </React.Fragment>
        );
      })}
      <button onClick={() => console.log(dataListaNatRec)}>console</button>
    </>
  );
};

export default Renuncia;
