export const especificacoes = [
  {
    label: "Demandas Judiciais - PASSIVOS CONTINGENTES",
    value: "Demandas Judiciais - PASSIVOS CONTINGENTES",
  },
  {
    label: "Dívidas em Processo de Reconhecimento- PASSIVOS CONTINGENTES",
    value: "Dívidas em Processo de Reconhecimento- PASSIVOS CONTINGENTES",
  },
  {
    label: "Avais e Garantias Concedidas- PASSIVOS CONTINGENTES",
    value: "Avais e Garantias Concedidas- PASSIVOS CONTINGENTES",
  },
  {
    label: "Assunção de Passivos- PASSIVOS CONTINGENTES",
    value: "Assunção de Passivos- PASSIVOS CONTINGENTES",
  },
  {
    label: "Assistências Diversas- PASSIVOS CONTINGENTES",
    value: "Assistências Diversas- PASSIVOS CONTINGENTES",
  },
  {
    label: "Outros Passivos Contingentes- PASSIVOS CONTINGENTES",
    value: "Outros Passivos Contingentes- PASSIVOS CONTINGENTES",
  },
  {
    label: "Frustração de Arrecadação - DEMAIS RISCOS FISCAIS PASSIVOS",
    value: "Frustração de Arrecadação - DEMAIS RISCOS FISCAIS PASSIVOS",
  },
  {
    label: "Restituição de Tributos a Maior - DEMAIS RISCOS FISCAIS PASSIVOS",
    value: "Restituição de Tributos a Maior - DEMAIS RISCOS FISCAIS PASSIVOS",
  },
  {
    label: "Discrepância de Projeções:- DEMAIS RISCOS FISCAIS PASSIVOS",
    value: "Discrepância de Projeções:- DEMAIS RISCOS FISCAIS PASSIVOS",
  },
  {
    label: "Outros Riscos Fiscais - DEMAIS RISCOS FISCAIS PASSIVOS",
    value: "Outros Riscos Fiscais - DEMAIS RISCOS FISCAIS PASSIVOS",
  },
];
