import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { TituloAbaSolo } from "../../../../styles/StyledComponents/LayoutGerais";
import { CCol, CColButtonsAcoes2Icons } from "../../../../components/Grid/CCol";
import { PTitulosInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import Section from "./section";

const PatrimonioLiquido = () => {
  const [values1, setValues1] = useState({
    values1: Array(6).fill({ value: "" }),
    values2: Array(6).fill({ value: "" }),
    values3: Array(6).fill({ value: "" }),
  });
  const [values2, setValues2] = useState({
    values1: Array(6).fill({ value: "" }),
    values2: Array(6).fill({ value: "" }),
    values3: Array(6).fill({ value: "" }),
  });

  const md = [2, 1, 2, 1, 2, 1];

  const tempTitulo = [
    { titulo: "2022" },
    { titulo: "%" },
    { titulo: "2021" },
    { titulo: "%" },
    { titulo: "2020" },
    { titulo: "%" },
  ];

  const sections1 = [
    {
      title: "Patrimônio / Capital",
      key: "values1",
      showActionTitle: true,
      position: 1,
      tempTitulo: tempTitulo,
    },
    { title: "Reservas", key: "values2" },
    { title: "Resultado Acumulado", key: "values3", position: 2 },
  ];
  const sections2 = [
    {
      title: "Patrimônio / Capital",
      key: "values1",
      showActionTitle: true,
      position: 1,
      tempTitulo: tempTitulo,
    },
    { title: "Reservas", key: "values2" },
    { title: "Lucro ou Prejuízo Acumulado", key: "values3", position: 2 },
  ];

  const fillValuesTest = {
    values1: {
      values1: [
        { value: "500" },
        { value: "80%" },
        { value: "300" },
        { value: "70%" },
        { value: "240" },
        { value: "25%" },
      ],
      values2: [
        { value: "250" },
        { value: "20%" },
        { value: "400" },
        { value: "30%" },
        { value: "800" },
        { value: "75%" },
      ],
      values3: [
        { value: "100" },
        { value: "10%" },
        { value: "200" },
        { value: "15%" },
        { value: "400" },
        { value: "45%" },
      ],
    },
    values2: {
      values1: [
        { value: "600" },
        { value: "50%" },
        { value: "700" },
        { value: "60%" },
        { value: "900" },
        { value: "55%" },
      ],
      values2: [
        { value: "150" },
        { value: "10%" },
        { value: "200" },
        { value: "15%" },
        { value: "1200" },
        { value: "80%" },
      ],
      values3: [
        { value: "350" },
        { value: "40%" },
        { value: "100" },
        { value: "35%" },
        { value: "600" },
        { value: "65%" },
      ],
    },
  };

  useEffect(() => {
    setValues1(fillValuesTest.values1);
    setValues2(fillValuesTest.values2);
    console.log("Entrei no set do Initial Values");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row className="row_form mt-1">
        <TituloAbaSolo>Patrimônio Líquido</TituloAbaSolo>
      </Row>

      <Row className="row_form mt-1">
        <CCol>
          <PTitulosInputs>Especificação</PTitulosInputs>
          <FloatingLabelInput
            disabled
            value={"Patrimônio"}
            style={{ border: "none" }}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
        {sections1.map((section, index) => (
          <Section
            key={section.key}
            title={section.title}
            values={values1[section.key]}
            setValues={(newValues) =>
              setValues1((prev) => ({ ...prev, [section.key]: newValues }))
            }
            tempTitulo={section.tempTitulo}
            showActionTitle={section.showActionTitle}
            position={section.position}
            md={md}
          />
        ))}
      </Row>

      <Row className="row_form mt-1">
        <CCol>
          <PTitulosInputs>Especificação</PTitulosInputs>
          <FloatingLabelInput
            disabled
            value={"Patrimônio (REGIME PREVIDENCIÁRIO)"}
            style={{ border: "none" }}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
        {sections2.map((section, index) => (
          <Section
            key={section.key}
            title={section.title}
            values={values2[section.key]}
            setValues={(newValues) =>
              setValues2((prev) => ({ ...prev, [section.key]: newValues }))
            }
            tempTitulo={section.tempTitulo}
            showActionTitle={section.showActionTitle}
            position={section.position}
            md={md}
          />
        ))}
      </Row>
    </>
  );
};

export default PatrimonioLiquido;
