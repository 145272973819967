import React, { useState } from "react";
import { ContainerToModal, RowToModal } from "../../../../components/Grid/CCol";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import {
  ButtonOptionsFirst,
  ButtonOptionsLast,
  ButtonOptionsPages,
  ColButtons,
} from "../../../../styles/StyledComponents/ButtonsSelectPage";

import ContaBancariaComponent from "./ContaBancariaComponent";
import Agencia from "./Agencia";

const ContaBancariaPag = () => {
  const [abaPrincipalSelecionada, setAbaPrincipalSelecionada] = useState(0);
  const [abaSecundariaSelecionada, setAbaSecundariaSelecionada] = useState(0);

  return (
    <ContainerToModal
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        withRow={false}
        NomePaginaTitulo={
          abaPrincipalSelecionada === 0 ? "Conta Bancária" : "Agência"
        }
        PaginaSubtitulo={"Cadastro"}
      />

      <RowToModal>
        <ColButtons md={12}>
          <ButtonOptionsPages>
            <ButtonOptionsFirst
              ativo={abaPrincipalSelecionada === 0}
              onClick={() => setAbaPrincipalSelecionada(0)}
            >
              Conta Bancária
            </ButtonOptionsFirst>
            <ButtonOptionsLast
              ativo={abaPrincipalSelecionada === 1}
              onClick={() => setAbaPrincipalSelecionada(1)}
            >
              Agência
            </ButtonOptionsLast>
          </ButtonOptionsPages>
        </ColButtons>
      </RowToModal>

      {abaPrincipalSelecionada === 0 && (
        <ContaBancariaComponent
          abaSecundariaSelecionada={abaSecundariaSelecionada}
          setAbaSecundariaSelecionada={setAbaSecundariaSelecionada}
        />
      )}

      {abaPrincipalSelecionada === 1 && <Agencia />}
    </ContainerToModal>
  );
};

export default ContaBancariaPag;
