import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { useFetchData } from "../../../../hooks/useFetchData";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol } from "../../../../components/Grid/CCol";
import Dashboard from "../../../../components/ComponentesDeDashboard/Dashboard";

export const DashboardEixoEstrategico = () => {
  const { currentClient } = useContext(CurrentClientContext);
  const { data: dataEixoEstrategico } = useFetchData(
    `eixoEstrategicoPlanejamento/cliente/${currentClient.clienteId}/filtro?filter=`
  );
  const [quantidadeEixoEstrategico, setQuantidadeEixoEstrategico] =
    useState("0");
  const [box1, setBox1] = useState("0");
  const navigate = useNavigate();
  const options = [
    { label: "Quantidade", value: null },
    { label: "Quantidade", value: 1 },
  ];

  useEffect(() => {
    if (dataEixoEstrategico) {
      setQuantidadeEixoEstrategico(
        dataEixoEstrategico.length < 10
          ? "0" + dataEixoEstrategico.length
          : dataEixoEstrategico.length
      );
    }
  }, [dataEixoEstrategico]);

  useEffect(() => {
    if (quantidadeEixoEstrategico > 0) {
      setBox1({
        boxTitle: "Quantidade",
        boxSubtitle: "Eixo Estratégico",
        boxValue: quantidadeEixoEstrategico,
      });
    }
  }, [quantidadeEixoEstrategico]);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Eixo Estratégico"}
        PaginaSubtitulo={"Dashboard"}
        PaginaConsulta={"/planejamento/cadastro/eixo-estrategico/consulta"}
        BotaoNovoFunction={() =>
          navigate("/planejamento/cadastro/eixo-estrategico")
        }
        BotaoSaveAtivo={false}
      />
      <Row className="row_form_2 mt-1" style={{ padding: "20px 10px" }}>
        <CCol>
          <Dashboard box1={box1} options={options} />
        </CCol>
        <CCol>
          <Dashboard />
        </CCol>
      </Row>
      <div className="p-3"></div>
    </Container>
  );
};

export default DashboardEixoEstrategico;
