import React, { useContext, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import HeaderCadastros from "../../../components/HeaderCadastros/HeaderCadastros";
import FloatingLabelInput from "../../../components/Grid/FloatingLabelInput";
import { CCol } from "../../../components/Grid/CCol";
import SearchIcon from "@mui/icons-material/Search";
import InputData from "../../../components/Grid/InputData";
import {
  CColAbaInferior,
  OptionAbaInferior,
  RowAbaInferior,
} from "../../../styles/StyledComponents/RowsPagEstrutura";
import EstruturaPcasp from "./EstruturaPcasp";
import { toast, ToastContainer } from "react-toastify";
import PlanoDeContasPcasp from "./PlanoDeContasPcasp";
import { CurrentClientContext } from "../../../contexts/CurrentClientContext";
import { FormatContaPCASP } from "../../../utils/FormatacaoDeDados/FormatContaPCASP";
import { contaPCASPAutoPreencher } from "../../../utils/FormatacaoDeDados/ContaPCASPAutoPreencher";
import { ReturnDataAtual } from "../../../utils/Gerais/ReturnDataAtual";
import api from "../../../utils/api";

const PCASP = () => {
  const dataAtual = ReturnDataAtual();
  const ano = dataAtual.split("/")[2];
  const [abaSelecionada, setAbaSelecionada] = useState(0);
  const [teclaPressionada, setTeclaPressionada] = useState(0);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const { currentClient } = useContext(CurrentClientContext);
  const [codigoConta, setCodigoConta] = useState("");

  const [valuesPCASP, setValuesPCASP] = useState({
    conta: "",
    titulo: "",
    funcao: "",
    naturezaDeSaldo: "",

    status: "",
    nivelDetalhado: "",

    idIc: "",
    infoComplementar: "",
    ano: "",
  });

  const [arrayEstruturaDaConta, setArrayEstruturaDaConta] = useState([]);

  const nivelContaEstrutura = () => {
    let nivelArray = arrayEstruturaDaConta.length;
    let nivelConta = "";

    switch (nivelArray) {
      case 0:
        nivelConta = "1º Nível - Classe";
        break;
      case 1:
        nivelConta = "2º Nível - Grupo";
        break;
      case 2:
        nivelConta = "3º Nível - Subgrupo";
        break;
      case 3:
        nivelConta = "4º Nível - Título";
        break;
      case 4:
        nivelConta = "5º Nível - Subtítulo";
        break;
      case 5:
        nivelConta = "6º Nível - Item";
        break;
      case 6:
        nivelConta = "7º Nível - Subitem";
        break;
      default:
        nivelConta = "";
        break;
    }

    return nivelConta;
  };

  const naturezaDaContaEstrutura = (valorNivel) => {
    if (valorNivel >= 1 && valorNivel <= 4) {
      return "Patrimonial";
    } else if (valorNivel >= 5 && valorNivel <= 6) {
      return "Orçamentária";
    } else {
      return "Controle";
    }
  };

  const fetchContaFilter = () => {
    setLoadingFetch(true);
    console.log("essas");
    const valorFormated = contaPCASPAutoPreencher(codigoConta);

    api
      .get(
        `pcasp/${currentClient.clienteId}/ano/${ano}/filtro?filter=${valorFormated}`
      )
      .then((resp) => {
        const data = resp.data.pcasp;
        console.log(data);

        if (data.length === 0) {
          throw new Error("Array vazio");
        }

        if (data.length === 1) {
          if (
            !arrayEstruturaDaConta.some((item) => item.conta === valorFormated)
          ) {
            console.log("entrou no if");
            const infosNivelConta = nivelContaEstrutura();
            const novoArrayDeEstrutura = [
              ...arrayEstruturaDaConta,
              {
                nivel: infosNivelConta,
                natureza: naturezaDaContaEstrutura(valorFormated[0]),
                conta: data[0].conta,
                titulo: data[0].titulo,
                funcao: data[0].funcao,
                naturezaDeSaldo: data[0].naturezaDeSaldo,
                status: data[0].status,
                nivelDetalhado: data[0].nivelDetalhado,
                idIc: data[0].idIc,
                infoComplementar: data[0].infoComplementar,
                ano: ano,
                //
              },
            ];

            setArrayEstruturaDaConta(novoArrayDeEstrutura);

            setValuesPCASP((prevValues) => ({
              ...prevValues,
              conta: data[0].conta,
              titulo: data[0].titulo,
              funcao: data[0].funcao,
              naturezaDeSaldo: data[0].naturezaDeSaldo,
              status: data[0].status,
              nivelDetalhado: data[0].nivelDetalhado,
              idIc: data[0].idIc,
              infoComplementar: data[0].infoComplementar,
              ano: ano,
              //----------------
            }));
          } else {
            console.log("entrou no else");
            setValuesPCASP((prevValues) => ({
              ...prevValues,
              conta: data[0].conta,
              titulo: data[0].titulo,
              funcao: data[0].funcao,
              naturezaDeSaldo: data[0].naturezaDeSaldo,
              status: data[0].status,
              nivelDetalhado: data[0].nivelDetalhado,
              idIc: data[0].idIc,
              infoComplementar: data[0].infoComplementar,
              ano: ano,
            }));
          }
        }
        setLoadingFetch(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.message === "Array vazio") {
          toast.error(
            "Não foi encontrado nenhum registro com essa combinação númerica"
          );
        } else {
          toast.error("Algo deu errado. Por favor, tente mais tarde.");
        }
        setLoadingFetch(false);
      });
  };

  const limpaValuesPcasp = () => {
    setValuesPCASP((prevValues) => ({
      ...prevValues,
      conta: "",
      titulo: "",
      funcao: "",
      naturezaDeSaldo: "",
      status: "",
      nivelDetalhado: "",
      idIc: "",
      infoComplementar: "",
      ano: "",
    }));
  };

  useEffect(() => {
    console.log("effect");
    if (codigoConta.length > 0) {
      fetchContaFilter();
    }

    if (codigoConta.length === 0) {
      limpaValuesPcasp();
    }
    //eslint-disable-next-line
  }, [codigoConta]);

  const deletaItemEstrutura = () => {
    console.log(`valor que o caba tem que deletar: ${codigoConta}`);
    let novoArray = [...arrayEstruturaDaConta];
    const contaComparativa = contaPCASPAutoPreencher(codigoConta);
    if (novoArray.some((item) => item.conta === contaComparativa)) {
      novoArray.pop();
      console.log(novoArray);
      setArrayEstruturaDaConta(novoArray);
    } else {
      console.log("esse dado nn existe no array");
    }
  };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"PCASP"}
        PaginaSubtitulo={"Plano de Contas Aplicado ao Setor Público"}
        PaginaConsulta={"/planejamento/pcasp/consulta"}
      />
      <ToastContainer />
      {/* <button onClick={() => console.log(arrayEstruturaDaConta)}>
        console
      </button> */}
      <Row className="row_form mt-1">
        <button onClick={() => console.log(valuesPCASP)}>console</button>
        <CCol md={3}>
          <SearchIcon
            sx={{
              marginTop: "8px",
              marginLeft: "10px",
              position: "absolute",
              color: "#515C70",
            }}
          />
          <FloatingLabelInput
            style={{ paddingLeft: "37px" }}
            label="Conta"
            readOnly={loadingFetch ? true : false}
            placeholder="Conta"
            onKeyDown={(e) => {
              setTeclaPressionada(e.keyCode);
              if (
                e.keyCode === 8 &&
                e.target.selectionStart < e.target.value.length
              ) {
                e.preventDefault(); // Impede a exclusão
              }
              console.log(e.keyCode);
            }}
            onChange={(e) => {
              FormatContaPCASP(e.target);
              setCodigoConta(e.target.value);

              if (
                teclaPressionada === 8 &&
                e.target.selectionStart >= e.target.value.length
              ) {
                deletaItemEstrutura();
              }
            }}
          />
        </CCol>
        <CCol md={9}>
          <FloatingLabelInput
            placeholder="Título"
            label="Título"
            value={valuesPCASP.titulo}
          />
        </CCol>
      </Row>

      <Row className="row_form" style={{ marginTop: "-14px" }}>
        <CCol md={3}>
          <InputData placeholder="Ano" value={valuesPCASP.ano} />
        </CCol>

        <CCol md={9}>
          <FloatingLabelInput
            placeholder="Função"
            label="Função"
            title={valuesPCASP.funcao}
            value={valuesPCASP.funcao}
          />
        </CCol>
      </Row>

      <RowAbaInferior>
        <CColAbaInferior md={12}>
          <OptionAbaInferior
            onClick={() => {
              setAbaSelecionada(0);
            }}
            selecionado={abaSelecionada === 0}
          >
            Plano de Contas
          </OptionAbaInferior>
          <OptionAbaInferior
            onClick={() => {
              setAbaSelecionada(1);
            }}
            selecionado={abaSelecionada === 1}
          >
            Estrutura
          </OptionAbaInferior>
        </CColAbaInferior>
      </RowAbaInferior>

      {abaSelecionada === 1 && <EstruturaPcasp valuesPCASP={valuesPCASP} />}
      {abaSelecionada === 0 && (
        <PlanoDeContasPcasp arrayConta={arrayEstruturaDaConta} />
      )}
    </Container>
  );
};

export default PCASP;
