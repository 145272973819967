import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
  CCol,
  CColButtonsAcoes2Icons,
} from "../../../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../../../components/Grid/FloatingLabelInput";
import styled from "styled-components";
import { Icon } from "@iconify/react/dist/iconify.js";

const SpanStyledInfo = styled.span`
  padding: 0px;
  color: #888a8e;
  font-weight: 600;
  font-size: 14px;
  flex-grow: 1;
  display: block;
  white-space: nowrap;
  overflow-x: visible;
  transform: ${(props) => (props.rotate ? "rotate(-90deg)" : "none")};
  transform-origin: ${(props) => (props.rotate ? "left bottom" : "initial")};
  width: ${(props) => (props.rotate ? "fit-content" : "auto")};
  height: ${(props) => (props.rotate ? "fit-content" : "auto")};
  margin-left: ${(props) => (props.rotate ? "23px" : "0")};
  margin-right: ${(props) => (props.rotate ? "0" : "initial")};
  position: ${(props) => (props.rotate ? "relative" : "initial")};
  top: ${(props) => (props.rotate ? "-6px" : "0")};
`;

const ScrollableCCol = styled(Col)`
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 20px;
  align-items: center;
  position: relative;

  &::-webkit-scrollbar {
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ScrollableRow = styled(Row)`
  padding-left: 10px;
  display: flex;
  flex-wrap: nowrap;
  margin-top: ${(props) => (props.hasRotate ? "60px" : "0")};
`;

const StatusBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border: 1px solid #ccc;
  background-color: ${(props) => (props.disabled ? "#e9ecef" : "#fefeff")};
`;

const StatusBall = styled.div`
  width: ${(props) => (props.size ? props.size : "30px")};
  height: ${(props) => (props.size ? props.size : "30px")};
  border-radius: 50%;
  background-color: #43a422;
`;

const StatusInput = ({ disabled, activated, borderRadius, width }) => {
  return (
    <StatusBox disabled={disabled} style={{ borderRadius: borderRadius }}>
      {activated && (
        <StatusBall
          size={
            width
              ? parseInt(width.slice(0, -2), 10) > 50
                ? "22px"
                : `${parseInt(width.slice(0, -2), 10) - 8}px`
              : "22px"
          }
        />
      )}
    </StatusBox>
  );
};

const PCASPEstendidoMenu = () => {
  const [adicionados, setAdicionados] = useState([]);
  const [inputs, setInputs] = useState([
    { md: 1, width: "40px", label: "Status", value: "" },
    { md: 1, width: "40px", label: "Classe", value: "" },
    { md: 1, width: "40px", label: "Grupo", value: "" },
    { md: 1, width: "40px", label: "Subgrupo", value: "" },
    { md: 1, width: "40px", label: "Título", value: "" },
    { md: 1, width: "40px", label: "Subtítulo", value: "" },
    { md: 2, width: false, label: "Conta", value: "" },
    { md: 5, width: false, label: "Título", value: "" },
    { md: 3, width: false, label: "Função", value: "" },
    { md: 2, width: false, label: "InputAMais1", value: "" },
    { md: 2, width: false, label: "InputAMais2", value: "" },
    { md: 2, width: false, label: "InputAMais3", value: "" },
  ]);

  const adicionar = () => {
    const valores = inputs.map((input) => input.value);
    setAdicionados((prevValues) => [
      ...prevValues,
      { disabled: true, values: valores },
    ]);
    limparCampos();
  };

  const limparCampos = () => {
    const novosInputs = inputs.map((prevValues) => ({
      ...prevValues,
      value: "",
    }));
    setInputs(novosInputs);
  };

  const hasRotate = inputs.some((col) => parseInt(col.width, 10) < 50);

  return (
    <>
      <Row>
        <ScrollableCCol>
          <ScrollableRow hasRotate={hasRotate}>
            {inputs.map((col, index) => (
              <CCol
                key={index}
                md={col.md}
                style={{
                  padding: "0px 5px",
                  width: col.width || null,
                }}
              >
                <SpanStyledInfo rotate={parseInt(col.width, 10) < 50}>
                  {col.label}
                </SpanStyledInfo>
                {index === 0 ? (
                  <StatusInput
                    borderRadius={
                      adicionados.length === 0
                        ? "5px 5px 5px 5px"
                        : "5px 5px 0 0"
                    }
                    width={col.width}
                  />
                ) : (
                  <FloatingLabelInput
                    value={inputs[index].value}
                    onChange={(e) =>
                      setInputs((prevInputs) =>
                        prevInputs.map((input, i) =>
                          i === index
                            ? { ...input, value: e.target.value }
                            : input
                        )
                      )
                    }
                    new={col.new}
                    tipo={col.tipo}
                    placeholder={col.placeholder}
                    options={col.options}
                    height={"30px"}
                    style={{
                      borderRadius:
                        adicionados.length === 0
                          ? "5px 5px 5px 5px"
                          : "5px 5px 0 0",
                    }}
                    radiusborder={
                      adicionados.length === 0
                        ? "5px 5px 5px 5px"
                        : "5px 5px 0 0"
                    }
                  />
                )}
              </CCol>
            ))}
          </ScrollableRow>
          {adicionados.map((item, rowIndex) => (
            <ScrollableRow key={rowIndex}>
              {inputs.map((col, colIndex) => (
                <CCol
                  key={colIndex}
                  md={col.md}
                  style={{
                    padding: "0px 5px",
                    width: col.width ? col.width : null,
                  }}
                >
                  {colIndex === 0 ? (
                    <StatusInput
                      activated
                      disabled={item.disabled}
                      borderRadius={
                        rowIndex === adicionados.length - 1
                          ? "0px 0px 5px 5px"
                          : "0"
                      }
                    />
                  ) : (
                    <FloatingLabelInput
                      value={
                        item.values && item.values[colIndex] !== undefined
                          ? item.values[colIndex]
                          : ""
                      }
                      onChange={(e) =>
                        setAdicionados((prevValues) =>
                          prevValues.map((item, i) =>
                            i === rowIndex
                              ? {
                                  ...item,
                                  values: item.values.map((value, j) =>
                                    j === colIndex ? e.target.value : value
                                  ),
                                }
                              : item
                          )
                        )
                      }
                      disabled={item.disabled}
                      new={col.new}
                      tipo={col.tipo}
                      placeholder={col.placeholder}
                      options={col.options}
                      height={"30px"}
                      style={{
                        borderRadius:
                          rowIndex === adicionados.length - 1
                            ? "0px 0px 5px 5px"
                            : "0",
                      }}
                      radiusborder={
                        rowIndex === adicionados.length - 1
                          ? "0px 0px 5px 5px"
                          : "0"
                      }
                    />
                  )}
                </CCol>
              ))}
            </ScrollableRow>
          ))}
        </ScrollableCCol>
        <CColButtonsAcoes2Icons
          md={1}
          style={{ marginLeft: "15px", marginTop: "60px" }}
        >
          <SpanStyledInfo>Ação</SpanStyledInfo>
          <Icon
            icon="ic:baseline-add-box"
            color="#105200"
            height="28"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
              marginBottom: "2px",
              marginTop: "1px",
            }}
            onClick={() => adicionar()}
          />
          {adicionados.map((item, index) => (
            <Row key={index}>
              <CCol>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "9px",
                    height: "30px",
                  }}
                >
                  {item.disabled ? (
                    <Icon
                      icon="bx:edit"
                      color="#5971C8"
                      height="26"
                      onClick={() =>
                        setAdicionados((prevAdicionados) =>
                          prevAdicionados.map((item, i) =>
                            i === index
                              ? {
                                  ...item,
                                  disabled: false,
                                  oldValues: item.values,
                                }
                              : item
                          )
                        )
                      }
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <Icon
                      icon="fluent:save-24-filled"
                      height="27"
                      color="#008CFF"
                      onClick={() =>
                        setAdicionados((prevAdicionados) =>
                          prevAdicionados.map((item, i) =>
                            i === index
                              ? {
                                  ...item,
                                  disabled: true,
                                  oldValues: undefined,
                                }
                              : item
                          )
                        )
                      }
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  )}
                  {item.disabled ? (
                    <Icon
                      icon="ic:baseline-delete"
                      color="#E79900"
                      height="26"
                      onClick={() => {
                        setAdicionados((prevAdicionados) =>
                          prevAdicionados.filter((_, i) => i !== index)
                        );
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <Icon
                      icon="ic:baseline-cancel"
                      height="26"
                      color="#F24E1E"
                      onClick={() =>
                        setAdicionados((prevAdicionados) =>
                          prevAdicionados.map((item, i) =>
                            i === index
                              ? {
                                  ...item,
                                  disabled: true,
                                  values: item.oldValues,
                                  oldValues: undefined,
                                }
                              : item
                          )
                        )
                      }
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </CCol>
            </Row>
          ))}
        </CColButtonsAcoes2Icons>
      </Row>
    </>
  );
};

export default PCASPEstendidoMenu;
