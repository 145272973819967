import React from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
  RowToModal,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputData from "../../../../components/Grid/InputData";
import { OptionsStyle } from "../../../Paginas_Sistemas_Planejamento/Cadastros/FonteDeRecursos/FonteDeRecursos";
import { PTitulosInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { Icon } from "@iconify/react/dist/iconify.js";

const ContaBancariaComponent = ({
  abaSecundariaSelecionada,
  setAbaSecundariaSelecionada,
}) => {
  return (
    <>
      <RowToModal className={"row_form mt-1"}>
        <CCol md={2}>
          <FloatingLabelInput disabled label="Número" placeholder="Número" />
        </CCol>
        <CCol md={8}>
          <MyAutoComplete
            labelInput="Agência"
            placeholder="Agência"
            options={[{ label: "Teste", value: 1 }]}
            labelFormat={(option) => `${option.label} - ${option.value}`}
            style={{ height: "38px" }}
          />
        </CCol>
        <CCol>
          <InputData />
        </CCol>
      </RowToModal>
      <RowToModal className={"row_form"} style={{ marginTop: "-15px" }}>
        <CCol md={2}>
          <FloatingLabelInput
            disabled
            label="Nº Conta"
            placeholder="Nº Conta"
          />
        </CCol>
        <CCol md={1}>
          <FloatingLabelInput disabled label="D" placeholder="D" />
        </CCol>
        <CCol>
          <FloatingLabelInput disabled label="Nome" placeholder="Nome" />
        </CCol>
      </RowToModal>
      <RowToModal className={"row_form"} style={{ marginTop: "-15px" }}>
        <CCol md={9}>
          <FloatingLabelInput
            tipo="input_select"
            options={[{ label: "Tipo de Conta", value: "" }]}
            label={"Tipo de Conta"}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            options={[{ label: "Tipo de Vinculo", value: "" }]}
            label={"Tipo de Vinculo"}
          />
        </CCol>
      </RowToModal>

      <RowToModal
        style={{ backgroundColor: "#fff", borderRadius: "0px 0px 5px 5px" }}
        className={"mt-1"}
      >
        <CCol style={{ display: "flex", alignItems: "center", padding: "0px" }}>
          <OptionsStyle
            selecionado={abaSecundariaSelecionada === 0}
            onClick={() => setAbaSecundariaSelecionada(0)}
          >
            Fonte de Recursos / CO
          </OptionsStyle>
          <OptionsStyle
            selecionado={abaSecundariaSelecionada === 1}
            onClick={() => setAbaSecundariaSelecionada(1)}
          >
            Outras Informações
          </OptionsStyle>
        </CCol>
      </RowToModal>

      {abaSecundariaSelecionada === 0 && (
        <>
          <RowToModal
            className={"row_form"}
            style={{
              marginTop: "12px",
              borderRadius: "5px 5px 0px 0px",
              padding: "8px 10px",
            }}
          >
            <CCol
              style={{
                fontSize: "16px",
                color: "#515c70",
                fontWeight: "700",
              }}
            >
              Fonte de Recursos / CO
            </CCol>
          </RowToModal>

          <RowToModal className={"row_form_2 mt-1"}>
            <CCol>
              <PTitulosInputs>
                Orientações para utilização Fontes ou Destinações de Recursos -
                Exclusivas da Conta Bancária
              </PTitulosInputs>
            </CCol>
            <CCol md={2}>
              <PTitulosInputs>Valor</PTitulosInputs>
            </CCol>
            <CColButtonsAcoes2Icons md={1}>
              <PTitulosInputs>Ação</PTitulosInputs>
            </CColButtonsAcoes2Icons>

            <RowAninhada>
              <CCol>
                <MyAutoComplete
                  options={[{ label: "teste", value: 1 }]}
                  labelFormat={(option) => `${option.label} - ${option.value}`}
                />
              </CCol>
              <CCol md={2}>
                <InputFormatRealBrasileiro listaDeInputs={true} />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                <Icon
                  icon="ic:baseline-add-box"
                  color="#105200"
                  height="28"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "1px",
                  }}
                />
              </CColButtonsAcoes2Icons>
            </RowAninhada>
          </RowToModal>
        </>
      )}
    </>
  );
};

export default ContaBancariaComponent;
