import React from "react";
import { RowTituloAba } from "../../PagContaPerfil/StyledComponentsContaPerfil";
import { CCol, RowAninhada } from "../../../../components/Grid/CCol";
import { Row } from "react-bootstrap";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import { BoxSistemas } from "./StyledComponents";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";

const ComplementacaoClientes = ({ valuesCliente, setValuesCliente }) => {
  return (
    <>
      <RowTituloAba>
        <CCol>
          <span>Complementação</span>
        </CCol>
      </RowTituloAba>

      <Row className="row_form_2 mt-1">
        <CCol>
          <PTitulosInputs>Especificação</PTitulosInputs>
          <FormControlListaDeInputs disabled value={"Códigos Intituições"} />
        </CCol>

        <Row style={{ padding: "0px", margin: "0px", marginTop: "16px" }}>
          <CCol>
            <BoxSistemas>
              <RowAninhada>
                <CCol>
                  <PTitulosInputs>TCE</PTitulosInputs>
                  <FormControlListaDeInputs
                    maxLength={6}
                    value={valuesCliente.codigoTCE}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      setValuesCliente((prev) => ({
                        ...prev,
                        codigoTCE: e.target.value,
                      }));
                    }}
                  />
                </CCol>
                <CCol>
                  <PTitulosInputs>Siconfi</PTitulosInputs>
                  <FormControlListaDeInputs
                    maxLength={9}
                    value={valuesCliente.codigoSiconfi}
                    onChange={(e) => {
                      // e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      setValuesCliente((prev) => ({
                        ...prev,
                        codigoSiconfi: e.target.value,
                      }));
                    }}
                  />
                </CCol>
                <CCol>
                  <PTitulosInputs>IBGE</PTitulosInputs>
                  <FormControlListaDeInputs
                    maxLength={7}
                    value={valuesCliente.ibge}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      setValuesCliente((prev) => ({
                        ...prev,
                        ibge: e.target.value,
                      }));
                    }}
                  />
                </CCol>
                <CCol>
                  <PTitulosInputs>Poder e Orgão</PTitulosInputs>
                  <FloatingLabelInput
                    new
                    tipo="input_select"
                    height="30px"
                    value={1}
                    options={[
                      {
                        label: "10131 - Poder Executivo – Prefeitura Municipal",
                        value: 1,
                      },
                    ]}
                  />
                </CCol>
              </RowAninhada>
            </BoxSistemas>
          </CCol>
        </Row>
      </Row>
    </>
  );
};

export default ComplementacaoClientes;
