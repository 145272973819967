import { createContext, useEffect, useState } from "react";

export const CurrentCityContext = createContext();

export const CurrentCityProvider = ({ children }) => {
  const [currentCity, setCurrentCity] = useState(null);

  useEffect(() => {
    const userHistory = JSON.parse(localStorage.getItem("userHistory"));
    const regiao = userHistory?.regiao;

    if (regiao) {
      setCurrentCity({
        municipio: regiao.municipio,
        estado: regiao.estado,
      });
    }
  }, []);

  return (
    <CurrentCityContext.Provider value={{ currentCity, setCurrentCity }}>
      {children}
    </CurrentCityContext.Provider>
  );
};
