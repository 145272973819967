import React from "react";
import { useEffect, useState, useContext } from "react";
import { Container, Row } from "react-bootstrap";
import { useFetchData } from "../../../../hooks/useFetchData";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol } from "../../../../components/Grid/CCol";
import Dashboard from "../../../../components/ComponentesDeDashboard/Dashboard";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import { useNavigate } from "react-router-dom";

export const DashboardNatRec = () => {
  const navigate = useNavigate();
  const { currentClient } = useContext(CurrentClientContext);
  const { data: quantidades } = useFetchData(
    `/naturezaDaReceita/total/por-cliente/${currentClient.clienteId}/${
      ReturnDataAtual().split("/")[2]
    }`
  );
  const [boxes, setBoxes] = useState({ box1: "", box2: "", box4: "" });
  const options = [
    { label: "Quantidade", value: null },
    { label: "Quantidade", value: 1 },
  ];

  const getQuantidades = (value) => {
    if (value) {
      return value < 10 ? "0" + value : value;
    }
  };

  useEffect(() => {
    if (quantidades) {
      setBoxes((prevValues) => ({
        ...prevValues,
        box1: {
          boxTitle: "Quantidade",
          boxSubtitle: "Registros",
          boxValue: getQuantidades(quantidades.totalRegistros),
        },
      }));
    }
    if (quantidades) {
      setBoxes((prevValues) => ({
        ...prevValues,
        box2: {
          boxTitle: "Quantidade",
          boxSubtitle: "Tipos",
          boxValue: getQuantidades(quantidades.totalTipo),
        },
      }));
    }
    if (quantidades) {
      setBoxes((prevValues) => ({
        ...prevValues,
        box4: {
          boxTitle: "Quantidade",
          boxSubtitle: "Desdobramentos",
          boxValue: getQuantidades(quantidades.numeroDesdobramentos),
        },
      }));
    }
  }, [quantidades]);

  useEffect(() => {
    console.log("Boxes: ", boxes);
  }, [boxes]);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Natureza da Receita"}
        PaginaSubtitulo={"Dashboard"}
        PaginaConsulta={"/planejamento/cadastro/natureza-da-receita/consulta"}
        //PaginaRelatorio={""}
        BotaoNovoFunction={() =>
          navigate("/planejamento/cadastro/natureza-da-receita")
        }
        BotaoSaveAtivo={false}
        PaginaRelatorio={"/planejamento/cadastro/natureza-da-receita/relatorio"}
      />
      <Row className="row_form_2 mt-1" style={{ padding: "20px 10px" }}>
        <CCol>
          <Dashboard box1={boxes.box1} box2={boxes.box2} options={options} />
        </CCol>
        <CCol>
          <Dashboard box1={boxes.box4} />
        </CCol>
      </Row>
      <div className="p-3"></div>
    </Container>
  );
};

export default DashboardNatRec;
