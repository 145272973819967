import styled from "styled-components";

const InputContainer = styled.div`
  border: 1px solid #cccccc;
  padding: 6px;
  padding-left: 10px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ disable, backgroundColor }) =>
    disable ? "#e9ecef" : backgroundColor ? backgroundColor : ""};
`;

const InputImportDBStyle = styled.input`
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  width: 100%;
  font-weight: 500;
  color: #515c70;
`;

const ImportDBIcon = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='%23699BF7' d='M12 3C8.59 3 5.69 4.07 4.54 5.57l5.25 5.25c.71.11 1.43.18 2.21.18c4.42 0 8-1.79 8-4s-3.58-4-8-4M3.92 7.08L2.5 8.5L5 11H0v2h5l-2.5 2.5l1.42 1.42L8.84 12M20 9c0 2.21-3.58 4-8 4c-.66 0-1.3-.05-1.91-.13l-2.47 2.47c1.26.41 2.76.66 4.38.66c4.42 0 8-1.79 8-4m0 2c0 2.21-3.58 4-8 4c-2.28 0-4.33-.5-5.79-1.25l-1.68 1.68C5.68 19.93 8.59 21 12 21c4.42 0 8-1.79 8-4'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 23px;
  width: 24px;
  height: 24px;
`;

export const InputImportDB = ({
  handleDate,
  disable = false,
  backgroundColor,
  ...props
}) => {
  return (
    <InputContainer disable={disable} backgroundColor={backgroundColor}>
      <InputImportDBStyle
        type="text"
        onChange={(e) => handleDate(e.target.value)}
        disabled={disable}
        {...props}
      />
      <ImportDBIcon />
    </InputContainer>
  );
};
