import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Icon } from "@iconify/react";
import outlineSearch from "@iconify/icons-ic/outline-search";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import InputSvg from "../../../../components/Grid/InputSvg";
import { ToastContainer, toast } from "react-toastify";
import ButtonsAcoesListas from "../../../../styles/StyledComponents/ButtonsAcoesListas";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import api from "../../../../utils/api";

function ConsultaDiretriz() {
  const [itens, setItens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [itensFiltrados, setItensFiltrados] = useState([]);
  const [loadingLista, setLoadingLista] = useState(true);

  const { currentClient } = useContext(CurrentClientContext);
  const [modalOpened, setModalOpened] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  axios.defaults.headers.common["Authorization"] = null;

  const fetchData = () => {
    setLoadingLista(true);
    api
      .get(`diretrizPlanejamento/por-cliente/${currentClient.clienteId}`)
      .then((response) => {
        setItens(response.data);
        setLoadingLista(false);
      })
      .catch((error) =>
        toast.error(
          "Algo deu errado ao carregar a lista. Por favor, tente mais tarde"
        )
      );
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [currentClient]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = itens.filter((item) => {
      return (
        item.titulo.toLowerCase().includes(value.toLowerCase()) ||
        item.codigo.toString().includes(value) ||
        item.audiencia.toLowerCase().includes(value.toLowerCase())
      );
    });
    setItensFiltrados(filteredItems);
    setCurrentPage(0);
  };

  const deleteDiretriz = () => {
    if (currentClient.tipoNaturezaJuridica.includes("2 - Prefeitura")) {
      api
        .delete(
          `diretrizPlanejamento/cliente/${currentClient.clienteId}/delete/${deleteData.diretrizId}`
        )
        .then(() => {
          toast.success("Diretriz deletada com sucesso!");
          fetchData();
        })
        .catch((error) => {
          toast.error(
            error.response.data.error ||
              "Algo deu errado. Por favor, tente mais tarde."
          );
        });
    } else {
      toast.error("Apenas prefeituras podem deletar diretrizes");
    }
  };

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = itens.slice(startIndex, endIndex);
  const currentFilteredItens = itensFiltrados.slice(startIndex, endIndex);

  const pages = Math.ceil(itens.length / itensPorPagina);
  const filteredPages = Math.ceil(itensFiltrados.length / itensPorPagina);

  function openModal() {
    setModalOpened(true);
  }

  function closeModal() {
    setModalOpened(false);
  }
  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <ToastContainer />
      <ModalDeleteData
        closeModal={closeModal}
        modalOpened={modalOpened}
        setDataDelete={setDeleteData}
        deleteDataFunction={deleteDiretriz}
      />
      <HeaderOptions
        withRow={true}
        TituloPagina={"Diretriz"}
        SubTituloPagina={"Consulta"}
        ToPag={"/planejamento/cadastro/diretriz"}
        PaginaRelatorio={"/planejamento/cadastro/diretriz/relatorio"}
      />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loadingLista ? (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </Col>
        ) : null}
        <Table
          responsive
          style={loadingLista ? { display: "none" } : { marginTop: "-10px" }}
        >
          <thead>
            <tr>
              <TituloTable>Código</TituloTable>
              <TituloTable>Título</TituloTable>
              <TituloTable>Audiência</TituloTable>
              <TituloTable>Exercício</TituloTable>
              <TituloTableAction>Ação</TituloTableAction>
            </tr>
          </thead>
          <BodyTable>
            {searchTerm
              ? currentFilteredItens.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                    >
                      {item.codigo}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                      title={item.titulo}
                    >
                      {item.titulo.length > 59
                        ? item.titulo.substring(0, 59) + "..."
                        : item.titulo}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                      title={item.audiencia}
                    >
                      {item.audiencia.length > 28
                        ? item.audiencia.substring(0, 28) + "..."
                        : item.audiencia}
                    </td>

                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                    >
                      {item.exercicio}
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      <ButtonsAcoesListas
                        FuncaoIconDelete={() => {
                          openModal();
                          setDeleteData({ diretrizId: item.id });
                        }}
                        CaminhoPagUpdate={`/planejamento/cadastro/diretriz/visualizar/${item.id}`}
                      />
                    </td>
                  </tr>
                ))
              : currentItens.map((item, index) => (
                  <tr key={index}>
                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                    >
                      {item.codigo}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                      title={item.titulo}
                    >
                      {item.titulo.length > 59
                        ? item.titulo.substring(0, 59) + "..."
                        : item.titulo}
                    </td>
                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                      title={item.audiencia}
                    >
                      {item.audiencia.length > 28
                        ? item.audiencia.substring(0, 28) + "..."
                        : item.audiencia}
                    </td>

                    <td
                      style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                    >
                      {item.exercicio}
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      <ButtonsAcoesListas
                        FuncaoIconDelete={() => {
                          openModal();
                          setDeleteData({ diretrizId: item.id });
                        }}
                        CaminhoPagUpdate={`/planejamento/cadastro/diretriz/visualizar/${item.id}`}
                      />
                    </td>
                  </tr>
                ))}
          </BodyTable>
        </Table>
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? itensFiltrados.length : itens.length} registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default ConsultaDiretriz;
