import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";
import { NavLink } from "react-router-dom";
import ButtonDropOrHover from "./ButtonDropOrHover";
import { NavLinksMenores, TitulosNavs } from "./SideBarComponent";
import IconModulosHeader from "../SvgComponents/IconModulosHeader";

const GerenciadorNav = ({ checkSidebar }) => {
  return (
    <>
      <NavLink to={"/acesso-interno/inicio"} className={"link_direto"}>
        <Icon icon="ion:home" width={25} />
        <span style={{ marginLeft: "3px" }}>Início</span>
      </NavLink>
      <NavLink
        to={"/acesso-interno/gerenciador-clientes/consulta"}
        className={"link_direto"}
      >
        <Icon icon="ic:outline-home-work" width={25} />
        <span style={{ marginLeft: "3px" }}>Clientes</span>
      </NavLink>
      <NavLink
        to={"/acesso-interno/usuarios/consulta"}
        className={"link_direto"}
      >
        <Icon icon="mdi:user" width={25} />
        <span style={{ marginLeft: "3px" }}>Usuários</span>
      </NavLink>
      <NavLink
        to={"/acesso-interno/gerenciador-financeiro"}
        className={"link_direto"}
      >
        <Icon icon="mdi:chart-finance" width={25} />
        <span style={{ marginLeft: "3px" }}>Financeiro</span>
      </NavLink>
      <NavLink to={"/"} className={"link_direto"}>
        <IconModulosHeader
          savebot
          width={23}
          height={23}
          style={{
            transform: "translate(0px, 0px)",
            marginRight: "7px",
          }}
        />
        <span style={{ marginLeft: "3px" }}>SaveBot</span>
      </NavLink>

      <NavLink to={"/acesso-interno/master-cadastro"} className={"link_direto"}>
        <Icon
          icon="teenyicons:password-solid"
          width={25}
          style={{ marginTop: "-2px" }}
        />
        <span style={{ marginLeft: "3px" }}>Master</span>
      </NavLink>

      <TitulosNavs>Ferramentas</TitulosNavs>
      <ButtonDropOrHover
        smallerType
        checkSidebar={checkSidebar}
        tituloButton={"Cadastros"}
        iconSrc={"ic:round-addchart"}
        navOptions={[
          {
            titulo: "CPF/CNPJ",
            path: "/acesso-interno/cadastro/cadastros-cpf-cnpj/consulta",
          },
        ]}
      />
      <NavLinksMenores>
        <NavLink to={"/"} className={"link_direto"}>
          <Icon icon="tabler:file-import" width={25} />
          <span style={{ marginLeft: "3px" }}>Importar</span>
        </NavLink>
        <NavLink to={"/"} className={"link_direto"}>
          <Icon icon="tabler:file-export" width={25} />
          <span style={{ marginLeft: "3px" }}>Exportar</span>
        </NavLink>
      </NavLinksMenores>

      {/* {arrayDropdownsPrincipais.map((item, index) => (
        <ButtonDropOrHover
          key={index}
          checkSidebar={checkSidebar}
          tituloButton={item.titulo}
          navOptions={item.options}
          iconSrc={item.icon}
        />
      ))} */}
    </>
  );
};

export default GerenciadorNav;
