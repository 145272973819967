export const arrayTodosCamposPCASP = [
  { nome: "Cód. Reduzido" },
  { nome: "Conta" },
  { nome: "Conta Corrente" },
  { nome: "Titulo" },
  { nome: "Saldo Anterior" },
  { nome: "Débito" },
  { nome: "Crédito" },
  { nome: "Saldo Atual" },
  { nome: "Função" },
  { nome: "Natureza de Saldo" },
  { nome: "Status" },
  { nome: "Nivel de Detalhamento" },
  { nome: "Indicador de Superavit Financeiro" },
  { nome: "IdIc" },
  { nome: "Infos Complementares" },
];

export const arrayCamposPCASPContasCorrentes = [
  { nome: "Cód. Reduzido" },
  { nome: "Conta" },
  { nome: "Conta Corrente" },
  { nome: "Título" },
  { nome: "Saldo Anterior" },
  { nome: "Débito" },
  { nome: "Crédito" },
  { nome: "Saldo Atual" },
];

export const arrayCamposPCASPReduzido = [
  { nome: "Cód. Reduzido" },
  { nome: "Conta" },
  { nome: "Título" },
  { nome: "Saldo Anterior" },
  { nome: "Débito" },
  { nome: "Crédito" },
  { nome: "Saldo Atual" },
];
