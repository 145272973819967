import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Container } from "react-bootstrap";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { useGenerateRelatorio } from "../../../../../hooks/useGenerateRelatorio";
import { CurrentClientContext } from "../../../../../contexts/CurrentClientContext";
import { DivPrintOnly } from "../../../../../styles/StyledComponents/StylesRelatorios";
import GlobalStyles from "../../../../../styles/StyledComponents/GlobalStyles";
import HeaderCadastros from "../../../../../components/HeaderCadastros/HeaderCadastros";
import PagRelatorioComponente from "../../../../../components/ComponenteDeRelatorio/PagRelatorioComponente";
import { PdfNatDesp } from "./PdfNatDesp";
import VisualizacaoRelatorioNatDesp from "./VisualizacaoRelatorioNatDesp";
import { ReturnDataAtual } from "../../../../../utils/Gerais/ReturnDataAtual";
import { isBase64 } from "../../../../../utils/Gerais/isBase64";

const RelatorioNatDesp = () => {
  const relatorioName = "Relatório Fonte de Recurso";
  const columnWidth = [15, 22, 15, 36, 12];

  const { currentClient } = useContext(CurrentClientContext);

  const [niveis, setNiveis] = useState([]);
  const [selectBoxesOptionsAbaOptions, setSelectBoxesOptionsAbaOptions] =
    useState([
      {
        opcao: "Exibir", //"Níveis",
        checkBoxes: [
          {
            label: "Código",
            value: "codigo",
            selected: true,
          },
          { label: "Nome", value: "nome", selected: true },
          { label: "Nível", value: "nivel", selected: true },
          {
            label: "Descrição",
            value: "descricao",
            selected: true,
          },
          {
            label: "Valorizável",
            value: "valorizavel",
            selected: true,
          },
        ],
      },
    ]);
  const [selectBoxesOptionsAbaPrincipal, setSelectBoxesOptionsAbaPrincipal] =
    useState([]);

  const { data: dataListaNatDesp } = useFetchData(
    `/naturezaDaDespesa/por-cliente/${currentClient.clienteId}/${
      ReturnDataAtual().split("/")[2]
    }/filtro`
  );

  const {
    dadosRelatorio,
    dataClientInfos,
    handleGenerateDocx,
    handleGeneratePdf,
    handleGenerateXls,
    handlePrint,
    pdfData,
  } = useGenerateRelatorio(
    selectBoxesOptionsAbaOptions,
    selectBoxesOptionsAbaPrincipal,
    currentClient,
    relatorioName,
    columnWidth
  );

  const getDadosRelatorio = () => {
    const selectBox = selectBoxesOptionsAbaPrincipal?.find(
      (item) => item.opcao === "Niveis"
    );

    const selectBoxSelected = selectBox?.checkBoxes.some(
      (item) => item.selected
    );

    if (!selectBoxSelected) return [];

    const itensSelecionados = selectBox.checkBoxes
      .filter((item) => item.selected)
      .map((checkBox) => checkBox.label);

    const dadosSelecionados = dataListaNatDesp?.filter((item) =>
      itensSelecionados.includes(item.naturezaDaDespesa.nivel)
    );

    const dadosFiltrados = dadosSelecionados?.map((item) => ({
      codigo: item.naturezaDaDespesa.codigo,
      nome: item.naturezaDaDespesa.nome,
      nivel: item.naturezaDaDespesa.nivel,
      descricao: item.naturezaDaDespesa.funcao,
      valorizavel: item.naturezaDaDespesa.valorizavel,
    }));

    return dadosFiltrados;
  };

  const handleGenerateDataXls = () => {
    const dadosRelatorio = getDadosRelatorio();
    console.log("dadosRelatorio: ", dadosRelatorio);

    const dataTable = dadosRelatorio
      ?.map((item) => {
        const selectedFields = {};
        selectBoxesOptionsAbaOptions[0]?.checkBoxes?.forEach(
          ({ label, value, selected }) => {
            const actualItem = item[value];
            if (selected && !isBase64(actualItem)[0]) {
              selectedFields[label] = actualItem;
            }
          }
        );

        return Object.keys(selectedFields).length > 0 ? selectedFields : null;
      })
      .filter((item) => item);
    if (dataTable.length !== 0) {
      handleGenerateXls(dataTable);
    } else {
      toast.warn(
        "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
      );
    }
  };

  useEffect(() => {
    if (dataListaNatDesp) {
      const uniqueFuncoes = dataListaNatDesp.reduce((acc, current) => {
        const exists = acc.find(
          (item) => item.nivel === current.naturezaDaDespesa.nivel
        );
        if (!exists) {
          acc.push({
            nivel: current.naturezaDaDespesa.nivel,
            id: current.naturezaDaDespesa.id,
          });
        }
        return acc;
      }, []);
      setNiveis(uniqueFuncoes);
      console.log("uniqueFuncoes: ", uniqueFuncoes);
    }
  }, [dataListaNatDesp]);

  useEffect(() => {
    if (
      niveis.length > 0 &&
      !selectBoxesOptionsAbaPrincipal.some((item) => item.opcao === "Niveis")
    ) {
      console.log(
        "Atualizando selectBoxesOptionsAbaPrincipal com niveis: ",
        niveis
      );

      setSelectBoxesOptionsAbaPrincipal((prev) => [
        ...prev,
        {
          opcao: "Niveis",
          checkBoxes: niveis.map((item, index) => ({
            label: item.nivel,
            value: index,
            selected: false,
          })),
        },
      ]);
    }
  }, [niveis, selectBoxesOptionsAbaPrincipal]);

  return (
    <>
      <GlobalStyles orientation={"portrait"} />
      <DivPrintOnly>
        <VisualizacaoRelatorioNatDesp
          dadosRelatorio={dadosRelatorio}
          dataClientInfos={dataClientInfos}
          numeroDaPagina={1}
          totalDePaginas={2}
        />
      </DivPrintOnly>

      <Container
        id="pags_responsividade_padding_geral"
        fluid
        className="container_conteudo conteudo_pagina hide_on_print"
      >
        <HeaderCadastros
          PaginaConsulta={"/planejamento/cadastro/natureza-da-despesa/consulta"}
          NomePaginaTitulo={"Natureza da Despesa"}
          PaginaSubtitulo={"Relatório"}
          BotaoNovoAtivo={false}
          BotaoSaveAtivo={false}
          OpcoesDeArquivo={true}
          DashboardPage={"/planejamento/cadastro/natureza-da-despesa/dashboard"}
          PaginaRelatorio={
            "/planejamento/cadastro/natureza-da-despesa/relatorio"
          }
          ButtonVisualizar={() => handlePrint(getDadosRelatorio)}
          ButtonPDF={(action) =>
            handleGeneratePdf(PdfNatDesp, getDadosRelatorio, action)
          }
          ButtonDocx={() => handleGenerateDocx(getDadosRelatorio)}
          PdfData={pdfData}
          ButtonXls={handleGenerateDataXls}
        />
        <ToastContainer />

        <PagRelatorioComponente
          topoEsquerdoOptions={[
            { label: "Relatório", value: null },
            { label: "Relatório", value: 1 },
          ]}
          principalOptions={[
            {
              label: "Niveis",
              value: "Niveis",
            },
          ]}
          labelsCheckAbaPrincipal={selectBoxesOptionsAbaPrincipal}
          setLabelsCheckAbaPrincipal={setSelectBoxesOptionsAbaPrincipal}
          labelsCheckAbaOpcoes={selectBoxesOptionsAbaOptions}
          setLabelsCheckAbaOpcoes={setSelectBoxesOptionsAbaOptions}
          hasAssinatura={false}
          hasFavorite={false}
        />
      </Container>
    </>
  );
};

export default RelatorioNatDesp;
