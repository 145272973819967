import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { formatValue } from "../utils/formatValue";

export const RenderPdfReceitaDespesaCEconomica = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear
) => {
  const colNames = ["RECEITA", "DESPESA"];
  const styledCol = [
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1, textAlign: "center" },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1, textAlign: "center" },
  ];
  const styledCol2 = [
    { flexBasis: "70%", flexShrink: 1, flexGrow: 1, padding: "2px 0" },
    {
      flexBasis: "30%",
      flexShrink: 1,
      flexGrow: 1,
      padding: "2px 0",
      textAlign: "right",
    },
    { flexBasis: "70%", flexShrink: 1, flexGrow: 1, padding: "2px 0" },
    {
      flexBasis: "30%",
      flexShrink: 1,
      flexGrow: 1,
      padding: "2px 0",
      textAlign: "right",
    },
  ];
  const titles = [
    "receitas correntes",
    "deducao da receita",
    "total das receitas correntes",
    "total das despesas correntes",
    "total das receitas de capital",
    "total das despesas de capital",
    "receitas de capital",
    "despesas de capital",
    "despesas correntes",
  ];

  const bolder = ["superavit", "deficit"];

  const normalizeString = (str) => {
    if (str) {
      const lowerStr = str.toLowerCase();
      const noAccents = lowerStr
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const trimmed = noAccents.trim();
      const singleSpaced = trimmed.replace(/\s+/g, " ");

      return singleSpaced.endsWith(".")
        ? singleSpaced.slice(0, -1)
        : singleSpaced;
    }
  };

  return (
    <Page orientation="portrait" size={"A4"} style={stylesPdf.page}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={
          "Demonstração da Receita e Despesa segundo as Categorias Econômicas"
        }
        plusText={"Anexo I"}
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
      />
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
        fixed
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={stylesPdf.colunas} fixed>
        {opcoes[0]?.checkBoxes?.map(
          (each, index) =>
            each.selected && (
              <PdfColunas
                key={index}
                colName={colNames[index]}
                styledCol={styledCol[index]}
                hideBar
              />
            )
        )}
      </View>
      <View style={stylesPdf.divRelatorio}>
        <View style={{ margin: "0 -3px" }}>
          {dados.data.map((item, index) => {
            const isTitle1 = titles.includes(normalizeString(item.title1));
            const isTitle2 = titles.includes(normalizeString(item.title2));
            const isBolder1 = bolder.includes(normalizeString(item.title1));
            const isBolder2 = bolder.includes(normalizeString(item.title2));
            return (
              <React.Fragment key={index}>
                <View
                  style={[
                    stylesPdf.colunas,
                    {
                      gap: 0,
                      backgroundColor:
                        isTitle1 && isTitle2 ? "#a6a6a6" : "transparent",
                    },
                  ]}
                >
                  <View
                    style={[
                      stylesPdf.colunas,
                      {
                        flex: 1,
                        backgroundColor:
                          isTitle1 && !isTitle2 ? "#a6a6a6" : "transparent",
                        fontWeight: isTitle1 || isBolder1 ? "bold" : "normal",
                      },
                    ]}
                  >
                    {opcoes[0]?.checkBoxes[0].selected && (
                      <PdfInfos
                        pdfInfo={item.title1 || ""}
                        styledCol={[styledCol2[0]]}
                      />
                    )}
                    {opcoes[0]?.checkBoxes[0].selected && (
                      <PdfInfos
                        pdfInfo={formatValue(item.value1) || ""}
                        styledCol={[styledCol2[1]]}
                      />
                    )}
                  </View>
                  <View
                    style={[
                      stylesPdf.colunas,
                      {
                        flex: 1,
                        backgroundColor:
                          isTitle2 && !isTitle1 ? "#a6a6a6" : "transparent",
                        fontWeight: isTitle2 || isBolder2 ? "bold" : "normal",
                      },
                    ]}
                  >
                    {opcoes[0]?.checkBoxes[1].selected && (
                      <PdfInfos
                        pdfInfo={item.title2 || ""}
                        styledCol={[styledCol2[2]]}
                      />
                    )}
                    {opcoes[0]?.checkBoxes[1].selected && (
                      <PdfInfos
                        pdfInfo={formatValue(item.value2) || ""}
                        styledCol={[styledCol2[3]]}
                      />
                    )}
                  </View>
                </View>
              </React.Fragment>
            );
          })}
        </View>
        <View
          style={[
            stylesPdf.divInferiorColuna,
            {
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 10,
              textTransform: "uppercase",
              color: "white",
            },
          ]}
        >
          <Text>Resumo</Text>
        </View>
      </View>
      <View style={stylesPdf.divRelatorio}>
        <View style={{ margin: "0 -3px" }}>
          {dados.summary.map((item, index) => {
            const isTitle1 = normalizeString(item.title1) === "total";
            const isTitle2 = normalizeString(item.title2) === "total";
            return (
              <React.Fragment key={index}>
                <View
                  style={[
                    stylesPdf.colunas,
                    {
                      gap: 0,
                      backgroundColor:
                        isTitle1 && isTitle2 ? "#a6a6a6" : "transparent",
                    },
                  ]}
                >
                  <View
                    style={[
                      stylesPdf.colunas,
                      {
                        flex: 1,
                        backgroundColor:
                          isTitle1 && !isTitle2 ? "#a6a6a6" : "transparent",
                        fontWeight: isTitle1 ? "bold" : "normal",
                      },
                    ]}
                  >
                    {opcoes[0]?.checkBoxes[0].selected && (
                      <PdfInfos
                        pdfInfo={item.title1 || ""}
                        styledCol={[styledCol2[0]]}
                      />
                    )}
                    {opcoes[0]?.checkBoxes[0].selected && (
                      <PdfInfos
                        pdfInfo={formatValue(item.value1) || "00,00"}
                        styledCol={[styledCol2[1]]}
                      />
                    )}
                  </View>
                  <View
                    style={[
                      stylesPdf.colunas,
                      {
                        flex: 1,
                        backgroundColor:
                          isTitle2 && !isTitle1 ? "#a6a6a6" : "transparent",
                        fontWeight: isTitle2 ? "bold" : "normal",
                      },
                    ]}
                  >
                    {opcoes[0]?.checkBoxes[1].selected && (
                      <PdfInfos
                        pdfInfo={item.title2 || ""}
                        styledCol={[styledCol2[2]]}
                      />
                    )}
                    {opcoes[0]?.checkBoxes[1].selected && (
                      <PdfInfos
                        pdfInfo={formatValue(item.value2) || "00,00"}
                        styledCol={[styledCol2[3]]}
                      />
                    )}
                  </View>
                </View>
              </React.Fragment>
            );
          })}
        </View>
        {/* <View style={stylesPdf.divInferiorColuna}></View> */}
      </View>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
