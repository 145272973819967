import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useContext } from "react";
import styled, { css, keyframes } from "styled-components";
import { CurrentYearContext } from "../../contexts/YearContext";

const CustomSelectContainer = styled.div`
  position: relative;
  width: 90px;
  border-radius: 5px;
  margin-right: 22px;
  margin-left: 6px;
`;

const CustomSelect = styled.div`
  width: 100%;
  border-color: rgba(255, 255, 255, 1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' transform='rotate(270)' viewBox='0 0 24 24'%3E%3Cpath fill='%23515c70' d='M12.727 3.687a1 1 0 1 0-1.454-1.374l-8.5 9a1 1 0 0 0 0 1.374l8.5 9.001a1 1 0 1 0 1.454-1.373L4.875 12z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) calc(50% - 1px);
  background-size: 20px;
  border-radius: 5px;
  padding: 7px 0 0 15px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  height: 40px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  box-shadow: 0px 2px 2px #9399a4;
  font-size: 18px;
  color: #515c70;
  font-weight: 600;

  &:focus {
    outline: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

const fadeIn = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `;

const fadeOut = keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  `;

const OptionsContainer = styled.div`
  font-size: 16px;
  position: absolute;
  left: 0;
  width: 100%;
  max-height: 278px;
  overflow-y: ${({ hasScroll }) => (hasScroll ? "auto" : "hidden")};
  overflow-x: hidden;
  background-color: #fbfafb;
  box-shadow: 0px 3px 5px #bebebe;
  border-radius: 0 0 5px 5px;
  white-space: nowrap;
  z-index: 99;
  top: 50px;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 0.3s forwards;
`;

const Option = styled.div`
  color: #5f5f5f;
  padding: 5px;
  cursor: pointer;

  &:hover,
  &.selected {
    background-color: #d9d9d980;
  }
`;

const SeletorAno = forwardRef(
  (
    {
      options,
      onSelect,
      placeholder,
      value = null,
      height,
      hasScroll = true,
      onKeyDown,
      radiusborder,
      disabled = false,
      holdSelect = false,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
    const [optionChangedByMouse, setOptionChangedByMouse] = useState(false);
    const { setCurrentYear } = useContext(CurrentYearContext);

    const selectContainerRef = useRef(null);
    const selectRef = useRef(null);

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (!disabled) {
          selectRef.current.focus();
        }
      },
    }));

    useEffect(() => {
      if (options.value !== null) {
        const foundOption = options.find((option) => option.value === value);
        if (foundOption) {
          setSelectedOption(foundOption);
        }
      }
    }, [options, value]);

    const toggleOptions = () => {
      if (!disabled) {
        if (!isOpen) {
          setIsOpen(true);
          setIsVisible(true);
        } else {
          setIsVisible(false);
          setTimeout(() => setIsOpen(false), 300); // Delay to allow fadeOut animation
        }
      }
    };
    //
    const handleSelectOption = (option) => {
      if (!disabled) {
        setSelectedOption(option);
        setIsVisible(false);
        setCurrentYear(option.value);
        setTimeout(() => setIsOpen(false), 300);
        if (onSelect) {
          onSelect(option);
        }
      }
    };

    const handleClickOutside = (event) => {
      if (
        selectContainerRef.current &&
        !selectContainerRef.current.contains(event.target)
      ) {
        setIsVisible(false);
        setTimeout(() => setIsOpen(false), 300);
      }
    };

    useEffect(() => {
      const handleArrowKeys = (event) => {
        if (!disabled) {
          setOptionChangedByMouse(false);
          if (event.key === "ArrowDown") {
            setSelectedOptionIndex((prevIndex) =>
              prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex
            );
          } else if (event.key === "ArrowUp") {
            setSelectedOptionIndex((prevIndex) =>
              prevIndex > 0 ? prevIndex - 1 : prevIndex
            );
          }
        }
      };

      if (isOpen) {
        document.addEventListener("keydown", handleArrowKeys);
      }

      return () => {
        document.removeEventListener("keydown", handleArrowKeys);
      };
    }, [isOpen, options, disabled]);

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    useEffect(() => {
      if (isOpen && !optionChangedByMouse) {
        const optionElement = document.querySelector(
          `#option-${selectedOptionIndex}`
        );
        if (optionElement) {
          const container = document.querySelector("#options-container");
          const scrollOffset = optionElement.offsetTop - container.offsetTop;
          container.scrollTop = scrollOffset;
        }
      }
    }, [isOpen, selectedOptionIndex, optionChangedByMouse]);

    const handleFocus = () => {
      if (!disabled) {
        setIsOpen(true);
        setIsVisible(true);
        if (!holdSelect) {
          setSelectedOptionIndex(0);
        }
      }
    };

    const handleKeyDownInternal = (event) => {
      if (!disabled) {
        if (event.key === "Enter") {
          event.preventDefault();
          if (options[0].value === null) {
            handleSelectOption(options[selectedOptionIndex + 1]);
          } else {
            handleSelectOption(options[selectedOptionIndex]);
          }
        }
        if (onKeyDown) {
          onKeyDown(event);
        }
      }
    };

    return (
      <CustomSelectContainer ref={selectContainerRef}>
        <CustomSelect
          ref={selectRef}
          onMouseDown={toggleOptions}
          onFocus={handleFocus}
          onBlur={() => {
            if (!optionChangedByMouse) {
              setIsVisible(false);
              setTimeout(() => setIsOpen(false), 300); // Delay to allow fadeOut animation
            }
          }}
          onKeyDown={handleKeyDownInternal}
          style={{ height: height, fontSize: "15px" }}
          tabIndex={0}
          radiusborder={radiusborder}
          disabled={disabled}
        >
          {selectedOption && selectedOption !== null
            ? selectedOption.label
            : placeholder}
        </CustomSelect>
        {isOpen && (
          <OptionsContainer
            id="options-container"
            hasScroll={hasScroll}
            isVisible={isVisible}
          >
            {options
              .filter((option) => option.value !== null)
              .map((option, index) => (
                <Option
                  id={`option-${index}`}
                  title={option.label}
                  key={option.value}
                  onMouseMove={() => {
                    setSelectedOptionIndex(index);
                    setOptionChangedByMouse(true);
                  }}
                  className={index === selectedOptionIndex ? "selected" : ""}
                  onClick={() => handleSelectOption(option)}
                >
                  {option.label}
                </Option>
              ))}
          </OptionsContainer>
        )}
      </CustomSelectContainer>
    );
  }
);

export default SeletorAno;
