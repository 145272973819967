import { Font, StyleSheet } from "@react-pdf/renderer";

import RobotoRegular from "../../../assets/Fonts/Roboto/Roboto-Regular.ttf";
import RobotoMedium from "../../../assets/Fonts/Roboto/Roboto-Bold.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoRegular, fontStyle: "normal", fontWeight: "normal" },
    { src: RobotoMedium, fontStyle: "normal", fontWeight: "bold" },
  ],
});

export const stylesPdf = StyleSheet.create({
  divExternaCabecalhoRelatorio: {
    paddingBottom: "4px",
    // marginBottom: "10px",
    display: "flex",
    flexDirection: "row",
    // borderBottom: '2px' "solid" "#000",
    borderBottomColor: "#000",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    fontSize: 9,
  },
  divInternaCabecalho: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    gap: "8px",
  },
  titleCabecalho: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 11,
    color: "#212529",
    paddingTop: 5,
    marginBottom: "1px",
  },
  divImageCabecalho: {
    width: 65,
    height: 60,
    // flexBasis: '150px',
    // flexShrink: '0',
    // overflow: 'hidden',
    // marginRight: '10px',
    // backgroundColor: 'red'
  },
  img: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  divInfosCabecalho: {
    height: "100%",
    width: "88%",
    justifyContent: "flex-end",
  },
  divRelatorio: {
    display: "flex",
    flexDirection: "column",
    width: '100%'
  },
  colunas: {
    display: "flex",
    flexDirection: "row",
    // gap: 2,
  },
  coluna: {
    flexShrink: 0,
  },
  divTituloColuna: {
    backgroundColor: "#515c70",
    // padding: "16px 0px",
    justifyContent: 'center',
    height: 48
  },
  textTituloColuna: {
    color: "#fff",
    fontSize: 10,
    fontWeight: "bold",
  },
  subDivColunas: {
    backgroundColor: "#a6a6a6",
    paddingVertical: 2,
    minHeight: "12px",
    // maxHeight: '10px',
  },
  divInfosDoRelatorio: {
    // paddingRight: 4,
  },
  textInfosRelatorio: {
    fontSize: 9,
    // color: "#212529",
    color: '#000',
    paddingHorizontal: "8px",
    // paddingLeft: '4px'
  },
  divInferiorColuna: {
    minHeight: 12,
    backgroundColor: "#515c70",
    paddingHorizontal: 10,
    paddingVertical: 2,
    margin: "0 -3px",
  },
  rodapeRelatorio: {
    position: "absolute",
    bottom: 20,
    right: 20,
    width: "100%",
    fontSize: 7,
    color: "#212529",
  },
  rodapeRelatorioInfos: {
    textTransform: "uppercase",
    color: "#212529",
  },
  infosGeraisRodape: {
    flexDirection: "row",
    borderTopColor: "#000",
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    // fontFamily: 'Roboto',
    justifyContent: "space-between",
  },
  infosSave: {
    fontWeight: "bold",
    // fontFamily: 'Roboto'
  },
  container: {
    // width: '1200px',
    // transform: 'scale(0.68)',
    // marginLeft: '-175px',
    // height: '100%',
    // top: '-70px',
    // paddingRight: '20px',
    // fontFamily: 'Roboto'
  },
  containerCabecalho: {
    flexDirection: "row",
    justifyContent: "space-between",
    // width: '100%',
    color: "#212529",
  },
  page: {
    fontFamily: "Roboto",
    paddingTop: "20px",
    paddingHorizontal: "15px",
    paddingBottom: "45px",
    fontSize: 9,
  },
  textBar: {
    fontWeight: "bold",
    color: "black",
    fontSize: 7,
    textTransform: "uppercase",
    textAlign: "left",
    marginLeft: "10px",
  },
  headerInfos: {
    backgroundColor: "#515c70",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    paddingHorizontal: 4,
    textTransform: "capitalize",
  },
  barTitleInfos: {
    flexDirection: "row",
    // justifyContent: "space-between",
    alignItems: "center",
    gap: "1px",
    // fontSize: "9px",
    fontWeight: "bold",
  },
  containerTitleInfos: {
    flexDirection: "row",
    minWidth: "85%",
    maxWidth: "85%",
    width: "100%",
    gap: "16px",
    paddingVertical: "2px",
    paddingLeft: "4px",
  },
  textTotalInfos: {
    flex: "1",
    textAlign: "right",
    paddingRight: "4px",
    paddingVertical: "2px",
    // fontSize: "9px",
    textTransform: "uppercase",
  },
  containerInfosRelatorio: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2px 4px",
  },
  subTitleInfos: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginLeft: "20px",
    padding: "2px 4px",
    alignItems: "center",
  },
  textFiltro: {
    // fontSize: "9px",
    width: "100%",
    textAlign: "right",
    paddingVertical: "1px",
  },
  textCodigoQdd: {
    maxWidth: "15%",
    width: "100%",
    textAlign: "right",
    paddingHorizontal: "4px",
    paddingVertical: "2px",
  },
  textTituloQdd: {
    width: "70%",
    paddingLeft: "4px",
    paddingVertical: "2px",
  },
  textTotalQdd: {
    maxWidth: "15%",
    width: "100%",
    textAlign: "right",
    paddingRight: "4px",
    paddingVertical: "2px",
  },
});
