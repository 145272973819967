import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
  SpanStyledInfo,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { Icon } from "@iconify/react/dist/iconify.js";
import { especificacoes } from "./Especificacoes";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { ToastContainer, toast } from "react-toastify";

const RiscosFiscais = () => {
  const [arrayEspecificacoes, setArrayEspecificacoes] = useState([
    // {
    //   especificacao: "Testando",
    //   infos: [
    //     {
    //       descricao: "2224",
    //       valorDescricao: 30,
    //       providencias: "231",
    //       valorProvidencias: 32,
    //     },
    //   ],
    // },
  ]);
  const [inputSelectEspecificacao, setInputSelectEspecificacao] = useState("");

  const adicionaEspecificacao = () => {
    let newArray = [...arrayEspecificacoes];

    if (
      !newArray.some(
        (item) => item.especificacao === inputSelectEspecificacao
      ) &&
      inputSelectEspecificacao !== ""
    ) {
      newArray.push({
        especificacao: inputSelectEspecificacao,
        infos: [
          {
            descricao: "",
            valorDescricao: "0,00",
            providencias: "",
            valorProvidencias: "0,00",
          },
        ],
      });
    } else if (inputSelectEspecificacao === "") {
      toast.error("Selecione uma especificação válida para adicionar.");
    } else {
      toast.error(
        "Não é possivel adicionar a mesma especificação mais de uma vez."
      );
    }

    setArrayEspecificacoes([...newArray]);
  };

  const handleChangeCampoEspecificacao = (
    indexEspecificacao,
    indexInfo,
    nomeCampo,
    valor
  ) => {
    console.log(indexEspecificacao, indexInfo, nomeCampo, valor);

    let newArray = [...arrayEspecificacoes];

    newArray[indexEspecificacao].infos[indexInfo][nomeCampo] = valor;

    setArrayEspecificacoes([...newArray]);
  };

  const adicionaInfosEspecificacao = (indexEspecificacao) => {
    let newArray = [...arrayEspecificacoes];

    newArray[indexEspecificacao].infos.push({
      descricao: "",
      valorDescricao: "0,00",
      providencias: "",
      valorProvidencias: "0,00",
    });

    setArrayEspecificacoes([...newArray]);
  };

  const deleteInfoEspecificacao = (indexEspecificacao, indexInfo) => {
    let newArray = [...arrayEspecificacoes];

    newArray[indexEspecificacao].infos = newArray[
      indexEspecificacao
    ].infos.filter((_, index) => index !== indexInfo);

    setArrayEspecificacoes([...newArray]);
  };

  const deleteEspecificacao = (indexEspecificacao) => {
    let newArray = [...arrayEspecificacoes];

    newArray = newArray.filter((_, index) => indexEspecificacao !== index);

    setArrayEspecificacoes([...newArray]);
  };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"LDO"}
        PaginaSubtitulo={"Riscos Fiscais"}
      />
      <ToastContainer />
      <Row
        className={`${
          arrayEspecificacoes.length > 0 ? "row_form" : "row_form_2"
        } mt-1`}
      >
        {/* <button onClick={() => console.log(inputSelectEspecificacao)}>
          console
        </button> */}
        <CCol>
          <FloatingLabelInput
            new={true}
            tipo={"input_select"}
            placeholder={"Especificações"}
            label={"Especificações"}
            options={[
              { label: "Especificações", value: null },
              ...especificacoes,
            ]}
            onSelect={(option) => setInputSelectEspecificacao(option.value)}
            value={inputSelectEspecificacao}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <Icon
            icon="ic:baseline-add-box"
            color="#105200"
            width="28"
            style={{ cursor: "pointer" }}
            onClick={adicionaEspecificacao}
          />
        </CColButtonsAcoes2Icons>
      </Row>
      {arrayEspecificacoes.map(
        (especificacao, indexEspecificacao, selfArray) => (
          <Row
            className={`${
              selfArray.length - 1 === indexEspecificacao
                ? "row_form_2"
                : "row_form"
            } mt-1`}
            key={indexEspecificacao}
          >
            <RowAninhada>
              <CCol>
                <PTitulosInputs>Especificação</PTitulosInputs>
                <FloatingLabelInput
                  style={{ border: "none" }}
                  disabled
                  value={especificacao.especificacao}
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                <PTitulosInputs>Ação</PTitulosInputs>
                <Icon
                  icon="ic:baseline-add-box"
                  onClick={() => adicionaInfosEspecificacao(indexEspecificacao)}
                  color="#105200"
                  width="28"
                  style={{ marginTop: "5px", cursor: "pointer" }}
                />
                <Icon
                  icon="ic:baseline-delete"
                  style={{ marginTop: "5px", cursor: "pointer" }}
                  onClick={() => deleteEspecificacao(indexEspecificacao)}
                  color="#E79900"
                  width="28"
                />
              </CColButtonsAcoes2Icons>
            </RowAninhada>

            {especificacao.infos.map((info, indexInfo, selfArrayInfos) => (
              <React.Fragment key={indexInfo}>
                <RowAninhada style={{ marginTop: "25px" }}>
                  <CCol md={1}>
                    <SpanStyledInfo>Descrição</SpanStyledInfo>
                  </CCol>
                  <CCol>
                    <FormControlListaDeInputs
                      radiusborder={"5px 5px 0px 0px"}
                      value={info.descricao}
                      onChange={(e) =>
                        handleChangeCampoEspecificacao(
                          indexEspecificacao,
                          indexInfo,
                          "descricao",
                          e.target.value
                        )
                      }
                    />
                  </CCol>
                  <CCol md={2}>
                    <InputFormatRealBrasileiro
                      listaDeInputs={true}
                      radiusborder={"5px 5px 0px 0px"}
                      externalValue={info.valorDescricao}
                      onChange={(e, valor) =>
                        handleChangeCampoEspecificacao(
                          indexEspecificacao,
                          indexInfo,
                          "valorDescricao",
                          valor
                        )
                      }
                    />
                  </CCol>
                  <CColButtonsAcoes2Icons md={1}>
                    {selfArrayInfos.length > 1 && (
                      <Icon
                        icon="ic:baseline-delete"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          deleteInfoEspecificacao(indexEspecificacao, indexInfo)
                        }
                        color="#E79900"
                        width="28"
                      />
                    )}
                  </CColButtonsAcoes2Icons>
                </RowAninhada>
                <RowAninhada style={{ marginTop: "-1px" }}>
                  <CCol md={1}>
                    <SpanStyledInfo>Providências</SpanStyledInfo>
                  </CCol>
                  <CCol>
                    <FormControlListaDeInputs
                      radiusborder={"0px 0px 5px 5px"}
                      value={info.providencias}
                      onChange={(e, valor) =>
                        handleChangeCampoEspecificacao(
                          indexEspecificacao,
                          indexInfo,
                          "providencias",
                          e.target.value
                        )
                      }
                    />
                  </CCol>
                  <CCol md={2}>
                    <InputFormatRealBrasileiro
                      listaDeInputs={true}
                      radiusborder={"0px 0px 5px 5px"}
                      externalValue={info.valorProvidencias}
                      onChange={(e, valor) =>
                        handleChangeCampoEspecificacao(
                          indexEspecificacao,
                          indexInfo,
                          "valorProvidencias",
                          valor
                        )
                      }
                    />
                  </CCol>
                  <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
                </RowAninhada>
              </React.Fragment>
            ))}
          </Row>
        )
      )}
      <div className="p-3"></div>
    </Container>
  );
};

export default RiscosFiscais;
