import React, { useState } from "react";
import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowForm } from "../../../../components/Grid/CCol";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import styled from "styled-components";
import { FonteDeRecursosAba } from "./FonteDeRecursosAba";

const CColNumero = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

export const QDRDE = () => {
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Fonte de Recursos / CO",
    numeroAba: 0,
  });

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"QDRDE"}
        PaginaSubtitulo={"Cadastro"}
        RowTitle={"Quadro Detalhado da Receita e Despesa Extra"}
      />

      <RowForm className="mt-1">
        <CColNumero md={1}>
          <FloatingLabelInput disabled placeholder="Número" label="Número" />
        </CColNumero>
        <CCol>
          <FloatingLabelInput placeholder="Título" label="Título" />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <CCol>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Categoria da Conta - PCASP"
          />
        </CCol>
        <CCol md={3}>
          <FloatingLabelInput
            tipo="input_select"
            new
            options={[{ label: "Teste", value: "1" }]}
            placeholder="Origem do Lançamento"
          />
        </CCol>
      </RowForm>
      <RowForm style={{ marginTop: "-14px" }}>
        <FloatingLabelInput
          disabled
          value="1.1.3.8.1.08.00 - CRÉDITOS A RECEBER POR REEMBOLSO DE SALÁRIO FAMÍLIA PAGO"
        />
      </RowForm>

      <RowSelecaoAbasInferior
        abas={["Fonte de Recursos / CO", "Outras Informações"]}
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
      />

      {abaSelecionada.nomeAba === "Fonte de Recursos / CO" && (
        <FonteDeRecursosAba />
      )}
    </Container>
  );
};
