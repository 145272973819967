import api from "../../../../../../utils/api";
import { FormatValueToLocaleString } from "../../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

import { calcularTotalUnidade } from "../../../../LOA_QDD/Relatorio/calcularTotalUnidade";

export const getDespesaQdd = async (clienteId) => {
  try {
    const response = await api.get(`qdd/relatorio-qdd/${clienteId}/2024`);
    console.log("response.data", response.data);
    return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
  }
};

export const processAllData = (dataQddRelatorioCatEconOrgao) => {
  dataQddRelatorioCatEconOrgao.sort((unidadeA, unidadeB) => {
    const codigoUnidadeA = parseFloat(
      unidadeA.codigoUnidadeOrcamentaria.replace(".", "")
    );
    const codigoUnidadeB = parseFloat(
      unidadeB.codigoUnidadeOrcamentaria.replace(".", "")
    );

    return codigoUnidadeA - codigoUnidadeB;
  });

  const totalUnidades = FormatValueToLocaleString(
    calcularTotalUnidade(dataQddRelatorioCatEconOrgao)
  );

  return {
    data: dataQddRelatorioCatEconOrgao,
    sum: totalUnidades,
  };
};
