import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { CCol, CColButtonsAcoes2Icons } from "../../../../components/Grid/CCol";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import { RowInterna } from "../../../../styles/StyledComponents/LayoutGerais";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useState } from "react";
import { toast } from "react-toastify";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import api from "../../../../utils/api";

const DesdobramentoNatRec = ({
  arrayDesdobramento,
  setArrayDesdobramento,
  valuesNaturezaDaReceita,
  clienteId,
}) => {
  const ano = ReturnDataAtual().split("/")[2];

  const [openedModal, setOpenedModal] = useState(false);
  const [dataDelete, setDataDelete] = useState({
    id: null,
    codigo: "",
  });
  const [valuesDesdobramentoAdicionar, setValuesDesdobramentoAdicionar] =
    useState({
      codigo: "1",
      titulo: "",
      ano: ano,
      clienteId: clienteId,
    });

  const [itensAntesDaEdicao, setItensAntesDaEdicao] = useState([]);

  const adicionarDesdobramento = () => {
    let codigoDesdobramento;

    if (
      valuesDesdobramentoAdicionar.codigo.length > 0 &&
      valuesDesdobramentoAdicionar.codigo[0] !== "0"
    ) {
      codigoDesdobramento =
        parseInt(valuesDesdobramentoAdicionar.codigo) < 9
          ? "0" + valuesDesdobramentoAdicionar.codigo
          : valuesDesdobramentoAdicionar.codigo;
    } else if (
      valuesDesdobramentoAdicionar.codigo.length > 0 &&
      valuesDesdobramentoAdicionar.codigo[0] === "0"
    ) {
      codigoDesdobramento = valuesDesdobramentoAdicionar.codigo;
    }

    console.log(codigoDesdobramento);

    if (
      !arrayDesdobramento.some((item) => item.codigo === codigoDesdobramento) &&
      !["", "0", "00"].includes(valuesDesdobramentoAdicionar.codigo) &&
      valuesDesdobramentoAdicionar.titulo !== ""
    ) {
      setArrayDesdobramento((prev) => [
        ...prev,
        {
          ...valuesDesdobramentoAdicionar,
          naturezaId: valuesNaturezaDaReceita.naturezaDaReceitaId,
          disabled: true,
          codigo: codigoDesdobramento,
        },
      ]);
    } else if (
      arrayDesdobramento.some((item) => item.codigo === codigoDesdobramento)
    ) {
      toast.error(
        "Não é possivel adicionar o mesmo código de desdobramento mais de uma vez."
      );
    } else if (["", "0", "00"].includes(valuesDesdobramentoAdicionar.codigo)) {
      toast.error(
        "Preencha corretamente o campo de código para adicionar um novo desdobramento na lista."
      );
    } else {
      toast.error(
        "Preencha corretamente o campo de título para adicionar um novo desdobramento na lista"
      );
    }
  };

  const handleEdit = (index) => {
    let newArray = [...arrayDesdobramento];

    if (!newArray[index].disabled) {
      newArray[index].disabled = true;
    } else if (
      newArray[index].disabled &&
      !newArray.some((obj) => obj.disabled === false)
    ) {
      console.log("itens antes da alteracao");
      console.log(arrayDesdobramento);
      setItensAntesDaEdicao(
        JSON.parse(JSON.stringify([...arrayDesdobramento]))
      );
      newArray[index].disabled = false;
    } else if (newArray.some((obj) => obj.disabled === false)) {
      toast.error("Conclua a edição anterior antes de editar um novo campo.");
    }

    setArrayDesdobramento([...newArray]);
  };

  const handleIputEditChange = (index, campo, valor) => {
    let newArray = [...arrayDesdobramento];
    console.log(campo, valor);

    newArray[index][campo] = valor;

    setArrayDesdobramento([...newArray]);
  };

  const handleSaveInputEdit = (indexParaEdicao) => {
    let newArray = [...arrayDesdobramento];
    let arraySemOItemEspecifico = [...arrayDesdobramento].filter(
      (_, index) => index !== indexParaEdicao
    );

    if (
      !["", "0", "00"].includes(arrayDesdobramento[indexParaEdicao].codigo) &&
      arrayDesdobramento[indexParaEdicao].titulo !== "" &&
      !arraySemOItemEspecifico.some(
        (item) => item.codigo === arrayDesdobramento[indexParaEdicao].codigo
      )
    ) {
      newArray[indexParaEdicao].disabled = true;
      setArrayDesdobramento([...newArray]);
    } else if (
      ["", "0", "00"].includes(arrayDesdobramento[indexParaEdicao].codigo) ||
      arrayDesdobramento[indexParaEdicao].titulo === ""
    ) {
      toast.error(
        "Preencha os campos corretamente para poder salvar suas alterações."
      );
    } else {
      toast.error(
        "Não é possivel adicionar o mesmo código de desdobramento mais de uma vez."
      );
    }
  };

  const handleEditCancel = () => {
    let newArray = [...itensAntesDaEdicao];
    console.log(newArray);
    setArrayDesdobramento([...newArray]);
  };

  const nextCodigo = () => {
    const existingCodes = arrayDesdobramento
      .map((item) => parseInt(item.codigo))
      .sort((a, b) => a - b);

    let lastUsedCode = 1;
    console.log("arrayDesdobramento completo: ", arrayDesdobramento);
    existingCodes.forEach((each) => {
      if (each === lastUsedCode) {
        lastUsedCode += 1;
      }
    });
    setValuesDesdobramentoAdicionar((prevValues) => ({
      ...prevValues,
      codigo: String(lastUsedCode),
      titulo: "",
    }));
  };

  useEffect(() => {
    nextCodigo();
    // eslint-disable-next-line
  }, [arrayDesdobramento]);

  const handleShowOrHideModal = () => {
    setOpenedModal((prev) => !prev);
  };

  const deleteDesdobramento = () => {
    if (dataDelete.id) {
      api
        .delete(`naturezaDaReceita/desdobramento/${dataDelete.id}`)
        .then((resp) => {
          toast.success("Desdobramento deletado com sucesso!");
          setArrayDesdobramento((prev) =>
            prev.filter((obj) => obj.codigo !== dataDelete.codigo)
          );
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            "Algo deu errado ao deletar esse desdobramento, por favor tente mais tarde ou entre em contato com o suporte."
          );
        });
    } else {
      setArrayDesdobramento((prev) =>
        prev.filter((obj) => obj.codigo !== dataDelete.codigo)
      );

      toast.success("Desdobramento deletado com sucesso!");
    }
  };

  return (
    <>
      <ModalDeleteData
        modalOpened={openedModal}
        closeModal={handleShowOrHideModal}
        deleteDataFunction={deleteDesdobramento}
        setDataDelete={setDataDelete}
      />

      <Row>
        <Col
          className="d-flex align-items-center p-2 row_endereco_conteudo"
          md={12}
        >
          <span className="mx-4">Desdobramento</span>
        </Col>
      </Row>

      <Row className="row_form mt-1">
        <CCol md={1}>
          <PTitulosInputs>Código</PTitulosInputs>
        </CCol>
        <CCol>
          <PTitulosInputs>Título</PTitulosInputs>
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
        </CColButtonsAcoes2Icons>

        <RowInterna>
          <CCol md={1}>
            <FormControlListaDeInputs
              value={valuesDesdobramentoAdicionar.codigo}
              radiusborder={
                arrayDesdobramento.length > 0 ? "5px 5px 0px 0px" : "5px"
              }
              onChange={(e) => {
                setValuesDesdobramentoAdicionar((prev) => ({
                  ...prev,
                  codigo: e.target.value.replace(/[^0-9]/g, ""),
                }));
              }}
              maxLength="2"
            />
          </CCol>
          <CCol>
            <FormControlListaDeInputs
              value={valuesDesdobramentoAdicionar.titulo}
              radiusborder={
                arrayDesdobramento.length > 0 ? "5px 5px 0px 0px" : "5px"
              }
              onChange={(e) => {
                setValuesDesdobramentoAdicionar((prev) => ({
                  ...prev,
                  titulo: e.target.value,
                }));
              }}
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <Icon
              icon="ic:baseline-add-box"
              color="#105200"
              height="28"
              style={{ marginLeft: "5px", cursor: "pointer" }}
              onClick={adicionarDesdobramento}
            />
          </CColButtonsAcoes2Icons>
        </RowInterna>

        {arrayDesdobramento.map((item, index, selfArray) => (
          <RowInterna key={index} style={{ marginTop: "-1px" }}>
            <CCol md={1}>
              <FormControlListaDeInputs
                value={item.codigo}
                disabled={item.disabled}
                maxLength="2"
                onChange={(e) =>
                  handleIputEditChange(
                    index,
                    "codigo",
                    e.target.value.replace(/[^0-9]/g, "")
                  )
                }
                radiusborder={
                  selfArray.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                }
              />
            </CCol>
            <CCol>
              <FormControlListaDeInputs
                value={item.titulo}
                disabled={item.disabled}
                onChange={(e) =>
                  handleIputEditChange(index, "titulo", e.target.value)
                }
                radiusborder={
                  selfArray.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                }
              />
            </CCol>
            <CColButtonsAcoes2Icons md={1}>
              {item.disabled ? (
                <>
                  <Icon
                    onClick={() => handleEdit(index)}
                    icon="bx:edit"
                    color="#5971C8"
                    height="26"
                    style={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      marginTop: "3px",
                    }}
                  />
                  <Icon
                    icon="ic:baseline-delete"
                    onClick={() => {
                      handleShowOrHideModal();
                      setDataDelete({
                        id: item.id ? item.id : null,
                        codigo: item.codigo,
                      });
                    }}
                    color="#E79900"
                    height="26"
                    style={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      marginTop: "1px",
                    }}
                  />
                </>
              ) : (
                <>
                  <Icon
                    onClick={() => handleSaveInputEdit(index)}
                    icon="fluent:save-24-filled"
                    color="#008CFF"
                    height="26"
                    style={{
                      cursor: "pointer",
                      marginLeft: "5px",
                      marginTop: "3px",
                    }}
                  />
                  <Icon
                    onClick={handleEditCancel}
                    icon="ic:baseline-cancel"
                    height="26"
                    color="#F24E1E"
                    style={{
                      display: item.disabled ? "none" : "inline-block",
                      pointerEvents: item.disabled ? "none" : "auto",
                      cursor: item.disabled ? "not-allowed" : "pointer",
                      marginLeft: "2px",
                      marginRight: "-5px",
                      marginTop: "3px",
                    }}
                  />
                </>
              )}
            </CColButtonsAcoes2Icons>
          </RowInterna>
        ))}
      </Row>
    </>
  );
};

export default DesdobramentoNatRec;
