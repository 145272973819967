import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import {
  CCol,
  CColButtonsAcoes,
  RowAninhada,
  RowForm,
} from "../../../../components/Grid/CCol";
import {
  CColImgUsuarioPagConta,
  CheckboxUsuarioAtivo,
} from "../../PagContaPerfil/PagCadastroDeUsuario";
import { LabelSelectFileImgUser } from "../../../../styles/StyledComponents/InputsPersonalizados";
import InputEmail from "../../../../components/Inputs/InputEmail";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { useParams } from "react-router-dom";
import api from "../../../../utils/api";
import { toast, ToastContainer } from "react-toastify";
import UsuarioPerfil from "../../../../assets/NavBars/user.png";
import { useFetchData } from "../../../../hooks/useFetchData";
import { FormatCpfPaste } from "../../../../utils/FormatacaoDeDados/FormtCpfPaste";
import RowSelecaoAbasInferior from "../../../../components/Grid/RowSelecaoAbasInferior";
import {
  BoxOptionsSistemas,
  LabelCheckStyled,
  RowSelecaoSistemas,
  RowTituloAba,
} from "../../PagContaPerfil/StyledComponentsContaPerfil";
import { PTitulosInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import SwitchButton from "../../../../components/Inputs/SwitchButton";
import { permissoes, userTypes } from "./utils/permissoes";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
import styled from "styled-components";
import { Icon } from "@iconify/react/dist/iconify.js";

const Boxstates = styled.div`
  width: 100%;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  gap: 15px;

  & > div {
    display: flex;
    border: 1px solid #e8e8e8;
    border-radius: 20px;
    padding: 3px 8px;
    gap: 20px;

    p {
      margin: 0;
      padding: 0;
    }
  }
`;

const CadastroMaster = () => {
  const [valuesUser, setValuesUser] = useState({
    pessoaFisicaId: null,
    fotoPerfil: "foto",
    usuarioAtivo: true,
    email: null,
    cnpj: [
      {
        pessoaJuridicaId: null,
        usuarioTipoId: 3,
        permissoes: [...permissoes],
        estados: [],
      },
    ],
  });
  // const [nomeDoUsuarioECpf, setnomeDoUsuarioECpf] = useState("");
  const { data: listaCpfs } = useFetchData("/pessoas");
  const [inputAutocompleteCpf, setInputAutocompleteCpf] = useState("");
  const [optionsCpf, setOptionsCpf] = useState([]);
  const [abaSelecionada, setAbaSelecionada] = useState({
    nomeAba: "Cadastros",
    numeroAba: 0,
  });
  const [inputSelectEstate, setinputSelectEstate] = useState({
    label: "Paraíba",
    value: "PB",
  });
  const optionsStates = [
    {
      label: "Paraíba",
      value: "PB",
    },
    {
      label: "Pernambuco",
      value: "PE",
    },
  ];
  const [states, setStates] = useState([]);

  const { id: paramsId } = useParams();

  useEffect(() => {
    console.log("loop?");
    setValuesUser((prev) => ({
      ...prev,
      cnpj: [
        {
          ...prev.cnpj[0],
          estados: [...states.map((item) => item.label)],
        },
      ],
    }));
  }, [states]);

  useEffect(() => {
    if (listaCpfs) {
      console.log(listaCpfs.filter((item) => item.dados.tipo !== "Física"));
      const novoArray = listaCpfs
        .filter(
          (item) =>
            item.dados.nomeCompleto &&
            item.dados.cpf &&
            item.dados.tipo === "Física"
        )
        .map((item) => ({
          nome: item.dados.nomeCompleto,
          cpf: item.dados.cpf,
          id: item.dados.id,
        }));

      setOptionsCpf(novoArray);

      const saveId = listaCpfs
        .filter((item) => item.dados.tipo !== "Física")
        .find((item) => item.dados.cnpj === "10571183000159")?.dados.id;
      if (saveId) {
        setValuesUser((prev) => ({
          ...prev,
          cnpj: [
            {
              ...prev.cnpj[0],
              pessoaJuridicaId: saveId,
            },
          ],
        }));
      }
    }
  }, [listaCpfs]);

  const handleImageChange = (e) => {
    console.log(e.target.files[0]);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setValuesUser((prev) => ({
          ...prev,
          fotoPerfil: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpdate = async (e, userId) => {
    console.log("aqui");
    const file = e.target.files[0];
    let base64Image = "";
    if (file) {
      base64Image = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = (error) => {
          reject(error);
        };
      });
    }

    setValuesUser((prev) => ({
      ...prev,
      fotoPerfil: base64Image,
    }));

    if (base64Image && base64Image !== "") {
      try {
        api
          .put(`/usuario/atualiza-fotoPerfil/${userId}`, {
            fotoPerfil: base64Image,
          })
          .then((resp) => {
            toast.success("Foto de perfil atualizada com sucesso!");
          });
      } catch (error) {
        console.log(error);
        toast.error(
          "Algo deu errado ao atualizar a foto de perfil. Por favor, tente mais tarde"
        );
      }
    }
  };

  const handleAutocompleteChange = (newValue) => {
    if (newValue) {
      setValuesUser((prev) => ({
        ...prev,
        pessoaFisicaId: newValue.id,
      }));

      const objetoCpf = listaCpfs.find((item) => item.dados.id === newValue.id);

      if (objetoCpf && objetoCpf.contato.email) {
        setValuesUser((prev) => ({
          ...prev,
          email: objetoCpf.contato.email,
        }));
      }
    }
  };

  const handleAddState = () => {
    if (![undefined, null, ""].includes(inputSelectEstate.value)) {
      if (!states.some((state) => state.value === inputSelectEstate.value)) {
        setStates((prev) => [...prev, inputSelectEstate]);
      } else {
        toast.error("Esse estado ja foi adicionado.");
      }
    } else {
      toast.error("Selecione um estado válido para adicionar.");
    }
  };

  const handleDeleteState = (UF) => {
    setStates((prev) => prev.filter((item) => item.value !== UF));
  };

  const handleSwitch = (indexMenu) => {
    const newArray = [...valuesUser.cnpj[0].permissoes];
    newArray[indexMenu].menuAtivo = !newArray[indexMenu].menuAtivo;

    setValuesUser((prev) => ({
      ...prev,
      cnpj: [
        {
          ...prev.cnpj[0],
          permissoes: newArray,
        },
      ],
    }));
  };

  const handleChangePermission = (indexMenu, indexPermission) => {
    const newArray = [...valuesUser.cnpj[0].permissoes];
    newArray[indexMenu].acoes[indexPermission].acaoAtiva =
      !newArray[indexMenu].acoes[indexPermission].acaoAtiva;

    setValuesUser((prev) => ({
      ...prev,
      cnpj: [
        {
          ...prev.cnpj[0],
          permissoes: newArray,
        },
      ],
    }));
  };

  const registerUser = async () => {
    const valuesToPost = {
      ...valuesUser,
      cnpj: [
        {
          ...valuesUser.cnpj[0],
          permissoes: valuesUser.cnpj[0].permissoes.filter(
            (item) => item.menuAtivo
          ),
        },
      ],
    };

    console.log(valuesToPost);

    try {
      const resp = await api.post("usuario/padrao", valuesToPost);
      console.log(resp);
      toast.success("Usuário cadastrado com sucesso.");
    } catch (error) {
      console.log(error);
      // toast.error("Algo deu errado ao cadastrar o usuário.");
      toast.error(
        error.response.data.error
          ? error.response.data.error
          : "Erro ao cadastrar o usuário"
      );
      console.log(error.response.data.error);
    }
  };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Cadastro de usuários"}
        PaginaSubtitulo={"Master"}
        ButtonSaveFunction={registerUser}
      />
      {/* <button onClick={() => console.log(valuesUser)}>console</button> */}
      <ToastContainer />

      <RowForm className="mt-1">
        <CColImgUsuarioPagConta
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LabelSelectFileImgUser>
            <input
              type="file"
              accept="image/*"
              onChange={
                !paramsId
                  ? handleImageChange
                  : (e) => handleImageUpdate(e, paramsId)
              }
            />
            <div>
              <img
                src={
                  valuesUser.fotoPerfil.includes("base64")
                    ? valuesUser.fotoPerfil
                    : UsuarioPerfil
                }
                alt="Foto do Usuário"
              />
            </div>
          </LabelSelectFileImgUser>
        </CColImgUsuarioPagConta>

        <CCol>
          <RowAninhada>
            <CCol>
              {paramsId ? (
                <>
                  <FloatingLabelInput
                    label={"Nome - CPF"}
                    value={""}
                    disabled
                  />
                </>
              ) : (
                <>
                  <MyAutoComplete
                    inputValue={inputAutocompleteCpf}
                    setInputValue={setInputAutocompleteCpf}
                    labelInput={"Nome ou CPF do usuário"}
                    style={{ height: "38px" }}
                    options={optionsCpf}
                    labelFormat={(option) =>
                      `${option.nome} - ${FormatCpfPaste(option.cpf)}`
                    }
                    onOptionSelect={handleAutocompleteChange}
                  />
                </>
              )}
            </CCol>
            <CCol md={3}>
              <CheckboxUsuarioAtivo
                valuesUser={valuesUser}
                setValuesUser={setValuesUser}
              />
            </CCol>
          </RowAninhada>
          <RowAninhada style={{ marginTop: "16px" }}>
            <CCol>
              <InputEmail
                label={"@ E-mail"}
                placeholder="@ E-mail"
                value={valuesUser.email}
                disabled={paramsId}
                onChange={(e) => {
                  setValuesUser((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                }}
              />
            </CCol>
            <CCol md={3}>
              <Button
                style={{ padding: "0px", height: "100%", width: "100%" }}
                variant="success"
              >
                Alterar Senha
              </Button>
            </CCol>
          </RowAninhada>
        </CCol>
      </RowForm>

      <RowSelecaoAbasInferior
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
        abas={["Cadastros"]}
      />

      <RowTituloAba>
        <CCol>
          <span>Cadastro do Usuário</span>
        </CCol>
      </RowTituloAba>

      <RowForm $borderRadiusType={2} className="mt-1">
        <CCol md={3}>
          <PTitulosInputs>Tipo de usuário</PTitulosInputs>
          <FloatingLabelInput
            tipo="input_select"
            new
            height="30px"
            placeholder="Tipo de usuário"
            value={valuesUser.cnpj[0].usuarioTipoId}
            onSelect={(item) =>
              setValuesUser((prev) => ({
                ...prev,
                cnpj: [
                  {
                    ...prev.cnpj[0],
                    usuarioTipoId: item.value,
                  },
                ],
              }))
            }
            options={userTypes}
          />
        </CCol>
        {/* <CCol>
          <PTitulosInputs>Usuário Responsável</PTitulosInputs>
          <FloatingLabelInput
            tipo="input_select"
            new
            height="30px"
            placeholder="Usuário Responsável"
            options={[{ label: "Todos", value: "1" }]}
          />
        </CCol> */}
        <RowAninhada style={{ padding: "0 8px", marginTop: "16px" }}>
          <RowSelecaoSistemas>
            {valuesUser.cnpj[0].permissoes.map((menu, indexMenu, selfArray) => (
              <CCol md={2} style={{ padding: "0px" }}>
                <BoxOptionsSistemas>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontWeight: "600", color: "#515c70" }}>
                      {menu.menu}
                    </span>
                    <SwitchButton
                      $checked={menu.menuAtivo}
                      onClick={() => handleSwitch(indexMenu)}
                    />
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    {menu.acoes.map((acao, indexAcao) => (
                      <LabelCheckStyled
                        key={indexAcao}
                        checked={acao.acaoAtiva}
                        label={acao.acao}
                        onChange={() =>
                          handleChangePermission(indexMenu, indexAcao)
                        }
                      />
                    ))}
                  </div>
                </BoxOptionsSistemas>
              </CCol>
            ))}
          </RowSelecaoSistemas>
        </RowAninhada>

        <RowAninhada style={{ marginTop: "16px", alignItems: "center" }}>
          <CCol md={3}>
            <PTitulosInputs>Estado</PTitulosInputs>
            <FloatingLabelInput
              tipo="input_select"
              new
              height="30px"
              placeholder="Usuário Responsável"
              onSelect={(item) => setinputSelectEstate(item)}
              value={inputSelectEstate.value}
              options={optionsStates}
            />
          </CCol>
          <CColButtonsAcoes md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
            <IconAdicionar onClick={handleAddState} />
          </CColButtonsAcoes>
          <CCol>
            <Boxstates>
              {states.map((state) => (
                <div>
                  <p>{state.label}</p>
                  <Icon
                    icon="ic:baseline-cancel"
                    height="24"
                    color="#F24E1E"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleDeleteState(state.value)}
                  />
                </div>
              ))}
            </Boxstates>
          </CCol>
        </RowAninhada>
      </RowForm>
    </Container>
  );
};

export default CadastroMaster;
