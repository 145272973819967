import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";

import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { FormatValueToLocaleString } from "../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

export const RenderPdfDespesaCatEconOrgao = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear
) => {
  console.log(dados);
  console.log(opcoes);

  if(dados) return null
  
  const colNames = [
    "Código",
    "Especificações",
    "Desdobramento",
    "Grupo da Natureza",
    "Categoria Econômica",
  ];
  const styledCol = [
    { flexBasis: "150px", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "100%", flexShrink: 1, flexGrow: 1 },
    {
      flexBasis: "225px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "left",
    },
    {
      flexBasis: "160px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
    {
      flexBasis: "160px",
      flexShrink: 1,
      flexGrow: 1,
      textAlign: "center",
    },
  ];

  const optionDespesaCatEconOrgao = opcoes.find(
    (option) => option.opcao === "Despesa Segundo as Categorias Econômicas - Órgão"
  );

  const normalizeString = (str) => {
    return str.endsWith(".") ? str.slice(0, -1) : str;
  };

  
  const renderReceitas = (natureza, key) => {
    // const naturezaSection = codigosSectionRelatorio.includes(natureza.codigo);
    // const fonteRecurso = natureza.codigo < codigosSectionRelatorio[0].length;

    return (
      <React.Fragment key={key}>
        <View
          style={[
            stylesPdf.colunas,
            {
              gap: 0,
              // backgroundColor: naturezaSection ? "#a6a6a6" : "transparent",
              // fontWeight: naturezaSection | fonteRecurso ? "bold" : "normal",
            },
          ]}
        >
          {optionDespesaCatEconOrgao?.checkBoxes[0].selected && (
            <PdfInfos
              pdfInfo={natureza.codigo || ""}
              styledCol={[
                styledCol[0],
                {
                  padding: "2px 0",
                  textAlign: "right",
                },
              ]}
            />
          )}
          {optionDespesaCatEconOrgao?.checkBoxes[1].selected && (
            <PdfInfos
              pdfInfo={normalizeString(natureza.especificacao) || ""}
              styledCol={[styledCol[1], { padding: "2px 0" }]}
            />
          )}
          {optionDespesaCatEconOrgao?.checkBoxes[2].selected && (
            <PdfInfos
              pdfInfo={
                natureza.desdobramento
                  ? FormatValueToLocaleString(
                      parseFloat(natureza.desdobramento)
                    )
                  : ""
              }
              styledCol={[
                {
                  padding: "2px 0",
                  textAlign: "right",
                  flexBasis: "160px",
                  flexShrink: 1,
                  flexGrow: 1,
                },
              ]}
            />
          )}
          {optionDespesaCatEconOrgao?.checkBoxes[3].selected && (
            <PdfInfos
              pdfInfo={
                natureza.grupoNatureza ? FormatValueToLocaleString(natureza.grupoNatureza) : ""
              }
              styledCol={[
                styledCol[3],
                {
                  padding: "2px 0",
                  textAlign: "right",
                  fontWeight: "bold",
                },
              ]}
            />
          )}
          {optionDespesaCatEconOrgao?.checkBoxes[4].selected && (
            <PdfInfos
              pdfInfo={
                natureza.catEcon
                  ? FormatValueToLocaleString(natureza.catEcon)
                  : ""
              }
              styledCol={[
                styledCol[4],
                {
                  padding: "2px 0",
                  textAlign: "right",
                },
              ]}
            />
          )}
        </View>
      </React.Fragment>
    );
  };

  return (
    <Page orientation="portrait" size={"A4"} style={stylesPdf.page}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={"Despesa Segundo a Categoria Econômica por Órgão e Unidade Orçamentária"}
        plusText={"Anexo II"}
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
      />
      <View
        fixed
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
      >
        <Text>Lei Federal 4.320/64</Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={[stylesPdf.colunas]} fixed>
        {opcoes[1]?.checkBoxes?.map(
          (each, index) =>
            each.selected && (
              <PdfColunas
                key={index}
                colName={colNames[index].toUpperCase()}
                styledCol={styledCol[index]}
                hideBar
              />
            )
        )}
      </View>
      <View style={stylesPdf.divRelatorio}>
        <View style={{ margin: "0 -3px" }}>
          {dados?.data?.map((item, itemIndex) => (
            <React.Fragment key={itemIndex}>
              {/* {item.length > 0 && (
                <View
                  style={[
                    stylesPdf.colunas,
                    {
                      gap: 0,
                      backgroundColor: "#a6a6a6",
                      fontWeight: "bold",
                      marginBottom: 10,
                    },
                  ]}
                >
                  {opcoes[1]?.checkBoxes[0].selected && (
                    <PdfInfos
                      pdfInfo={item.infoCliente.codigo || ""}
                      styledCol={[
                        styledCol[0],
                        {
                          padding: "2px 0",
                          textAlign: "right",
                        },
                      ]}
                    />
                  )}
                  {opcoes[1]?.checkBoxes[1].selected && (
                    <PdfInfos
                      pdfInfo={
                        item.infoCliente.especificacao.toLowerCase() || ""
                      }
                      styledCol={[
                        styledCol[1],
                        { padding: "2px 0", textTransform: "capitalize" },
                      ]}
                    />
                  )}
                  {opcoes[1]?.checkBoxes[2].selected && (
                    <PdfInfos
                      pdfInfo={""}
                      styledCol={[
                        styledCol[2],
                        {
                          padding: "2px 0",
                          textAlign: "right",
                        },
                      ]}
                    />
                  )}
                  {opcoes[1]?.checkBoxes[3].selected && (
                    <PdfInfos
                      pdfInfo={""}
                      styledCol={[
                        styledCol[3],
                        {
                          padding: "2px 0",
                          textAlign: "center",
                        },
                      ]}
                    />
                  )}
                  {opcoes[1]?.checkBoxes[4].selected && (
                    <PdfInfos
                      pdfInfo={""}
                      styledCol={[
                        styledCol[4],
                        {
                          padding: "2px 0",
                          textAlign: "right",
                        },
                      ]}
                    />
                  )}
                </View>
              )} */}
                {renderReceitas(item, `${itemIndex}`)}
              

              
            </React.Fragment>
          ))}

          <View
            wrap={false}
            style={[
              stylesPdf.divInferiorColuna,
              {
                fontWeight: "bold",
                fontSize: 10,
                textTransform: "uppercase",
                color: "white",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              },
            ]}
          >
            <Text>TOTAL</Text>
            <Text>{dados.sum}</Text>
          </View>
        </View>
      </View>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
