import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import { CurrentClientContext } from "../../contexts/CurrentClientContext";
import { CurrentCityContext } from "../../contexts/CurrentCityContext";
import api from "../../utils/api";

const PrivateRoute = ({
  permissaoNecessaria,
  apenasAcessoMaster = false,
  children,
}) => {
  const {
    setUserAuthData,
    setTokenAuth,
    tokenAuth,
    userAuthData,
    permissoesDoUsuario,
  } = useContext(AuthContext);
  const { setCurrentClient, currentClient } = useContext(CurrentClientContext);
  const { currentCity, setCurrentCity } = useContext(CurrentCityContext);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [usuarioEncontrado, setUsuarioEncontrado] = useState(false);
  const [navegacaoPermitida, setNavegacaoPermitida] = useState(null);
  const bearerToken = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleChangeSystem = (systemName) => {
    const userHistory = JSON.parse(localStorage.getItem("userHistory"));
    console.log(userHistory);

    if (userHistory) {
      switch (systemName?.toLowerCase()) {
        case "acesso-interno":
          userHistory.sistema = "gerenciador";
          break;
        case "planejamento":
          userHistory.sistema = "planejamento";
          break;
        default:
          userHistory.sistema = "gerenciador";
          break;
      }

      localStorage.setItem("userHistory", JSON.stringify(userHistory));
    }
  };

  useEffect(() => {
    authUser();

    const modulePath = window.location.pathname.split("/")[1];
    console.log(modulePath);

    handleChangeSystem(modulePath);

    console.log("passou pra a pagina " + window.location.pathname);

    //eslint-disable-next-line
  }, [window.location.pathname]);

  const authUser = () => {
    if (localStorage.getItem("token")) {
      if (Object.keys(userAuthData).length === 0) {
        api
          .get("usuario/pesquisa-via/token")
          .then((resp) => {
            console.log("Fazendo requisicao para autenticação do usuario...");
            console.log(resp);
            setUserAuthData(resp.data[0]);
            setTokenAuth(bearerToken);
            setUsuarioEncontrado(true);
          })
          .catch((error) => {
            console.log(error);
            setTokenAuth(null);
            setIsAuthenticated(false);
          });
      } else {
        console.log("User ja autenticado");
        setTokenAuth(bearerToken);
        setUsuarioEncontrado(true);
      }
    } else {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    if (usuarioEncontrado) {
      if (
        Object.keys(currentClient).length === 0 &&
        Object.keys(userAuthData).length !== 0
      ) {
        console.log(userAuthData);
        console.log("entrou no if");
        const userHistory = JSON.parse(localStorage.getItem("userHistory"));
        const regiaoInicial = userHistory?.regiao;
        const idDoCliente = userHistory?.clienteId;

        if (regiaoInicial && idDoCliente) {
          const clienteInicialRegiao = userAuthData.clientes
            .filter((cliente) => cliente.clienteAtivo === 1)
            .filter(
              (cliente) =>
                cliente.endereco.municipio === regiaoInicial.municipio &&
                cliente.endereco.estado === regiaoInicial.estado &&
                cliente.clienteId === idDoCliente
            )[0];

          console.log(clienteInicialRegiao);

          if (clienteInicialRegiao) {
            setCurrentClient({
              ...clienteInicialRegiao,
            });
          } else {
            setCurrentClient({
              ...userAuthData.clientes.filter(
                (cliente) => cliente.clienteAtivo === 1
              )[0],
            });
          }

          setUsuarioEncontrado(false);
        } else {
          console.log("entrou no else");
          //----------------------------
          const clienteInicial = userAuthData.clientes.filter(
            (cliente) => cliente.clienteAtivo === 1
          )[0];
          const sistemaInicial = clienteInicial.permissoes.sistemas.filter(
            (sistema) => sistema.sistemaAtivo === 1
          )[0];

          const object = {
            userId: userAuthData.userId,
            clienteId: clienteInicial?.clienteId,
            sistema: sistemaInicial?.sistema,
            regiao: {
              estado: clienteInicial?.endereco.estado,
              municipio: clienteInicial?.endereco.municipio,
            },
          };

          localStorage.setItem("userHistory", JSON.stringify(object));
          //-----------------------------

          setUsuarioEncontrado(false);
          setCurrentClient({
            ...clienteInicial,
          });

          setCurrentCity({ ...clienteInicial.endereco });
        }

        console.log(userAuthData.clientes);
        console.log(userAuthData.clientes[0]);
        console.log("fim adicao do cliente");
      }
    }
  }, [
    tokenAuth,
    usuarioEncontrado,
    userAuthData,
    currentClient,
    setCurrentClient,
    setCurrentCity,
  ]);

  useEffect(() => {
    if (
      apenasAcessoMaster &&
      Object.keys(currentClient).length > 0 &&
      permissoesDoUsuario.length > 0
    ) {
      const permissoesAtuais = permissoesDoUsuario.find(
        (permissao) => permissao.clienteId === currentClient.clienteId
      );
      const userType = permissoesAtuais.tipoDeUsuario.toLowerCase();

      if (userType === "master") {
        setNavegacaoPermitida(true);
      } else {
        setNavegacaoPermitida(false);
      }
    }
  }, [apenasAcessoMaster, permissoesDoUsuario, currentClient]);

  useEffect(() => {
    if (
      Object.keys(currentClient).length > 0 &&
      Object.keys(userAuthData).length !== 0 &&
      permissoesDoUsuario.length > 0 &&
      permissaoNecessaria
    ) {
      console.log(currentClient);
      console.log(userAuthData);
      console.log(permissoesDoUsuario);
      console.log(permissaoNecessaria);
      const permissoesAtuais = permissoesDoUsuario.find(
        (permissao) => permissao.clienteId === currentClient.clienteId
      );

      console.log(permissoesAtuais);

      const tipoDeUsuario = permissoesAtuais.tipoDeUsuario.toLowerCase();

      if (tipoDeUsuario === "master") {
        console.log("é master");
        setNavegacaoPermitida(true);
        return;
      }

      const sistemaNecessario = permissoesAtuais.permissoes.find(
        (permissao) =>
          permissao.sistema.toLowerCase() ===
          permissaoNecessaria.sistema.toLowerCase()
      );
      const menuNecessario = permissaoNecessaria.menu
        ? sistemaNecessario?.menus?.find(
            (menu) =>
              menu.menu.toLowerCase() === permissaoNecessaria.menu.toLowerCase()
          )
        : true;
      const acaoNecessaria = permissaoNecessaria.acao
        ? menuNecessario?.acoes?.find(
            (acao) =>
              acao.acao.toLowerCase() === permissaoNecessaria.acao.toLowerCase()
          )
        : true;

      if (
        (tipoDeUsuario === "gestor" || tipoDeUsuario === "administrador") &&
        sistemaNecessario
      ) {
        console.log("é gestor");
        setNavegacaoPermitida(true);
        return;
      }

      if (sistemaNecessario && menuNecessario && acaoNecessaria) {
        console.log(sistemaNecessario);
        console.log(menuNecessario);
        console.log(acaoNecessaria);
        console.log("é usuario");
        setNavegacaoPermitida(true);
        return;
      }

      if (!sistemaNecessario || !menuNecessario || !acaoNecessaria) {
        console.log("não passarás");
        setNavegacaoPermitida(false);
      }
      console.log(permissoesAtuais);
    }
  }, [currentClient, userAuthData, permissoesDoUsuario, permissaoNecessaria]);

  useEffect(() => {
    console.log(Object.keys(currentClient).length !== 0);
    console.log(currentCity);
    console.log(navegacaoPermitida);
    if (
      Object.keys(currentClient).length !== 0 &&
      currentCity &&
      (navegacaoPermitida || !permissaoNecessaria)
    ) {
      console.log("permitiu a navegacao");
      setIsAuthenticated(true);
    }
  }, [currentClient, currentCity, navegacaoPermitida, permissaoNecessaria]);

  useEffect(() => {
    if (navegacaoPermitida === false) {
      console.log("redirecionado");
      navigate("/");
    }
  }, [navegacaoPermitida, navigate]);

  if (!bearerToken) {
    return <Navigate to="/" />;
  }

  if (!isAuthenticated) {
    // Você pode adicionar um componente de carregamento ou algo enquanto a autenticação está sendo verificada.
    return <div>...</div>;
  }

  return isAuthenticated && children;
};

export default PrivateRoute;
