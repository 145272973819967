import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Col, Container, Row, Table } from "react-bootstrap";
import outlineSearch from "@iconify/icons-ic/outline-search";
import {
  BodyTable,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  PassadorPage,
  TituloTable,
  TituloTableAction,
} from "../../../../components/TableConsulta/TableConsulta";
import Pagination from "../../../../components/PaginationComponent/Pagination";
import HeaderOptions from "../../../../components/HeaderOptions/HeaderOptions";
import InputSvg from "../../../../components/Grid/InputSvg";
import { ToastContainer, toast } from "react-toastify";
import ButtonsAcoesListas from "../../../../styles/StyledComponents/ButtonsAcoesListas";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import { useFetchData } from "../../../../hooks/useFetchData";
//import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import api from "../../../../utils/api";
import LoadingComponent from "../../../../components/AssetsComponents.jsx/LoadingComponent";

function ConsultaDespesa() {
  const { currentClient } = useContext(CurrentClientContext);
  const dataAtual = ReturnDataAtual();
  const { data: dataListaNatDesp, loading: loadingFetch } = useFetchData(
    `/naturezaDaDespesa/por-cliente/${currentClient.clienteId}/${
      dataAtual.split("/")[2]
    }/filtro`
  );
  const [itens, setItens] = useState([]);
  const [itensPorPagina, setItensPorPagina] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [itensFiltrados, setItensFiltrados] = useState([]);

  axios.defaults.headers.common["Authorization"] = null;

  const [modalOpened, setModalOpened] = useState(false);
  const [dataDelete, setDataDelete] = useState({});

  useEffect(() => {
    if (dataListaNatDesp) {
      setItens(dataListaNatDesp);
    }
  }, [dataListaNatDesp]);

  const reverseMask = (value) => {
    let noDots = value.replace(/\./g, "");
    return noDots;
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    const filteredItems = itens.filter((item) => {
      let itemCodigo = reverseMask(item.naturezaDaDespesa.codigo);
      return (
        item.naturezaDaDespesa.nome
          .toLowerCase()
          .includes(value.toLowerCase()) || itemCodigo.includes(value)
      );
    });
    setItensFiltrados(filteredItems);
    setCurrentPage(0);
  };

  const deleteTipoDePrograma = () => {
    api
      .delete(
        `tipoDePrograma/custom/${currentClient.clienteId}/delete/${dataDelete.codigo}`
      )
      .then(() => {
        toast.success("Tipo de Programa deletado com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Algo deu errado. Por favor, tente mais tarde.");
        }
      });
  };

  function openModal() {
    setModalOpened(true);
    console.log("FILTERED: ", currentFilteredItens);
    console.log("ITENS :", itens);
  }

  function closeModal() {
    setModalOpened(false);
  }

  const startIndex = currentPage * itensPorPagina;
  const endIndex = startIndex + itensPorPagina;
  const currentItens = itens.slice(startIndex, endIndex);
  const currentFilteredItens = itensFiltrados.slice(startIndex, endIndex);

  const pages = Math.ceil(itens.length / itensPorPagina);
  const filteredPages = Math.ceil(itensFiltrados.length / itensPorPagina);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <ModalDeleteData
        modalOpened={modalOpened}
        closeModal={closeModal}
        setDataDelete={setDataDelete}
        deleteDataFunction={deleteTipoDePrograma}
      />
      <ToastContainer />
      <HeaderOptions
        withRow={true}
        TituloPagina={"Natureza da Despesa"}
        SubTituloPagina={"Consulta"}
        ToPag={"/planejamento/cadastro/natureza-da-despesa"}
        DashboardPage={"/planejamento/cadastro/natureza-da-despesa/dashboard"}
        PaginaRelatorio={"/planejamento/cadastro/natureza-da-despesa/relatorio"}
      />
      <Row className="row_form mt-1">
        <Col md={8}>
          <FilterContainer>
            <FilterLabel>Itens por página:</FilterLabel>
            <FilterSelect
              value={itensPorPagina}
              onChange={(event) =>
                setItensPorPagina(Number(event.target.value))
              }
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </FilterSelect>
          </FilterContainer>
        </Col>
        <Col md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Row className="row_form mt-1">
        {loadingFetch && (
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingComponent size={50} />
          </Col>
        )}
        {!loadingFetch && (
          <Table responsive style={{ marginTop: "-10px" }}>
            <thead>
              <tr>
                <TituloTable>Código</TituloTable>
                <TituloTable>Descrição</TituloTable>
                {/*
              <TituloTable>Grupo da Natureza</TituloTable>
              <TituloTable>Valor</TituloTable>
              */}
                <TituloTableAction>Ação</TituloTableAction>
              </tr>
            </thead>
            <BodyTable>
              {searchTerm
                ? currentFilteredItens.map((item, index) => (
                    <tr key={index}>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {item.naturezaDaDespesa.codigo}
                      </td>
                      <td
                        style={{ whiteSpace: "nowrap" }}
                        title={item.naturezaDaDespesa.nome}
                      >
                        {item.naturezaDaDespesa.nome.length > 100
                          ? item.naturezaDaDespesa.nome.substring(0, 100) +
                            "..."
                          : item.naturezaDaDespesa.nome}
                      </td>
                      {/*
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.naturezaDaDespesa.nivel}
                    </td>
                    <td title={item.valores.valorEmpenhado}>
                      {`R$ ${FormatValueToLocaleString(
                        parseFloat(item.valores.valorEmpenhado)
                      )}`}
                    </td>*/}
                      <td>
                        <ButtonsAcoesListas
                          FuncaoIconDelete={() => {
                            openModal();
                            setDataDelete({
                              codigo: item.naturezaDaDespesa.codigo,
                            });
                          }}
                          CaminhoPagUpdate={`/planejamento/cadastro/natureza-da-despesa/visualizar/${item.naturezaDaDespesa.codigo}`}
                        />
                      </td>
                    </tr>
                  ))
                : currentItens.map((item, index) => (
                    <tr key={index}>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {item.naturezaDaDespesa.codigo}
                      </td>
                      <td
                        style={{ whiteSpace: "nowrap" }}
                        title={item.naturezaDaDespesa.nome}
                      >
                        {item.naturezaDaDespesa.nome.length > 100
                          ? item.naturezaDaDespesa.nome.substring(0, 100) +
                            "..."
                          : item.naturezaDaDespesa.nome}
                      </td>
                      {/*
                    <td style={{ whiteSpace: "nowrap" }}>
                      {item.naturezaDaDespesa.nivel}
                    </td>*
                    <td
                      title={FormatValueToLocaleString(
                        parseFloat(item.valores.valorEmpenhado)
                      )}
                    >
                      {`R$ ${FormatValueToLocaleString(
                        parseFloat(item.valores.valorEmpenhado)
                      )}`}
                    </td>*/}
                      <td>
                        <ButtonsAcoesListas
                          FuncaoIconDelete={() => {
                            openModal();
                            setDataDelete({
                              codigo: item.naturezaDaDespesa.codigo,
                            });
                          }}
                          CaminhoPagUpdate={`/planejamento/cadastro/natureza-da-despesa/visualizar/${item.naturezaDaDespesa.codigo}`}
                        />
                      </td>
                    </tr>
                  ))}
            </BodyTable>
          </Table>
        )}
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <Col md={4}>
          <span>
            Mostrando de {startIndex + 1} a {endIndex} de{" "}
            {searchTerm ? itensFiltrados.length : itens.length} registros
          </span>
        </Col>
        <Col md={8}>
          <PassadorPage>
            <span>
              {currentPage + 1} de {searchTerm ? filteredPages : pages} páginas
            </span>
            <Pagination
              pages={searchTerm ? filteredPages : pages}
              itemsPerPage={itensPorPagina}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </PassadorPage>
        </Col>
      </Row>
      <div style={{ padding: "11px" }}></div>
    </Container>
  );
}

export default ConsultaDespesa;
