import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Container } from "react-bootstrap";
import GlobalStyles from "../../../../../styles/StyledComponents/GlobalStyles";
import { DivPrintOnly } from "../../../../../styles/StyledComponents/StylesRelatorios";
import { useFetchData } from "../../../../../hooks/useFetchData";
import { CurrentClientContext } from "../../../../../contexts/CurrentClientContext";
import HeaderCadastros from "../../../../../components/HeaderCadastros/HeaderCadastros";
import PagRelatorioComponente from "../../../../../components/ComponenteDeRelatorio/PagRelatorioComponente";
import VizualizacaoRelatorioDiretriz from "./VisualizacaoRelatorioDiretriz";
import { PdfDiretriz } from "./PdfDiretriz";
import { useGenerateRelatorio } from "../../../../../hooks/useGenerateRelatorio";
import { isBase64 } from "../../../../../utils/Gerais/isBase64";

const RelatorioDiretriz = () => {
  const relatorioName = "Relatório Diretriz";
  const columnWidth = [8, 40, 26, 26];
  const navigate = useNavigate();
  const { currentClient } = useContext(CurrentClientContext);
  const [selectBoxesOptionsAbaPrincipal, setSelectBoxesOptionsAbaPrincipal] =
    useState([]);
  const [selectBoxesOptionsAbaOptions, setSelectBoxesOptionsAbaOptions] =
    useState([
      {
        opcao: "Exibir", //"Diretriz",
        checkBoxes: [
          { label: "Código", value: "codigo", selected: true },
          { label: "Título", value: "titulo", selected: true },
          { label: "Audiência", value: "audiencia", selected: true },
          { label: "Exercício", value: "exercicio", selected: true },
        ],
      },
    ]);
  const { data: dataDiretriz } = useFetchData(
    `diretrizPlanejamento/por-cliente/${currentClient.clienteId}`
  );

  const {
    dadosRelatorio,
    dataClientInfos,
    handleGenerateDocx,
    handleGeneratePdf,
    handleGenerateXls,
    handlePrint,
    pdfData,
  } = useGenerateRelatorio(
    selectBoxesOptionsAbaOptions,
    selectBoxesOptionsAbaPrincipal,
    currentClient,
    relatorioName,
    columnWidth
  );

  const getDadosRelatorio = () => {
    const selectBox = selectBoxesOptionsAbaPrincipal?.find(
      (item) => item.opcao === "Diretriz"
    );

    const selectBoxSelected = selectBox?.checkBoxes.some(
      (item) => item.selected
    );

    if (!selectBoxSelected) return [];

    const itensSelecionados = selectBox.checkBoxes
      .filter((item) => item.selected)
      .map((checkBox) => checkBox.value);

    const dadosSelecionados = dataDiretriz?.filter((item) =>
      itensSelecionados.includes(item.id)
    );

    return dadosSelecionados;
  };

  const handleGenerateDataXls = () => {
    const dadosRelatorio = getDadosRelatorio();

    const dataTable = dadosRelatorio
      ?.map((item) => {
        const selectedFields = {};
        selectBoxesOptionsAbaOptions[0]?.checkBoxes?.forEach(
          ({ label, value, selected }) => {
            const actualItem = item[value];
            if (selected && !isBase64(actualItem)[0]) {
              selectedFields[label] = actualItem;
            }
          }
        );

        return Object.keys(selectedFields).length > 0 ? selectedFields : null;
      })
      .filter((item) => item);
    if (dataTable.length !== 0) {
      handleGenerateXls(dataTable);
    } else {
      toast.warn(
        "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
      );
    }
  };

  useEffect(() => {
    if (
      dataDiretriz &&
      !selectBoxesOptionsAbaPrincipal.some((item) => item.opcao === "Diretriz")
    ) {
      console.log(dataDiretriz);

      setSelectBoxesOptionsAbaPrincipal((prev) => [
        ...prev,
        {
          opcao: "Diretriz",
          checkBoxes: dataDiretriz.map((item) => ({
            label: `${item.codigo} - ${
              item.titulo.length > 40
                ? `${item.titulo.substring(0, 40)}...`
                : item.titulo
            }`,
            value: item.id,
            selected: false,
          })),
        },
      ]);
    }
  }, [dataDiretriz, selectBoxesOptionsAbaPrincipal]);

  return (
    <>
      <GlobalStyles orientation={"landscape"} />
      <DivPrintOnly>
        <VizualizacaoRelatorioDiretriz
          dadosRelatorio={dadosRelatorio}
          dataClientInfos={dataClientInfos}
          numeroDaPagina={1}
          totalDePaginas={2}
        />
      </DivPrintOnly>

      <Container
        id="pags_responsividade_padding_geral"
        fluid
        className="container_conteudo conteudo_pagina hide_on_print"
      >
        <HeaderCadastros
          PaginaConsulta={"/planejamento/cadastro/diretriz/consulta"}
          NomePaginaTitulo={"Diretriz"}
          PaginaSubtitulo={"Relatório"}
          BotaoNovoAtivo={true}
          BotaoSaveAtivo={false}
          BotaoNovoFunction={() => navigate("/planejamento/cadastro/diretriz")}
          OpcoesDeArquivo={true}
          ButtonVisualizar={() => handlePrint(getDadosRelatorio)}
          ButtonPDF={(action) =>
            handleGeneratePdf(PdfDiretriz, getDadosRelatorio, action)
          }
          ButtonDocx={() => handleGenerateDocx(getDadosRelatorio)}
          PdfData={pdfData}
          ButtonXls={handleGenerateDataXls}
        />
        <ToastContainer />

        <PagRelatorioComponente
          topoEsquerdoOptions={[
            { label: "Relatório", value: null },
            { label: "Relatório", value: 1 },
          ]}
          principalOptions={[
            {
              label: "Diretriz",
              value: "Diretriz",
            },
            // {
            //   label: "Teste",
            //   value: "Teste",
            // },
          ]}
          labelsCheckAbaPrincipal={selectBoxesOptionsAbaPrincipal}
          setLabelsCheckAbaPrincipal={setSelectBoxesOptionsAbaPrincipal}
          labelsCheckAbaOpcoes={selectBoxesOptionsAbaOptions}
          setLabelsCheckAbaOpcoes={setSelectBoxesOptionsAbaOptions}
          hasAssinatura={false}
          hasFavorite={false}
        />
      </Container>
    </>
  );
};

export default RelatorioDiretriz;
