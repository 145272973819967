import React, { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useFetchData } from "../../../hooks/useFetchData";
import { CurrentClientContext } from "../../../contexts/CurrentClientContext";
import Dashboard, {
  DivIndicadoresQuantidade,
} from "../../../components/ComponentesDeDashboard/Dashboard";
import { CCol } from "../../../components/Grid/CCol";
import { FormControlListaDeInputs } from "../../../styles/StyledComponents/ListaDeInputs";
import { ReturnDataAtual } from "../../../utils/Gerais/ReturnDataAtual";

export const InicioDashboard = () => {
  const { currentClient } = useContext(CurrentClientContext);
  const { data: dataIndicadores } = useFetchData(
    `programaPlanejamento/indicadores/${currentClient.clienteId}`
  );
  const [boxes, setBoxes] = useState({ box1: "0" });
  const [option, setOption] = useState();
  const options = [
    { label: "Quantidade", value: null },
    { label: "Quantidade", value: 1 },
    { label: "LDO - Lei de Diretrizes Orçamentária", value: 2 },
    { label: "LOA - Lei Orçamentária Anual", value: 3 },
    { label: "PPA - Plano Plurianual", value: 4 },
  ];

  const getQuantidades = (value) => {
    if (value) {
      return value < 10 ? "0" + value : value;
    }
  };

  useEffect(() => {
    console.log(option);
  }, [option]);

  useEffect(() => {
    if (dataIndicadores) {
      setBoxes((prevValues) => ({
        ...prevValues,
        box1: {
          boxTitle: "Quantidade",
          boxSubtitle: "Indicadores",
          boxValue: getQuantidades(dataIndicadores.length),
        },
      }));
    }
  }, [dataIndicadores]);

  return (
    <>
      <Row className="row_form_2 mt-1" style={{ padding: "20px 10px" }}>
        <CCol>
          <Dashboard
            box1={
              option === 2
                ? {
                    component: (
                      <CustomComponent1
                        title={"Receita Atualizada"}
                        values={{
                          value1: "36.425.510,00",
                          value2: "4,00",
                          value3: "36.425.510,00",
                        }}
                        subText={{
                          subText1: "2024",
                          subText2: "Taxa de Inflação",
                          subText3: "2025",
                        }}
                      />
                    ),
                  }
                : boxes.box1
            }
            box2={
              option === 2
                ? {
                    component: (
                      <CustomComponent1
                        title={"Despesa Atualizada"}
                        values={{
                          value1: "36.425.510,00",
                          value2: "4,00",
                          value3: "36.425.510,00",
                        }}
                        subText={{
                          subText1: "2024",
                          subText2: "Taxa de Inflação",
                          subText3: "2025",
                        }}
                      />
                    ),
                  }
                : null
            }
            box3={
              option === 2
                ? {
                    component: (
                      <CustomComponent4
                        title={"Comparativo Receita x Despesa"}
                      />
                    ),
                  }
                : null
            }
            options={options}
            setOption={setOption}
          />
        </CCol>
        <CCol>
          <Dashboard
            box1={
              option === 2
                ? {
                    component: (
                      <CustomComponent2
                        title={"Inflação para o Exercício"}
                        value={"4,00"}
                        subText={"2024"}
                      />
                    ),
                  }
                : null
            }
            box2={
              option === 2
                ? {
                    component: (
                      <CustomComponent2
                        title={
                          "Percentual de Crédito Adicional Autorizado pelo poder Legislativo"
                        }
                        value={"50,00"}
                        subText={"2024"}
                      />
                    ),
                  }
                : null
            }
            box3={
              option === 2
                ? {
                    component: <CustomComponent3 title={"Fonte de Recursos"} />,
                  }
                : null
            }
          />
        </CCol>
      </Row>
      <Row
        className="row_form mt-1"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ReceitaDespesa />
      </Row>
      <div style={{ padding: "11px" }}></div>
    </>
  );
};

export default InicioDashboard;

const ReceitaDespesa = () => {
  const dataAtual = parseInt(ReturnDataAtual().split("/")[2]);
  const [despesaReceitaInputs, setDespesaReceitaInputs] = useState([]);

  useEffect(() => {
    setDespesaReceitaInputs([
      { key: "atual", receita: "32000000", despesa: "32000000" },
      {
        key: dataAtual + 1,
        receita: "32000000",
        despesa: "32000000",
      },
      {
        key: dataAtual + 3,
        receita: "32000000",
        despesa: "32000000",
      },
    ]);
  }, [dataAtual]);

  const formatValue = (valor) => {
    if (valor !== 0 || valor !== "" || valor !== null) {
      if (valor === null || valor === undefined) return "";
      const strValor = String(valor);

      const partes = strValor.split(".");
      const parteInteira = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      const parteDecimal =
        partes.length > 1 ? "," + (partes[1] || "00").padEnd(2, "0") : ",00";

      return `${parteInteira}${parteDecimal}`;
    }
  };

  return (
    <>
      {despesaReceitaInputs.map((item, index) => (
        <Row
          key={item.index}
          style={{
            marginTop: index !== 0 ? "20px" : null,
            width: "100%",
            alignItems: "center",
          }}
        >
          <CCol
            md={4}
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: index === 0 ? 700 : null,
            }}
          >
            Receita Prevista{" "}
            {index === 1
              ? dataAtual + index
              : index === 2
              ? dataAtual + index + 2
              : null}
          </CCol>
          <CCol md={2} style={{ display: "flex", alignItems: "center" }}>
            <FormControlListaDeInputs
              style={{
                borderRadius: "10px",
                padding: "15px",
                border: "none",
                textAlign: "end",
                fontWeight: index === 0 ? 700 : null,
              }}
              disabled
              value={formatValue(item.receita)}
            />
          </CCol>
          <CCol
            md={4}
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: index === 0 ? 700 : null,
            }}
          >
            Despesa Fixada{" "}
            {index === 1
              ? dataAtual + index
              : index === 2
              ? dataAtual + index + 2
              : null}
          </CCol>
          <CCol md={2} style={{ display: "flex", alignItems: "center" }}>
            <FormControlListaDeInputs
              style={{
                borderRadius: "10px",
                padding: "15px",
                border: "none",
                textAlign: "end",
                fontWeight: index === 0 ? 700 : null,
              }}
              disabled
              value={formatValue(item.despesa)}
            />
          </CCol>
        </Row>
      ))}
    </>
  );
};

const CustomComponent1 = ({ title, values, subText }) => (
  <>
    <h6>{title}</h6>
    <DivIndicadoresQuantidade>
      <span style={{ fontWeight: "700", fontSize: "22px" }}>
        R$ {values?.value1}
      </span>
      <span style={{ marginTop: "-7px" }}>{subText?.subText1}</span>
      <div style={{ display: "flex", alignItems: "center", margin: "10px 0" }}>
        <span style={{ fontWeight: "700", fontSize: "20px" }}>
          {values?.value2}%
        </span>
        <span>{subText?.subText2}</span>
      </div>
      <span style={{ fontWeight: "700", fontSize: "22px" }}>
        R$ {values?.value3}
      </span>
      <span style={{ marginTop: "-7px" }}>{subText?.subText3}</span>
    </DivIndicadoresQuantidade>
  </>
);

const CustomComponent2 = ({ title, value, subText }) => (
  <>
    <h6>{title}</h6>
    <DivIndicadoresQuantidade>
      <span style={{ fontWeight: "700", fontSize: "38px" }}>{value}%</span>
      <span style={{ marginTop: "-7px" }}>{subText}</span>
    </DivIndicadoresQuantidade>
  </>
);

const CustomComponent3 = ({ title }) => (
  <>
    <h6>{title}</h6>
    <DivIndicadoresQuantidade
      style={{ justifyContent: "start", marginTop: "20px" }}
    >
      <Row style={{ width: "100%" }}>
        <CCol md={4}>{""}</CCol>
        <CCol md={4}>
          <span style={{ fontWeight: "600" }}>Receita</span>
        </CCol>
        <CCol md={4}>
          <span style={{ fontWeight: "600" }}>Despesa</span>
        </CCol>
      </Row>
      {Array.from({ length: 3 }, (_, index) => (
        <Row style={{ width: "100%" }} key={index}>
          <CCol md={4}>1500 - Recursos...</CCol>
          <CCol md={4}>
            <span>R$ 150.000,00</span>
          </CCol>
          <CCol md={4}>
            <span>R$ 150.000,00</span>
          </CCol>
        </Row>
      ))}
    </DivIndicadoresQuantidade>
  </>
);

const BarGraph = ({ title, value, percentage, color }) => {
  return (
    <>
      <h6 style={{ fontWeight: "600", margin: "15px 0 5px 10px" }}>{title}</h6>
      <div
        style={{
          height: "80px",
          backgroundColor: color,
          display: "flex",
          alignItems: "end",
          fontWeight: "600",
          padding: "0 20px 5px 20px",
          paddingLeft: "20px",
          color: "#fff",
          minWidth: "fit-content",
          width: `${percentage}%`,
          textShadow:
            "0.5px 0.5px 0 #000, -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000",
        }}
      >
        R$ {value}
      </div>
    </>
  );
};

const CustomComponent4 = ({ title }) => (
  <>
    <h6>{title}</h6>
    <DivIndicadoresQuantidade
      style={{
        alignItems: "start",
        justifyContent: "start",
        marginTop: "20px",
      }}
    >
      <BarGraph
        title={"Receita"}
        value={"36.485.500,00"}
        percentage={70}
        color={"#515C70"}
      />
      <BarGraph
        title={"Despesa"}
        value={"36.485.500,00"}
        percentage={70}
        color={"#D9D9D9 "}
      />
      <BarGraph title={"Diferença"} value={"0,00"} color={"#515C70"} />
    </DivIndicadoresQuantidade>
  </>
);
