import { createContext, useCallback, useEffect, useState } from "react";

//1 - Criando Contexto

export const AuthContext = createContext();

//2 - Criando Provider

export const AuthContextProvider = ({ children }) => {
  const [userAuthData, setUserAuthData] = useState({});
  const [permissoesDoUsuario, setPermissoesDoUsuario] = useState([]);
  const [tokenAuth, setTokenAuth] = useState(null);

  const fillPermissions = useCallback(() => {
    const permissoes = userAuthData.clientes.map((cliente) => {
      const sistemasAtivos = cliente.permissoes.sistemas.filter(
        (sistema) => sistema.sistemaAtivo === 1
      );
      const permissoesAtivas = sistemasAtivos.map((sistema) => ({
        sistema: sistema.sistema,
        menus: sistema.menus
          .filter((menu) => menu.menuAtivo === 1)
          .map((menu) => ({
            menu: menu.menu,
            acoes: menu.acoes
              .filter((acao) => acao.acaoAtiva === 1)
              .map((acao) => ({
                acao: acao.acao,
              })),
          })),
      }));

      return {
        clienteId: cliente.clienteId,
        tipoDeUsuario: cliente.tipoUsuario,
        permissoes: [...permissoesAtivas],
      };
    });

    console.log("-------------Array de permissoes do usuario---------------");
    console.log(permissoes);
    setPermissoesDoUsuario(permissoes);
  }, [userAuthData.clientes]);

  useEffect(() => {
    if (Object.keys(userAuthData).length > 0) {
      fillPermissions();
    }
  }, [userAuthData, fillPermissions]);

  return (
    <AuthContext.Provider
      value={{
        userAuthData,
        setUserAuthData,
        tokenAuth,
        setTokenAuth,
        permissoesDoUsuario,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
