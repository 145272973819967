import React, { useContext, useEffect, useState } from "react";
import {
  CCol,
  CColCodigo,
  ContainerToModal,
  RowToModal,
} from "../../../../components/Grid/CCol";
import { OptionsStyle } from "../../../../components/SelectOptionsAbaForm/OptionsStyle";
import Secretario from "./Secretario";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import { FormatCnpjPaste } from "../../../../utils/FormatacaoDeDados/FormatCnpjPaste";
import { PTitulosInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import { CheckBoxCircle } from "../../../../styles/StyledComponents/InputsPersonalizados";
import api from "../../../../utils/api";
import ModalAviso from "../../../../components/Modais/ModalAviso";
import ButtonSaveModalAutoComplete from "../../../../components/Modais/ButtonSaveModalAutoComplete";
import { OpenedModalsContext } from "../../../../contexts/OpenedModalsContext";
import { DefinirRequisicaoDoModalComoConluida } from "../../../../utils/Gerais/DefinirRequisicaoDoModalComoConcluida";

function UnidadeOrcamentaria({ isAModalPage = false }) {
  const [botaoAtivo, setBotaoAtivo] = useState(0);
  const [optionsUnidadeGestora, setOptionsUnidadeGestora] = useState([]);
  const [teclaPressionada, setTeclaPressionada] = useState("");
  const [modalOpened, setModalOpened] = useState(false);

  const [checkCircleYes, setCheckCircleYes] = useState(false);
  const [checkCircleNo, setCheckCircleNo] = useState(false);
  const [unidadeIdForPut, setUnidadeIdForPut] = useState(0);
  const [unidadeResponsavelOrcamento, setUnidadeResponsavelOrcamento] =
    useState();

  const { currentClient } = useContext(CurrentClientContext);

  const { codigo: paramsCodigo } = useParams();

  //   1 - Educação
  // 2 - Saúde
  // 3 - Previdência
  // 4 - Assistencia Social
  // 9 - Demais

  const [valuesSecretarioAdicionar, setValuesSecretarioAdicionar] = useState({
    dataContrato: "",
    nome: "",
    pessoaId: "",
    atoNomeacao: "1 - Lei",
  });
  const { openedModals, setOpenedModals } = useContext(OpenedModalsContext);

  const [optionsFuncao, setOptionsFuncao] = useState([]);

  const [valuesUnidadeOrcamentaria, setValuesUnidadeOrcamentaria] = useState({
    unidade: {
      clienteId: currentClient.clienteId, //Int
      unidadeGestoraId: "", //Int
      codigo: "", //Int
      poder: "", //String
      naturezaJuridica: "", //String
      funcaoId: "", //String
      titulo: "", //String
      responsavelOrcamento: 0,
    },
    secretarios: [
      {
        pessoaId: "", //Int
        atoNomeacao: "1 - Lei", //String
        dataContrato: "", //String
      },
    ],
    palavrasChavePersonalizadas: {
      funcaoId: 0,
      palavras: [],
    },
  });

  // const [pagAtualizacao, setPagAtualizacao] = useState(false);
  const [nomeUnidadeGestora, setNomeUnidadeGestora] = useState("");

  //Array de palavras chaves

  useEffect(() => {
    getFuncoes();
    if (
      (!paramsCodigo || isAModalPage) &&
      currentClient.tipoNaturezaJuridica !== "Prefeitura"
    ) {
      setNomeUnidadeGestora(currentClient.nomeFantasia);
      fetchSelectedUnidade(currentClient.clienteId);
    }
  }, [paramsCodigo, currentClient, isAModalPage]);

  useEffect(() => {
    if (!paramsCodigo || isAModalPage) {
      fetchUnidadesOrcamentarias();
    }

    //eslint-disable-next-line
  }, [paramsCodigo, isAModalPage]);

  useEffect(() => {
    fetchUnidadesGestoras();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (paramsCodigo && !isAModalPage) {
      preencheDadosAtualizacao();
    }
    //eslint-disable-next-line
  }, [paramsCodigo, isAModalPage]);

  useEffect(() => {
    const tipoDeVinculo = valuesUnidadeOrcamentaria.unidade.funcaoId;
    let vinculoId = 0;

    switch (tipoDeVinculo) {
      case "Educação":
        vinculoId = 1;
        break;
      case "Saúde":
        vinculoId = 2;
        break;
      case "Previdência":
        vinculoId = 3;
        break;
      case "Assistencia Social":
        vinculoId = 4;
        break;
      case "Demais":
        vinculoId = 5;
        break;
      default:
        vinculoId = 0;
        break;
    }

    setValuesUnidadeOrcamentaria((prevValues) => ({
      ...prevValues,
      palavrasChavePersonalizadas: {
        ...prevValues.palavrasChavePersonalizadas,
        funcaoId: vinculoId,
      },
    }));
  }, [valuesUnidadeOrcamentaria.unidade.funcaoId]);

  const openModal = () => {
    setModalOpened(true);
  };

  const preencheDadosAtualizacao = () => {
    api
      .get(`unidadeOrcamentaria/por-id/${paramsCodigo}`)
      .then((resp) => {
        console.log("get aqui: ", resp.data);
        const data = resp.data;
        setValuesUnidadeOrcamentaria((prevValues) => ({
          ...prevValues,
          unidade: {
            ...prevValues.unidade,
            clienteId: data.unidade.clienteId, //Int
            unidadeGestoraId: data.unidade.unidadeGestoraId, //Int
            codigo: data.unidade.codigo, //Int
            poder: data.unidade.poder, //String
            naturezaJuridica: data.unidade.naturezaJuridica, //String
            funcaoId: data.unidade.funcaoId,
            id: data.unidade.id, //String
            titulo: data.unidade.titulo, //String
            responsavelOrcamento: parseInt(data.unidade.responsavelOrcamento),
          },
          secretarios: data.secretarios.map((secretario) => ({
            pessoaId: secretario.pessoaId,
            atoNomeacao: secretario.atoNomeacao,
            dataContrato: secretario.dataContrato,
          })),
        }));

        setUnidadeIdForPut(data.unidade.id);

        if (parseInt(data.unidade.responsavelOrcamento)) {
          setCheckCircleYes(true);
        } else {
          setCheckCircleNo(true);
        }

        // setPagAtualizacao(true);

        setNomeUnidadeGestora(data.unidadeGestora.nomeFantasia);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Algo deu errado ao carregar os dados.");
      });
  };

  const fetchUnidadesOrcamentarias = () => {
    api
      .get(`unidadeOrcamentaria/cliente/${currentClient.clienteId}`)
      .then((resp) => {
        const data = resp.data;
        console.log(data);
        const unidadeResponsavelPeloOrcamentoObject = data.find(
          (item) => item.unidade.responsavelOrcamento === 1
        );

        if (unidadeResponsavelPeloOrcamentoObject) {
          setCheckCircleNo(true);
          setUnidadeResponsavelOrcamento(
            unidadeResponsavelPeloOrcamentoObject.unidade.codigo
          );
        } else {
          setValuesUnidadeOrcamentaria((prev) => ({
            ...prev,
            unidade: {
              ...prev.unidade,
              responsavelOrcamento: 1,
            },
          }));
          setCheckCircleYes(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchUnidadesGestoras = () => {
    api
      .get(
        `cliente/pesquisa/regiao/${currentClient.endereco.municipio}/${currentClient.endereco.estado}`
      )
      .then((resp) => {
        const data = resp.data;
        console.log(data);
        const selectOptions = data.map((item) => ({
          label: `${item.pessoaJuridica.nomeFantasia} - ${FormatCnpjPaste(
            item.pessoaJuridica.cnpj
          )}`,
          value: item.id,
        }));

        console.log(selectOptions);

        setOptionsUnidadeGestora(selectOptions);
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Algo deu errado. Por favor, tente acessar essa pagina mais tarde."
        );
      });
  };

  const fetchSelectedUnidade = (id) => {
    console.log(id);
    api.get(`cliente/pesquisa/id/${id}`).then((resp) => {
      const data = resp.data[0];
      console.log(data);

      setValuesUnidadeOrcamentaria((prevValues) => ({
        ...prevValues,
        unidade: {
          ...prevValues.unidade,
          unidadeGestoraId: parseInt(id),
          poder: data.poder,
          naturezaJuridica: data.tipoNaturezaJuridica,
        },
      }));
    });
  };

  const cadastraUnidadeOrcamentaria = () => {
    console.log("Values to post: ", valuesUnidadeOrcamentaria);
    api
      .post(`unidadeOrcamentaria`, valuesUnidadeOrcamentaria)
      .then((resp) => {
        console.log(resp);
        toast.success("Cadastro realizado com sucesso");
        limpaValoresUnidadeOrcamentaria();

        //se for uma pagina de modal entramos aqui para retornamos a informacao que a req de cadastro foi concluida
        if (isAModalPage) {
          DefinirRequisicaoDoModalComoConluida(
            openedModals,
            setOpenedModals,
            "Unidade Orçamentária"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data);
      });
  };

  const limpaValoresUnidadeOrcamentaria = () => {
    setValuesUnidadeOrcamentaria((prev) => ({
      ...prev,
      unidade: {
        ...prev.unidade,
        codigo: "", //Int
        funcaoId: null, //String
        titulo: "", //String
        responsavelOrcamento: 0,
      },
      secretarios: [
        {
          dataContrato: "",
          nome: "",
          pessoaId: "",
          atoNomeacao: "1 - Lei",
        },
      ],
      palavrasChavePersonalizadas: {
        ...prev.palavrasChavePersonalizadas,
        funcaoId: 0,
        palavras: [],
      },
    }));

    setCheckCircleYes(false);
    setCheckCircleNo(true);
  };

  const atualizaUnidadeOrcamentaria = () => {
    console.log(unidadeIdForPut);

    const valuesForPut = {
      responsavelOrcamento:
        valuesUnidadeOrcamentaria.unidade.responsavelOrcamento,
      unidadeGestoraId: valuesUnidadeOrcamentaria.unidade.unidadeGestoraId,
      codigo: valuesUnidadeOrcamentaria.unidade.codigo,
      poder: valuesUnidadeOrcamentaria.unidade.poder,
      naturezaJuridica: valuesUnidadeOrcamentaria.unidade.naturezaJuridica,
      funcaoId: valuesUnidadeOrcamentaria.unidade.funcaoId,
      titulo: valuesUnidadeOrcamentaria.unidade.titulo,
      palavrasChavePersonalizadas:
        valuesUnidadeOrcamentaria.palavrasChavePersonalizadas.palavras,
    };

    console.log("Values for put: ", valuesForPut);

    api
      .put(`unidadeOrcamentaria/${unidadeIdForPut}`, valuesForPut)
      .then((resp) => {
        toast.success("Registro atualizado com sucesso!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Algo deu errado ao atualizar o registro.");
      });
  };

  console.log(valuesUnidadeOrcamentaria);

  const getFuncoes = () => {
    api
      .get(`funcao/funcoes`)
      .then((response) => {
        const funcoes = response.data;
        const newOptions = funcoes.map((funcao) => ({
          label: `${funcao.valor} - ${funcao.nome}`,
          value: funcao.id,
        }));
        setOptionsFuncao([
          { label: "Tipo de Vínculo", value: null },
          ...newOptions,
        ]);
        console.log("Funções: ", funcoes);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ContainerToModal
      id={isAModalPage ? undefined : "pags_responsividade_padding_geral"}
      fluid
      isAModalPage={isAModalPage}
      className={
        isAModalPage ? undefined : "container_conteudo conteudo_pagina"
      }
    >
      {!isAModalPage && (
        <HeaderCadastros
          NomePaginaTitulo={"Unidade Orçamentária"}
          PaginaSubtitulo={"Cadastro"}
          ButtonSaveFunction={
            paramsCodigo
              ? atualizaUnidadeOrcamentaria
              : cadastraUnidadeOrcamentaria
          }
          PaginaConsulta={
            "/planejamento/cadastro/unidade-orcamentaria/consulta"
          }
          PaginaRelatorio={"/planejamento/unidade-orcamentaria/relatorio"}
        />
      )}
      {/* <button onClick={() => console.log(valuesUnidadeOrcamentaria)}>
        console
      </button> */}
      {!isAModalPage && <ToastContainer />}

      {
        <ButtonSaveModalAutoComplete
          SaveFunction={cadastraUnidadeOrcamentaria}
          pageTitle={"Unidade Orçamentária"}
        />
      }
      <ModalAviso
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
        tituloModal={"Substituir Unidade Responsavel Pelo Orçamento"}
        msg={`A unidade de codigo ${unidadeResponsavelOrcamento} já está registrada como Responsavel Pelo Orçamento, cadastrar outra unidade como Responsavel substituira a Unidade Responsavel Anterior`}
      />
      <RowToModal isAModalPage={isAModalPage} className="row_form mt-1">
        <CCol md={6}>
          {!paramsCodigo &&
            currentClient.tipoNaturezaJuridica
              .toLowerCase()
              .includes("prefeitura") && (
              <FloatingLabelInput
                new={true}
                disabled={paramsCodigo ? true : false}
                value={valuesUnidadeOrcamentaria.unidade.unidadeGestoraId}
                style={{ color: "#515c70" }}
                tipo="input_select"
                placeholder="Unidade Gestora"
                label="Unidade Gestora"
                defaultValue=""
                onSelect={(option) => fetchSelectedUnidade(option.value)}
                options={[
                  { label: "Unidade Gestora", value: null },
                  ...optionsUnidadeGestora,
                ]}
              />
            )}
          {paramsCodigo &&
            !currentClient.tipoNaturezaJuridica
              .toLowerCase()
              .includes("prefeitura") && (
              <FloatingLabelInput
                new={true}
                type="text"
                value={nomeUnidadeGestora}
                disabled={true}
                placeholder="Unidade Gestora"
                label="Unidade Gestora"
              />
            )}
          {!paramsCodigo &&
            !currentClient.tipoNaturezaJuridica
              .toLowerCase()
              .includes("prefeitura") && (
              <FloatingLabelInput
                new={true}
                type="text"
                value={nomeUnidadeGestora}
                disabled={true}
                placeholder="Unidade Gestora"
                label="Unidade Gestora"
              />
            )}

          {paramsCodigo &&
            currentClient.tipoNaturezaJuridica
              .toLowerCase()
              .includes("prefeitura") && (
              <FloatingLabelInput
                new={true}
                value={valuesUnidadeOrcamentaria.unidade.unidadeGestoraId}
                style={{ color: "#515c70" }}
                tipo="input_select"
                label="Unidade Gestora"
                defaultValue=""
                onSelect={(option) => fetchSelectedUnidade(option.value)}
                options={[
                  { label: "Unidade Gestora", value: null },
                  ...optionsUnidadeGestora,
                ]}
              />
            )}
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            type="text"
            value={valuesUnidadeOrcamentaria.unidade.poder}
            disabled={true}
            placeholder="Poder"
            label="Poder"
          />
        </CCol>
        <CCol md={4}>
          <FloatingLabelInput
            value={valuesUnidadeOrcamentaria.unidade.naturezaJuridica}
            disabled={true}
            type="text"
            placeholder="Natureza Juridica"
            label="Natureza Juridica"
          />
        </CCol>
        <div className="pt-3"></div>
        <CColCodigo md={1}>
          <FloatingLabelInput
            style={{ color: "#515c70" }}
            type="text"
            maxLength="6"
            value={valuesUnidadeOrcamentaria.unidade.codigo}
            onKeyDown={(e) => setTeclaPressionada(e.key)}
            onChange={(e) => {
              if (teclaPressionada !== "Backspace") {
                const value = e.target.value.replace(/[^0-9]/g, "");
                let mask = "";

                for (let i = 0; i < value.length; i++) {
                  mask += value[i];
                  if (i === 1) {
                    mask += ".";
                  }
                }
                e.target.value = mask;
              }

              setValuesUnidadeOrcamentaria((prevValues) => ({
                ...prevValues,
                unidade: {
                  ...prevValues.unidade,
                  codigo: e.target.value,
                },
              }));
            }}
            placeholder="Código"
            label="Código"
          />
        </CColCodigo>
        <CCol md={7}>
          <FloatingLabelInput
            type="text"
            placeholder="Título"
            value={valuesUnidadeOrcamentaria.unidade.titulo || ""}
            label="Título"
            onChange={(e) => {
              setValuesUnidadeOrcamentaria((prevValues) => ({
                ...prevValues,
                unidade: {
                  ...prevValues.unidade,
                  titulo: e.target.value,
                },
              }));
            }}
          />
        </CCol>
        <CCol md={2}>
          <FloatingLabelInput
            new={true}
            style={{ color: "#515c70" }}
            tipo="input_select"
            value={valuesUnidadeOrcamentaria.unidade.funcaoId}
            label="Tipo de Vínculo"
            placeholder="Tipo de Vínculo"
            onChange={(e) => {
              setValuesUnidadeOrcamentaria((prevValues) => ({
                ...prevValues,
                unidade: {
                  ...prevValues.unidade,
                  funcaoId: e.target.value,
                },
              }));
            }}
            options={optionsFuncao}
            onSelect={(option) =>
              setValuesUnidadeOrcamentaria((prevValues) => ({
                ...prevValues,
                unidade: {
                  ...prevValues.unidade,
                  funcaoId: option.value,
                },
              }))
            }
          />
        </CCol>
        <CCol>
          <PTitulosInputs>Responsavel LOA</PTitulosInputs>
          <div
            style={{
              display: "flex",
            }}
          >
            <label
              style={{
                display: "flex",
                marginRight: "20px",
                fontWeight: "500",
                color: "#515C70",
                cursor: "pointer",
              }}
              onClick={() => {
                console.log(checkCircleYes);

                if (checkCircleNo) {
                  setCheckCircleYes(true);
                  setCheckCircleNo(false);

                  if (unidadeResponsavelOrcamento) {
                    openModal();
                  }

                  setValuesUnidadeOrcamentaria((prevValues) => ({
                    ...prevValues,
                    unidade: {
                      ...prevValues.unidade,
                      responsavelOrcamento: 1,
                    },
                  }));
                }
              }}
            >
              <CheckBoxCircle
                disabled={checkCircleYes ? true : false}
                checked={checkCircleYes}
              />{" "}
              Sim
            </label>

            <label
              style={{
                display: "flex",
                fontWeight: "500",
                color: "#515C70",
                cursor: "pointer",
              }}
              onClick={() => {
                console.log(checkCircleNo);

                if (checkCircleYes) {
                  setCheckCircleYes(false);
                  setCheckCircleNo(true);

                  setValuesUnidadeOrcamentaria((prevValues) => ({
                    ...prevValues,
                    unidade: {
                      ...prevValues.unidade,
                      responsavelOrcamento: 0,
                    },
                  }));
                }
              }}
            >
              <CheckBoxCircle
                disabled={checkCircleNo ? true : false}
                checked={checkCircleNo}
              />{" "}
              Não
            </label>
          </div>
        </CCol>
      </RowToModal>
      <div>
        <RowToModal isAModalPage={isAModalPage}>
          <CCol
            className="d-flex align-items-center row_endereco_aba mt-1"
            md={12}
          >
            <OptionsStyle
              ativo={botaoAtivo === 0}
              onClick={() => setBotaoAtivo(0)}
            >
              Secretário
            </OptionsStyle>
          </CCol>
        </RowToModal>
        {botaoAtivo === 0 && (
          <Secretario
            isAModalPage={isAModalPage}
            setValues={setValuesUnidadeOrcamentaria}
            values={valuesUnidadeOrcamentaria}
            valuesSecretarioAdicionar={valuesSecretarioAdicionar}
            setValuesSecretarioAdicionar={setValuesSecretarioAdicionar}
            currentClient={currentClient}
          />
        )}
      </div>
    </ContainerToModal>
  );
}

export default UnidadeOrcamentaria;
