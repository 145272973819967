import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { TituloAbaSolo } from "../../../../styles/StyledComponents/LayoutGerais";
import { CCol, CColButtonsAcoes2Icons } from "../../../../components/Grid/CCol";
import { PTitulosInputs } from "../../../../styles/StyledComponents/ListaDeInputs";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import Section from "./section";
import { Icon } from "@iconify/react/dist/iconify.js";
import { especificacoes } from "../Riscos_Fiscais/Especificacoes";
import IconAdicionar from "../../../../components/ListaDeInputs/IconAdicionar";
//import { ReturnArrayAnos } from "./returnArrayAnos";

const Divida = ({
  arrayEspecificacoesNominal,
  setArrayEspecificacoesNominal,
  valueEspecificacao,
  setValueEspecificacao,
}) => {
  const [values, setValues] = useState([
    {
      1: {
        key: "1",
        data: Array(7).fill({ value: "" }),
      },
      2: {
        key: "2",
        data: Array(7).fill({ value: "" }),
      },
    },
  ]);

  const [especificacao, setEspecificacao] = useState(["Dívida Consolidada"]);

  const widths = ["14%", "14%", "14%", "14%", "6.2%", "14%", "6.2%"];

  const tempTitulo = [
    { titulo: "2021" },
    { titulo: "2022" },
    { titulo: "2023" },
    { titulo: "2024" },
    { titulo: "%" },
    { titulo: "2025" },
    { titulo: "%" },
  ];

  const sections = [
    {
      key: "1",
      title: "Prevista",
      showActionTitle: true,
      position: 1,
      tempTitulo,
    },
    { key: "2", title: "Realizada", position: 2 },
  ];

  const adicionarEspecificacao = () => {
    if (valueEspecificacao) {
      setEspecificacao((prevValues) => [...prevValues, valueEspecificacao]);
      setValues((prevValues) => [
        ...prevValues,
        {
          1: {
            key: "1",
            data: Array(7).fill({ value: "" }),
          },
          2: {
            key: "2",
            data: Array(7).fill({ value: "" }),
          },
        },
      ]);
    }
  };

  const deleteEspecificacao = (index) => {
    setValues((prevValues) => {
      const newValue = [...prevValues];
      newValue.splice(index, 1);
      return newValue;
    });
    setEspecificacao((prevValues) => {
      const newValue = [...prevValues];
      newValue.splice(index, 1);
      return newValue;
    });
  };

  return (
    <>
      <Row className="row_form mt-1">
        <TituloAbaSolo>Dívida Consolidada</TituloAbaSolo>
      </Row>
      <Row
        className="row_form mt-1"
        style={
          arrayEspecificacoesNominal.length === 0
            ? { borderRadius: "0px 0px 5px 5px" }
            : {}
        }
      >
        <CCol>
          <FloatingLabelInput
            placeholder="Especificações"
            new={true}
            label={"Especificações"}
            tipo="input_select"
            options={[...especificacoes]}
            onSelect={(option) => setValueEspecificacao(option.value)}
            value={valueEspecificacao}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <IconAdicionar onClick={adicionarEspecificacao} />
        </CColButtonsAcoes2Icons>
      </Row>
      {values.map((value, index) => (
        <React.Fragment key={index}>
          <Row className="row_form mt-1">
            <CCol>
              <PTitulosInputs style={{ marginTop: "0px" }}>
                Especificação
              </PTitulosInputs>
              <FloatingLabelInput
                disabled
                value={especificacao[index]}
                style={{ border: "none" }}
              />
            </CCol>
            <CColButtonsAcoes2Icons md={1}>
              <Icon
                icon="ic:baseline-delete"
                color="#E79900"
                height="28"
                onClick={() => deleteEspecificacao(index)}
                style={{
                  cursor: "pointer",
                  marginLeft: "2px",
                  marginTop: "22px",
                }}
              />
            </CColButtonsAcoes2Icons>
            {sections.map((section) => (
              <Section
                key={section.key}
                values={value[section.key]?.data}
                title={section.title}
                setValues={(newValues) =>
                  setValues((prev) =>
                    prev.map((item) =>
                      item.key === section.key
                        ? { ...item, data: newValues }
                        : item
                    )
                  )
                }
                tempTitulo={section.tempTitulo}
                showActionTitle={section.showActionTitle}
                position={section.position}
                widths={widths}
              />
            ))}
          </Row>
        </React.Fragment>
      ))}
    </>
  );
};

export default Divida;
