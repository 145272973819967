import styled from "styled-components";

const InputContainer = styled.div`
  border: 1px solid #cccccc;
  padding: 6px;
  padding-left: 10px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ disable, backgroundColor }) =>
    disable ? "#e9ecef" : backgroundColor ? backgroundColor : ""};
`;

const InputDate = styled.input`
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  width: 100%;
  font-weight: 500;
  color: #515c70;
`;

const DateIcon = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='%23515C70' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M8 2v4m8-4v4'/%3E%3Crect width='18' height='18' x='3' y='4' rx='2'/%3E%3Cpath d='M3 10h18'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  width: 24px;
  height: 24px;
`;

export const InputDateRelatorio = ({
  handleDate,
  disable = false,
  backgroundColor,
  ...props
}) => {
  return (
    <InputContainer disable={disable} backgroundColor={backgroundColor}>
      <InputDate
        type="text"
        onChange={(e) => handleDate(e.target.value)}
        disabled={disable}
        {...props}
      />
      <DateIcon />
    </InputContainer>
  );
};
