//import { calcularTotalReceitasComDeducao } from "../../../../LOA_QDR/RELATORIO_QDR/calcularReceitas";
import api from "../../../../../../utils/api";

export const getQuadroDetalhadoQDDAplicacao = async (clienteId) => {
  try {
    const response = await api.get(`qdd/relatorio-qdd/${clienteId}/2024`);
    console.log("response.data", response.data);
    return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
  }
};

const processAllData = (dataQddRelatorio) => {
  let sumValues = 0;
  const orderData = (data) => {
    const sortAcoes = (unidade) => {
      unidade.acoes.sort((a, b) => a.nAcao.localeCompare(b.nAcao));
    };
    data.forEach((unidade) => sortAcoes(unidade));
    data.sort((a, b) =>
      a.codigoUnidadeOrcamentaria.localeCompare(b.codigoUnidadeOrcamentaria)
    );
    return data;
  };

  const part1 = (data) => {
    let filteredData = [];
    data.map((item) => {
      if (item.totalAcoesDaUnidade) {
        sumValues += parseFloat(item.totalAcoesDaUnidade);
      }
      //02.010 Gabinete do Prefeito - Exemplo
      filteredData.push({
        serial: null,
        codigo: item.codigoUnidadeOrcamentaria,
        titulo: item.tituloUnidadeOrcamentaria,
        tipo: null,
        valor: item.totalAcoesDaUnidade,
      });
      item.acoes.map((subItem) => {
        //04.122.2001 2003 Manutenção das Atividades do Gabinete do Prefeito  - Exemplo
        filteredData.push(
          {
            serial: `${subItem.funcao.valor}.${subItem.subFuncao.valor}.${subItem.programaPlanejamentoId.numeroPrograma}`,
            codigo: subItem.nAcao,
            titulo: subItem.titulo,
            tipo: null,
            valor: subItem.valorTotalDaAcao,
          },
          //Manter as atividades do Gabinete do Prefeito... - exemplo
          {
            serial: null,
            codigo: null,
            titulo: subItem.objetivos,
            tipo: null,
            valor: null,
          }
        );
        //1.500 Recursos não Vinculados de Impostos - Exemplo
        subItem.despesaFixadaQDD.map((subSubItem) => {
          filteredData.push({
            serial: null,
            codigo: subSubItem.fonteDeRecurso.conta,
            titulo: subSubItem.fonteDeRecurso.titulo,
            tipo: null,
            valor: subSubItem.totalPorFonte,
          });
          return "";
        });
        return "";
      });
      return "";
    });
    return filteredData;
  };

  const dadosRelatorio = part1(orderData(dataQddRelatorio));
  console.log("DADOS RELATOOORIOS: ", dadosRelatorio);

  return {
    data: dadosRelatorio,
    sum: sumValues,
  };
};
