import React from "react";
import { Container } from "react-bootstrap";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";

const Obra = () => {
  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros NomePaginaTitulo={"Obra"} PaginaSubtitulo={"Cadastro"} />
    </Container>
  );
};

export default Obra;
