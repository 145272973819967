import api from "../../../../../../utils/api";

export const getReceitaPorFonteRec = async (clienteId) => {
  try {
    const response = await api.get(
      `qdr/relatorio/fontes-esfera/${clienteId}/2024?fontesId=`
    );
    console.log("response.data", response.data);
    return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
  }
};

export const processAllData = (fontes) => {
  const letsCompare = (number) => {
    const valor = parseFloat(number.replace(".", ""));
    switch (true) {
      case valor < 1540:
        return "Recursos Livres (Não Vinculados)";
      case valor < 1700:
        return "Recursos Vinculados a Educação";
      case valor < 1750:
        return "Demais Vinculações Decorrentes de Transferências";
      case valor < 1800:
        return "Demais Vinculações Legais";
      case valor < 1860:
        return "Recursos Vinculados à Previdência Social";
      case valor < 1880:
        return "Recursos Extraorçamentários";
      case valor < 1900:
        return "Outras Vinculações";
      default:
        return "Valor fora do intervalo esperado";
    }
  };
  let fontesNaturezas = fontes.fontes;
  let fontesDeducao = fontes.fontesDeducao;

  const part1 = () => {
    let fontesRec = [];
    let dadosFiltrados = [];
    let actualType = null;

    fontesNaturezas.resultados.forEach((item) => {
      fontesRec.push({
        funcao: item.funcao,
        codigo: item.conta,
        especificacao: item.titulo,
        valor: parseFloat(item.totalFiscal) + parseFloat(item.totalSeguridade),
      });
    });

    fontesRec = fontesRec.sort((a, b) => {
      if (a.codigo < b.codigo) return -1;
      if (a.codigo > b.codigo) return 1;
      return 0;
    });

    fontesRec.forEach((item) => {
      const especificacao = letsCompare(item.codigo);
      if (actualType !== especificacao) {
        actualType = especificacao;
        dadosFiltrados.push({
          codigo: null,
          especificacao: especificacao,
          valor: null,
        });
      }
      dadosFiltrados.push(item);
    });
    return dadosFiltrados;
  };

  const part2 = () => {
    let fontesRec = [];
    fontesDeducao.resultados.forEach((item) => {
      fontesRec.push({
        codigo: item.conta,
        especificacao: `${item.titulo}`,
        valor: parseFloat(item.totalFiscal) + parseFloat(item.totalSeguridade),
      });
    });
    return fontesRec;
  };

  const allData = {
    data: [
      ...part1(),
      {
        codigo: "",
        especificacao: "Contribuição Para a Formação do FUNDEB - DEDUÇÃO",
        valor: "",
      },
      ...part2(),
    ],
    sum:
      parseFloat(fontesNaturezas.totais.totalGeral) -
      parseFloat(fontesDeducao.totais.totalGeral),
  };

  const sumAndGroupValues = (data) => {
    let soma = 0;
    return data.map((item, index, arr) => {
      if (item.codigo === "" && item.valor === "") {
        soma = 0;
        for (let i = index + 1; i < arr.length && arr[i].codigo !== ""; i++) {
          soma += arr[i].valor ? arr[i].valor : 0;
        }
        return { ...item, valor: soma };
      }
      return item;
    });
  };

  const filteredData = {
    data: sumAndGroupValues(allData.data),
    sum: allData.sum,
  };
  return filteredData;
};
