import { FormatValueToLocaleString } from "../../../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { calcularTotalReceitas } from "../../../../LOA_QDR/RELATORIO_QDR/calcularReceitas";
import api from "../../../../../../utils/api";

export const getReceitasCatEconOrgao = async (clienteId) => {
  try {
    const response = await api.get(
      `qdr/relatorio/consolidado/${clienteId}/2024`
    );
    console.log("response.data", response.data);
    return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
  }
};

export const processAllData = (dataQdrRelatorioCatEconOrgao) => {
  const codigosSectionRelatorio = ["1.0.0.0.00.0.0", "2.0.0.0.00.0.0"];
  const fonteComDeducao = 3;
  const fonteSemDeducao = 1;

  const processItem = (item, deducao) => {
    const seccoes = item.nr.split(".").filter((secao) => parseInt(secao) !== 0);
    let desdobramento = null,
      fonte = null,
      catEcon = null;

    if (seccoes.length === 1) {
      catEcon = item.valor;
    } else if (seccoes.length === 2) {
      fonte = item.valor;
    } else if (seccoes.length > 2) {
      desdobramento = item.valor;
    }

    const resultado = [
      {
        codigo: item.nr,
        especificacao: item.especificacao,
        desdobramento: desdobramento,
        fonte: fonte,
        catEcon: catEcon,
        valor: item.valor,
      },
    ];

    if (
      item.qdr &&
      item.qdr.fontesDeRecursos &&
      item.qdr.fontesDeRecursos.length > 0
    ) {
      const recursos = item.qdr.fontesDeRecursos
        .map((recurso) => {
          if (recurso.tipo === deducao) {
            return {
              codigo: recurso.conta,
              especificacao: recurso.titulo,
              desdobramento: recurso.valorPrevisto,
              fonte: null,
              catEcon: null,
              valor: recurso.valorPrevisto,
            };
          }
          return null;
        })
        .filter((recurso) => recurso !== null);
      resultado.push(...recursos);
    }

    return resultado;
  };

  const dadosFiltrados = Object.keys(dataQdrRelatorioCatEconOrgao).map(
    (key) => {
      const clienteData = dataQdrRelatorioCatEconOrgao[key];

      return {
        infoCliente: {
          codigo: clienteData.infoCliente[0].id,
          especificacao: clienteData.infoCliente[0].unidadeGestora.nomeFantasia,
        },
        naturezas: clienteData.naturezas.flatMap((natureza) =>
          processItem(natureza, fonteSemDeducao)
        ),
        naturezasDeducao: clienteData.naturezasDeducao.flatMap((natureza) =>
          processItem(natureza, fonteComDeducao)
        ),
      };
    }
  );
  const totalReceitas = Object.keys(dataQdrRelatorioCatEconOrgao).reduce(
    (acc, key) => {
      const clienteData = dataQdrRelatorioCatEconOrgao[key];
      const receitas = {
        naturezas: clienteData.naturezas,
        naturezasDeducao: clienteData.naturezasDeducao,
      };

      const total = calcularTotalReceitas(receitas, codigosSectionRelatorio);

      return acc + total;
    },
    0
  );

  return {
    data: dadosFiltrados,
    sum: FormatValueToLocaleString(totalReceitas),
  };
};
