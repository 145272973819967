import React from "react";
import {
  CCol,
  CColButtonsAcoes2Icons,
  RowAninhada,
  RowToModal,
} from "../../../../components/Grid/CCol";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { Icon } from "@iconify/react/dist/iconify.js";

const FonteDeRecursosAba = () => {
  return (
    <>
      <RowToModal
        className={"row_form mt-3"}
        style={{
          borderRadius: "5px 5px 0px 0px",
          padding: "8px 10px",
        }}
      >
        <CCol
          style={{
            fontSize: "16px",
            color: "#515c70",
            fontWeight: "700",
          }}
        >
          Fonte de Recursos / CO
        </CCol>
      </RowToModal>
      <RowToModal className={"row_form_2 mt-1"}>
        <CCol>
          <PTitulosInputs>Fonte de Recursos + CO</PTitulosInputs>
        </CCol>
        <CCol md={1}>
          <PTitulosInputs>%</PTitulosInputs>
        </CCol>
        <CCol md={2}>
          <PTitulosInputs>Valor</PTitulosInputs>
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
        </CColButtonsAcoes2Icons>

        <RowAninhada>
          <CCol>
            <MyAutoComplete
              options={[{ label: "teste", value: 1 }]}
              labelFormat={(option) => `${option.label} - ${option.value}`}
            />
          </CCol>
          <CCol md={1}>
            <InputFormatRealBrasileiro listaDeInputs={true} />
          </CCol>
          <CCol md={2}>
            <InputFormatRealBrasileiro listaDeInputs={true} />
          </CCol>
          <CColButtonsAcoes2Icons md={1}>
            <Icon
              icon="ic:baseline-add-box"
              color="#105200"
              height="28"
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                marginTop: "1px",
              }}
            />
          </CColButtonsAcoes2Icons>
        </RowAninhada>
        <RowAninhada style={{ marginTop: "8px" }}>
          <CCol
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "600", color: "#515c70" }}>
              Valor Total
            </span>
          </CCol>
          <CCol md={1}>
            <FormControlListaDeInputs
              style={{
                borderRadius: "10px",
                padding: "15px",
                border: "none",
                textAlign: "end",
              }}
              value="0.00%"
              disabled
            />
          </CCol>
          <CCol md={2}>
            <FormControlListaDeInputs
              style={{
                borderRadius: "10px",
                padding: "15px",
                border: "none",
                textAlign: "end",
              }}
              value="R$ 0,00"
              disabled
            />
          </CCol>
          <CColButtonsAcoes2Icons md={1}></CColButtonsAcoes2Icons>
        </RowAninhada>
      </RowToModal>
    </>
  );
};

export default FonteDeRecursosAba;
