import React, { useContext, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Container } from "react-bootstrap";

import { useFetchData } from "../../../../hooks/useFetchData";
import { useFavoritesLocalStorage } from "../../../../hooks/useFavoritesLocalStorage";

import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { CurrentYearContext } from "../../../../contexts/YearContext";
import { OpenedModalsContext } from "../../../../contexts/OpenedModalsContext";

import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import api from "../../../../utils/api";
import { generateFileXls } from "../../../../utils/Gerais/GenerateXls";
import { FormatValueToLocaleString } from "../../../../utils/FormatacaoDeDados/FormatValueToLocaleString";

import ModalAutoComplete from "../../../../components/Modais/ModalAutoComplete";
import {
  CCol,
  ContainerToModal,
  RowToModal,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import ButtonSaveModalAutoComplete from "../../../../components/Modais/ButtonSaveModalAutoComplete";
import HeaderCadastros from "./HeaderCadastros";
import PagRelatorioComponente from "../../../../components/ComponenteDeRelatorio/PagRelatorioComponente";
import { PdfQDD } from "./PdfQDD";

import { generateDocxQdd } from "./generateDocxQdd";

const options = [
  {
    label: "Unidade Orçamentária",
    value: "Unidade Orçamentária",
  },
  {
    label: "Ação Governamental",
    value: "Ação Governamental",
  },
  {
    label: "Elemento de Despesa",
    value: "Elemento de Despesa",
  },
  {
    label: "Fonte de Recursos",
    value: "Fonte de Recursos",
  },
  {
    label: "Função",
    value: "Função",
  },
  {
    label: "Subfunção",
    value: "Subfunção",
  },
  {
    label: "Programa",
    value: "Programa",
  },
  {
    label: "Esfera",
    value: "Esfera",
  },
];

const optionsEsfera = [
  {
    name: "Fiscal",
    id: "01",
  },
  {
    name: "Seguridade",
    id: "02",
  },
];

const headerGeral = ["Código", "Descrição", "Valor R$"];
const headerGeralEsfera = ["Código", "Descrição", "Esfera", "Valor R$"];
const headerFiltroPorFonte = [
  "Exercício",
  "Fonte De Recurso",
  "Descrição Fonte De Recursos",
  "Valor R$",
];
const headerFiltroFonteEsfera = [
  "Exercício",
  "Fonte De Recurso",
  "Descrição Fonte De Recursos",
  "Esfera",
  "Valor R$",
];
const headerFiltroPorEsfera = ["Esfera", "Valor R$"];

const showOptions = [
  {
    opcao: "Exibir",
    checkBoxes: [
      { label: "Código", value: "codigo", selected: true },
      { label: "Descrição", value: "descricao", selected: true },
      {
        label: "Classificação Funcional",
        value: "classificacao",
        selected: true,
      },
      {
        label: "Valor R$",
        value: "valor",
        selected: true,
      },
    ],
  },
  // {
  //   opcao: "Exibir Fonte De Recurso",
  //   checkBoxes: [
  //     { label: "Exercício", value: "exerciocio", selected: false },
  //     { label: "Fonte de Recurso", value: "fonte de recurso", selected: false },
  //     { label: "Descrição", value: "descrição", selected: false },
  //     {
  //       label: "Valor R$",
  //       value: "valor",
  //       selected: false,
  //     },
  //   ],
  // },
];

const RelatorioQDD = () => {
  const relatorioName = "Save - Quadro Detalhado Despesa";
  const dataAtual = ReturnDataAtual();
  const ano = String(parseInt(dataAtual.split("/")[2]));
  const localStorageKey = "save/qdd/favoritos";
  const nameModal = "Favorito QDD";

  const { currentClient } = useContext(CurrentClientContext);
  const { currentYear } = useContext(CurrentYearContext);
  const { userAuthData } = useContext(AuthContext);
  const { setOpenedModals } = useContext(OpenedModalsContext);

  const [selectBoxesOptionsAbaPrincipal, setSelectBoxesOptionsAbaPrincipal] =
    useState([]);
  const [dadosRelatorio, setDadosRelatorio] = useState(null);
  const [idsSelected, setIdsSelected] = useState({});
  const [readyToPdf, setReadyToPdf] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [inputFavoriteValue, setInputFavoriteValue] = useState("");
  const [selectBoxesOptionsAbaOptions, setSelectBoxesOptionsAbaOptions] =
    useState(showOptions);

  const { data: dataQDD } = useFetchData(
    `/qdd/${currentClient.clienteId}/${ano}`
  );
  const { data: dataClientInfos } = useFetchData(
    `cliente/pesquisa/id/${currentClient.clienteId}`
  );

  const {
    dataFavorites,
    favoritosOptions,
    handleDeleteFavoriteStorage,
    handleSaveFavorite,
  } = useFavoritesLocalStorage(
    selectBoxesOptionsAbaPrincipal,
    localStorageKey,
    setOpenedModals,
    nameModal
  );

  const floatingLabelInputRef = useRef(null);
  const navigate = useNavigate();

  const uniqueDataFiltered = (data) => {
    const uniqueData = [];
    const seenIds = new Set();

    data.forEach((item) => {
      if (!seenIds.has(item.id)) {
        seenIds.add(item.id);
        uniqueData.push(item);
      }
    });
    return uniqueData;
  };

  useEffect(() => {
    if (dataQDD) {
      console.log("oi");
      const options = [
        {
          opcao: "Unidade Orçamentária",
          getData: () =>
            dataQDD.map(
              (item) => item.acaoGovernamentalInfo.unidadeOrcamentariaId
            ),
          getLabel: (item) =>
            `${item.codigoUnidadeOrcamentaria} - ${item.tituloUnidadeOrcamentaria}`,
          getValue: (item) => item.id,
        },
        {
          opcao: "Ação Governamental",
          getData: () => dataQDD.map((item) => item.acaoGovernamentalInfo),
          getLabel: (item) => `${item.nAcao} - ${item.titulo}`,
          getValue: (item) => item.id,
        },
        {
          opcao: "Elemento de Despesa",
          getData: () =>
            dataQDD.map(
              (item) => item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa
            ),
          getLabel: (item) => `${item.codigo} - ${item.nome}`,
          getValue: (item) => item.id,
        },
        {
          opcao: "Fonte de Recursos",
          getData: () =>
            dataQDD.map((item) => item.despesaFixadaQDD.fonteDeRecurso),
          getLabel: (item) => `${item.conta} - ${item.titulo}`,
          getValue: (item) => item.id,
        },
        {
          opcao: "Função",
          getData: () =>
            dataQDD.map((item) => item.acaoGovernamentalInfo.funcao),
          getLabel: (item) => `${item.valor} - ${item.nome}`,
          getValue: (item) => item.id,
        },
        {
          opcao: "Subfunção",
          getData: () =>
            dataQDD.map((item) => item.acaoGovernamentalInfo.subFuncao),
          getLabel: (item) => `${item.valor} - ${item.nome}`,
          getValue: (item) => item.id,
        },
        {
          opcao: "Programa",
          getData: () =>
            dataQDD.map(
              (item) => item.acaoGovernamentalInfo.programaPlanejamentoId
            ),
          getLabel: (item) => `${item.numeroPrograma} - ${item.tituloPrograma}`,
          getValue: (item) => item.id,
        },
        {
          opcao: "Esfera",
          getData: () => optionsEsfera,
          getLabel: (item) => item.name,
          getValue: (item) => item.id,
        },
      ];

      options.forEach(({ opcao, getData, getLabel, getValue }) => {
        if (
          !selectBoxesOptionsAbaPrincipal.some((item) => item.opcao === opcao)
        ) {
          console.log("oi");
          const data = uniqueDataFiltered(getData());
          setSelectBoxesOptionsAbaPrincipal((prev) => [
            ...prev,
            {
              opcao,
              checkBoxes: data.map((item) => ({
                label: getLabel(item),
                value: getValue(item),
                selected: false,
              })),
            },
          ]);
        }
      });
    }
  }, [selectBoxesOptionsAbaPrincipal, dataQDD]);

  const getIdsSelectBox = (option) => {
    const selectBox = selectBoxesOptionsAbaPrincipal?.find(
      (item) => item.opcao === option
    );
    const selectBoxSelected = selectBox?.checkBoxes.some(
      (item) => item.selected
    );

    if (!selectBoxSelected) return [];
    console.log(selectBox);

    const checkBoxIdsSelected = selectBox.checkBoxes
      .filter((item) => item.selected)
      .map((checkBox) => checkBox.value);

    console.log(checkBoxIdsSelected);

    return [...new Set(checkBoxIdsSelected)];
  };

  const getEsferaSelected = () => {
    const selectBox = selectBoxesOptionsAbaPrincipal?.find(
      (item) => item.opcao === "Esfera"
    );
    const selectBoxSelected = selectBox?.checkBoxes.some(
      (item) => item.selected
    );

    if (!selectBoxSelected) return [];
    const esferaSelected = selectBox.checkBoxes
      .filter((item) => item.selected)
      .map((checkBox) => checkBox.label);
    console.log(esferaSelected);
    return esferaSelected;
  };

  const filtrarDataQDD = () => {
    const idsAcaoGovernamentalSelected = getIdsSelectBox("Ação Governamental");
    const idsElementoDespesaSelected = getIdsSelectBox("Elemento de Despesa");
    const idsFonteDeRecursoSelected = getIdsSelectBox("Fonte de Recursos");
    const idsFuncaoSelected = getIdsSelectBox("Função");
    const idsSubfuncaoSelected = getIdsSelectBox("Subfunção");
    const idsProgramaSelected = getIdsSelectBox("Programa");
    const idsUnidadeOrcamentariaSelected = getIdsSelectBox(
      "Unidade Orçamentária"
    );
    const esferaSelected = getEsferaSelected("Esfera");

    setIdsSelected({
      idsAcaoGovernamentalSelected,
      idsElementoDespesaSelected,
      idsFonteDeRecursoSelected,
      idsFuncaoSelected,
      idsSubfuncaoSelected,
      idsProgramaSelected,
      idsUnidadeOrcamentariaSelected,
      esferaSelected,
    });

    const filtrosIds = [
      idsAcaoGovernamentalSelected,
      idsElementoDespesaSelected,
      idsFuncaoSelected,
      idsSubfuncaoSelected,
      idsProgramaSelected,
      idsUnidadeOrcamentariaSelected,
      idsFonteDeRecursoSelected,
      esferaSelected,
    ];

    const emptyFilter = filtrosIds.every((arr) => arr.length === 0);

    if (emptyFilter) {
      return {
        idsAcaoGovernamental: [],
        idsElementoDespesa: [],
        idsFonteDeRecurso: [],
        idsSubfuncao: [],
        idsPrograma: [],
        idsUnidadeOrcamentaria: [],
        idsFuncao: [],
        esferaValue: [],
      };
    }

    const dataQddFiltrado = dataQDD.filter((item) => {
      const acaoGovernamental =
        idsAcaoGovernamentalSelected.length === 0 ||
        idsAcaoGovernamentalSelected.includes(item.acaoGovernamentalInfo.id);

      const elementoDespesa =
        idsElementoDespesaSelected.length === 0 ||
        idsElementoDespesaSelected.includes(
          item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.id
        );

      const fonteDeRecurso =
        idsFonteDeRecursoSelected.length === 0 ||
        idsFonteDeRecursoSelected.includes(
          item.despesaFixadaQDD.fonteDeRecurso.id
        );

      const funcao =
        idsFuncaoSelected.length === 0 ||
        idsFuncaoSelected.includes(item.acaoGovernamentalInfo.funcao.id);

      const subFuncao =
        idsSubfuncaoSelected.length === 0 ||
        idsSubfuncaoSelected.includes(item.acaoGovernamentalInfo.subFuncao.id);

      const programa =
        idsProgramaSelected.length === 0 ||
        idsProgramaSelected.includes(
          item.acaoGovernamentalInfo.programaPlanejamentoId.id
        );

      const unidadeOrcamentaria =
        idsUnidadeOrcamentariaSelected.length === 0 ||
        idsUnidadeOrcamentariaSelected.includes(
          item.acaoGovernamentalInfo.unidadeOrcamentariaId.id
        );

      const esfera =
        esferaSelected.length === 0 ||
        esferaSelected.includes(item.despesaFixadaQDD.fichasQDD.esfera);

      return (
        acaoGovernamental &&
        elementoDespesa &&
        fonteDeRecurso &&
        funcao &&
        subFuncao &&
        programa &&
        unidadeOrcamentaria &&
        esfera
      );
    });

    const idsAcaoGovernamental = idsAcaoGovernamentalSelected.length
      ? dataQddFiltrado.map((item) => item.acaoGovernamentalInfo.id)
      : [];

    const idsElementoDespesa = idsElementoDespesaSelected.length
      ? dataQddFiltrado.map(
          (item) => item.despesaFixadaQDD.fichasQDD.naturezaDaDespesa.id
        )
      : [];

    const idsFonteDeRecurso = idsFonteDeRecursoSelected.length
      ? dataQddFiltrado.map((item) => item.despesaFixadaQDD.fonteDeRecurso.id)
      : [];

    const idsFuncao = idsFuncaoSelected.length
      ? dataQddFiltrado.map((item) => item.acaoGovernamentalInfo.funcao.id)
      : [];

    const idsSubfuncao = idsSubfuncaoSelected.length
      ? dataQddFiltrado.map((item) => item.acaoGovernamentalInfo.subFuncao.id)
      : [];

    const idsPrograma = idsProgramaSelected.length
      ? dataQddFiltrado.map(
          (item) => item.acaoGovernamentalInfo.programaPlanejamentoId.id
        )
      : [];

    const idsUnidadeOrcamentaria = idsUnidadeOrcamentariaSelected.length
      ? dataQddFiltrado.map(
          (item) => item.acaoGovernamentalInfo.unidadeOrcamentariaId.id
        )
      : [];

    return {
      idsAcaoGovernamental: [...new Set(idsAcaoGovernamental)],
      idsElementoDespesa: [...new Set(idsElementoDespesa)],
      idsFonteDeRecurso: [...new Set(idsFonteDeRecurso)],
      idsFuncao: [...new Set(idsFuncao)],
      idsSubfuncao: [...new Set(idsSubfuncao)],
      idsPrograma: [...new Set(idsPrograma)],
      idsUnidadeOrcamentaria: [...new Set(idsUnidadeOrcamentaria)],
      esferaValue: esferaSelected,
    };
  };

  const getDataQdd = async () => {
    const {
      idsAcaoGovernamental,
      idsElementoDespesa,
      idsFonteDeRecurso,
      idsFuncao,
      idsSubfuncao,
      idsPrograma,
      idsUnidadeOrcamentaria,
      esferaValue,
    } = filtrarDataQDD();

    const filtrosIds = [
      idsAcaoGovernamental,
      idsElementoDespesa,
      idsFonteDeRecurso,
      idsFuncao,
      idsSubfuncao,
      idsPrograma,
      idsUnidadeOrcamentaria,
      esferaValue,
    ];

    const emptyFilter = filtrosIds.every((arr) => arr.length === 0);

    if (emptyFilter) {
      setDadosRelatorio(null);
      return;
    }

    const idsAcaoGovernamentalFormatted = idsAcaoGovernamental.length
      ? idsAcaoGovernamental.join(",")
      : "";
    const idsElementoDespesaFormatted = idsElementoDespesa.length
      ? idsElementoDespesa.join(",")
      : "";
    const idsFonteDeRecursoFormatted = idsFonteDeRecurso.length
      ? idsFonteDeRecurso.join(",")
      : "";
    const idsFuncaoFormatted = idsFuncao.length ? idsFuncao.join(",") : "";
    const idsSubfuncaoFormatted = idsSubfuncao.length
      ? idsSubfuncao.join(",")
      : "";
    const idsProgramaFormatted = idsPrograma.length
      ? idsPrograma.join(",")
      : "";
    const idsUnidadeOrcamentariaFormatted = idsUnidadeOrcamentaria.length
      ? idsUnidadeOrcamentaria.join(",")
      : "";

    const idsEsferaFormatted = esferaValue.length ? esferaValue.join(",") : "";

    let url;

    if (
      !idsAcaoGovernamental.length &&
      !idsElementoDespesa.length &&
      !idsFuncao.length &&
      !idsSubfuncao.length &&
      !idsPrograma.length &&
      !idsUnidadeOrcamentaria.length &&
      (idsFonteDeRecurso.length || esferaValue.length)
    ) {
      url = `qdd/relatorio/fontes-esfera/${currentClient.clienteId}/${ano}?fontesId=${idsFonteDeRecursoFormatted}&esferas=${idsEsferaFormatted}`;
    } else {
      url = `qdd/relatorio-qdd/${
        currentClient.clienteId
      }/${ano}?unidadeOrcamentariaId=${idsUnidadeOrcamentariaFormatted}&acaoId=${idsAcaoGovernamentalFormatted}&funcaoId=${idsFuncaoFormatted}&subfuncaoId=${idsSubfuncaoFormatted}&programaId=${idsProgramaFormatted}&fonteDeRecursoId=${idsFonteDeRecursoFormatted}&naturezaDaDespesaId=${idsElementoDespesaFormatted}&esfera=${
        esferaValue.length > 1 ? "" : idsEsferaFormatted
      }`;
    }

    try {
      const dataTitulos = await api.get(url);
      return dataTitulos.data;
    } catch (error) {
      console.log("Erro ao buscar os dados", error);
      return null;
    }
  };

  const handleGeneratePdf = async () => {
    const dadosRelatorio = await getDataQdd();
    setDadosRelatorio(dadosRelatorio);
    setReadyToPdf(true);
  };

  const headerDocx = (idsSelected) => {
    const {
      idsAcaoGovernamentalSelected,
      idsElementoDespesaSelected,
      idsFonteDeRecursoSelected,
      idsFuncaoSelected,
      idsSubfuncaoSelected,
      idsProgramaSelected,
      idsUnidadeOrcamentariaSelected,
      esferaSelected,
    } = idsSelected;

    const filters = [
      idsAcaoGovernamentalSelected,
      idsElementoDespesaSelected,
      idsFuncaoSelected,
      idsSubfuncaoSelected,
      idsProgramaSelected,
      idsUnidadeOrcamentariaSelected,
    ];

    const filtroFonte =
      idsFonteDeRecursoSelected.length &&
      !esferaSelected.length &&
      filters.every((arr) => arr.length === 0);

    const filtroFonteEsfera =
      idsFonteDeRecursoSelected.length &&
      esferaSelected.length &&
      filters.every((arr) => arr.length === 0);

    const filtroEsfera =
      esferaSelected.length > 0 &&
      !idsFonteDeRecursoSelected.length &&
      filters.every((arr) => arr.length === 0);

    const filtroGeralComEsfera =
      esferaSelected.length > 0 && idsElementoDespesaSelected.length > 0;

    if (filtroFonte) {
      return {
        optionsHeader: headerFiltroPorFonte,
        columnWidth: [10, 10, 65, 15],
      };
    }

    if (filtroFonteEsfera) {
      return {
        optionsHeader: headerFiltroFonteEsfera,
        columnWidth: [10, 10, 55, 10, 15],
      };
    }

    if (filtroEsfera) {
      return {
        optionsHeader: headerFiltroPorEsfera,
        columnWidth: [50, 50],
      };
    }

    if (filtroGeralComEsfera) {
      return {
        optionsHeader: headerGeralEsfera,
        columnWidth: [16, 59, 10, 15],
      };
    }

    return {
      optionsHeader: headerGeral,
      columnWidth: [16, 69, 15],
    };
  };

  const handleGenerateDocx = async () => {
    const dadosRelatorio = await getDataQdd();

    const idsAcaoGovernamentalSelected = getIdsSelectBox("Ação Governamental");
    const idsElementoDespesaSelected = getIdsSelectBox("Elemento de Despesa");
    const idsFonteDeRecursoSelected = getIdsSelectBox("Fonte de Recursos");
    const idsFuncaoSelected = getIdsSelectBox("Função");
    const idsSubfuncaoSelected = getIdsSelectBox("Subfunção");
    const idsProgramaSelected = getIdsSelectBox("Programa");
    const idsUnidadeOrcamentariaSelected = getIdsSelectBox(
      "Unidade Orçamentária"
    );
    const esferaSelected = getEsferaSelected("Esfera");

    const idsSelected = {
      idsAcaoGovernamentalSelected,
      idsElementoDespesaSelected,
      idsFonteDeRecursoSelected,
      idsFuncaoSelected,
      idsSubfuncaoSelected,
      idsProgramaSelected,
      idsUnidadeOrcamentariaSelected,
      esferaSelected,
    };

    const { optionsHeader, columnWidth } = headerDocx(idsSelected);

    const title = "QDD - Quadro Detalhado da Despesa";

    const pdfContent = {
      dadosRelatorio,
      columnWidth,
      optionsHeader,
      userData: {
        currentClient,
        userAuthData,
      },
      dateRelatorio: { year: currentYear },
      selectBoxesOptionsAbaOptions,
    };

    if (dadosRelatorio) {
      toast.promise(
        generateDocxQdd(
          { pdfContent },
          title,
          dataClientInfos[0].brasao,
          20,
          [null, null],
          idsSelected
        ).then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = title + ".docx";
          link.click();
          URL.revokeObjectURL(url);
        }),
        {
          pending: "Gerando o documento...",
          success: "Documento gerado com sucesso!",
          error: "Falha ao gerar o documento.",
        }
      );
    } else {
      toast.warn(
        "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
      );
    }
  };

  const handleGenerateXls = async () => {
    const dadosRelatorio = await getDataQdd();

    const resolvePromise = new Promise((resolve) => setTimeout(resolve, 1000));
    toast.promise(resolvePromise, {
      pending: "Gerando XLS",
      success: "XLS Gerado Com Sucesso",
      error: "Falha Inesperada ao Gerar XLS",
    });

    const idsAcaoGovernamentalSelected = getIdsSelectBox("Ação Governamental");
    const idsElementoDespesaSelected = getIdsSelectBox("Elemento de Despesa");
    const idsFonteDeRecursoSelected = getIdsSelectBox("Fonte de Recursos");
    const idsFuncaoSelected = getIdsSelectBox("Função");
    const idsSubfuncaoSelected = getIdsSelectBox("Subfunção");
    const idsProgramaSelected = getIdsSelectBox("Programa");
    const idsUnidadeOrcamentariaSelected = getIdsSelectBox(
      "Unidade Orçamentária"
    );
    const esferaSelected = getEsferaSelected("Esfera");

    const filters = [
      idsAcaoGovernamentalSelected,
      idsElementoDespesaSelected,
      idsFuncaoSelected,
      idsSubfuncaoSelected,
      idsProgramaSelected,
      idsUnidadeOrcamentariaSelected,
    ];

    const filtroFonte =
      idsFonteDeRecursoSelected.length &&
      filters.every((arr) => arr.length === 0);

    const filtroEsfera =
      esferaSelected.length > 0 &&
      !idsFonteDeRecursoSelected.length &&
      filters.every((arr) => arr.length === 0);

    if (filtroFonte) {
      console.log("fontes");
      generateFonteRecursoDataTableXls(dadosRelatorio, esferaSelected);
      return;
    }
    if (filtroEsfera) {
      console.log("esfera");
      generateEsferaDataTableXls(dadosRelatorio, esferaSelected);
      return;
    }
    generateDataGeralTableXls(dadosRelatorio);
  };

  const generateDataGeralTableXls = (dadosRelatorio) => {
    console.log("generate xls");

    const dataTable = dadosRelatorio.flatMap((unidade) => {
      let codigoUnidade = "";
      let codigoAcao = "";
      let codigoFonte = "";
      return unidade.acoes.flatMap((acao) =>
        acao.despesaFixadaQDD.flatMap((despesa) =>
          despesa.fichasQDD.map((ficha) => {
            const result = {
              "Unidade Orcamentaria":
                codigoUnidade === unidade.codigoUnidadeOrcamentaria
                  ? ""
                  : `${unidade.codigoUnidadeOrcamentaria} - ${unidade.tituloUnidadeOrcamentaria}`,
              "Ação Governamental":
                codigoAcao === acao.nAcao
                  ? ""
                  : `${acao.nAcao} - ${acao.titulo}`,
              "Fonte de Recurso":
                codigoFonte === despesa.fonteDeRecurso.conta
                  ? ""
                  : `${despesa.fonteDeRecurso.conta} - ${despesa.fonteDeRecurso.titulo}`,
              "Natureza da Despesa": `${ficha.naturezaDaDespesa.codigo} - ${ficha.naturezaDaDespesa.nome}`,
              Valor: `R$ ${FormatValueToLocaleString(ficha.valor)}`,
            };
            codigoUnidade = unidade.codigoUnidadeOrcamentaria;
            codigoAcao = acao.nAcao;
            codigoFonte = despesa.fonteDeRecurso.conta;
            return result;
          })
        )
      );
    });

    generateFileXls(dataTable, relatorioName);
  };

  const generateFonteRecursoDataTableXls = (dadosRelatorio, idsEsfera) => {
    if (idsEsfera.length) {
      const filtroPorFiscal = idsEsfera.includes("Fiscal");
      const filtroPorSeguridade = idsEsfera.includes("Seguridade");

      const dataTableFonteEsfera = dadosRelatorio.fontes.map((fonte) => ({
        Exercício: fonte.conta[0],
        "Fonte De Recurso": fonte.conta.split(".")[1],
        "Descrição Fonte De Recursos ": fonte.titulo,
        Esfera: `${idsEsfera[0] || ""} ${idsEsfera.length > 1 ? "|" : ""} ${
          idsEsfera[1] || ""
        }`,
        "Valor R$": `${
          filtroPorFiscal ? FormatValueToLocaleString(fonte.totalFiscal) : ""
        } ${idsEsfera.length > 1 ? "|" : ""} ${
          filtroPorSeguridade
            ? FormatValueToLocaleString(fonte.totalSeguridade)
            : ""
        }`,
      }));
      generateFileXls(dataTableFonteEsfera, relatorioName);
    }
  };

  const generateEsferaDataTableXls = (dadosRelatorio, idsEsfera) => {
    const data = [];

    if (idsEsfera.includes("Fiscal")) {
      data.push({
        Esfera: "Fiscal",
        "Valor R$": FormatValueToLocaleString(
          dadosRelatorio.totais.totalGeralFiscal
        ),
      });
    }

    if (idsEsfera.includes("Seguridade")) {
      data.push({
        Esfera: "Seguridade",
        "Valor R$": FormatValueToLocaleString(
          dadosRelatorio.totais.totalGeralSeguridade
        ),
      });
    }

    if (data.length > 0) {
      generateFileXls(data, relatorioName);
    }
  };

  const handleOpenModal = () => {
    console.log("openmodal");
    setOpenedModals((prev) => [
      ...prev,
      { tituloModal: nameModal, requisicaoDoModalConcluida: false },
    ]);
  };

  const handleDeleteFavorite = (optionValue) => {
    const checkBoxesAtualizados = handleDeleteFavoriteStorage(optionValue);
    setSelectBoxesOptionsAbaPrincipal(checkBoxesAtualizados);
    setInputFavoriteValue(null);
  };

  useEffect(() => {
    if (readyToPdf && dadosRelatorio) {
      const pdfData = {
        pdfName: "QDD - Quadro Detalhado da Despesa",
        PdfComponent: PdfQDD,
        pdfContent: {
          dadosRelatorio,
          userData: {
            currentClient,
            userAuthData,
            dataClientInfos,
          },
          idsSelected,
          currentYear,
          selectBoxesOptionsAbaOptions,
        },
      };
      setPdfData(pdfData);
      setReadyToPdf(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToPdf, dadosRelatorio]);

  return (
    <>
      <Container
        id="pags_responsividade_padding_geral"
        fluid
        className="container_conteudo conteudo_pagina hide_on_print"
      >
        <ModalAutoComplete
          tituloModal={nameModal}
          Component={
            <ContainerToModal isAModalPage fluid style={{ width: "40vw" }}>
              <RowToModal isAModalPage className="row_form mt-1">
                <CCol md="12">
                  <FloatingLabelInput
                    label={"Nome do Favorito"}
                    placeholder={"Nome do Favorito"}
                    ref={floatingLabelInputRef}
                  />
                </CCol>
              </RowToModal>
              <ButtonSaveModalAutoComplete
                SaveFunction={() =>
                  handleSaveFavorite(floatingLabelInputRef?.current?.value)
                }
                pageTitle={nameModal}
              />
            </ContainerToModal>
          }
        />

        <HeaderCadastros
          PaginaConsulta={"/planejamento/loa/qdd/consulta"}
          NomePaginaTitulo={"QDD - Quadro Detalhado da Despesa"}
          PaginaSubtitulo={"Relatório"}
          BotaoNovoAtivo={true}
          BotaoSaveAtivo={false}
          BotaoNovoFunction={() => navigate("/planejamento/loa/qdd/cadastro")}
          OpcoesDeArquivo={true}
          // ButtonVisualizar={handlePrint}
          PdfData={pdfData}
          ButtonPDF={handleGeneratePdf}
          ButtonXls={handleGenerateXls}
          ButtonDocx={handleGenerateDocx}
        />
        <ToastContainer />
        {/* <div style={{ height: '200px', width: '100%', backgroundColor: 'red' }}>
          <button onClick={() => getDataQdd()}>test</button>
        </div> */}

        <PagRelatorioComponente
          topoEsquerdoOptions={[
            { label: "Relatório", value: null },
            { label: "Relatório", value: 1 },
          ]}
          principalOptions={options}
          labelsCheckAbaPrincipal={selectBoxesOptionsAbaPrincipal}
          setLabelsCheckAbaPrincipal={setSelectBoxesOptionsAbaPrincipal}
          // handleOptionSelected={handleOptionSelected}
          hasAssinatura={false}
          handleOpenModal={handleOpenModal}
          favoritosOptions={favoritosOptions}
          dataFavorites={dataFavorites}
          handleOptionButton={handleDeleteFavorite}
          inputFavoriteValue={inputFavoriteValue}
          setInputFavoriteValue={setInputFavoriteValue}
          labelsCheckAbaOpcoes={selectBoxesOptionsAbaOptions}
          setLabelsCheckAbaOpcoes={setSelectBoxesOptionsAbaOptions}
        />
      </Container>
    </>
  );
};

export default RelatorioQDD;
