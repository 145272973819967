//import { calcularTotalReceitasComDeducao } from "../../../../LOA_QDR/RELATORIO_QDR/calcularReceitas";
import api from "../../../../../../utils/api";

export const getDespesaSegCEconomica = async (clienteId) => {
  try {
    const response = await api.get(
      `naturezaDaDespesa/relatorio/${clienteId}/2024`
    );
    const naturezaDaDespesa = response.data.contas.naturezasConsolidadas;
    console.log("response.data", naturezaDaDespesa);
    //return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
  }
};

// const processAllData = (dataQdrRelatorio) => {
//   let sumValues = 0;
//   const processItem = (item, sum = "sum") => {
//     const seccoes = item.nr.split(".").filter((secao) => parseInt(secao) !== 0);
//     let desdobramento = null,
//       fonte = null,
//       catEcon = null;

//     if (seccoes.length === 1) {
//       catEcon = item.valor;
//       if (sum === "reduce") {
//         sumValues -= parseFloat(item.valor);
//       } else {
//         sumValues += parseFloat(item.valor);
//       }
//     } else if (seccoes.length === 2) {
//       fonte = item.valor;
//     } else if (seccoes.length > 2) {
//       desdobramento = item.valor;
//     }

//     const resultado = [
//       {
//         codigo: item.nr,
//         especificacao: item.especificacao,
//         desdobramento: desdobramento,
//         fonte: fonte,
//         catEcon: catEcon,
//         valor: item.valor,
//       },
//     ];

//     if (
//       item.qdr &&
//       item.qdr.fontesDeRecursos &&
//       item.qdr.fontesDeRecursos.length > 0
//     ) {
//       const recursos = item.qdr.fontesDeRecursos.map((recurso) => {
//         return {
//           codigo: recurso.conta,
//           especificacao: recurso.titulo,
//           desdobramento: recurso.valorPrevisto,
//           fonte: null,
//           catEcon: null,
//         };
//       });
//       const filteredRec = recursos.sort((a, b) => {
//         if (a.codigo < b.codigo) return -1;
//         if (a.codigo > b.codigo) return 1;
//         return 0;
//       });
//       resultado.push(...filteredRec);
//     }
//     return resultado;
//   };

//   const codigosSectionRelatorio = ["1.0.0.0.00.0.0", "2.0.0.0.00.0.0"];
//   const totalDeducao = calcularTotalReceitasComDeducao(
//     { naturezasDeducao: dataQdrRelatorio.naturezasDeducao },
//     codigosSectionRelatorio
//   );
//   const naturezas = dataQdrRelatorio?.naturezas?.flatMap((item) =>
//     processItem(item, "sum")
//   );
//   const naturezasDeducao = dataQdrRelatorio?.naturezasDeducao.flatMap((item) =>
//     processItem(item, "reduce")
//   );
//   const deducaoUnica = {
//     codigo: "0",
//     especificacao: "Contribuição para Formação do FUNDEB - DEDUÇÃO",
//     desdobramento: null,
//     fonte: null,
//     catEcon: totalDeducao,
//     valor: totalDeducao,
//   };
//   const dadosFiltrados = [...naturezas, deducaoUnica, ...naturezasDeducao];
//   const somaFormatada = sumValues;

//   return {
//     data: dadosFiltrados,
//     sum: somaFormatada,
//   };
// };
