import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../contexts/AuthContext";
import { CurrentYearContext } from "../contexts/YearContext";
import { useFetchData } from "./useFetchData";
import { generateDocxBlob } from "../utils/RelatorioDocx/DocxRelatorio";
import { generateFileXls } from "../utils/Gerais/GenerateXls";
import {
  getSelected,
  getSelectedWithValues,
  hasAnySelected,
  processarDadosRelatorio,
} from "../utils/RelatorioDocx/FuncoesRelatorioDocx";
import { pdf } from "@react-pdf/renderer";

export const useGenerateRelatorio = (
  selectBoxesOptionsAbaOptions,
  selectBoxesOptionsAbaPrincipal,
  currentClient,
  relatorioName,
  columnWidth
) => {
  const { currentYear } = useContext(CurrentYearContext);
  const { userAuthData } = useContext(AuthContext);

  const [dadosRelatorio, setDadosRelatorio] = useState(null);
  const [printReady, setPrintReady] = useState(false);

  const { data: dataClientInfos } = useFetchData(
    `cliente/pesquisa/id/${currentClient.clienteId}`
  );

  const handleGeneratePdf = async (
    PdfComponent,
    getDadosRelatorio,
    whatToDo = "download"
  ) => {
    try {
      const generatePdfPromise = async () => {
        let dadosRelatorio;
        if (typeof getDadosRelatorio === "function") {
          dadosRelatorio = await getDadosRelatorio();
        } else {
          dadosRelatorio = await getDadosRelatorio;
        }

        if (
          hasAnySelected(selectBoxesOptionsAbaOptions) &&
          hasAnySelected(selectBoxesOptionsAbaPrincipal)
        ) {
          if (dadosRelatorio) {
            const pdfContent = {
              dadosRelatorio,
              opcoes: selectBoxesOptionsAbaOptions,
              relatorioName,
              userData: {
                currentClient,
                userAuthData,
                dataClientInfos,
              },
              currentYear,
              selectBoxesOptionsAbaPrincipal,
            };
            const blob = await pdf(
              <PdfComponent pdfContent={pdfContent} />
            ).toBlob();
            const url = URL.createObjectURL(blob);

            if (whatToDo === "download") {
              const a = document.createElement("a");
              a.href = url;
              a.download = `${relatorioName}.pdf`;
              a.click();
            }
            if (whatToDo === "newTab") {
              window.open(url, "_blank");
            }
            if (whatToDo === "printView") {
              let iframe = document.createElement("iframe");
              iframe.id = "pdfIframe";
              iframe.style.position = "absolute";
              iframe.style.width = "0px";
              iframe.style.height = "0px";
              iframe.style.border = "none";
              document.body.appendChild(iframe);
              iframe.src = url;
              iframe.onload = () => {
                iframe.contentWindow.print();
              };
            }
            URL.revokeObjectURL(url);
          }
        } else {
          throw new Error(
            "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
          );
        }
      };
      await toast.promise(generatePdfPromise(), {
        pending: "Gerando PDF...",
        success: "PDF Gerado Com Sucesso!",
        error: "Erro ao Gerar PDF.",
      });
    } catch (error) {
      toast.error(error.message || "Ocorreu um erro ao gerar o relatório.");
    }
  };

  const handlePrint = (getDadosRelatorio) => {
    const dadosRelatorio = getDadosRelatorio();
    setDadosRelatorio(dadosRelatorio);
    setPrintReady(true);
  };

  const handleGenerateXls = (dataTable, tablesName = "", customTitles) => {
    if (tablesName) {
      generateFileXls(dataTable, relatorioName, tablesName, customTitles);
      return;
    }
    generateFileXls(dataTable, relatorioName);
  };

  const handleGenerateDocx = async (getDadosRelatorio) => {
    const dadosRelatorio = getDadosRelatorio();
    const title = relatorioName;
    const pdfContent = {
      dadosRelatorio: processarDadosRelatorio(
        dadosRelatorio,
        selectBoxesOptionsAbaOptions
      ),
      opcoes: getSelected(selectBoxesOptionsAbaOptions),
      columnWidth: getSelectedWithValues(
        selectBoxesOptionsAbaOptions[0],
        columnWidth
      ),
      userData: {
        currentClient,
        userAuthData,
      },
      dateRelatorio: { year: currentYear },
    };

    if (
      hasAnySelected(selectBoxesOptionsAbaOptions) &&
      hasAnySelected(selectBoxesOptionsAbaPrincipal)
    ) {
      toast.promise(
        generateDocxBlob({ pdfContent }, title, dataClientInfos[0].brasao).then(
          (blob) => {
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = title + ".docx";
            link.click();
            URL.revokeObjectURL(url);
          }
        ),
        {
          pending: "Gerando o documento...",
          success: "Documento gerado com sucesso!",
          error: "Falha ao gerar o documento.",
        }
      );
    } else {
      toast.warn(
        "Relatório vazio. Selecione pelo menos um item antes de gerar o relatório."
      );
    }
  };

  useEffect(() => {
    if (printReady) {
      window.print();
      setPrintReady(false);
    }
  }, [printReady]);

  useEffect(() => {
    const handleAfterPrint = () => {
      setDadosRelatorio(null);
    };

    window.addEventListener("afterprint", handleAfterPrint);

    return () => {
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, []);
  console.log("custon kook");

  return {
    dadosRelatorio,
    dataClientInfos,
    handlePrint,
    handleGeneratePdf,
    handleGenerateDocx,
    handleGenerateXls,
  };
};
