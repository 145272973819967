import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { stylesPdf } from "../../../../../components/ComponenteDeRelatorio/Pdf/StyledPdfRelatorio";
import { PdfHeader } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfHeader";
import { PdfColunas } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfColunas";
import { PdfInfos } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfInfos";
import { PdfRodape } from "../../../../../components/ComponenteDeRelatorio/Pdf/PdfRodape";
import { formatValue } from "../utils/formatValue";

export const RenderPdfQDDAplicacao = (
  dados,
  opcoes,
  currentClient,
  userAuthData,
  dataClientInfos,
  currentYear
) => {
  const colNames = ["CÓDIGO", "", "ESPECIFICAÇÕES", "", "TOTAL"];
  const styledCol = [
    { flexBasis: "60px", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "50px", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "300px", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "80px", flexShrink: 1, flexGrow: 1 },
    { flexBasis: "160px", flexShrink: 1, flexGrow: 1 },
  ];

  const bolderRegex = [
    /^\d{2}\.\d{3}$/, //'xx.xxx'
    /^\d{4}$/, //'xxxx'
    /^\d\.\d\.\d{2}$/, //'x.x.xx'
  ];

  const titleRegex = [
    /^\d{2}\.\d{3}$/, //'xx.xxx'
  ];

  const regexTest = (str, regex) => {
    return regex.some((regex) => regex.test(str));
  };

  return (
    <Page orientation="portrait" size={"A4"} style={stylesPdf.page}>
      <PdfHeader
        currentClient={currentClient}
        nameRelatorio={
          "Quadro Detalhado da Despesa - QDD por Modalidade de Aplicação "
        }
        plusText={" "}
        anoRelatorio={currentYear}
        dataClientInfos={dataClientInfos}
      />
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          fontSize: "9px",
          padding: "0 4px",
        }}
        fixed
      >
        <Text></Text>
        <Text>R$ 1,00</Text>
      </View>
      <View style={stylesPdf.colunas} fixed>
        {opcoes[1]?.checkBoxes?.map(
          (each, index) =>
            each.selected && (
              <PdfColunas
                key={index}
                colName={colNames[index]}
                styledCol={styledCol[index]}
                hideBar
              />
            )
        )}
      </View>
      <View style={stylesPdf.divRelatorio}>
        <View style={{ margin: "0 -3px" }}>
          {dados?.data?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <View
                  style={[
                    stylesPdf.colunas,
                    {
                      gap: 0,
                      fontWeight: regexTest(item.codigo, bolderRegex)
                        ? "bold"
                        : "normal",
                      backgroundColor: regexTest(item.codigo, titleRegex)
                        ? "#a6a6a6"
                        : "transparent",
                    },
                  ]}
                >
                  {opcoes[1]?.checkBoxes[0].selected && item.codigo !== "0" && (
                    <PdfInfos
                      pdfInfo={item.serial || ""}
                      styledCol={[
                        styledCol[0],
                        {
                          padding: "2px 0",
                          textAlign: "right",
                          fontWeight: "normal",
                        },
                      ]}
                    />
                  )}
                  {opcoes[1]?.checkBoxes[1].selected && (
                    <PdfInfos
                      pdfInfo={item.codigo || ""}
                      styledCol={[
                        styledCol[1],
                        { padding: "2px 0", textAlign: "left" },
                      ]}
                    />
                  )}
                  {opcoes[1]?.checkBoxes[2].selected && (
                    <PdfInfos
                      pdfInfo={item.titulo || ""}
                      styledCol={[
                        styledCol[2],
                        { padding: "2px 0", textAlign: "justifyContent" },
                      ]}
                    />
                  )}
                  {opcoes[1]?.checkBoxes[3].selected && (
                    <PdfInfos
                      pdfInfo={item.tipo || ""}
                      styledCol={[styledCol[3], { padding: "2px 0" }]}
                    />
                  )}
                  {opcoes[1]?.checkBoxes[4].selected && (
                    <PdfInfos
                      pdfInfo={
                        (item.valor && `R$ ${formatValue(item.valor)}`) || ""
                      }
                      styledCol={[
                        styledCol[4],
                        { padding: "2px 0", textAlign: "right" },
                      ]}
                    />
                  )}
                </View>
              </React.Fragment>
            );
          })}
          <View
            style={[
              stylesPdf.divInferiorColuna,
              {
                fontWeight: "bold",
                fontSize: 10,
                textTransform: "uppercase",
                color: "white",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              },
            ]}
          >
            <Text>TOTAL</Text>
            <Text>{formatValue(dados.sum)}</Text>
          </View>
        </View>
      </View>
      <PdfRodape currentClient={currentClient} userAuthData={userAuthData} />
    </Page>
  );
};
