import React, { useContext, useEffect, useState, useRef } from "react";
import { CCol, CColButtonsAcoes2Icons } from "../../../components/Grid/CCol";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../styles/StyledComponents/ListaDeInputs";
import { Icon } from "@iconify/react";
import { useFetchData } from "../../../hooks/useFetchData";
import { toast } from "react-toastify";
import { Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { CurrentClientContext } from "../../../contexts/CurrentClientContext";
import { SpanTotalPorFonte } from "./StyledComponentsQDD";
import { FormatValueToLocaleString } from "../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { ReturnDataAtual } from "../../../utils/Gerais/ReturnDataAtual";
import InputFormatRealBrasileiro from "../../../components/Grid/InputFormatRealBrasileiro";
import ModalDeleteData from "../../../components/ModalDeleteData/ModalDeleteData";
import api from "../../../utils/api";
import MyAutoComplete from "../../../components/Inputs/MyAutoComplete";
import FloatingLabelInput from "../../../components/Grid/FloatingLabelInput";

const dataAtual = ReturnDataAtual(); //.split("/")[2]
const DespesaFixada = ({
  valuesQDD = null,
  setValuesQDD,
  tipoDeVinculoAcao,
  comportamentoUpdate = false,
  pagDeReplica = false,
  pagDeUpdate = false,
  inputFontRecRef,
  setOpenRefFromFR,
}) => {
  const location = useLocation();
  const [inputNatDespesa, setInputNatDespesa] = useState([]);
  const [inputFonteRecursos, setInputFonteRecursos] = useState("");
  const [optionsFonteRecursos, setOptionsFonteRecursos] = useState([]);
  const [optionsNaturezaDaDespesa, setOptionsNaturezaDaDespesa] = useState([]);
  const [despesasFixadasOldLenght, setDespesasFixadasOldLenght] = useState(0);
  const [fonteRecursoParaAdicionar, setFonteRecursoParaAdicionar] =
    useState("");
  const [naturezasDaDespesaAdicionar, setNaturezasDaDespesaAdicionar] =
    useState([]);
  // const [inputsNatDesp, setInputsNatDesp] = useState([]);
  const [trazendoDadosUpdate, setTrazendoDadosUpdate] =
    useState(comportamentoUpdate);
  const [fichaReferenciaPagDeReplica, setFichaReferenciaPagDeReplica] =
    useState(null);

  const { currentClient } = useContext(CurrentClientContext);
  const { data } = useFetchData(
    `/fonteDeRecurso/por-ano/${dataAtual.split("/")[2]}`
  );
  const { data: naturezasDaDespesaDoCliente, error: errorFetchNaturezas } =
    useFetchData(
      `/naturezaDaDespesa/por-cliente/${currentClient.clienteId}/${
        dataAtual.split("/")[2]
      }/filtro?codigo=`
    );
  const [nextFicha, setNextFicha] = useState(null);
  const { data: proxFicha, error: errorGetProxFicha } = useFetchData(
    `/qdd/next-ficha/${currentClient.clienteId}/cadastro`
  );

  //Esse estado guarda os valores antes da confirmacao da alteracao dos inputs que podem ser editados
  //clicando no botao de edicao
  const [valorAntesDaEdicao, setValorAntesDaEdicao] = useState("");
  const [valorAntesDaEdicaoFonteRecursos, setValorAntesDaEdicaoFonteRecursos] =
    useState(0);

  //Estados de controle do modal
  const [modalOpened, setModalOpened] = useState(false);
  const [dataDelete, setDataDelete] = useState({
    qualAtributoExcluir: null,
  });

  //funcoes pra controle do modal
  function openModal() {
    setModalOpened(true);
  }

  function closeModal() {
    setModalOpened(false);
  }

  //useEffect para o controle do comportamento especifico dessa pagina do dataDelete
  useEffect(() => {
    if (Object.keys(dataDelete).length === 0) {
      setDataDelete({
        qualAtributoExcluir: null,
      });
    }
    console.log("teste do useEffect");
  }, [dataDelete]);

  useEffect(() => {
    console.log("VALUES QDD: ", valuesQDD);
    if (
      trazendoDadosUpdate &&
      valuesQDD.despesasFixadas.length > 0 &&
      nextFicha
    ) {
      preencheNaturezasDaDespesaAdicionar();
      setTrazendoDadosUpdate(false);
    }

    //eslint-disable-next-line
  }, [trazendoDadosUpdate, valuesQDD, nextFicha]);

  useEffect(() => {
    if (proxFicha && !pagDeReplica) {
      setNextFicha(proxFicha.nextFicha);
      console.log("teste 1 ficha");
    }

    if (pagDeReplica && fichaReferenciaPagDeReplica) {
      setNextFicha(fichaReferenciaPagDeReplica);
      console.log("teste 2 ficha", fichaReferenciaPagDeReplica);
    }

    if (errorGetProxFicha) {
      toast.error("Ouve um erro ao resgatar dados essenciais para pagina,");
    }
  }, [proxFicha, errorGetProxFicha, pagDeReplica, fichaReferenciaPagDeReplica]);

  useEffect(() => {
    if (
      proxFicha &&
      !fichaReferenciaPagDeReplica &&
      valuesQDD.despesasFixadas.length > 0
    ) {
      let fichaReferencia = proxFicha.nextFicha;

      valuesQDD.despesasFixadas.forEach((despesa) => {
        despesa.fichas.forEach((ficha) => {
          fichaReferencia += 1;
        });
      });

      setFichaReferenciaPagDeReplica(fichaReferencia);
    }
  }, [valuesQDD, proxFicha, fichaReferenciaPagDeReplica]);

  useEffect(() => {
    if (errorFetchNaturezas) {
      toast.error(
        "Ocorreu um erro ao carregar certos dados nessa página. A página não irá funcionar adequadamente, por favor tente mais tarde ou entre em contato com o nosso serviço de suporte"
      );
    }

    if (naturezasDaDespesaDoCliente) {
      console.log(naturezasDaDespesaDoCliente);
      const filterElementosDaDespesa = naturezasDaDespesaDoCliente.filter(
        (item) =>
          (item.naturezaDaDespesa.codigo[7] !== "0" ||
            item.naturezaDaDespesa.codigo[8] !== "0") &&
          item.naturezaDaDespesa.codigo.slice(-2) === "00"
      );
      const options = filterElementosDaDespesa.map((item) => ({
        codigo: item.naturezaDaDespesa.codigo.substring(0, 9),
        titulo: item.naturezaDaDespesa.nome,
        id: item.naturezaDaDespesa.id,
      }));
      setOptionsNaturezaDaDespesa([...options]);
      //console.log("OPTIONS DA NATUREZA: ", options)
    }
  }, [naturezasDaDespesaDoCliente, errorFetchNaturezas]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Insert") {
        inputFontRecRef.current.focus();
        window.scrollTo(0, 0);
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [inputFontRecRef]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.altKey && event.key === "a") {
        if (inputNatDespRef.current.length > 0) {
          inputNatDespRef.current[indexRef.current].focus();
          indexRef.current =
            (indexRef.current + 1) % inputNatDespRef.current.length;
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleAutocompleteFonteRecursos = (newValue) => {
    console.log("NEWVALUE: ", newValue);
    setFonteRecursoParaAdicionar(newValue.id);
    setInputFonteRecursos(`${newValue.codigo} - ${newValue.titulo}`);
  };

  const preencheNaturezasDaDespesaAdicionar = () => {
    console.log("enrou aqui", nextFicha);
    let newArray = valuesQDD.despesasFixadas.map((despesa) => {
      return {
        inputValue: "",
        fonteDeRecursoId: despesa.fonteDeRecursoId,
        numeroFicha: nextFicha,
        naturezaDaDespesaId: "",
        esfera: "Fiscal",
        valor: "0,00",
        disabled: true,
      };
    });

    console.log(newArray);

    setNaturezasDaDespesaAdicionar([...newArray]);

    // let arrayNaturezasDaFonte = [
    //   ...naturezasDaDespesaAdicionar,

    // ];
  };

  const adicionarFonteDeRecursos = () => {
    if (fonteRecursoParaAdicionar !== "") {
      if (valuesQDD.despesasFixadas.length > 0) {
        let fonteNaoExistenteEmDespesasFixadas = true;

        valuesQDD.despesasFixadas.forEach((item) => {
          if (item.fonteDeRecursoId === fonteRecursoParaAdicionar) {
            fonteNaoExistenteEmDespesasFixadas = false;
          }
        });

        if (fonteNaoExistenteEmDespesasFixadas) {
          let newArray = [
            ...valuesQDD.despesasFixadas,
            {
              fonteDeRecursoId: fonteRecursoParaAdicionar,
              totalPorFonte: 0,
              disabled: true,
              fichas: [
                //   {
                //     numeroFicha: 0,
                //     naturezaDaDespesaId: "",
                //     esfera: "",
                //     valor: "",
                //   },
              ],
            },
          ];
          console.log(newArray);

          setValuesQDD((prevValues) => ({
            ...prevValues,
            despesasFixadas: [...newArray],
          }));

          //Aqui iremos adicionar tambem o objeto responsavel por guardar os valores de cada natureza da despesa

          let arrayNaturezasDaFonte = [
            ...naturezasDaDespesaAdicionar,
            {
              inputValue: "",
              fonteDeRecursoId: fonteRecursoParaAdicionar,
              numeroFicha: nextFicha,
              naturezaDaDespesaId: "",
              esfera: "Fiscal",
              valor: "0,00",
              disabled: true,
            },
          ];

          setNaturezasDaDespesaAdicionar(arrayNaturezasDaFonte);

          setInputFonteRecursos("");

          // //Aqui estamos adicionando uma variavel para controlar cada input respectivamente
          // setInputsNatDesp((prevValues) => [...prevValues, ""]);
        } else {
          toast.error(
            "Essa fonte de recurso ja foi adicionada as despesas fixadas."
          );
        }

        console.log(valuesQDD.despesasFixadas);
      } else {
        let newArray = [
          ...valuesQDD.despesasFixadas,
          {
            fonteDeRecursoId: fonteRecursoParaAdicionar,
            totalPorFonte: 0,
            disabled: true,
            fichas: [
              //   {
              //     numeroFicha: 0,
              //     naturezaDaDespesaId: "",
              //     esfera: "",
              //     valor: "",
              //   },
            ],
          },
        ];

        setValuesQDD((prevValues) => ({
          ...prevValues,
          despesasFixadas: [...newArray],
        }));

        //Aqui iremos adicionar tambem o objeto responsavel por guardar os valores de cada natureza da despesa

        let arrayNaturezasDaFonte = [
          ...naturezasDaDespesaAdicionar,
          {
            inputValue: "",
            fonteDeRecursoId: fonteRecursoParaAdicionar,
            numeroFicha: nextFicha,
            naturezaDaDespesaId: "",
            esfera: "Fiscal",
            valor: "0,00",
            disabled: true,
          },
        ];

        setNaturezasDaDespesaAdicionar(arrayNaturezasDaFonte);
        setInputFonteRecursos("");

        // setInputsNatDesp([""]);
      }
    } else {
      toast.error("Nenhuma fonte de recurso selecionada para adicionar.");
    }
  };

  useEffect(() => {
    console.log("DATA AQUI: ", data);
    if (data && tipoDeVinculoAcao) {
      const options = data
        .filter(
          (item) =>
            ["1.500", "1.501", "1.502"].includes(item.conta) ||
            ((item.funcao.split(" - ")[1] === tipoDeVinculoAcao ||
              item.funcao.split(" - ")[1] === "Demais") &&
              /^[^.]+\.[^.]+$/.test(item.conta) &&
              /^(1|9)/.test(item.conta))
        )
        .map((item) => ({
          id: item.id,
          codigo: item.conta,
          titulo: item.titulo,
        }));
      console.log("OPTIONS", options);
      setOptionsFonteRecursos([...options]);
    }
  }, [data, tipoDeVinculoAcao]);

  const deletaFonteDeRecurso = (codigoDaFonte, indexDespesa) => {
    if (pagDeUpdate && valuesQDD.despesasFixadas[indexDespesa].id) {
      console.log(valuesQDD.despesasFixadas[indexDespesa].id);
      api
        .delete(
          `qdd/deleteDespesaFixada/${valuesQDD.despesasFixadas[indexDespesa].id}`
        )
        .then((resp) => {
          console.log(resp);
          let newArray = [...valuesQDD.despesasFixadas].filter(
            (item) => item.fonteDeRecursoId !== codigoDaFonte
          );

          let newNaturezasDaDespesa = [...naturezasDaDespesaAdicionar].filter(
            (item) => item.fonteDeRecursoId !== codigoDaFonte
          );

          setNaturezasDaDespesaAdicionar([...newNaturezasDaDespesa]);

          setValuesQDD((prevValues) => ({
            ...prevValues,
            despesasFixadas: [...newArray],
          }));
        })
        .catch((error) => {
          const errorMsg = error.response.data.error;
          if (errorMsg.includes("Não é possível excluir a despesaFixadaQDD")) {
            toast.error(
              "Não é possivel excluir essa fonte de recursos pois exxistem fichas vinculadas a ela. Exclua todas as fichas vinculadas a essa fonte para poder deletá-la"
            );
          } else {
            toast.error("Algo de errado, por favor, tente mais tarde.");
          }
        });
    } else {
      let newArray = [...valuesQDD.despesasFixadas].filter(
        (item) => item.fonteDeRecursoId !== codigoDaFonte
      );

      let newNaturezasDaDespesa = [...naturezasDaDespesaAdicionar].filter(
        (item) => item.fonteDeRecursoId !== codigoDaFonte
      );

      setNaturezasDaDespesaAdicionar([...newNaturezasDaDespesa]);

      setValuesQDD((prevValues) => ({
        ...prevValues,
        despesasFixadas: [...newArray],
      }));
    }

    // //Exclusao do estado de controle do input da natureza da despesa especifica

    // const indexPraExcluirNatureza = naturezasDaDespesaAdicionar.find(item => item.fonteDeRecursoId === codigoDaFonte)
  };

  const handleAutocompleteNaturezaDaDespesa = (newValue, index) => {
    console.log(newValue);
    // console.log(codigoDaFonte);
    // console.log(
    //   naturezasDaDespesaAdicionar.findIndex(
    //     (item) => item.fonteDeRecursoId === codigoDaFonte
    //   )
    // );

    // const indexDaNatureza = naturezasDaDespesaAdicionar.findIndex(
    //   (item) => item.fonteDeRecursoId === codigoDaFonte
    // );

    let newArrayNaturezas = [...naturezasDaDespesaAdicionar];

    newArrayNaturezas[index].naturezaDaDespesaId = newValue.id;

    setNaturezasDaDespesaAdicionar([...newArrayNaturezas]);
    handleGoRef(inputEsferaRef, index);
  };

  const handleInputChange = (campo, valor, codigoFonteRecurso, index) => {
    console.log("CAMPO E VALOR: ", campo, valor);

    // const indexDaNatureza = naturezasDaDespesaAdicionar.findIndex(
    //   (item) => item.fonteDeRecursoId === codigoFonteRecurso
    // );

    let newArrayNaturezas = [...naturezasDaDespesaAdicionar];

    newArrayNaturezas[index][campo] = valor;

    console.log(newArrayNaturezas);

    setNaturezasDaDespesaAdicionar([...newArrayNaturezas]);
    handleGoRef(inputDespFixRef, index);
  };

  const handleChangeEditValor = (valor, indexDespesa, indexFicha) => {
    console.log(valor);

    let newArrayDespesas = [...valuesQDD.despesasFixadas];
    newArrayDespesas[indexDespesa].fichas[indexFicha].valor = valor;

    //Calculando o total por fonte dessa despesa

    let valorTotalFonte = 0;

    newArrayDespesas[indexDespesa].fichas.forEach((ficha) => {
      valorTotalFonte += parseFloat(ficha.valor.replace(/,/g, "."));
      console.log(ficha.valor.replace(/","/g, "."));
    });

    newArrayDespesas[indexDespesa].totalPorFonte = valorTotalFonte;

    setValuesQDD((prevValues) => ({
      ...prevValues,
      despesasFixadas: newArrayDespesas,
    }));
  };

  const limpaInputNatDesp = (indexDaDespesa) => {
    console.log("entrou na limpeza de nat desp");

    let inputsNatDesp = [...naturezasDaDespesaAdicionar];
    inputsNatDesp[indexDaDespesa] = {
      ...inputsNatDesp[indexDaDespesa],
      inputValue: "",
      naturezaDaDespesaId: "",
      esfera: "Fiscal",
      valor: "0,00",
      disabled: true,
    };

    console.log(inputsNatDesp);
    setNaturezasDaDespesaAdicionar([...inputsNatDesp]);

    setInputNatDespesa((prevValues) => {
      const updatedInputNatDespesa = [...prevValues];
      updatedInputNatDespesa[indexDaDespesa] = "";
      return updatedInputNatDespesa;
    });
  };

  const atualizaNextFicha = () => {
    console.log("despesas fixadas: ", valuesQDD.despesasFixadas);
    console.log("atualiza next ficha", nextFicha);
    const fichasExistentes = valuesQDD.despesasFixadas.flatMap((despesa) =>
      despesa.fichas.map((ficha) => ficha.numeroFicha)
    );
    console.log("Fichas existentes: ", fichasExistentes);

    let numeroDaFichaAtualizada = proxFicha.nextFicha;
    while (fichasExistentes.includes(numeroDaFichaAtualizada)) {
      numeroDaFichaAtualizada += 1;
    }

    console.log(pagDeReplica);
    console.log(numeroDaFichaAtualizada);

    let newArrayComProximasFichas = [...naturezasDaDespesaAdicionar];
    newArrayComProximasFichas.forEach((item) => {
      item.numeroFicha = numeroDaFichaAtualizada;
    });

    console.log(newArrayComProximasFichas);

    setNextFicha(numeroDaFichaAtualizada + 1);
    setNaturezasDaDespesaAdicionar([...newArrayComProximasFichas]);
  };

  useEffect(() => {
    if (nextFicha) {
      atualizaNextFicha();
    }
    //eslint-disable-next-line
  }, [valuesQDD.despesasFixadas]);

  const adicionaNaturezaDaDespesa = (indexDaDespesa) => {
    let todosValoresPreenchidos = true;
    let naturezaDaDespesaNaoExisteNoArray = true;
    const valuesToAdd = naturezasDaDespesaAdicionar.map((item) => {
      let itemRefatorado = {
        ...item,
        valor: item.valor.replace(/[^0-9,]/g, ""),
      };
      const { fonteDeRecurso, inputValue, ...resto } = itemRefatorado;
      return resto;
    });

    Object.keys(valuesToAdd[indexDaDespesa]).forEach((key) => {
      if (valuesToAdd[indexDaDespesa][key] === "") {
        console.log("teste");
        todosValoresPreenchidos = false;
      }
    });

    if (
      valuesQDD.despesasFixadas[indexDaDespesa].fichas
        .map((ficha) => ficha.naturezaDaDespesaId)
        .includes(valuesToAdd[indexDaDespesa].naturezaDaDespesaId)
    ) {
      naturezaDaDespesaNaoExisteNoArray = false;
    }

    if (todosValoresPreenchidos && naturezaDaDespesaNaoExisteNoArray) {
      let newArray = [...valuesQDD.despesasFixadas];
      newArray[indexDaDespesa].fichas = [
        ...newArray[indexDaDespesa].fichas,
        valuesToAdd[indexDaDespesa],
      ];

      //Calculando o total por fonte dessa despesa

      let valorTotalFonte = 0;

      newArray[indexDaDespesa].fichas.forEach((ficha) => {
        valorTotalFonte += parseFloat(ficha.valor.replace(/,/g, "."));
        console.log(ficha.valor.replace(/","/g, "."));
      });

      newArray[indexDaDespesa].totalPorFonte = valorTotalFonte;

      setValuesQDD((prevValues) => ({
        ...prevValues,
        despesasFixadas: newArray,
      }));

      //Limpando o input da natureza da despesa
      limpaInputNatDesp(indexDaDespesa);
      handleGoRef(inputNatDespRef, indexDaDespesa);
      console.log("Next Ficha: ", lastAddedItemRef.current[indexDaDespesa]);
      if (lastAddedItemRef.current[indexDaDespesa]) {
        const elementRect =
          lastAddedItemRef.current[indexDaDespesa].getBoundingClientRect();
        // prettier-ignore
        const scrollToY = window.scrollY + elementRect.bottom - window.innerHeight + 160;
        window.scrollTo({
          top: scrollToY,
          behavior: "smooth",
        });
      }
    } else if (!naturezaDaDespesaNaoExisteNoArray) {
      toast.error(
        "Essa natureza da despesa ja foi adicionada a esta fonte de recursos."
      );
    } else {
      toast.error("Preencha todos os campos corretamente para adicionar.");
    }
  };

  const deletaNaturezaDaDespesa = (indexDaDespesa, indexDaFicha) => {
    if (
      pagDeUpdate &&
      valuesQDD.despesasFixadas[indexDaDespesa].fichas[indexDaFicha].id
    ) {
      console.log(
        valuesQDD.despesasFixadas[indexDaDespesa].fichas[indexDaFicha].id
      );
      api
        .delete(
          `qdd/deleteFichaQdd/${valuesQDD.despesasFixadas[indexDaDespesa].fichas[indexDaFicha].id}`
        )
        .then((resp) => {
          toast.success("Dado deletado com sucesso!");
          console.log(resp);
          let newArray = [...valuesQDD.despesasFixadas];
          newArray[indexDaDespesa].fichas.splice(indexDaFicha, 1);
          console.log(newArray);

          //Calculando o total por fonte dessa despesa

          let valorTotalFonte = 0;

          newArray[indexDaDespesa].fichas.forEach((ficha) => {
            valorTotalFonte += parseFloat(ficha.valor.replace(/,/g, "."));
            console.log(ficha.valor.replace(/","/g, "."));
          });

          newArray[indexDaDespesa].totalPorFonte = valorTotalFonte;

          setValuesQDD((prevValues) => ({
            ...prevValues,
            despesasFixadas: [...newArray],
          }));
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            "Algo deu errado ao excluir esse dado, por favor, tente mais tarde."
          );
        });
    } else {
      let newArray = [...valuesQDD.despesasFixadas];
      newArray[indexDaDespesa].fichas.splice(indexDaFicha, 1);
      console.log(newArray);

      //Calculando o total por fonte dessa despesa

      let valorTotalFonte = 0;

      newArray[indexDaDespesa].fichas.forEach((ficha) => {
        valorTotalFonte += parseFloat(ficha.valor.replace(/,/g, "."));
        console.log(ficha.valor.replace(/","/g, "."));
      });

      newArray[indexDaDespesa].totalPorFonte = valorTotalFonte;

      setValuesQDD((prevValues) => ({
        ...prevValues,
        despesasFixadas: [...newArray],
      }));
    }
  };

  // useEffect(() => {
  //   if (optionsFonteRecursos.length > 0) {
  //     console.log(optionsFonteRecursos.find((item) => item.id === 22).codigo);
  //   }
  // }, [optionsFonteRecursos]);

  const acionaEdicaoInput = (indexDespesa, indexFicha) => {
    if (!valuesQDD.despesasFixadas[indexDespesa].fichas[indexFicha].disabled) {
      let newArrayDespesas = [...valuesQDD.despesasFixadas];
      newArrayDespesas[indexDespesa].fichas[indexFicha].disabled = true;
      setValuesQDD((prevValues) => ({
        ...prevValues,
        despesasFixadas: newArrayDespesas,
      }));
    } else {
      //Guardando o valor sem alteracao caso o usuario cancele a alteracao
      setValorAntesDaEdicao(
        valuesQDD.despesasFixadas[indexDespesa].fichas[indexFicha].valor
      );
      //------------------------------------------------------------------
      let todosOsOutrosEstaoDesabilitados = true;
      valuesQDD.despesasFixadas.forEach((despesa) => {
        despesa.fichas.forEach((ficha) => {
          if (!ficha.disabled) {
            todosOsOutrosEstaoDesabilitados = false;
            return;
          }
        });
      });

      if (todosOsOutrosEstaoDesabilitados) {
        let newArrayDespesas = [...valuesQDD.despesasFixadas];
        newArrayDespesas[indexDespesa].fichas[indexFicha].disabled = false;
        setValuesQDD((prevValues) => ({
          ...prevValues,
          despesasFixadas: newArrayDespesas,
        }));
      } else {
        toast.error(
          "Você tem edições em aberto, por favor, conclua as edicoes pendentes antes de editar outros campos."
        );
      }
    }
  };

  const cancelaAlteracaoFicha = (indexDespesa, indexFicha) => {
    let arrayDespesasAlteracoesCanceladas = [...valuesQDD.despesasFixadas];
    arrayDespesasAlteracoesCanceladas[indexDespesa].fichas[indexFicha].valor =
      valorAntesDaEdicao;

    //Calculando o total por fonte dessa despesa

    let valorTotalFonte = 0;

    arrayDespesasAlteracoesCanceladas[indexDespesa].fichas.forEach((ficha) => {
      valorTotalFonte += parseFloat(ficha.valor.replace(/,/g, "."));
      console.log(ficha.valor.replace(/","/g, "."));
    });

    arrayDespesasAlteracoesCanceladas[indexDespesa].totalPorFonte =
      valorTotalFonte;

    //Desativando a edicao do input
    arrayDespesasAlteracoesCanceladas[indexDespesa].fichas[
      indexFicha
    ].disabled = true;

    //alterando os valuesQDD
    setValuesQDD((prevValues) => ({
      ...prevValues,
      despesasFixadas: arrayDespesasAlteracoesCanceladas,
    }));
  };

  const acionaEdicaoInputFonteRecursos = (indexDespesa) => {
    if (!valuesQDD.despesasFixadas[indexDespesa].disabled) {
      let newArray = [...valuesQDD.despesasFixadas];
      newArray[indexDespesa].disabled = true;

      setValuesQDD((prevValues) => ({
        ...prevValues,
        despesasFixadas: newArray,
      }));
    } else {
      //Guardando o valor sem alteracao caso o usuario cancele a alteracao
      setValorAntesDaEdicaoFonteRecursos(
        valuesQDD.despesasFixadas[indexDespesa].fonteDeRecursoId
      );
      //------------------------------------------------------------------
      let nenhumaOutraEdicaoAtiva = true;
      valuesQDD.despesasFixadas.forEach((despesa) => {
        if (!despesa.disabled) {
          nenhumaOutraEdicaoAtiva = false;
        }
      });

      if (nenhumaOutraEdicaoAtiva) {
        let newArray = [...valuesQDD.despesasFixadas];
        newArray[indexDespesa].disabled = false;

        setValuesQDD((prevValues) => ({
          ...prevValues,
          despesasFixadas: newArray,
        }));
      } else {
        toast.error(
          "Você tem edições em aberto, por favor, conclua as edicoes pendentes antes de editar outros campos."
        );
      }
    }
  };

  const cancelaAlteracaoFonteRecurso = (indexDespesa) => {
    let arrayDespesasAlteracoesCanceladas = [...valuesQDD.despesasFixadas];
    arrayDespesasAlteracoesCanceladas[indexDespesa].fonteDeRecursoId =
      valorAntesDaEdicaoFonteRecursos;

    arrayDespesasAlteracoesCanceladas[indexDespesa].disabled = true;

    //alterando os valuesQDD
    setValuesQDD((prevValues) => ({
      ...prevValues,
      despesasFixadas: arrayDespesasAlteracoesCanceladas,
    }));
  };

  const handleAutocompleteFonteRecursosEdicao = (newValue, indexDespesa) => {
    let fonteDeRecursoAindaNaoExisteNasDespesas = true;

    valuesQDD.despesasFixadas.forEach((despesa) => {
      if (despesa.fonteDeRecursoId === newValue.id) {
        fonteDeRecursoAindaNaoExisteNasDespesas = false;
      }
    });

    if (fonteDeRecursoAindaNaoExisteNasDespesas) {
      let newArray = [...valuesQDD.despesasFixadas];
      newArray[indexDespesa].fonteDeRecursoId = newValue.id;

      setValuesQDD((prevValues) => ({
        ...prevValues,
        despesasFixadas: newArray,
      }));
    } else {
      toast.error(
        "Essa fonte de recurso já está adicionada as despesas fixadas"
      );
    }
  };

  useEffect(() => {
    let valorTotalAcao = 0;
    let newFicha = 0;
    if (proxFicha) {
      newFicha = proxFicha.nextFicha;
    }

    valuesQDD.despesasFixadas.forEach((despesa) => {
      let totalPorFonte = 0;
      despesa.fichas.forEach((ficha) => {
        totalPorFonte += parseFloat(ficha.valor.replace(/,/g, "."));
        if (location.pathname.includes("replica") && proxFicha) {
          ficha.numeroFicha = newFicha;
          newFicha += 1;
        }
      });
      despesa.totalPorFonte = totalPorFonte.toFixed(2);
      valorTotalAcao += totalPorFonte;
    });

    setValuesQDD((prevValues) => ({
      ...prevValues,
      valorTotalDaAcao: valorTotalAcao.toFixed(2),
    }));

    //eslint-disable-next-line
  }, [valuesQDD.despesasFixadas, proxFicha]);

  useEffect(() => {
    if (!pagDeUpdate) {
      setOpenRefFromFR(true);
    }
  }, [pagDeUpdate, setOpenRefFromFR]);

  const inputNatDespRef = useRef([]);
  const inputEsferaRef = useRef([]);
  const inputDespFixRef = useRef([]);
  const indexRef = useRef(0);
  const lastAddedItemRef = useRef([]);

  const handleKeyDownFunction = (event, callback) => {
    if (event.key === "Enter") {
      event.preventDefault();
      callback();
    }
  };

  const handleGoRef = (refs, index = 0) => {
    if (refs.current[index]) {
      refs.current[index].focus();
    }
  };

  useEffect(() => {
    if (valuesQDD.despesasFixadas.length > despesasFixadasOldLenght) {
      handleGoRef(inputNatDespRef, valuesQDD.despesasFixadas.length - 1);
    }
    setDespesasFixadasOldLenght(valuesQDD.despesasFixadas.length);
  }, [valuesQDD.despesasFixadas.length, despesasFixadasOldLenght, pagDeUpdate]);

  const isElementInViewport = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 80 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  return (
    <>
      <ModalDeleteData
        modalOpened={modalOpened}
        closeModal={closeModal}
        setDataDelete={setDataDelete}
        deleteDataFunction={
          dataDelete.qualAtributoExcluir === "despesaFixada"
            ? () =>
                deletaFonteDeRecurso(
                  dataDelete.fonteDeRecursoId,
                  dataDelete.indexDaDespesa
                )
            : () =>
                deletaNaturezaDaDespesa(
                  dataDelete.indexDaDespesa,
                  dataDelete.indexDaFicha
                )
        }
      />
      <Row
        className={`${
          valuesQDD.despesasFixadas.length > 0 ? "row_form" : "row_form_2"
        } mt-1 `}
      >
        {/* <button onClick={() => console.log(valuesQDD)}>values qdd</button> */}
        {/* <button onClick={() => console.log(optionsNaturezaDaDespesa)}>
          options nat desp
        </button> */}
        <CCol>
          <PTitulosInputs>&zwnj;</PTitulosInputs>
          <MyAutoComplete
            ref={inputFontRecRef}
            inputValue={inputFonteRecursos}
            setInputValue={setInputFonteRecursos}
            style={{ height: "37px", fontSize: "15px" }}
            labelInput="Fonte de Recursos"
            placeholder="Nome ou Código da Fonte de Recursos"
            options={optionsFonteRecursos}
            onOptionSelect={handleAutocompleteFonteRecursos}
            labelFormat={(option) => `${option.codigo} - ${option.titulo}`}
            iconClearFunction={() => {
              setInputFonteRecursos("");
            }}
            onKeyDownFunction={(e) => {
              handleKeyDownFunction(e, adicionarFonteDeRecursos);
            }}
          />
        </CCol>
        <CColButtonsAcoes2Icons md={1}>
          <PTitulosInputs>Ação</PTitulosInputs>
          <Icon
            onClick={() => {
              adicionarFonteDeRecursos();
            }}
            icon="ic:baseline-add-box"
            color="#105200"
            height="28"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
              marginTop: "4px",
            }}
          />
        </CColButtonsAcoes2Icons>
      </Row>

      {valuesQDD.despesasFixadas.map((despesa, indexDespesa, selfArray) => (
        <React.Fragment key={indexDespesa}>
          <Row
            className={`${
              indexDespesa === selfArray.length - 1 ? "row_form_2" : "row_form"
            } mt-1 `}
          >
            <CCol>
              <PTitulosInputs>Fonte de Recursos</PTitulosInputs>
              {despesa.disabled ? (
                <FormControlListaDeInputs
                  style={{ fontSize: "15px" }}
                  disabled
                  value={
                    optionsFonteRecursos.length > 0
                      ? `${
                          optionsFonteRecursos.find(
                            (item) => item.id === despesa.fonteDeRecursoId
                          ).codigo
                        } - ${
                          optionsFonteRecursos.find(
                            (item) => item.id === despesa.fonteDeRecursoId
                          ).titulo
                        }`
                      : ""
                  }
                />
              ) : (
                <>
                  <MyAutoComplete
                    style={{ height: "37px", fontSize: "15px" }}
                    placeholder="Nome ou Código da Fonte de Recursos"
                    options={optionsFonteRecursos}
                    onOptionSelect={(value) =>
                      handleAutocompleteFonteRecursosEdicao(value, indexDespesa)
                    }
                    labelFormat={(option) =>
                      `${option.codigo} - ${option.titulo}`
                    }
                    iconClearFunction={() => {
                      setInputFonteRecursos("");
                    }}
                  />
                </>
              )}
            </CCol>
            <CColButtonsAcoes2Icons md={1}>
              <PTitulosInputs>Ação</PTitulosInputs>
              {despesa.disabled ? (
                <Icon
                  onClick={() => acionaEdicaoInputFonteRecursos(indexDespesa)}
                  icon="bx:edit"
                  color="#5971C8"
                  height="26"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "3px",
                  }}
                />
              ) : (
                <Icon
                  onClick={() => acionaEdicaoInputFonteRecursos(indexDespesa)}
                  icon="fluent:save-24-filled"
                  color="#008CFF"
                  height="26"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "3px",
                  }}
                />
              )}

              <Icon
                onClick={() => {
                  if (despesa.disabled) {
                    return;
                  } else {
                    cancelaAlteracaoFonteRecurso(indexDespesa);
                  }
                }}
                icon="ic:baseline-cancel"
                height="26"
                color={"#F24E1E"}
                style={{
                  pointerEvents: despesa.disabled ? "none" : "auto",
                  cursor: despesa.disabled ? "not-allowed" : "pointer",
                  marginLeft: "2px",
                  marginRight: "-5px",
                  marginTop: "3px",
                  display: despesa.disabled ? "none" : "",
                }}
              />
              <Icon
                onClick={() => {
                  openModal();
                  setDataDelete((prevValues) => ({
                    ...prevValues,
                    fonteDeRecursoId: despesa.fonteDeRecursoId,
                    indexDaDespesa: indexDespesa,
                    qualAtributoExcluir: "despesaFixada",
                  }));
                  // deletaFonteDeRecurso(despesa.fonteDeRecursoId, indexDespesa);
                }}
                icon="ic:baseline-delete"
                color="#E79900"
                height="28"
                style={{
                  cursor: "pointer",
                  marginLeft: "1px",
                  marginTop: "1px",
                  display: despesa.disabled ? "" : "none",
                }}
              />
            </CColButtonsAcoes2Icons>

            <Row
              style={{
                margin: "0px",
                marginTop: "15px",
                padding: "0px",
              }}
            >
              <CCol md={1}>
                <PTitulosInputs>Ficha</PTitulosInputs>
                <FormControlListaDeInputs
                  radiusborder={
                    selfArray[indexDespesa].fichas.length > 0
                      ? "5px 5px 0px 0px"
                      : "5px"
                  }
                  disabled
                  style={{ textAlign: "end", fontSize: "15px" }}
                  //value={nextFicha}
                />
              </CCol>
              <CCol md={6}>
                <PTitulosInputs>
                  Natureza da Despesa - Elemento de Despesa
                </PTitulosInputs>
                <MyAutoComplete
                  ref={(el) => (inputNatDespRef.current[indexDespesa] = el)}
                  style={{ fontSize: "15px", height: "30px" }}
                  inputValue={inputNatDespesa[indexDespesa]}
                  setInputValue={(value) => {
                    setInputNatDespesa((prevInputNatDespesa) => {
                      const updatedInputNatDespesa = [...prevInputNatDespesa];
                      updatedInputNatDespesa[indexDespesa] = value;
                      return updatedInputNatDespesa;
                    });
                  }}
                  radiusborder={
                    selfArray[indexDespesa].fichas.length > 0
                      ? "5px 5px 0px 0px"
                      : "5px"
                  }
                  placeholder="Nome ou Código da natureza da despesa"
                  options={optionsNaturezaDaDespesa}
                  onOptionSelect={(value) =>
                    handleAutocompleteNaturezaDaDespesa(value, indexDespesa)
                  }
                  labelFormat={(option) =>
                    `${option.codigo} - ${option.titulo}`
                  }
                  onChange={(e) => {
                    if (
                      !isElementInViewport(
                        inputNatDespRef.current[indexDespesa]
                      )
                    ) {
                      setTimeout(() => {
                        inputNatDespRef.current[indexDespesa].scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                      }, 100);
                    }
                  }}
                  //onKeyDown={(e) => handleKeyDown(e, inputEsferaRef)}
                  //iconClearFunction={limparTodosValores}
                />
              </CCol>
              <CCol md={2}>
                <PTitulosInputs>Esfera</PTitulosInputs>
                <FloatingLabelInput
                  radiusborder={
                    selfArray[indexDespesa].fichas.length > 0
                      ? "5px 5px 0px 0px"
                      : "5px"
                  }
                  ref={(el) => (inputEsferaRef.current[indexDespesa] = el)}
                  height={"30px"}
                  new={true}
                  holdSelect
                  style={{
                    color: "#515c70",
                  }}
                  tipo="input_select"
                  label="Fiscal"
                  placeholder="Fiscal"
                  options={[
                    { label: "Fiscal", value: "Fiscal" },
                    { label: "Seguridade", value: "Seguridade" },
                  ]}
                  onSelect={(option) =>
                    handleInputChange(
                      "esfera",
                      option.value,
                      despesa.fonteDeRecursoId,
                      indexDespesa
                    )
                  }
                />
              </CCol>
              <CCol>
                <PTitulosInputs>Despesas Fixadas</PTitulosInputs>
                <InputFormatRealBrasileiro
                  ref={(el) => (inputDespFixRef.current[indexDespesa] = el)}
                  listaDeInputs={true}
                  radiusborder={
                    selfArray[indexDespesa].fichas.length > 0
                      ? "5px 5px 0px 0px"
                      : "5px"
                  }
                  externalValue={
                    naturezasDaDespesaAdicionar.length > 0
                      ? naturezasDaDespesaAdicionar[indexDespesa].valor
                      : ""
                  }
                  onChange={(e, valorInput) => {
                    handleInputChange(
                      "valor",
                      valorInput,
                      despesa.fonteDeRecursoId,
                      indexDespesa
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      adicionaNaturezaDaDespesa(indexDespesa);
                    }
                  }}
                  style={{ textAlign: "end", fontSize: "15px" }}
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1}>
                <Icon
                  onClick={() => {
                    adicionaNaturezaDaDespesa(indexDespesa);
                  }}
                  icon="ic:baseline-add-box"
                  color="#105200"
                  height="28"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "9px",
                  }}
                />
              </CColButtonsAcoes2Icons>
            </Row>
            {valuesQDD.despesasFixadas[indexDespesa].fichas.map(
              (ficha, indexFicha, selfArrayFichas) => (
                <Row
                  key={indexFicha}
                  ref={
                    indexFicha === selfArrayFichas.length - 1
                      ? (el) => (lastAddedItemRef.current[indexDespesa] = el)
                      : null
                  }
                  style={{
                    padding: "0px",
                    margin: "0px",
                    marginTop: "-1px",
                  }}
                >
                  <CCol md={1}>
                    <FormControlListaDeInputs
                      disabled
                      radiusborder={
                        selfArrayFichas.length - 1 === indexFicha
                          ? "0px 0px 5px 5px"
                          : "0px"
                      }
                      style={{ textAlign: "end", fontSize: "15px" }}
                      value={ficha.numeroFicha}
                    />
                  </CCol>
                  <CCol md={6}>
                    <FormControlListaDeInputs
                      style={{ fontSize: "15px" }}
                      disabled
                      radiusborder={
                        selfArrayFichas.length - 1 === indexFicha
                          ? "0px 0px 5px 5px"
                          : "0px"
                      }
                      title={
                        optionsNaturezaDaDespesa.length > 0
                          ? `${
                              optionsNaturezaDaDespesa.find(
                                (natDesp) =>
                                  natDesp.id === ficha.naturezaDaDespesaId
                              )?.codigo
                            } - ${
                              optionsNaturezaDaDespesa.find(
                                (natDesp) =>
                                  natDesp.id === ficha.naturezaDaDespesaId
                              )?.titulo
                            }`
                          : ""
                      }
                      value={
                        optionsNaturezaDaDespesa.length > 0
                          ? `${
                              optionsNaturezaDaDespesa.find(
                                (natDesp) =>
                                  natDesp.id === ficha.naturezaDaDespesaId
                              )?.codigo
                            } - ${
                              optionsNaturezaDaDespesa.find(
                                (natDesp) =>
                                  natDesp.id === ficha.naturezaDaDespesaId
                              )?.titulo
                            }`
                          : ""
                      }
                    />
                  </CCol>
                  <CCol md={2}>
                    {ficha.disabled ? (
                      <FormControlListaDeInputs
                        style={{ fontSize: "15px" }}
                        disabled
                        radiusborder={
                          selfArrayFichas.length - 1 === indexFicha
                            ? "0px 0px 5px 5px"
                            : "0px"
                        }
                        value={ficha.esfera}
                      />
                    ) : (
                      <FloatingLabelInput
                        ref={(el) =>
                          (inputEsferaRef.current[indexDespesa] = el)
                        }
                        radiusborder={
                          selfArrayFichas.length - 1 === indexFicha
                            ? "0px 0px 5px 5px"
                            : "0px"
                        }
                        height={"30px"}
                        new={true}
                        style={{
                          color: "#515c70",
                        }}
                        tipo="input_select"
                        label={ficha.esfera}
                        placeholder={ficha.esfera}
                        options={[
                          { label: "Fiscal", value: "Fiscal" },
                          { label: "Seguridade", value: "Seguridade" },
                        ]}
                        onSelect={(option) => (ficha.esfera = option.value)}
                      />
                    )}
                  </CCol>
                  <CCol>
                    <InputFormatRealBrasileiro
                      listaDeInputs={true}
                      style={{ fontSize: "15px", textAlign: "right" }}
                      onChange={(e, valorInput) => {
                        console.log(valorInput);
                        handleChangeEditValor(
                          valorInput,
                          indexDespesa,
                          indexFicha
                        );
                      }}
                      radiusborder={
                        selfArrayFichas.length - 1 === indexFicha
                          ? "0px 0px 5px 5px"
                          : "0px"
                      }
                      disabled={ficha.disabled}
                      externalValue={ficha.valor}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          acionaEdicaoInput(indexDespesa, indexFicha);
                        }
                      }}
                    />
                  </CCol>
                  <CColButtonsAcoes2Icons md={1}>
                    {ficha.disabled ? (
                      <Icon
                        onClick={() => {
                          acionaEdicaoInput(indexDespesa, indexFicha);
                          console.log(
                            "indexFicha: ",
                            indexFicha,
                            "selfArrayFichas: ",
                            selfArrayFichas.length - 1
                          );
                        }}
                        icon="bx:edit"
                        color="#5971C8"
                        height="26"
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginTop: "3px",
                        }}
                      />
                    ) : (
                      <Icon
                        onClick={() =>
                          acionaEdicaoInput(indexDespesa, indexFicha)
                        }
                        icon="fluent:save-24-filled"
                        color="#008CFF"
                        height="26"
                        style={{
                          cursor: "pointer",
                          marginLeft: "5px",
                          marginTop: "3px",
                        }}
                      />
                    )}

                    <Icon
                      onClick={() => {
                        if (ficha.disabled) {
                          return;
                        } else {
                          cancelaAlteracaoFicha(indexDespesa, indexFicha);
                        }
                      }}
                      icon="ic:baseline-cancel"
                      height="26"
                      color={"#F24E1E"}
                      style={{
                        pointerEvents: ficha.disabled ? "none" : "auto",
                        cursor: ficha.disabled ? "not-allowed" : "pointer",
                        marginLeft: "2px",
                        marginRight: "-5px",
                        marginTop: "3px",
                        display: ficha.disabled ? "none" : "",
                      }}
                    />
                    <Icon
                      onClick={
                        () => {
                          openModal();
                          setDataDelete((prevValues) => ({
                            ...prevValues,
                            indexDaDespesa: indexDespesa,
                            indexDaFicha: indexFicha,
                            qualAtributoExcluir: "fichaQDD",
                          }));
                        }

                        // deletaNaturezaDaDespesa(indexDespesa, indexFicha)
                      }
                      icon="ic:baseline-delete"
                      color="#E79900"
                      height="28"
                      style={{
                        cursor: "pointer",
                        marginLeft: "1px",
                        marginTop: "2px",
                        display: ficha.disabled ? "" : "none",
                      }}
                    />
                  </CColButtonsAcoes2Icons>
                </Row>
              )
            )}
            <Row
              style={{
                padding: "0px",
                margin: "0px",
                marginTop: "9px",
              }}
            >
              <CCol
                md={/*selfArray.length - 1 === indexDespesa ? 3 : 7*/ 7}
              ></CCol>
              <CCol
                md={2}
                style={{
                  textAlign: "center",
                  marginTop: "4px",
                }}
              >
                <SpanTotalPorFonte>Total por Fonte</SpanTotalPorFonte>
              </CCol>
              <CCol>
                <FormControlListaDeInputs
                  style={{
                    borderRadius: "10px",
                    padding: "15px",
                    border: "none",
                    textAlign: "end",
                  }}
                  disabled
                  value={`R$ ${FormatValueToLocaleString(
                    parseFloat(despesa.totalPorFonte)
                  )}`}
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1} />
            </Row>
          </Row>
          {indexDespesa === selfArray.length - 1 && (
            <Row className={"row_form_2"} style={{ marginTop: "3px" }}>
              <CCol md={7}></CCol>
              <CCol md={2} style={{ textAlign: "center", marginTop: "7px" }}>
                <SpanTotalPorFonte>Total por Ação</SpanTotalPorFonte>
              </CCol>
              <CCol style={{ marginTop: "3px" }}>
                <FormControlListaDeInputs
                  style={{
                    borderRadius: "10px",
                    padding: "15px",
                    border: "none",
                    textAlign: "end",
                  }}
                  disabled
                  value={`R$ ${FormatValueToLocaleString(
                    parseFloat(valuesQDD.valorTotalDaAcao)
                  )}`}
                />
              </CCol>
              <CColButtonsAcoes2Icons md={1} />
            </Row>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default DespesaFixada;
