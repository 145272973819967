import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";

export const ReturnArrayAnos = () => {
  const anoAtual = parseInt(ReturnDataAtual().split("/")[2]);

  let arrayYears = [];

  for (let i = -3; i < 3; i++) {
    arrayYears.push(anoAtual + i);
  }

  return arrayYears;
};
