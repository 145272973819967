import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import EstruturaDespesa from "./EstruturaDespesa";
import ProcedimentosDespesa from "./ProcedimentosDespesa";
import { CCol } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { ToastContainer, toast } from "react-toastify";
import { contaNatDespAutoPreencher } from "../../../../utils/FormatacaoDeDados/ContaNatDespAutoPreencher";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { FormatContaNatDesp } from "../../../../utils/FormatacaoDeDados/FormatContaNatDesp";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import { useParams } from "react-router-dom";
import api from "../../../../utils/api";

export const OptionsStyle = styled.span`
  color: #515c70;
  line-height: 35px;
  font-size: 0.8em;
  margin-left: 20px;
  cursor: pointer;
  ${({ selecionado }) =>
    selecionado &&
    `
    font-weight: 600;
    border-bottom: 2px solid #515c70;
  `}
`;

function NaturezaDaDespesa() {
  const [abaSelecionada, setAbaSelecionada] = useState(0);
  const [arrayEstruturaDaConta, setArrayEstruturaDaConta] = useState([]);
  const [teclaPressionada, setTeclaPressionada] = useState(0);
  const dataAtual = ReturnDataAtual();
  const { currentClient } = useContext(CurrentClientContext);
  const { codigo: paramsCodigo } = useParams();
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [thisHasDD, setThisHasDD] = useState(false);
  const [codigoConta, setCodigoConta] = useState("");
  const [atributosConta, setAtributosConta] = useState({
    descricao: "",
    dePara: "",
    funcao: "",
    valorizavel: "",
  });
  const [valuesNaturezaDaDespesa, setValuesNaturezaDaDespesa] = useState({
    naturezaDaDespesaId: 0,
    clienteId: currentClient.clienteId,
    procedimentos: [
      {
        passo: 1,
        registros: [],
      },
      {
        passo: 2,
        registros: [],
      },
      {
        passo: 3,
        registros: [],
      },
      {
        passo: 4,
        registros: [],
      },
      {
        passo: 5,
        registros: [],
      },
    ],
  });

  useEffect(() => {
    hasAddFunction();
    // eslint-disable-next-line
  }, [arrayEstruturaDaConta]);

  useEffect(() => {
    if (paramsCodigo) {
      setCodigoConta(paramsCodigo);
    }
  }, [paramsCodigo]);

  const adicionarNaturezaDaDespesa = () => {
    let valuesToPost = { ...valuesNaturezaDaDespesa };

    let condition1 = true;

    let condition2 = valuesNaturezaDaDespesa.naturezaDaDespesaId !== 0;

    const arrayProcedimentosToPost = valuesToPost.procedimentos.filter(
      (item) => item.registros.length > 0
    );

    arrayProcedimentosToPost.forEach((item) => {
      item.registros.forEach((subItem) => {
        if (
          subItem.contaContabil === "" ||
          subItem.contaContabil.length !== 12
        ) {
          condition1 = false;
        }
      });
    });

    valuesToPost.procedimentos = arrayProcedimentosToPost;

    if (arrayProcedimentosToPost.length > 0 && condition1 && condition2) {
      api
        .post("naturezaDaDespesa", valuesToPost)
        .then((resp) => {
          console.log(resp.data);
          console.log("Óh, foi salvo, tá aqui oq foi salvo: ", valuesToPost);
          toast.success("Dados salvos com sucesso!");

          limpaValoresNaturezaDaDespesa();
        })
        .catch((error) => {
          console.log(error);
          toast.error("Algo deu errado, por favor, tente mais tarde.");
        });
    } else if (!condition2) {
      toast.error(
        "Preencha o campo de codigo da Natureza da Despesa com um codigo de Natureza da Despesa válido."
      );
    } else {
      toast.error(
        "Preencha todos os campos corretamente (Códigos de conta precisam ser informados explicitamente)."
      );
    }
  };

  const fetchContaFilter = () => {
    setLoadingFetch(true);
    const valorFormated = contaNatDespAutoPreencher(codigoConta);
    console.log(valorFormated);

    api
      .get(
        `naturezaDaDespesa/valoresEstrutura/${
          dataAtual.split("/")[2]
        }/${valorFormated}`
      )
      .then((resp) => {
        const data = resp.data.valorTransformado;
        console.log("EU SOU O DATA: ", data);

        if (data.length === 0) {
          setValuesNaturezaDaDespesa((prevValues) => ({
            ...prevValues,
            naturezaDaDespesaId: 0,
          }));
          throw new Error("Array vazio");
        } else {
          const arrayLenght = data.length;
          const novoArrayEstruturaDaConta = data.map((item) => ({
            nivel: item.naturezaDaDespesa.nivel,
            conta: item.naturezaDaDespesa.codigo,
            titulo: item.naturezaDaDespesa.nome,
          }));
          let newFuncao;
          console.log("AQUIIIII: ", novoArrayEstruturaDaConta);
          setArrayEstruturaDaConta(novoArrayEstruturaDaConta);
          if (data[arrayLenght - 1].naturezaDaDespesa.funcao.length > 0) {
            newFuncao = data[arrayLenght - 1].naturezaDaDespesa.funcao;
          } else {
            newFuncao = atributosConta.funcao;
          }
          setAtributosConta((prevValues) => ({
            ...prevValues,
            descricao: data[arrayLenght - 1].naturezaDaDespesa?.nome,
            dePara: data[arrayLenght - 1].naturezaDaDespesa?.dePara,
            funcao: newFuncao,
            valorizavel: data[arrayLenght - 1].naturezaDaDespesa?.valorizavel,
          }));
          setValuesNaturezaDaDespesa((prevValues) => ({
            ...prevValues,
            naturezaDaDespesaId: data[arrayLenght - 1].naturezaDaDespesa.id,
          }));

          if (data[arrayLenght - 1].procedimentos.Procedimento1) {
            const dataPar1 =
              data[arrayLenght - 1].procedimentos.Procedimento1.Par1;

            let procedimentosValues = [
              ...valuesNaturezaDaDespesa.procedimentos,
            ];
            procedimentosValues[0].registros = [
              {
                contaContabil: dataPar1[0].contaContabil,
                titulo: dataPar1[0].titulo,
                origem: dataPar1[0].origem,
                naturezaDaInfo: dataPar1[0].naturezaDaInfo,
                par: 1,
              },
              {
                contaContabil: dataPar1[1].contaContabil,
                titulo: dataPar1[1].titulo,
                origem: dataPar1[1].origem,
                naturezaDaInfo: dataPar1[1].naturezaDaInfo,
                par: 1,
              },
            ];

            setValuesNaturezaDaDespesa((prevValues) => ({
              ...prevValues,
              procedimentos: [...procedimentosValues],
            }));
          } else {
            setValuesNaturezaDaDespesa((prevValues) => ({
              ...prevValues,
              procedimentos: [
                {
                  passo: 1,
                  registros: [],
                },
                {
                  passo: 2,
                  registros: [],
                },
                {
                  passo: 3,
                  registros: [],
                },
                {
                  passo: 4,
                  registros: [],
                },
                {
                  passo: 5,
                  registros: [],
                },
              ],
            }));
          }
        }

        setLoadingFetch(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.message === "Array vazio") {
          toast.error(
            "Não foi encontrado nenhum registro com essa combinação númerica"
          );
        } else {
          toast.error("Algo deu errado. Por favor, tente mais tarde.");
        }
        setLoadingFetch(false);
      });
  };

  useEffect(() => {
    console.log("effect");
    if (codigoConta.length > 0 && codigoConta.length !== 9) {
      fetchContaFilter();
    }

    if (codigoConta.length === 0) {
      setValuesNaturezaDaDespesa((prevValues) => ({
        ...prevValues,
        naturezaDaDespesaId: 0,
      }));

      setAtributosConta((prevValues) => ({
        ...prevValues,
        descricao: "",
        funcao: "",
        valorizavel: "",
      }));
    }
    //eslint-disable-next-line
  }, [codigoConta]);

  const deletaItemEstrutura = () => {
    console.log(`valor que o caba tem que deletar: ${codigoConta}`);
    let novoArray = [...arrayEstruturaDaConta];
    const contaComparativa = contaNatDespAutoPreencher(codigoConta);
    if (novoArray.some((item) => item.conta === contaComparativa)) {
      novoArray.pop();
      console.log(novoArray);
      setArrayEstruturaDaConta(novoArray);
    } else {
      console.log("esse dado nn existe no array");
    }
  };

  const limpaValoresNaturezaDaDespesa = () => {
    setValuesNaturezaDaDespesa((prevValues) => ({
      ...prevValues,

      naturezaDaDespesaId: 0,
      clienteId: currentClient.clienteId,
      procedimentos: [
        {
          passo: 1,
          registros: [],
        },
        {
          passo: 2,
          registros: [],
        },
        {
          passo: 3,
          registros: [],
        },
        {
          passo: 4,
          registros: [],
        },
        {
          passo: 5,
          registros: [],
        },
      ],
      desdobramentos: [],
    }));

    setCodigoConta("");
    setArrayEstruturaDaConta([]);
  };

  const hasAddFunction = () => {
    setThisHasDD(
      arrayEstruturaDaConta.some((item) => item.nivel.substring(0, 2) === "DD")
    );
  };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        BotaoSaveAtivo={false}
        withRow={true}
        NomePaginaTitulo={"Natureza da Despesa"}
        PaginaConsulta={"/planejamento/cadastro/natureza-da-despesa/consulta"}
        PaginaSubtitulo={"Cadastro"}
        ButtonSaveFunction={() => {
          adicionarNaturezaDaDespesa();
        }}
      />
      <ToastContainer />
      <Row className="row_form mt-1">
        <CCol md={2}>
          <FloatingLabelInput
            placeholder="Código"
            label="Código"
            value={codigoConta}
            readOnly={loadingFetch ? true : false}
            onKeyDown={(e) => {
              if (
                e.keyCode === 8 &&
                e.target.selectionStart < e.target.value.length
              ) {
                e.preventDefault(); // Impede a exclusão
              }

              setTeclaPressionada(e.keyCode);
            }}
            onChange={(e) => {
              FormatContaNatDesp(e.target);
              setCodigoConta(e.target.value);
              console.log(
                "EU SOU O ARRAY: ",
                arrayEstruturaDaConta,
                "EU TÔ COMO?: ",
                thisHasDD
              );
              if (
                teclaPressionada === 8 &&
                e.target.selectionStart >= e.target.value.length
              ) {
                deletaItemEstrutura();
              }
            }}
          />
        </CCol>
        <CCol md={thisHasDD ? 8 : 10}>
          <FloatingLabelInput
            disabled
            placeholder="Descrição"
            value={atributosConta.descricao}
            label="Descrição"
          />
        </CCol>
        {thisHasDD ? (
          <CCol md={2}>
            <FloatingLabelInput
              disabled
              placeholder="De-Para"
              value={atributosConta.dePara}
              label="De-Para"
            />
          </CCol>
        ) : (
          ""
        )}
      </Row>
      <Row className="row_form" style={{ marginTop: "-14px" }}>
        <CCol md={12}>
          <FloatingLabelInput
            disabled
            as="textarea"
            tipo="text_area"
            style={{ height: "38px", minHeight: "38px" }}
            placeholder="Função"
            label="Função"
            value={atributosConta.funcao}
          />
        </CCol>
      </Row>
      <Row>
        <Col
          className="d-flex align-items-center row_endereco_aba mt-1"
          md={12}
        >
          <OptionsStyle
            selecionado={abaSelecionada === 0}
            onClick={() => setAbaSelecionada(0)}
          >
            Estrutura
          </OptionsStyle>
          <OptionsStyle
            selecionado={abaSelecionada === 1}
            onClick={() => setAbaSelecionada(1)}
          >
            Procedimentos Contábeis
          </OptionsStyle>
        </Col>
      </Row>
      {abaSelecionada === 0 && (
        <EstruturaDespesa arrayEstruturaDaConta={arrayEstruturaDaConta} />
      )}
      {abaSelecionada === 1 && (
        <ProcedimentosDespesa
          valuesNaturezaDaDespesa={valuesNaturezaDaDespesa}
          setValuesNaturezaDaDespesa={setValuesNaturezaDaDespesa}
          atributosConta={atributosConta}
        />
      )}
    </Container>
  );
}

export default NaturezaDaDespesa;
