import { Col, Container, Row } from "react-bootstrap";
import styled, { css } from "styled-components";

export const RowAninhada = styled(Row)`
  margin: 0px;
  padding: 0px;
`;

export const RowToModal = styled(Row)`
  ${({ isAModalPage }) =>
    isAModalPage &&
    css`
      margin: 0px;
    `}
`;

export const RowForm = styled(Row)`
  position: relative;
  padding: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 9px;
  padding-right: 9px;
  background-color: #fff;
  border-radius: ${({ $borderRadiusType }) => {
    switch (parseInt($borderRadiusType)) {
      case 1:
        return "5px 5px 0px 0px";
      case 2:
        return "0px 0px 5px 5px";
      case 3:
        return "5px";
      default:
        return "0px";
    }
  }};
`;

export const ContainerToModal = styled(Container)`
  ${({ isAModalPage }) =>
    isAModalPage &&
    css`
      width: 70vw;
      padding-left: 0px;
      padding-right: 0px;
      margin: 0px;
    `}
`;

export const CCol = styled(Col)`
  --bs-gutter-x: 17px;
  --bs-gutter-y: 0;
`;

//CCols inputs da pag metas fiscais - sist planejamento
export const CColReais = styled(CCol)`
  @media (min-width: 768px) {
    width: 12.66%;
  }
`;

export const CColPorcent = styled(CCol)`
  @media (min-width: 768px) {
    width: 12%;
  }
`;
//-----------------------------------------------------

//CCols inputs da pag acesso interno - Cadastro Gerenciador Clientes

export const CColCodigo = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

export const CColCliente = styled(CCol)`
  @media (min-width: 768px) {
    width: 64.95%;
  }
`;

export const CColNatJur = styled(CCol)`
  @media (min-width: 768px) {
    width: 22.5%;
  }
`;

export const CColPoderAndTce = styled(CCol)`
  @media (min-width: 768px) {
    width: 13%;
  }
`;

export const CColPoder = styled(CCol)`
  @media (min-width: 768px) {
    width: 14.7%;
  }
`;

export const CColCodSiconfi = styled(CCol)`
  @media (min-width: 768px) {
    width: 13%;
  }
`;

export const CColEntGestora = styled(CCol)`
  @media (min-width: 768px) {
    width: 48%;
  }
`;

//-----------------------------------------------------

//CCols inputs da pagina cadastro CPF/CNPJ

export const CColCpfCnpj = styled(CCol)`
  @media (min-width: 768px) {
    width: 22%;
  }
`;

//-----------------------------------------------------

//CCols inputs da pagina de cadastro Parametros e Indices

export const CColParametrosIndicesInput = styled(CCol)`
  @media (min-width: 768px) {
    width: 15.8%;
  }
`;

export const CColButtonAdicionarParametrosIndices = styled(CCol)`
  @media (min-width: 768px) {
    width: 2%;
  }
`;

//-----------------------------------------------------

//CCols inputs da pagina de cadastro Eixo Estrategico

export const CColButtonsAcoes = styled(CCol)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    width: 4%;
  }
  padding: 0px;
`;

export const CColButtonsAcoes2Icons = styled(CCol)`
  @media (min-width: 768px) {
    width: 6%;
  }
  min-width: 70px;
  padding: 0px;
`;

//-----------------------------------------------------

//CCols inputs da pagina de cadastro Tipo de Programa

export const CColCodigoTipoProg = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

//-----------------------------------------------------

//CCols inputs da pagina de Unidade Orcamentaria

export const CColCodigoUnidadeOrcamentaria = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

//----------------------------------------------

//CCols Inputs da pagina Programa

export const CColSelectTipoDePrograma = styled(CCol)`
  @media (min-width: 768px) {
    width: 22.5%;
  }
`;

export const CColFormListaInputs = styled(CCol)`
  @media (min-width: 768px) {
    width: 13.1%;
  }
`;

export const CColFormListaSelect = styled(CCol)`
  @media (min-width: 768px) {
    width: 15%;
  }
`;

//----------------------------------------------

//CCols Tabela da Pagina PCASP Consulta

export const CColCodigoReduzido = styled(CCol)`
  width: 2.5%;
`;

export const CColArrowListaPCASP = styled(CCol)`
  width: 0.5%;
`;

//----------------------------------------------
