import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "../utils/api";

export const useFetchData = (
  link = null,
  dadoDeletado = true,
  clienteId = null
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = () => {
      if (!dadoDeletado) {
        console.log("Erro ao deletar dado");
        return;
      }

      console.log("teste do hook personalizado");

      setLoading(true);

      api
        .get(`${link.replace(/^\//, "")}`)
        .then((resp) => {
          const data = resp.data;
          console.log(data);
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setError(error.message);
          setData(null);
          if (error.response.status === 404) {
            toast.error("Nenhuma informação encontrada.");
          } else {
            toast.error(
              "Ocorreu um erro ao carregar os dados dessa página, por favor, tente mais tarde."
            );
          }

          setLoading(false);
        });
    };

    if (link) {
      loadData();
    }
  }, [link, dadoDeletado, clienteId]);

  return { data, loading, error };
};
