export const FormatValueToLocaleString = (numero) => {
  // Configuração de opções para o formato brasileiro
  const opcoes = {
    style: "decimal",
    maximumFractionDigits: 2, // Número máximo de casas decimais
    minimumFractionDigits: 2, // Número mínimo de casas decimais
    useGrouping: true, // Usar separador de milhares
  };

  // Formata o número de acordo com as opções
  return numero.toLocaleString("pt-BR", opcoes);
};
