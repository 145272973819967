import { useEffect, useRef, useState } from "react";
import * as RS from "../../../../../components/ComponenteDeRelatorio/StyledCompsRelatorio";
import { Col } from "react-bootstrap";

import { LabelCheckLOA } from "../../../../../components/ComponenteDeRelatorio/StyledCompsRelatorio";
import FloatingLabelInput from "../../../../../components/Grid/FloatingLabelInput";

export const OptionsRelatorio = ({
  principalOptions = [],
  labelsCheckAbaPrincipal = [],
  setLabelsCheckAbaPrincipal,
  setAbaSelecionada,
  abaSelecionada,
  showOpcoesButton = false,
  showOutrosButton = false,
  // handleOptionSelected
}) => {
  const [optionSelectPrincipal, setOptionSelectPrincipal] = useState(
    principalOptions.find((item) => item.value !== "")?.value
  );
  const [checkBoxTodosSelecionados, setCheckBoxTodosSelecionados] =
    useState(false);
  const floatingLabelInputRef = useRef(null);

  const gerenciarSelects = (indexDaLabel) => {
    const indexOpcao = labelsCheckAbaPrincipal.findIndex(
      (item) => item.opcao === optionSelectPrincipal
    );

    console.log(indexOpcao);

    console.log(indexDaLabel);

    let newArray = [...labelsCheckAbaPrincipal];

    newArray[indexOpcao].checkBoxes[indexDaLabel].selected =
      !newArray[indexOpcao].checkBoxes[indexDaLabel].selected;

    console.log(newArray);

    setLabelsCheckAbaPrincipal([...newArray]);
  };

  const checkAllBoxes = (checked) => {
    console.log(checked);
    const indexOpcao = labelsCheckAbaPrincipal.findIndex(
      (item) => item.opcao === optionSelectPrincipal
    );

    let newArray = [...labelsCheckAbaPrincipal];

    if (checked) {
      newArray[indexOpcao].checkBoxes.forEach((item) => {
        item.selected = true;
      });

      console.log(newArray);
    } else {
      newArray[indexOpcao].checkBoxes.forEach((item) => {
        item.selected = false;
      });

      console.log(newArray);
    }

    setLabelsCheckAbaPrincipal(newArray);
  };

  useEffect(() => {
    if (optionSelectPrincipal) {
      setCheckBoxTodosSelecionados(
        labelsCheckAbaPrincipal
          .find((item) => item.opcao === optionSelectPrincipal)
          ?.checkBoxes.every((option) => option.selected === true)
      );
    }
  }, [optionSelectPrincipal, labelsCheckAbaPrincipal]);

  return (
    <>
      <Col style={{ marginTop: "10px" }} md={12}>
        <FloatingLabelInput
          new
          ref={floatingLabelInputRef}
          tipo="input_select"
          label="Relatório por..."
          onChange={(e) => {
            console.log(e.target.value);
            setOptionSelectPrincipal(e.target.value);
          }}
          value={optionSelectPrincipal}
          options={[
            { label: "Relatório por...", value: null },
            ...principalOptions,
          ]}
          onSelect={(option) => {
            console.log(option.value);
            // handleOptionSelected(option.value)
            setOptionSelectPrincipal(option.value);
          }}
        />
      </Col>
      <RS.ContainerMaiorEsquerda className="mt-3" style={{ height: "92%" }}>
        <RS.MenuTopo>
          <RS.MenuTopoMenus>
            <RS.ButtonSelectAba
              onClick={() => setAbaSelecionada(0)}
              active={abaSelecionada === 0}
            >
              Principal
            </RS.ButtonSelectAba>
            <RS.ButtonSelectAba
              onClick={() => setAbaSelecionada(1)}
              active={abaSelecionada === 1}
              style={{ display: showOpcoesButton ? "" : "none" }}
            >
              Opções
            </RS.ButtonSelectAba>
            <RS.ButtonSelectAba
              active={abaSelecionada === 2}
              onClick={() => setAbaSelecionada(2)}
              style={{ display: showOutrosButton ? "" : "none" }}
            >
              Outros
            </RS.ButtonSelectAba>
          </RS.MenuTopoMenus>
        </RS.MenuTopo>

        <Col
          style={{
            marginTop: "10px",
            maxHeight: "calc(100% - 136px)",
            paddingLeft: "12px",
            paddingRight: "12px",
          }}
          md={12}
        >
          {labelsCheckAbaPrincipal.find(
            (item) => item.opcao === optionSelectPrincipal
          )?.checkBoxes.length > 0 && (
            <LabelCheckLOA
              label={"Marcar Todos"}
              checked={checkBoxTodosSelecionados}
              onChange={(e) => {
                checkAllBoxes(e.target.checked);
              }}
            />
          )}

          {labelsCheckAbaPrincipal
            .find((item) => item.opcao === optionSelectPrincipal)
            ?.checkBoxes.map((option, index) => (
              <LabelCheckLOA
                key={index}
                title={option.label}
                label={`${
                  option.label.length > 73
                    ? `${option.label.substring(0, 73)}...`
                    : option.label
                }`}
                value={option.value}
                checked={option.selected}
                onChange={(e) => {
                  gerenciarSelects(index);
                  console.log(e.target.value);
                }}
                style={option?.style}
              />
            ))}
        </Col>
      </RS.ContainerMaiorEsquerda>
    </>
  );
};
