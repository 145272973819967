import { getReceitasCatEconOrgao } from "./getReceitasCatEconOrgao";
import { getReceitaDespesaCEconomica } from "./getReceitaDespesaCEconomica";
import { getReceitaSegCEconomica } from "./getReceitaSegCEconomica";
import { getReceitasEsfera } from "./getReceitasEsfera";
import { getReceitaPorFonteRec } from "./getReceitaPorFonteRec";
import { getReceitasQdr } from "./getReceitasQdr";
import { getDespesaCatEconOrgao } from "./getDespesaCatEconOrgao";
import { getDespesaQdd } from "./getDespesaQdd";
import { getDespesaSegCEconomica } from "./getDespesaSegCEconomica";
import { getDespesaPorFonteRec } from "./getDespesaPorFonteRec";
import { getQuadroDetalhadoQDDAplicacao } from "./getQuadroDetalhadoQDDAplicacao";

export const getDataLoa = {
  //Receita
  getReceitasCatEconOrgao,
  getReceitaDespesaCEconomica,
  getReceitaSegCEconomica,
  getReceitasEsfera,
  getReceitaPorFonteRec,
  getReceitasQdr,
  getDespesaCatEconOrgao,
  getDespesaQdd,
  getDespesaSegCEconomica,
  getDespesaPorFonteRec,
  getQuadroDetalhadoQDDAplicacao,
};
