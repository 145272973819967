import React, { useContext, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { CCol, CColButtonsAcoes } from "../../../../components/Grid/CCol";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
  RowItemListaDeInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import { Icon } from "@iconify/react";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
// import { FormatContaPCASP } from "../../../../utils/FormatacaoDeDados/FormatContaPCASP";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { toast } from "react-toastify";
import { useFetchData } from "../../../../hooks/useFetchData";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import api from "../../../../utils/api";
// import { FormatContaPCASPAutoComplete } from "../../../../utils/FormatacaoDeDados/FormatContaPCASPAutoComplete";

function Procedimentos({
  valuesNaturezaDaReceita,
  setValuesNaturezaDaReceita,
  atributosConta,
}) {
  const { currentClient } = useContext(CurrentClientContext);
  const dataAtual = ReturnDataAtual();
  const ano = dataAtual.split("/")[2];

  const { data: dataContasPCASP } = useFetchData(
    `/pcasp/${currentClient.clienteId}/${ano}/todos?limit=10000`
  );
  const [optionsContasPCASP, setOptionsContasPCASP] = useState([]);

  useEffect(() => {
    if (dataContasPCASP) {
      setOptionsContasPCASP(
        dataContasPCASP.pcasp.map((conta) => ({
          titulo: conta.titulo,
          codigo: conta.conta,
        }))
      );
    }
  }, [dataContasPCASP]);

  const deletarDadosDeLancamento = (
    codigoConta,
    indexProcedimento,
    numeroDoPar
  ) => {
    let registrosSemValorPorPar = 0;
    let indexLimpezaDados = [
      ...valuesNaturezaDaReceita.procedimentos[indexProcedimento].registros,
    ].findIndex((item) => item.contaContabil === codigoConta);

    let valuesLancamentoDeletado = { ...valuesNaturezaDaReceita };
    valuesLancamentoDeletado.procedimentos[indexProcedimento].registros[
      indexLimpezaDados
    ] = {
      ...valuesLancamentoDeletado.procedimentos[indexProcedimento].registros[
        indexLimpezaDados
      ],
      contaContabil: "",
      titulo: "",
      origem: "",
      naturezaDaInfo: "",
    };

    valuesLancamentoDeletado.procedimentos[indexProcedimento].registros
      .filter((item) => item.par === numeroDoPar)
      .forEach((item) => {
        if (item.contaContabil === "") {
          registrosSemValorPorPar++;
        }
      });

    if (registrosSemValorPorPar === 2) {
      valuesLancamentoDeletado.procedimentos[indexProcedimento].registros =
        valuesLancamentoDeletado.procedimentos[
          indexProcedimento
        ].registros.filter((item) => item.par !== numeroDoPar);
    }

    console.log(valuesLancamentoDeletado);

    setValuesNaturezaDaReceita({ ...valuesLancamentoDeletado });
  };

  const adicionaNovoLancamento = (passo) => {
    if (valuesNaturezaDaReceita.naturezaDaReceitaId !== 0) {
      const indexInsercao = valuesNaturezaDaReceita.procedimentos.findIndex(
        (item) => item.passo === passo
      );
      const tamanhoArrayDeRegistros =
        valuesNaturezaDaReceita.procedimentos[indexInsercao].registros.length;
      let numeroDoPar = 1;

      if (tamanhoArrayDeRegistros > 0) {
        numeroDoPar =
          valuesNaturezaDaReceita.procedimentos[indexInsercao].registros[
            tamanhoArrayDeRegistros - 1
          ].par + 1;
      }

      let estruturaLancamentoPar = [
        {
          contaContabil: "",
          titulo: "",
          origem: "",
          naturezaDaInfo: "",
          par: numeroDoPar,
        },
        {
          contaContabil: "",
          titulo: "",
          origem: "",
          naturezaDaInfo: "",
          par: numeroDoPar,
        },
      ];

      if (
        passo === 1 &&
        atributosConta.valorizavel === "SIM" &&
        valuesNaturezaDaReceita.procedimentos[indexInsercao].registros
          .length === 0
      ) {
        estruturaLancamentoPar = [
          {
            contaContabil: "5.1.1.1.0.00.00",
            titulo: "APROVAÇÃO INICIAL DO PPA",
            origem: "D",
            naturezaDaInfo: "Orçamentária",
            par: numeroDoPar,
          },
          {
            contaContabil: "6.2.1.1.0.00.00",
            titulo: "RECEITA A REALIZAR",
            origem: "C",
            naturezaDaInfo: "Orçamentária",
            par: 1,
          },
        ];
      }

      console.log(estruturaLancamentoPar);

      let naturezaValuesInsertNovosLancamentos = { ...valuesNaturezaDaReceita };
      naturezaValuesInsertNovosLancamentos.procedimentos[
        indexInsercao
      ].registros.push(...estruturaLancamentoPar);

      console.log(naturezaValuesInsertNovosLancamentos);

      setValuesNaturezaDaReceita(naturezaValuesInsertNovosLancamentos);
    } else {
      toast.warning(
        "Procedimentos só podem ser adicionados quando uma natureza da receita for especificada."
      );
    }
  };

  const preencheCamposLancamento = (
    value,
    indexProcedimento,
    indexRegistro
  ) => {
    let naturezaValuesPreencheCampos = { ...valuesNaturezaDaReceita };

    naturezaValuesPreencheCampos.procedimentos[indexProcedimento].registros[
      indexRegistro
    ].contaContabil = value;

    let naturezaInformacao = "";

    if (parseInt(value[0]) >= 1 && parseInt(value[0]) <= 4) {
      naturezaInformacao = "Patrimonial";
    } else if (parseInt(value[0]) >= 5 && parseInt(value[0]) <= 6) {
      naturezaInformacao = "Orçamentária";
    } else {
      naturezaInformacao = "Controle";
    }

    if (value[0] !== "0") {
      api
        .get(
          `pcasp/${currentClient.clienteId}/ano/${ano}/filtro?filter=${value}`
        )
        .then((resp) => {
          const data = resp.data.pcasp;
          if (data.length > 0) {
            let valuesCampos = {
              ...naturezaValuesPreencheCampos.procedimentos[indexProcedimento]
                .registros[indexRegistro],
              naturezaDaInfo: naturezaInformacao,
              origem: data[0].naturezaDeSaldo === "Devedora" ? "D" : "C",
              titulo: data[0].titulo,
            };

            console.log(valuesCampos);
            naturezaValuesPreencheCampos.procedimentos[
              indexProcedimento
            ].registros[indexRegistro] = valuesCampos;
            setValuesNaturezaDaReceita({ ...naturezaValuesPreencheCampos });
          } else {
            toast.warning(
              "Não existe nenhuma conta com essa combinação númerica"
            );
          }
        });
    } else {
      let valuesCampos = {
        ...naturezaValuesPreencheCampos.procedimentos[indexProcedimento]
          .registros[indexRegistro],
        naturezaDaInfo: "",
        origem: "",
        titulo: "",
      };

      naturezaValuesPreencheCampos.procedimentos[indexProcedimento].registros[
        indexRegistro
      ] = valuesCampos;

      setValuesNaturezaDaReceita({ ...naturezaValuesPreencheCampos });
    }
  };

  const selecionarCorInputNatInformacao = (valor) => {
    if (valor >= 1 && valor <= 4) {
      return "#C6D9FC";
    } else if (valor >= 5 && valor <= 6) {
      return "#F5D0F5";
    } else if (valor >= 7 && valor <= 8) {
      return "#cdeeb4";
    } else {
      return null;
    }
  };

  const handleAutocompleteChange = (
    newValue,
    indexProcedimento,
    indexRegistro
  ) => {
    console.log(newValue);
    console.log(indexProcedimento, indexRegistro);
    preencheCamposLancamento(newValue.codigo, indexProcedimento, indexRegistro);
  };

  return (
    <>
      <Row>
        <Col
          className="d-flex align-items-center p-2 row_endereco_conteudo"
          md={12}
        >
          <span className="mx-4">PCASP</span>
        </Col>
      </Row>
      {/* <button onClick={() => console.log(valuesNaturezaDaReceita)}>
        console
      </button> */}
      <Row className="row_form mt-1">
        <CCol>
          <FloatingLabelInput
            style={{ border: "none" }}
            disabled
            value="1 - No momento da previsão da receita na aprovação do Orçamento"
          />
        </CCol>
        <CColButtonsAcoes md={1}>
          {valuesNaturezaDaReceita.procedimentos[0].registros.length === 0 && (
            <Icon
              onClick={() => adicionaNovoLancamento(1)}
              icon="ic:baseline-add-box"
              color="#105200"
              height="28"
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                marginTop: "4px",
              }}
            />
          )}
        </CColButtonsAcoes>
      </Row>

      {valuesNaturezaDaReceita.procedimentos[0].registros.length > 0 && (
        <Row className="row_form" style={{ marginTop: "-10px" }}>
          <CCol md={8}>
            <PTitulosInputs>Conta Contabil</PTitulosInputs>
          </CCol>
          <CCol md={1}>
            <PTitulosInputs>Origem</PTitulosInputs>
          </CCol>
          <CCol>
            <PTitulosInputs>Natureza da Informação</PTitulosInputs>
          </CCol>
          <CColButtonsAcoes md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
          </CColButtonsAcoes>
        </Row>
      )}

      {/* ------------------------------------------------------------------------- */}
      <Row
        className="row_form"
        style={{
          marginTop: "-10px",
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
      >
        {valuesNaturezaDaReceita.procedimentos[0].registros.map(
          (item, index, selfArray) => (
            <RowItemListaDeInputs key={index}>
              <CCol md={8}>
                <MyAutoComplete
                  inputValue={
                    valuesNaturezaDaReceita.procedimentos[0].registros[index]
                      .contaContabil !== "" &&
                    `${valuesNaturezaDaReceita.procedimentos[0].registros[index].contaContabil} - ${valuesNaturezaDaReceita.procedimentos[0].registros[index].titulo}`
                  }
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  iconClearFunction={() => console.log("cleared")}
                  options={optionsContasPCASP}
                  onOptionSelect={(item) => {
                    handleAutocompleteChange(item, 0, index);
                  }}
                  labelFormat={(option) =>
                    `${option.codigo} - ${option.titulo}`
                  }
                />
              </CCol>
              <CCol md={1}>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  value={item.origem}
                  disabled
                />
              </CCol>
              <CCol>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  disabledbg={selecionarCorInputNatInformacao(
                    parseInt(item.contaContabil[0])
                  )}
                  value={item.naturezaDaInfo}
                  disabled
                />
              </CCol>
              <CColButtonsAcoes md={1}>
                <Icon
                  onClick={() => {
                    deletarDadosDeLancamento(item.contaContabil, 0, item.par);
                  }}
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="28"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "1px",
                  }}
                />
              </CColButtonsAcoes>
              {(index + 1) % 2 === 0 ? <div className="p-2"></div> : null}
            </RowItemListaDeInputs>
          )
        )}
      </Row>

      {/* Passo 2 */}
      <Row className="row_form">
        <CCol>
          <FloatingLabelInput
            style={{ border: "none" }}
            disabled
            value="2 - No momento do fato gerador da receita"
          />
        </CCol>
        <CColButtonsAcoes md={1}>
          <Icon
            onClick={() => adicionaNovoLancamento(2)}
            icon="ic:baseline-add-box"
            color="#105200"
            height="28"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
              marginTop: "4px",
            }}
          />
        </CColButtonsAcoes>
      </Row>

      {valuesNaturezaDaReceita.procedimentos[1].registros.length > 0 && (
        <Row className="row_form" style={{ marginTop: "-10px" }}>
          <CCol md={8}>
            <PTitulosInputs>Conta Contabil</PTitulosInputs>
          </CCol>
          <CCol md={1}>
            <PTitulosInputs>Origem</PTitulosInputs>
          </CCol>
          <CCol>
            <PTitulosInputs>Natureza da Informação</PTitulosInputs>
          </CCol>
          <CColButtonsAcoes md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
          </CColButtonsAcoes>
        </Row>
      )}
      {/* ------------------------------------------------------------------------- */}
      <Row
        className="row_form"
        style={{
          marginTop: "-10px",
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
      >
        {valuesNaturezaDaReceita.procedimentos[1].registros.map(
          (item, index, selfArray) => (
            <RowItemListaDeInputs key={index}>
              <CCol md={8}>
                <MyAutoComplete
                  inputValue={
                    valuesNaturezaDaReceita.procedimentos[1].registros[index]
                      .contaContabil !== "" &&
                    `${valuesNaturezaDaReceita.procedimentos[1].registros[index].contaContabil} - ${valuesNaturezaDaReceita.procedimentos[1].registros[index].titulo}`
                  }
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  iconClearFunction={() => console.log("cleared")}
                  options={optionsContasPCASP}
                  onOptionSelect={(item) => {
                    handleAutocompleteChange(item, 1, index);
                  }}
                  labelFormat={(option) =>
                    `${option.codigo} - ${option.titulo}`
                  }
                />
              </CCol>

              <CCol md={1}>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  value={item.origem}
                  disabled
                />
              </CCol>
              <CCol>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  value={item.naturezaDaInfo}
                  disabledbg={selecionarCorInputNatInformacao(
                    parseInt(item.contaContabil[0])
                  )}
                  disabled
                />
              </CCol>
              <CColButtonsAcoes md={1}>
                <Icon
                  onClick={() => {
                    deletarDadosDeLancamento(item.contaContabil, 1, item.par);
                  }}
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="28"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "1px",
                  }}
                />
              </CColButtonsAcoes>
              {(index + 1) % 2 === 0 ? <div className="p-2"></div> : null}
            </RowItemListaDeInputs>
          )
        )}
      </Row>

      {/* Passo 3 */}
      <Row className="row_form">
        <CCol>
          <FloatingLabelInput
            style={{ border: "none" }}
            disabled
            value="3 - No momento da arrecadação"
          />
        </CCol>
        <CColButtonsAcoes md={1}>
          <Icon
            onClick={() => adicionaNovoLancamento(3)}
            icon="ic:baseline-add-box"
            color="#105200"
            height="28"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
              marginTop: "4px",
            }}
          />
        </CColButtonsAcoes>
      </Row>
      {valuesNaturezaDaReceita.procedimentos[2].registros.length > 0 && (
        <Row className="row_form" style={{ marginTop: "-10px" }}>
          <CCol md={8}>
            <PTitulosInputs>Conta Contabil</PTitulosInputs>
          </CCol>
          <CCol md={1}>
            <PTitulosInputs>Origem</PTitulosInputs>
          </CCol>
          <CCol>
            <PTitulosInputs>Natureza da Informação</PTitulosInputs>
          </CCol>
          <CColButtonsAcoes md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
          </CColButtonsAcoes>
        </Row>
      )}
      {/* ------------------------------------------------------------------------- */}
      <Row
        className="row_form"
        style={{
          marginTop: "-10px",
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
      >
        {valuesNaturezaDaReceita.procedimentos[2].registros.map(
          (item, index, selfArray) => (
            <RowItemListaDeInputs key={index}>
              <CCol md={8}>
                <MyAutoComplete
                  inputValue={
                    valuesNaturezaDaReceita.procedimentos[2].registros[index]
                      .contaContabil !== "" &&
                    `${valuesNaturezaDaReceita.procedimentos[2].registros[index].contaContabil} - ${valuesNaturezaDaReceita.procedimentos[2].registros[index].titulo}`
                  }
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  iconClearFunction={() => console.log("cleared")}
                  options={optionsContasPCASP}
                  onOptionSelect={(item) => {
                    handleAutocompleteChange(item, 2, index);
                  }}
                  labelFormat={(option) =>
                    `${option.codigo} - ${option.titulo}`
                  }
                />
              </CCol>
              <CCol md={1}>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  value={item.origem}
                  disabled
                />
              </CCol>
              <CCol>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  value={item.naturezaDaInfo}
                  disabled
                  disabledbg={selecionarCorInputNatInformacao(
                    parseInt(item.contaContabil[0])
                  )}
                />
              </CCol>
              <CColButtonsAcoes md={1}>
                <Icon
                  onClick={() => {
                    deletarDadosDeLancamento(item.contaContabil, 2, item.par);
                  }}
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="28"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "1px",
                  }}
                />
              </CColButtonsAcoes>
              {(index + 1) % 2 === 0 ? <div className="p-2"></div> : null}
            </RowItemListaDeInputs>
          )
        )}
      </Row>

      {/* Passo 4 */}
      <Row className="row_form_2">
        <CCol>
          <FloatingLabelInput
            style={{ border: "none" }}
            disabled
            value="4 - Lançamento complementar"
          />
        </CCol>
        <CColButtonsAcoes md={1}>
          <Icon
            onClick={() => adicionaNovoLancamento(4)}
            icon="ic:baseline-add-box"
            color="#105200"
            height="28"
            style={{
              cursor: "pointer",
              marginLeft: "5px",
              marginTop: "4px",
            }}
          />
        </CColButtonsAcoes>
      </Row>

      {valuesNaturezaDaReceita.procedimentos[3].registros.length > 0 && (
        <Row className="row_form" style={{ marginTop: "-10px" }}>
          <CCol md={8}>
            <PTitulosInputs>Conta Contabil</PTitulosInputs>
          </CCol>
          <CCol md={1}>
            <PTitulosInputs>Origem</PTitulosInputs>
          </CCol>
          <CCol>
            <PTitulosInputs>Natureza da Informação</PTitulosInputs>
          </CCol>
          <CColButtonsAcoes md={1}>
            <PTitulosInputs>Ação</PTitulosInputs>
          </CColButtonsAcoes>
        </Row>
      )}
      {/* ------------------------------------------------------------------------- */}
      <Row
        className="row_form_2"
        style={{
          marginTop: "-10px",
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
      >
        {valuesNaturezaDaReceita.procedimentos[3].registros.map(
          (item, index, selfArray) => (
            <RowItemListaDeInputs key={index}>
              <CCol md={8}>
                <MyAutoComplete
                  inputValue={
                    valuesNaturezaDaReceita.procedimentos[3].registros[index]
                      .contaContabil !== "" &&
                    `${valuesNaturezaDaReceita.procedimentos[3].registros[index].contaContabil} - ${valuesNaturezaDaReceita.procedimentos[3].registros[index].titulo}`
                  }
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  iconClearFunction={() => console.log("cleared")}
                  options={optionsContasPCASP}
                  onOptionSelect={(item) => {
                    handleAutocompleteChange(item, 3, index);
                  }}
                  labelFormat={(option) =>
                    `${option.codigo} - ${option.titulo}`
                  }
                />
              </CCol>

              <CCol md={1}>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  value={item.origem}
                  disabled
                />
              </CCol>
              <CCol>
                <FormControlListaDeInputs
                  radiusborder={
                    (index + 1) % 2 === 0
                      ? "0px 0px 5px 5px"
                      : "5px 5px 0px 0px"
                  }
                  value={item.naturezaDaInfo}
                  disabled
                  disabledbg={selecionarCorInputNatInformacao(
                    parseInt(item.contaContabil[0])
                  )}
                />
              </CCol>
              <CColButtonsAcoes md={1}>
                <Icon
                  onClick={() => {
                    deletarDadosDeLancamento(item.contaContabil, 3, item.par);
                  }}
                  icon="ic:baseline-delete"
                  color="#E79900"
                  height="28"
                  style={{
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginTop: "1px",
                  }}
                />
              </CColButtonsAcoes>
              {(index + 1) % 2 === 0 ? <div className="p-2"></div> : null}
            </RowItemListaDeInputs>
          )
        )}
      </Row>
    </>
  );
}

export default Procedimentos;
