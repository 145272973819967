import React, { useContext, useState, useEffect, useRef } from "react";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import {
  CCol,
  ContainerToModal,
  RowToModal,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { ToastContainer, toast } from "react-toastify";

import { useFetchData } from "../../../../hooks/useFetchData";
import { useLocation, useParams } from "react-router-dom";
import api from "../../../../utils/api";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import ModalAutoComplete from "../../../../components/Modais/ModalAutoComplete";

import Programa from "../Programa/Programa";
import UnidadeOrcamentaria from "../UnidadeOrcamentaria/UnidadeOrcamentaria";
import { OpenedModalsContext } from "../../../../contexts/OpenedModalsContext";
import ButtonSaveModalAutoComplete from "../../../../components/Modais/ButtonSaveModalAutoComplete";
import { DefinirRequisicaoDoModalComoConluida } from "../../../../utils/Gerais/DefinirRequisicaoDoModalComoConcluida";

function AcaoGovernamental({ isAModalPage }) {
  const { currentClient } = useContext(CurrentClientContext);
  const { openedModals, setOpenedModals } = useContext(OpenedModalsContext);
  const { numeroAcao: numeroAcaoParams } = useParams();
  const location = useLocation();
  const rotaReplicar = location.pathname.includes("/replicar");

  const { data: dataAcaoGovernamental } = useFetchData(
    numeroAcaoParams
      ? `/acaoGovernamental/${currentClient.clienteId}/filtro?nAcao=${numeroAcaoParams}`
      : null
  );
  const { data: dataFuncoes } = useFetchData("/funcao/funcoes");
  const { data: dataSubfuncoes } = useFetchData("/subfuncao/subfuncoes");

  //---------------------------------------------------------------------

  const [valuesAcaoGovernamental, setValuesAcaoGovernamental] = useState({
    clienteId: currentClient.clienteId,
    unidadeOrcamentariaId: 0,
    programaPlanejamentoId: 0,
    funcaoId: 0,
    subFuncaoId: 0,
    titulo: "",
    objetivos: "",
    nAcao: "",
    tipoAcao: "",
  });

  const [idDaAcaoGovernamental, setIdDaAcaoGovernamental] = useState(null);
  const [tipoAcaoSave, setTipoACaoSave] = useState();

  //estados Unidade Orcamentaria
  const [
    inputAutocompleteUnidadeOrcamentaria,
    setInputAutocompleteUnidadeOrcamentaria,
  ] = useState("");
  const [optionsInputUnidadeOrcamentaria, setOptionsInputUnidadeOrcamentaria] =
    useState([]);
  //
  //estados funcao
  const [inputAutocompleteFuncao, setInputAutocompleteFuncao] = useState("");
  const [optionsInputFuncao, setOptionsInputFuncao] = useState([]);
  //
  //estados Subfuncao
  const [inputAutocompleteSubfuncao, setInputAutocompleteSubfuncao] =
    useState("");
  const [optionsInputSubfuncao, setOptionsInputSubfuncao] = useState([]);
  //
  //estados programa
  const [inputAutocompletePrograma, setInputAutocompletePrograma] =
    useState("");
  const [optionsInputPrograma, setOptionsInputPrograma] = useState([]);

  const inputTipoAcaoRef = useRef(null);
  const inputUnidadeOrcRef = useRef(null);
  const inputNAcaoRef = useRef(null);
  const inputDescricaoRef = useRef(null);
  const inputObjetivosRef = useRef(null);
  const inputFuncaoRef = useRef(null);
  const inputSubFuncaoRef = useRef(null);
  const inputProgramaRef = useRef(null);

  const handleAutocompleteUnidadeOrcamentaria = (newValue) => {
    console.log("unidade orcamentaria autocomplete");
    console.log(newValue.id);
    setValuesAcaoGovernamental((prevValues) => ({
      ...prevValues,
      unidadeOrcamentariaId: newValue.id,
    }));
    handleGoRef(inputTipoAcaoRef);
  };

  const handleAutocompleteFuncao = (newValue) => {
    console.log("funcao autocomplete");
    console.log(newValue.id);
    setValuesAcaoGovernamental((prevValues) => ({
      ...prevValues,
      funcaoId: newValue.id,
    }));
    handleGoRef(inputSubFuncaoRef);
  };

  const handleAutocompletePrograma = (newValue) => {
    console.log("Programa autocomplete");
    console.log(newValue.id);
    setValuesAcaoGovernamental((prevValues) => ({
      ...prevValues,
      programaPlanejamentoId: newValue.id,
    }));
  };

  const handleAutocompleteSubfuncao = (newValue) => {
    console.log("subfuncao autocomplete");
    console.log(newValue.id);
    setValuesAcaoGovernamental((prevValues) => ({
      ...prevValues,
      subFuncaoId: newValue.id,
    }));
    handleGoRef(inputProgramaRef);
  };

  const fetchUnidadesOrcamentarias = (reqDeModal) => {
    api
      .get(`/unidadeOrcamentaria/cliente/${currentClient.clienteId}`)
      .then((resp) => {
        const data = resp.data;
        console.log(data);
        setOptionsInputUnidadeOrcamentaria(
          data.map((item) => ({
            titulo: item.unidade.titulo,
            codigo: item.unidade.codigo,
            id: item.unidade.id,
          }))
        );

        if (reqDeModal) {
          const lastPostedData = data.reverse()[0];
          console.log(lastPostedData);

          console.log("reqDeModal");

          setInputAutocompleteUnidadeOrcamentaria(
            `${lastPostedData.unidade.codigo} - ${lastPostedData.unidade.titulo}`
          );
          setValuesAcaoGovernamental((prev) => ({
            ...prev,
            unidadeOrcamentariaId: lastPostedData.unidade.id,
          }));

          setOpenedModals((prev) =>
            prev.filter((item) => item.tituloModal !== "Unidade Orçamentária")
          );
        }
      });
  };

  const fetchProgramas = (reqDeModal) => {
    api
      .get(`/programaPlanejamento/cliente/${currentClient.clienteId}`)
      .then((resp) => {
        const data = resp.data;
        console.log(data);
        setOptionsInputPrograma(
          data.map((item) => ({
            titulo: item.titulo,
            codigo: item.numeroPrograma,
            id: item.id,
          }))
        );

        if (reqDeModal) {
          const lastPostedData = data[0];
          console.log(lastPostedData);

          setInputAutocompletePrograma(
            `${lastPostedData.numeroPrograma} - ${lastPostedData.titulo}`
          );
          setValuesAcaoGovernamental((prev) => ({
            ...prev,
            programaPlanejamentoId: lastPostedData.id,
          }));

          setOpenedModals((prev) =>
            prev.filter((item) => item.tituloModal !== "Programa")
          );
        }
      });
  };

  useEffect(() => {
    fetchUnidadesOrcamentarias();
    fetchProgramas();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataFuncoes) {
      setOptionsInputFuncao(
        dataFuncoes.map((item) => ({
          titulo: item.nome,
          codigo: item.valor,
          id: item.id,
        }))
      );
    }
  }, [dataFuncoes]);

  useEffect(() => {
    if (dataSubfuncoes) {
      setOptionsInputSubfuncao(
        dataSubfuncoes.map((item) => ({
          titulo: item.nome,
          codigo: item.valor,
          id: item.id,
        }))
      );
    }
  }, [dataSubfuncoes]);
  //---------------------------------------------

  useEffect(() => {
    if (dataAcaoGovernamental && numeroAcaoParams && !isAModalPage) {
      const dados = dataAcaoGovernamental[0];
      console.log(dados);

      //guardando o id da acao
      setIdDaAcaoGovernamental(dados.id);

      //setando unidade orcamentaria
      setInputAutocompleteUnidadeOrcamentaria(
        `${dados.unidadeOrcamentariaId.codigoUnidadeOrcamentaria} - ${dados.unidadeOrcamentariaId.tituloUnidadeOrcamentaria}`
      );

      //setando programa
      setInputAutocompletePrograma(
        `${dados.programaPlanejamentoId.numeroPrograma} - ${dados.programaPlanejamentoId.tituloPrograma}`
      );

      //setando funcao
      setInputAutocompleteFuncao(
        `${dados.funcaoId.valor} - ${dados.funcaoId.nome}`
      );

      //setando subfuncao
      setInputAutocompleteSubfuncao(
        `${dados.subFuncaoId.valor} - ${dados.subFuncaoId.nome}`
      );

      //setando valores da acao governamental
      setValuesAcaoGovernamental((prevValues) => ({
        ...prevValues,
        funcaoId: dados.funcaoId.id,
        programaPlanejamentoId: dados.programaPlanejamentoId.id,
        nAcao: dados.nAcao,
        objetivos: rotaReplicar ? "" : dados.objetivos,
        subFuncaoId: dados.subFuncaoId.id,
        tipoAcao: dados.tipoAcao,
        titulo: rotaReplicar ? "" : dados.titulo,
        unidadeOrcamentariaId: dados.unidadeOrcamentariaId.id,
      }));
    }
  }, [dataAcaoGovernamental, numeroAcaoParams, rotaReplicar, isAModalPage]);

  const limparValoresAcao = () => {
    setValuesAcaoGovernamental((prevValues) => ({
      ...prevValues,
      clienteId: currentClient.clienteId,
      unidadeGestoraId: currentClient.unidadeGestoraId,
      funcaoId: 0,
      subFuncaoId: 0,
      programaPlanejamentoId: 0,
      titulo: "",
      objetivos: "",
    }));

    setInputAutocompleteFuncao("");
    setInputAutocompletePrograma("");
    setInputAutocompleteSubfuncao("");
    handleGoRef(inputNAcaoRef);
  };

  const verificaValoresAcaoGovernamental = () => {
    console.log("Values Acao Governamental:", valuesAcaoGovernamental);
    for (const chave in valuesAcaoGovernamental) {
      if (
        valuesAcaoGovernamental[chave] === null ||
        valuesAcaoGovernamental[chave] === ""
      ) {
        console.log(valuesAcaoGovernamental[chave]);
        return false;
      }
    }
    return true;
  };

  const cadastrarAcao = () => {
    const todosItensPreenchidos = verificaValoresAcaoGovernamental();
    console.log(valuesAcaoGovernamental);

    if (todosItensPreenchidos) {
      console.log("cadastro");
      api
        .post(`acaoGovernamental`, valuesAcaoGovernamental)
        .then((resp) => {
          toast.success("Ação Governamental cadastrada com sucesso!");
          limparValoresAcao();
          fetchNumeroAcao(tipoAcaoSave);
          if (isAModalPage) {
            DefinirRequisicaoDoModalComoConluida(
              openedModals,
              setOpenedModals,
              "Ação Governamental"
            );
          }

          fetchNumeroAcao(valuesAcaoGovernamental.tipoAcao);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 400) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Algo deu errado, por favor, tente mais tarde.");
          }
        });
    }
    if (!todosItensPreenchidos) {
      toast.error("Preencha todos os campos corretamente.");
    }
  };

  const atualizarAcaoGovernamental = () => {
    const todosItensPreenchidos = verificaValoresAcaoGovernamental();
    if (todosItensPreenchidos) {
      console.log("update: ", valuesAcaoGovernamental);
      api
        .put(
          `acaoGovernamental/${idDaAcaoGovernamental}`,
          valuesAcaoGovernamental
        )
        .then((resp) => {
          console.log(resp);
          toast.success("Dados atualizados com sucesso");
        })
        .catch((error) => {
          console.log(error);
          toast.error("Algo deu errado, por favor, tente mais tarde.");
        });
    }
    if (!todosItensPreenchidos) {
      toast.error("Preencha todos os campos corretamente.");
    }
  };

  const fetchNumeroAcao = (tipoAcao) => {
    api
      .get(
        `acaoGovernamental/nextNAcao/${
          currentClient.clienteId
        }/${tipoAcao.replace(/[^0-9]/g, "")}`
      )
      .then((resp) => {
        console.log(resp.data);
        setValuesAcaoGovernamental((prevValues) => ({
          ...prevValues,
          nAcao: resp.data.proximoNumeroAcao,
        }));
      })
      .catch((error) => {
        console.log(error);
        toast.error("Algo deu errado, por favor, tente mais tarde.");
      });
  };

  const handleGoRef = (nextRef) => {
    nextRef.current.focus();
  };

  useEffect(() => {
    if (valuesAcaoGovernamental.tipoAcao !== "" && rotaReplicar) {
      fetchNumeroAcao(valuesAcaoGovernamental.tipoAcao);
    }

    //eslint-disable-next-line
  }, [valuesAcaoGovernamental.tipoAcao, rotaReplicar]);

  // const ordenarUnidadeOrcamentaria = (itemA, itemB) => {
  //   if (itemA.unidade.codigo < itemB.unidade.codigo) return -1;
  //   if (itemA.unidade.codigo > itemB.unidade.codigo) return 1;
  //   return 0;
  // };

  const handleOpenNewModal = (titulo) => {
    setOpenedModals((prev) => [
      ...prev,
      { tituloModal: titulo, requisicaoDoModalConcluida: false },
    ]);
  };

  useEffect(() => {
    const modalUnidadeOrcamentaria = openedModals.find(
      (modal) => modal.tituloModal === "Unidade Orçamentária"
    );
    const modalPrograma = openedModals.find(
      (modal) => modal.tituloModal === "Programa"
    );

    if (
      modalUnidadeOrcamentaria &&
      modalUnidadeOrcamentaria.requisicaoDoModalConcluida
    ) {
      fetchUnidadesOrcamentarias(true);
    }

    if (modalPrograma && modalPrograma.requisicaoDoModalConcluida) {
      fetchProgramas(true);
    }

    //eslint-disable-next-line
  }, [openedModals]);

  useEffect(() => {
    if (inputUnidadeOrcRef) {
      handleGoRef(inputUnidadeOrcRef);
    }
  }, [inputUnidadeOrcRef]);

  return (
    <ContainerToModal
      isAModalPage={isAModalPage}
      id={isAModalPage ? undefined : "pags_responsividade_padding_geral"}
      fluid
      className={
        isAModalPage ? undefined : "container_conteudo conteudo_pagina"
      }
    >
      <ModalAutoComplete
        tituloModal={"Programa"}
        Component={<Programa isAModalPage={true} />}
      />

      <ModalAutoComplete
        tituloModal={"Unidade Orçamentária"}
        Component={<UnidadeOrcamentaria isAModalPage={true} />}
      />

      {!isAModalPage && (
        <>
          <HeaderCadastros
            NomePaginaTitulo={"Ação Governamental"}
            PaginaSubtitulo={
              numeroAcaoParams
                ? rotaReplicar
                  ? "Cadastro"
                  : "Edição"
                : "Cadastro"
            }
            ButtonSaveFunction={
              numeroAcaoParams
                ? rotaReplicar
                  ? cadastrarAcao
                  : atualizarAcaoGovernamental
                : cadastrarAcao
            }
            PaginaConsulta={
              "/planejamento/cadastro/acao-governamental/consulta"
            }
            PaginaRelatorio={
              "/planejamento/cadastro/acao-governamental/relatorio"
            }
            BotaoNovoFunction={() => handleGoRef(inputUnidadeOrcRef)}
          />
          <ToastContainer />
        </>
      )}

      {isAModalPage && (
        <ButtonSaveModalAutoComplete
          SaveFunction={cadastrarAcao}
          pageTitle={"Ação Governamental"}
        />
      )}

      <RowToModal isAModalPage={isAModalPage} className="row_form mt-1">
        <CCol md="12">
          <MyAutoComplete
            ref={inputUnidadeOrcRef}
            addNewDataFunction={() =>
              handleOpenNewModal("Unidade Orçamentária")
            }
            labelInput="Unidade Orçamentária"
            placeholder="Unidade Orçamentária"
            style={{ height: "37px" }}
            inputValue={inputAutocompleteUnidadeOrcamentaria}
            setInputValue={setInputAutocompleteUnidadeOrcamentaria}
            options={optionsInputUnidadeOrcamentaria}
            labelFormat={(option) => `${option.codigo} - ${option.titulo}`}
            onOptionSelect={handleAutocompleteUnidadeOrcamentaria}
            iconClearFunction={() =>
              setValuesAcaoGovernamental((prev) => ({
                ...prev,
                unidadeOrcamentariaId: 0,
              }))
            }
          />
        </CCol>
      </RowToModal>

      <RowToModal isAModalPage={isAModalPage} className="row_form mt-1">
        <CCol md={2}>
          <FloatingLabelInput
            ref={inputTipoAcaoRef}
            new={true}
            placeholder="Tipo de Ação"
            label="Tipo de Ação"
            tipo="input_select"
            options={[
              { label: "Tipo de Ação", value: null },
              { label: "0 - Especiais", value: "0 - ESPECIAIS" },
              { label: "1 - Projetos", value: "1 - PROJETOS" },
              { label: "2 - Atividades", value: "2 - ATIVIDADES" },
            ]}
            value={valuesAcaoGovernamental.tipoAcao}
            onSelect={(option) => {
              setValuesAcaoGovernamental((prevValues) => ({
                ...prevValues,
                tipoAcao: option.value,
              }));
              fetchNumeroAcao(option.value);
              setTipoACaoSave(option.value);
              handleGoRef(inputNAcaoRef);
            }}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            ref={inputDescricaoRef}
            placeholder="Descrição"
            label="Descrição"
            value={valuesAcaoGovernamental.titulo}
            onChange={(e) => {
              setValuesAcaoGovernamental((prevValues) => ({
                ...prevValues,
                titulo: e.target.value,
              }));
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleGoRef(inputObjetivosRef);
              }
            }}
          />
        </CCol>
      </RowToModal>

      <RowToModal
        isAModalPage={isAModalPage}
        className="row_form"
        style={{ marginTop: "-15px" }}
      >
        <CCol md={2}>
          <FloatingLabelInput
            ref={inputNAcaoRef}
            placeholder="Nº Ação"
            label="Nº Ação"
            disabled={!!numeroAcaoParams && !rotaReplicar}
            value={valuesAcaoGovernamental.nAcao}
            onChange={(e) => {
              e.target.value = e.target.value.replace(/[^\d.]/g, "");
              if (e.target.value.length <= 4) {
                setValuesAcaoGovernamental((prevValues) => ({
                  ...prevValues,
                  nAcao: e.target.value,
                }));
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleGoRef(inputDescricaoRef);
              }
            }}
          />
        </CCol>
        <CCol md="10">
          <FloatingLabelInput
            ref={inputObjetivosRef}
            placeholder="Objetivos"
            as="textarea"
            style={{
              height: "38px",
              minHeight: "38px",
            }}
            tipo="text_area"
            label="Objetivos"
            value={valuesAcaoGovernamental.objetivos}
            onChange={(e) => {
              setValuesAcaoGovernamental((prevValues) => ({
                ...prevValues,
                objetivos: e.target.value,
              }));
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                handleGoRef(inputFuncaoRef);
              }
            }}
          />
        </CCol>
      </RowToModal>

      <RowToModal
        isAModalPage={isAModalPage}
        className="row_form mt-1"
        style={{ marginTop: "-14px" }}
      >
        <CCol md="12">
          <MyAutoComplete
            ref={inputFuncaoRef}
            labelInput="Função"
            placeholder="Função"
            style={{ height: "37px" }}
            inputValue={inputAutocompleteFuncao}
            setInputValue={setInputAutocompleteFuncao}
            options={optionsInputFuncao}
            labelFormat={(option) => `${option.codigo} - ${option.titulo}`}
            onOptionSelect={handleAutocompleteFuncao}
            iconClearFunction={() =>
              setValuesAcaoGovernamental((prev) => ({
                ...prev,
                funcaoId: 0,
              }))
            }
          />
        </CCol>
      </RowToModal>
      <RowToModal
        isAModalPage={isAModalPage}
        className="row_form_2"
        style={{ marginTop: "-14px" }}
      >
        <CCol md="12">
          <MyAutoComplete
            ref={inputSubFuncaoRef}
            labelInput="Subfunção"
            placeholder="Subfunção"
            style={{ height: "37px" }}
            inputValue={inputAutocompleteSubfuncao}
            setInputValue={setInputAutocompleteSubfuncao}
            options={optionsInputSubfuncao}
            labelFormat={(option) => `${option.codigo} - ${option.titulo}`}
            onOptionSelect={handleAutocompleteSubfuncao}
            iconClearFunction={() =>
              setValuesAcaoGovernamental((prev) => ({
                ...prev,
                subFuncaoId: 0,
              }))
            }
          />
        </CCol>
      </RowToModal>
      <RowToModal
        isAModalPage={isAModalPage}
        className="row_form_2"
        style={{ marginTop: "-15px" }}
      >
        <CCol md="12">
          <MyAutoComplete
            ref={inputProgramaRef}
            labelInput="Programa"
            placeholder="Programa"
            style={{ height: "37px" }}
            inputValue={inputAutocompletePrograma}
            addNewDataFunction={() => handleOpenNewModal("Programa")}
            setInputValue={setInputAutocompletePrograma}
            options={optionsInputPrograma}
            labelFormat={(option) => `${option.codigo} - ${option.titulo}`}
            onOptionSelect={handleAutocompletePrograma}
            iconClearFunction={() =>
              setValuesAcaoGovernamental((prev) => ({
                ...prev,
                programaPlanejamentoId: 0,
              }))
            }
          />
        </CCol>
      </RowToModal>
      {!isAModalPage && <div className="p-3"></div>}
    </ContainerToModal>
  );
}

export default AcaoGovernamental;
