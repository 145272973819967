import React, { useContext, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import HeaderOptions from "../../../components/HeaderOptions/HeaderOptions";
import {
  CCol,
  CColCodigoReduzido,
  CColArrowListaPCASP,
} from "../../../components/Grid/CCol";
import outlineSearch from "@iconify/icons-ic/outline-search";
import {
  FilterContainer,
  FilterLabel,
  FilterSelect,
} from "../../../components/TableConsulta/TableConsulta";
import styled from "styled-components";
import { PTitulosListaPersonalizada } from "../../../styles/StyledComponents/ListasPersonalizadas";
import {
  arrayTodosCamposPCASP,
  arrayCamposPCASPContasCorrentes,
  arrayCamposPCASPReduzido,
} from "./arrayCamposPCASP";
import {
  CColAtributoTituloListaPCASP,
  CColContaListaPCASP,
  CColItensGeraisListaPCASP,
  CheckBoxListaPCASP,
  RowListaPdc,
} from "../../../styles/StyledComponents/ListaPdcPcasp";
import { Icon } from "@iconify/react";
// import Collapse from "react-bootstrap/Collapse";
import InputSvg from "../../../components/Grid/InputSvg";
import { CurrentClientContext } from "../../../contexts/CurrentClientContext";
import { ToastContainer, toast } from "react-toastify";
import FloatingLabelInput from "../../../components/Grid/FloatingLabelInput";
import { FormatValueToLocaleString } from "../../../utils/FormatacaoDeDados/FormatValueToLocaleString";
import { ReturnDataAtual } from "../../../utils/Gerais/ReturnDataAtual";
import api from "../../../utils/api";

const CColDivTabela = styled(CCol)`
  overflow-x: scroll;
  padding-left: 0px;
  padding-right: 0px;
  max-height: 350px;

  ::-webkit-scrollbar {
    width: 2px; /* Largura da barra de rolagem */
    height: 5px;
  }

  /* Estilizando o track (trilha) da barra de rolagem */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Cor de fundo do track */
  }

  /* Estilizando o thumb (ponteiro) da barra de rolagem */
  ::-webkit-scrollbar-thumb {
    background: #888; /* Cor do thumb */
    border-radius: 10px;
  }

  /* Estilizando o thumb ao passar o mouse por cima */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Cor do thumb ao passar o mouse por cima */
  }
`;

const DivTabela = styled.div`
  width: ${({ Width }) => (Width ? Width : "5000px")};
`;

const TextSemContaEncontrada = styled.h6`
  color: #515c70;
  background-color: yellow;
  max-width: 320px;
  padding: 10px;
  border-radius: 5px;
`;

const ConsultaPCASP = () => {
  const dataAtual = ReturnDataAtual();
  const ano = dataAtual.split("/")[2];
  const { currentClient } = useContext(CurrentClientContext);
  const [loadingLista, setLoadingLista] = useState(false);
  // const refRenderizacoes = useRef(0);

  const [exibirContas, setExibirContas] = useState(0);

  const [arrayTodasContasDoCliente, setArrayTodasContasDoCliente] = useState(
    []
  );
  const [filteredContas, setFilteredContas] = useState([]);

  const [arrayContasCorrentes, setArrayContasCorrentes] = useState([]);
  const [dadosTotaisDasContas, setDadosTotaisDasContas] = useState({
    totalSaldoAnterior: "-",
    totalDebito: "",
    totalCredito: "",
    totalSaldoAtual: "",
  });
  const [searchConta, setSearchConta] = useState("");

  const [arrayCompletoPCASP] = useState([...arrayTodosCamposPCASP]);
  const [arrayReduzidoPCASP] = useState([...arrayCamposPCASPReduzido]);
  const [arrayContasCorrentesPCASP] = useState([
    ...arrayCamposPCASPContasCorrentes,
  ]);

  const [contasPCASPForamInseridas, setContasPCASPForamInseridas] =
    useState(false);

  const [toggleContas, setToggleContas] = useState({
    conta1Opened: false,
    conta2Opened: false,
    conta3Opened: false,
    conta4Opened: false,
    conta5Opened: false,
    conta6Opened: false,
    conta7Opened: false,
    conta8Opened: false,
  });

  const [fetchContasCompleto, setFetchContasCompleto] = useState(false);

  // const [widthTabela, setWidthTabela] = useState("1800px");

  // useEffect(() => {
  //   refRenderizacoes.current += 1;
  // });

  const search = () => {
    let contas = arrayTodasContasDoCliente.filter(
      (item) =>
        item.conta.includes(searchConta) ||
        item.titulo.toLowerCase().includes(searchConta.toLowerCase())
    );

    if (contas.length > 0) {
      setFilteredContas(contas);
    } else {
      setFilteredContas([]);
    }
  };

  useEffect(() => {
    if (searchConta !== "") {
      search();
    }

    //eslint-disable-next-line
  }, [searchConta]);

  useEffect(() => {
    if (arrayTodasContasDoCliente.length > 0 && !fetchContasCompleto) {
      console.log("FETCH CONTAS");
      setFetchContasCompleto(true);
    }
  }, [arrayTodasContasDoCliente, fetchContasCompleto]);

  const fetchContasPCASP = () => {
    setLoadingLista(true);
    api
      .get(`pcasp/${currentClient.clienteId}/${ano}/todos?limit=10000`)
      .then((resp) => {
        const data = resp.data;
        //Setando os valores totais das contas PCASP
        setDadosTotaisDasContas((prevValues) => ({
          ...prevValues,
          totalCredito: data.totalSaldos.totalCredito,
          totalDebito: data.totalSaldos.totalDebito,
          totalSaldoAtual: data.totalSaldos.totalSaldoAtual,
        }));

        //Setando o array com todas as contas PCASP
        let arrayDadosDasContas = data.pcasp.map((contaPcasp) => {
          const {
            id,
            classe,
            grupo,
            subGrupo,
            tituloNumero,
            subTituloNumero,
            item,
            subItem,
            ...resto
          } = contaPcasp;

          return {
            ...resto,
            codReduzido: "-",
            conta_corrente: "-",
            saldo_anterior: "-",
            saldo_atual: FormatValueToLocaleString(
              parseFloat(resto.saldoDaContaPcasp)
            ),
            debito: FormatValueToLocaleString(
              parseFloat(resto.debitoDaContaPcasp)
            ),
            credito: FormatValueToLocaleString(
              parseFloat(resto.creditoDaContaPcasp)
            ),
          };
        });

        arrayDadosDasContas = arrayDadosDasContas.map((objeto) =>
          reorganizarAtributosDoArray(objeto)
        );

        setArrayTodasContasDoCliente(arrayDadosDasContas);

        //Setando o array com todas as contas correntes

        let arrayDeContasCorrentes = data.contaCorrenteSaldos.map((item) => {
          let contaAssociada = arrayDadosDasContas.find(
            (itemConta) => itemConta.conta === item.contaPcasp
          );

          console.log(contaAssociada);

          return {
            ...contaAssociada,
            saldo_atual: FormatValueToLocaleString(
              parseFloat(item.saldo_atual)
            ),
            conta_corrente: item.contaCorrente,
            debito: FormatValueToLocaleString(parseFloat(item.debito)),
            credito: FormatValueToLocaleString(parseFloat(item.credito)),
          };
        });

        console.log(arrayDeContasCorrentes);
        setArrayContasCorrentes(arrayDeContasCorrentes);
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Algo deu errado ao carregar os dados. Por favor, tente mais tarde."
        );
      });
  };

  const adicionaContasCorrentesAoArrayPCASP = () => {
    let arrayContasCorrentesInseridas = [...arrayTodasContasDoCliente];

    arrayContasCorrentes.forEach((item, index) => {
      console.log("Essa é a conta a qual essa conta corrente está associada");
      console.log(item.conta);
      let indexDeInsercaoDaContaCorrente = arrayTodasContasDoCliente.findIndex(
        (itemArrayContas) => itemArrayContas.conta === item.conta
      );
      console.log(indexDeInsercaoDaContaCorrente);

      if (indexDeInsercaoDaContaCorrente !== -1) {
        arrayContasCorrentesInseridas.splice(
          index === 0
            ? indexDeInsercaoDaContaCorrente + 1
            : indexDeInsercaoDaContaCorrente + 2,
          0,
          item
        );
        console.log(arrayContasCorrentesInseridas);
      }
    });

    if (
      arrayContasCorrentesInseridas.length > arrayTodasContasDoCliente.length
    ) {
      console.log("inseriu");
      setArrayTodasContasDoCliente([...arrayContasCorrentesInseridas]);
    }

    console.log("chega aqui");
    setLoadingLista(false);
  };

  useEffect(() => {
    if (arrayContasCorrentes.length > 0 && fetchContasCompleto) {
      adicionaContasCorrentesAoArrayPCASP();
      console.log(arrayContasCorrentes);
    }
    //eslint-disable-next-line
  }, [arrayContasCorrentes, fetchContasCompleto]);

  const reorganizarAtributosDoArray = (objeto) => {
    const funcao = objeto.funcao.substring(0, 23) + "...";

    const {
      codReduzido,
      conta,
      conta_corrente,
      titulo,
      saldo_anterior,
      debito,
      credito,
      saldo_atual,
      naturezaDeSaldo,
      status,
      nivelDetalhado,
      indicadorDoSuperavitFinanceiro,
      idIc,
      infoComplementar,
    } = objeto;
    return {
      codReduzido,
      conta,
      conta_corrente,
      titulo,
      saldo_anterior,
      debito,
      credito,
      saldo_atual,
      funcao,
      naturezaDeSaldo,
      status,
      nivelDetalhado,
      indicadorDoSuperavitFinanceiro,
      idIc,
      infoComplementar,
    };

    // setArrayTodasContasDoCliente(arrayOrganizado);
  };

  useEffect(() => {
    if (!contasPCASPForamInseridas) {
      console.log("teste pcasp", contasPCASPForamInseridas);
      fetchContasPCASP();
    }

    //eslint-disable-next-line
  }, [contasPCASPForamInseridas]);

  useEffect(() => {
    if (arrayTodasContasDoCliente.length > 0 && !contasPCASPForamInseridas) {
      console.log("TODAS AS CONTAAAAAAAS");
      console.log(arrayTodasContasDoCliente);
      setContasPCASPForamInseridas(true);
    }
  }, [arrayTodasContasDoCliente, contasPCASPForamInseridas]);

  // const HandleWidthTabela = (numeroOptionArray) => {
  //   switch (numeroOptionArray) {
  //     case 1:
  //       setWidthTabela("2000px");
  //       break;
  //     case 2:
  //       setWidthTabela("2000px");
  //       break;
  //     case 3:
  //       setWidthTabela("7000px");
  //       break;
  //     default:
  //       setWidthTabela("4000px");
  //       break;
  //   }
  // };

  const selecionarBackgroundRow = (valor) => {
    if (valor >= 1 && valor <= 4) {
      return "#C6D9FC";
    } else if (valor >= 5 && valor <= 6) {
      return "#F5D0F5";
    } else if (valor >= 7 && valor <= 8) {
      return "#cdeeb4";
    } else {
      return null;
    }
  };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderOptions
        withRow={true}
        TituloPagina={"PCASP"}
        SubTituloPagina={"Plano de Contas Aplicado ao Setor Público"}
        ToPag={"/planejamento/pcasp/cadastro"}
      />
      <ToastContainer />
      <Row className="row_form mt-1">
        {/* <button onClick={console.log(refRenderizacoes.current)}>console</button> */}
        <CCol md={8}>
          <FilterContainer>
            <FilterLabel>Contas por página:</FilterLabel>
            <FilterSelect
              value={exibirContas}
              onChange={(e) => setExibirContas(Number(e.target.value))}
            >
              <option value={0}>Todas</option>
              <option value={1}>Classe: 1</option>
              <option value={2}>Classe: 2</option>
              <option value={3}>Classe: 3</option>
              <option value={4}>Classe: 4</option>
              <option value={5}>Classe: 5</option>
              <option value={6}>Classe: 6</option>
              <option value={7}>Classe: 7</option>
              <option value={8}>Classe: 8</option>
            </FilterSelect>
          </FilterContainer>
        </CCol>

        <CCol md={4}>
          <InputSvg
            icon={outlineSearch}
            height={"23px"}
            color={"#6C7585"}
            placeholder={"pesquise"}
            onChange={(e) => {
              setSearchConta(e.target.value);
            }}
          />
          {/* <button onClick={() => console.log(toggleContas)}>coonsole</button> */}
        </CCol>
      </Row>

      {loadingLista ? (
        <Row>
          <CCol
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon height={50} icon="line-md:loading-loop" color="#515c70" />
          </CCol>
        </Row>
      ) : null}
      <div style={loadingLista ? { display: "none" } : null}>
        <Row className="row_form mt-1">
          <CColDivTabela md={12}>
            <DivTabela>
              {(!(filteredContas.length === 0 && searchConta.length > 0) ||
                searchConta === "") && (
                <Row style={{ margin: "0px" }}>
                  <CColArrowListaPCASP md={1}></CColArrowListaPCASP>
                  {arrayCompletoPCASP.map((item, index) => (
                    <React.Fragment key={index}>
                      {index === 0 && (
                        <CColCodigoReduzido md={1}>
                          <PTitulosListaPersonalizada>
                            {item.nome}
                          </PTitulosListaPersonalizada>
                        </CColCodigoReduzido>
                      )}
                      {item.nome === "Conta" && (
                        <CColContaListaPCASP md={1}>
                          <PTitulosListaPersonalizada>
                            {item.nome}
                          </PTitulosListaPersonalizada>
                        </CColContaListaPCASP>
                      )}
                      {/* // */}
                      {item.nome === "Titulo" && (
                        <CColAtributoTituloListaPCASP md={1}>
                          <PTitulosListaPersonalizada>
                            {item.nome}
                          </PTitulosListaPersonalizada>
                        </CColAtributoTituloListaPCASP>
                      )}
                      {/* // */}
                      {index !== 0 &&
                        item.nome !== "Conta" &&
                        item.nome !== "Titulo" && (
                          <CColItensGeraisListaPCASP>
                            <PTitulosListaPersonalizada>
                              {item.nome}
                            </PTitulosListaPersonalizada>
                          </CColItensGeraisListaPCASP>
                        )}
                    </React.Fragment>
                  ))}
                </Row>
              )}

              {searchConta === "" && (
                <>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map(
                    (numeroInicialDaConta, indexNumeroInicial) => (
                      <React.Fragment key={numeroInicialDaConta}>
                        {[0, numeroInicialDaConta].includes(exibirContas) && (
                          <>
                            <RowListaPdc
                              style={{
                                cursor: "pointer",
                                marginBottom: "0px",
                              }}
                              background={selecionarBackgroundRow(
                                numeroInicialDaConta
                              )}
                              onClick={() => {
                                switch (numeroInicialDaConta) {
                                  case 1:
                                    setToggleContas((prev) => ({
                                      ...prev,
                                      conta1Opened: !prev.conta1Opened,
                                    }));
                                    break;
                                  case 2:
                                    setToggleContas((prev) => ({
                                      ...prev,
                                      conta2Opened: !prev.conta2Opened,
                                    }));
                                    break;
                                  case 3:
                                    setToggleContas((prev) => ({
                                      ...prev,
                                      conta3Opened: !prev.conta3Opened,
                                    }));
                                    break;
                                  case 4:
                                    setToggleContas((prev) => ({
                                      ...prev,
                                      conta4Opened: !prev.conta4Opened,
                                    }));
                                    break;
                                  case 5:
                                    setToggleContas((prev) => ({
                                      ...prev,
                                      conta5Opened: !prev.conta5Opened,
                                    }));
                                    break;
                                  case 6:
                                    setToggleContas((prev) => ({
                                      ...prev,
                                      conta6Opened: !prev.conta6Opened,
                                    }));
                                    break;
                                  case 7:
                                    setToggleContas((prev) => ({
                                      ...prev,
                                      conta7Opened: !prev.conta7Opened,
                                    }));
                                    break;
                                  case 8:
                                    setToggleContas((prev) => ({
                                      ...prev,
                                      conta8Opened: !prev.conta8Opened,
                                    }));
                                    break;
                                  default:
                                    break;
                                }
                              }}
                            >
                              <CColArrowListaPCASP md={1}>
                                <Icon
                                  icon="material-symbols:arrow-back-ios-rounded"
                                  color="#515c70"
                                  style={{ marginTop: "-5px" }}
                                  rotate={3}
                                />
                              </CColArrowListaPCASP>
                              {arrayTodasContasDoCliente
                                .filter(
                                  (item) =>
                                    parseInt(item.conta[0]) ===
                                    numeroInicialDaConta
                                )
                                .map(
                                  (itemArrayPrincipal, indexArrayPrincipal) => {
                                    return (
                                      <React.Fragment key={indexArrayPrincipal}>
                                        {indexArrayPrincipal === 0 && (
                                          <React.Fragment>
                                            {Object.keys(
                                              itemArrayPrincipal
                                            ).map(
                                              (
                                                atributoDoObjeto,
                                                indexDoObjeto
                                              ) => (
                                                <React.Fragment
                                                  key={indexDoObjeto}
                                                >
                                                  {indexDoObjeto === 0 && (
                                                    <CColCodigoReduzido md={1}>
                                                      {
                                                        itemArrayPrincipal[
                                                          atributoDoObjeto
                                                        ]
                                                      }
                                                    </CColCodigoReduzido>
                                                  )}
                                                  {/* // */}
                                                  {atributoDoObjeto ===
                                                    "conta" && (
                                                    <CColContaListaPCASP md={1}>
                                                      {
                                                        itemArrayPrincipal[
                                                          atributoDoObjeto
                                                        ]
                                                      }
                                                    </CColContaListaPCASP>
                                                  )}
                                                  {/* // */}
                                                  {atributoDoObjeto ===
                                                    "titulo" && (
                                                    <CColAtributoTituloListaPCASP
                                                      md={1}
                                                    >
                                                      {
                                                        itemArrayPrincipal[
                                                          atributoDoObjeto
                                                        ]
                                                      }
                                                    </CColAtributoTituloListaPCASP>
                                                  )}
                                                  {/* // */}
                                                  {indexDoObjeto !== 0 &&
                                                    atributoDoObjeto !==
                                                      "conta" &&
                                                    atributoDoObjeto !==
                                                      "titulo" && (
                                                      <CColItensGeraisListaPCASP>
                                                        {
                                                          itemArrayPrincipal[
                                                            atributoDoObjeto
                                                          ]
                                                        }
                                                      </CColItensGeraisListaPCASP>
                                                    )}
                                                </React.Fragment>
                                              )
                                            )}
                                          </React.Fragment>
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                )}
                            </RowListaPdc>

                            <div
                              id={`collapseContas${numeroInicialDaConta}`}
                              style={
                                toggleContas[
                                  `conta${numeroInicialDaConta}Opened`
                                ]
                                  ? { display: "block" }
                                  : { display: "none" }
                              }
                            >
                              <>
                                {arrayTodasContasDoCliente
                                  .filter(
                                    (item) =>
                                      parseInt(item.conta[0]) ===
                                      numeroInicialDaConta
                                  )
                                  .map(
                                    (
                                      itemArrayPrincipal,
                                      indexArrayPrincipal
                                    ) => (
                                      <React.Fragment key={indexArrayPrincipal}>
                                        {indexArrayPrincipal > 0 && (
                                          <RowListaPdc>
                                            {Object.keys(
                                              itemArrayPrincipal
                                            ).map(
                                              (
                                                atributoDoObjeto,
                                                indexDoObjeto
                                              ) => (
                                                <React.Fragment
                                                  key={indexDoObjeto}
                                                >
                                                  {indexDoObjeto === 0 && (
                                                    <CColArrowListaPCASP
                                                      md={1}
                                                    ></CColArrowListaPCASP>
                                                  )}
                                                  {indexDoObjeto === 0 && (
                                                    <CColCodigoReduzido md={1}>
                                                      {
                                                        itemArrayPrincipal[
                                                          atributoDoObjeto
                                                        ]
                                                      }
                                                    </CColCodigoReduzido>
                                                  )}
                                                  {/* // */}
                                                  {atributoDoObjeto ===
                                                    "conta" && (
                                                    <CColContaListaPCASP md={1}>
                                                      {
                                                        itemArrayPrincipal[
                                                          atributoDoObjeto
                                                        ]
                                                      }
                                                    </CColContaListaPCASP>
                                                  )}
                                                  {/* // */}
                                                  {/* // */}
                                                  {atributoDoObjeto ===
                                                    "titulo" && (
                                                    <CColAtributoTituloListaPCASP
                                                      md={1}
                                                    >
                                                      {
                                                        itemArrayPrincipal[
                                                          atributoDoObjeto
                                                        ]
                                                      }
                                                    </CColAtributoTituloListaPCASP>
                                                  )}
                                                  {/* // */}
                                                  {indexDoObjeto !== 0 &&
                                                    atributoDoObjeto !==
                                                      "conta" &&
                                                    atributoDoObjeto !==
                                                      "titulo" && (
                                                      <CColItensGeraisListaPCASP>
                                                        {
                                                          itemArrayPrincipal[
                                                            atributoDoObjeto
                                                          ]
                                                        }
                                                      </CColItensGeraisListaPCASP>
                                                    )}
                                                </React.Fragment>
                                              )
                                            )}
                                          </RowListaPdc>
                                        )}
                                      </React.Fragment>
                                    )
                                  )}
                              </>
                            </div>
                          </>
                        )}
                      </React.Fragment>
                    )
                  )}
                </>
              )}

              {searchConta !== "" && (
                <>
                  {filteredContas.length === 0 && (
                    <Row>
                      <CCol style={{ paddingLeft: "25px" }}>
                        <TextSemContaEncontrada>
                          Nenhuma conta PCASP encontrada
                        </TextSemContaEncontrada>{" "}
                      </CCol>
                    </Row>
                  )}

                  {filteredContas.length > 0 && (
                    <>
                      {filteredContas.map(
                        (itemArrayPrincipal, indexArrayPrincipal) => (
                          <RowListaPdc
                            key={indexArrayPrincipal}
                            background={selecionarBackgroundRow(
                              parseInt(itemArrayPrincipal.conta[0])
                            )}
                          >
                            {Object.keys(itemArrayPrincipal).map(
                              (atributoDoObjeto, indexObjeto) => (
                                <React.Fragment key={indexObjeto}>
                                  {indexObjeto === 0 && (
                                    <CColArrowListaPCASP
                                      md={1}
                                    ></CColArrowListaPCASP>
                                  )}
                                  {indexObjeto === 0 && (
                                    <CColCodigoReduzido md={1}>
                                      {itemArrayPrincipal[atributoDoObjeto]}
                                    </CColCodigoReduzido>
                                  )}
                                  {/* // */}
                                  {atributoDoObjeto === "conta" && (
                                    <CColContaListaPCASP md={1}>
                                      {itemArrayPrincipal[atributoDoObjeto]}
                                    </CColContaListaPCASP>
                                  )}
                                  {/* // */}
                                  {/* // */}
                                  {atributoDoObjeto === "titulo" && (
                                    <CColAtributoTituloListaPCASP md={1}>
                                      {itemArrayPrincipal[atributoDoObjeto]}
                                    </CColAtributoTituloListaPCASP>
                                  )}
                                  {/* // */}
                                  {indexObjeto !== 0 &&
                                    atributoDoObjeto !== "conta" &&
                                    atributoDoObjeto !== "titulo" && (
                                      <CColItensGeraisListaPCASP>
                                        {itemArrayPrincipal[atributoDoObjeto]}
                                      </CColItensGeraisListaPCASP>
                                    )}
                                </React.Fragment>
                              )
                            )}
                          </RowListaPdc>
                        )
                      )}
                    </>
                  )}
                </>
              )}
            </DivTabela>
          </CColDivTabela>
        </Row>

        <Row className="row_form" style={{ marginTop: "-14px" }}>
          <CCol
            style={{
              display: "flex",
              justifyContente: "center",
              alignItems: "center",
            }}
          >
            <CheckBoxListaPCASP
              onChange={(e) => {
                console.log(arrayReduzidoPCASP);
              }}
              label="Tabela Reduzida"
              value={1}
            />
          </CCol>
          <CCol>
            <CheckBoxListaPCASP
              onChange={(e) => {
                console.log(arrayContasCorrentesPCASP);
              }}
              label="Mostrar Contas Correntes"
              value={2}
            />
          </CCol>
          <CCol>
            <CheckBoxListaPCASP
              onChange={(e) => {
                console.log(arrayCompletoPCASP);
              }}
              label="Mostrar tudo"
              value={3}
            />
          </CCol>
        </Row>

        <Row className="row_form_2 mt-1">
          <CCol md={3}>
            <FloatingLabelInput
              disabled
              placeholder="Saldo Anterior"
              label="Saldo Anterior"
              value={dadosTotaisDasContas.totalSaldoAnterior}
            />
          </CCol>
          <CCol md={3}>
            <FloatingLabelInput
              disabled
              placeholder="Débitos"
              label="Débitos"
              value={FormatValueToLocaleString(
                parseFloat(dadosTotaisDasContas.totalDebito)
              )}
            />
          </CCol>
          <CCol md={3}>
            <FloatingLabelInput
              disabled
              placeholder="Créditos"
              label="Créditos"
              value={FormatValueToLocaleString(
                parseFloat(dadosTotaisDasContas.totalCredito)
              )}
            />
          </CCol>
          <CCol md={3}>
            <FloatingLabelInput
              disabled
              placeholder="Saldo Atual"
              label="Saldo Atual"
              value={FormatValueToLocaleString(
                parseFloat(dadosTotaisDasContas.totalSaldoAtual)
              )}
            />
          </CCol>
        </Row>
      </div>
    </Container>
  );
};

export default ConsultaPCASP;
