import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { TituloAbaSolo } from "../../../../styles/StyledComponents/LayoutGerais";
import { CCol } from "../../../../components/Grid/CCol";
import styled from "styled-components";
import ScrollableSection from "./ScrollableSection";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";

export const StyledCCol = styled(CCol)`
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Receita = () => {
  const anoAtual = ReturnDataAtual().split("/")[2];
  const rowsInfos = [
    {
      key: 1,
      title: "UG",
    },
    {
      key: 2,
      title: "Natureza da Receita",
    },
    {
      key: 3,
      title: "Fonte de Recursos",
    },
    {
      key: 4,
      title: "Esfera",
    },
    {
      key: 5,
      title: Number(anoAtual) - 4 + " - Previsão",
      type: "money",
    },
    {
      key: 6,
      title: Number(anoAtual) - 4 + " - Arrecadada",
      type: "money",
    },
    {
      key: 7,
      title: Number(anoAtual) - 3 + " - Previsão",
      type: "money",
    },
    {
      key: 8,
      title: Number(anoAtual) - 3 + " Arrecadada",
      type: "money",
    },
    {
      key: 9,
      title: Number(anoAtual) - 2 + " - Previsão",
      type: "money",
    },
    {
      key: 10,
      title: Number(anoAtual) - 2 + " - Arrecadada",
      type: "money",
    },
    {
      key: 11,
      title: Number(anoAtual) - 1 + " - Previsão",
      type: "money",
    },
    {
      key: 12,
      title: Number(anoAtual) - 1 + " - Arrecadada",
      type: "money",
    },
    {
      key: 13,
      title: Number(anoAtual) + " - Previsão",
      type: "money",
    },
    {
      key: 14,
      title: Number(anoAtual) + 1 + " - Previsão",
      type: "money",
    },
    {
      key: 15,
      title: Number(anoAtual) + 1 + " - Arrecadada",
      type: "money",
    },
    {
      key: 16,
      title: Number(anoAtual) + 2 + " - Previsão",
      type: "money",
    },
    {
      key: 17,
      title: Number(anoAtual) + 2 + " - Arrecadada",
      type: "money",
    },
  ];

  const initialData = [
    {
      UG: "Teste UG 1",
      NaturezaReceita: "NatTeste 1",
      FonteRecursos: "FontRecTest 1",
      Esfera: "Fiscal",
      Previsao2020: "100000",
      Arrecadada2020: "75000",
      Previsao2021: "120000",
      Arrecadada2021: "80000",
      Previsao2022: "130000",
      Arrecadada2022: "85000",
      Previsao2023: "140000",
      Arrecadada2023: "90000",
      Previsao2024: "150000",
      Previsao2025: "160000",
      Arrecadada2025: "110000",
      Previsao2026: "170000",
      Arrecadada2026: "120000",
    },
    {
      UG: "Teste UG 2",
      NaturezaReceita: "NatTeste 2",
      FonteRecursos: "FontRecTest 2",
      Esfera: "Seguridade",
      Previsao2020: "90000",
      Arrecadada2020: "60000",
      Previsao2021: "100000",
      Arrecadada2021: "65000",
      Previsao2022: "110000",
      Arrecadada2022: "70000",
      Previsao2023: "120000",
      Arrecadada2023: "75000",
      Previsao2024: "130000",
      Previsao2025: "140000",
      Arrecadada2025: "95000",
      Previsao2026: "150000",
      Arrecadada2026: "100000",
    },
  ];

  const [inputs, setInputs] = useState(initialData);

  const handleInputChange = (rowIndex, colIndex, value) => {
    const newInputs = [...inputs];
    newInputs[rowIndex][colIndex] = value;
    setInputs(newInputs);
  };

  return (
    <>
      <Row className="row_form mt-1">
        <TituloAbaSolo>Receita Total</TituloAbaSolo>
      </Row>
      <ScrollableSection
        rowsInfos={rowsInfos}
        inputs={inputs}
        setInputs={setInputs}
        handleInputChange={handleInputChange}
      />
    </>
  );
};

export default Receita;
