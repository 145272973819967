import React from "react";
import { CCol, RowToModal } from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";

const Agencia = () => {
  return (
    <>
      <RowToModal className="row_form mt-1">
        <CCol md={2}>
          <FloatingLabelInput disabled label="Número" placeholder="Número" />
        </CCol>
        <CCol>
          <MyAutoComplete
            labelInput={"Código Banco"}
            placeholder="Código Banco"
            style={{ height: "38px" }}
            options={[{ label: "teste", value: 1 }]}
            labelFormat={(option) => `${option.label} - ${option.value}`}
          />
        </CCol>
        <CCol md={2}>
          <InputData />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CCol md={2}>
          <FloatingLabelInput label="Nº Agência" placeholder="Nº Agência" />
        </CCol>
        <CCol md={1}>
          <FloatingLabelInput label="D" placeholder="D" />
        </CCol>
        <CCol>
          <FloatingLabelInput label="Nome" placeholder="Nome" />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CCol md={3}>
          <MyAutoComplete
            labelInput={"CEP"}
            placeholder="CEP"
            style={{ height: "38px" }}
            options={[{ label: "teste", value: 1 }]}
            labelFormat={(option) => `${option.label} - ${option.value}`}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput label="Logradouro" placeholder="Logradouro" />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form_2" style={{ marginTop: "-15px" }}>
        <CCol md={6}>
          <FloatingLabelInput label="Bairro" placeholder="Bairro" />
        </CCol>
        <CCol md={3}>
          <FloatingLabelInput label="Cidade" placeholder="Cidade" />
        </CCol>
        <CCol>
          <FloatingLabelInput label="Estado" placeholder="Estado" />
        </CCol>
      </RowToModal>
    </>
  );
};

export default Agencia;
