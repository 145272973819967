import React from "react";
import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useFetchData } from "../../../../hooks/useFetchData";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import { CCol } from "../../../../components/Grid/CCol";
import Dashboard from "../../../../components/ComponentesDeDashboard/Dashboard";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import { useNavigate } from "react-router-dom";

export const DashboardFontRec = () => {
  const navigate = useNavigate();
  const { data: quantidades } = useFetchData(
    `fonteDeRecurso/total/${ReturnDataAtual().split("/")[2]}`
  );
  const options = [
    { label: "Quantidade", value: null },
    { label: "Quantidade", value: 1 },
  ];
  const [boxes, setBoxes] = useState({ box1: "", box2: "" });

  const getQuantidades = (value) => {
    if (value) {
      return value < 10 ? "0" + value : value;
    }
  };

  useEffect(() => {
    if (quantidades) {
      setBoxes((prevValues) => ({
        ...prevValues,
        box1: {
          boxTitle: "Quantidade",
          boxSubtitle: "Fontes de Recursos",
          boxValue: getQuantidades(quantidades.totalFontes.totalFontes),
        },
      }));
      setBoxes((prevValues) => ({
        ...prevValues,
        box2: {
          boxTitle: "Quantidade",
          boxSubtitle: "CO",
          boxValue: getQuantidades(quantidades.totalFontes.totalComCo),
        },
      }));
    }
  }, [quantidades]);

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      className="container_conteudo conteudo_pagina"
    >
      <HeaderCadastros
        NomePaginaTitulo={"Fonte de Recursos"}
        PaginaSubtitulo={"Dashboard"}
        PaginaConsulta={"/planejamento/cadastro/fonte-de-recursos/consulta"}
        //PaginaRelatorio={""}
        BotaoNovoFunction={() =>
          navigate("/planejamento/cadastro/fonte-de-recursos")
        }
        BotaoSaveAtivo={false}
        PaginaRelatorio={"/planejamento/cadastro/fonte-de-recurso/relatorio"}
      />
      <Row className="row_form_2 mt-1" style={{ padding: "20px 10px" }}>
        <CCol>
          <Dashboard box1={boxes.box1} box2={boxes.box2} options={options} />
        </CCol>
        <CCol>
          <Dashboard />
        </CCol>
      </Row>
      <div className="p-3"></div>
    </Container>
  );
};

export default DashboardFontRec;
