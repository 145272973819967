import React, { useState } from "react";
import {
  CCol,
  ContainerToModal,
  RowToModal,
} from "../../../../components/Grid/CCol";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import styled from "styled-components";
import InputData from "../../../../components/Grid/InputData";
import InputFormatRealBrasileiro from "../../../../components/Grid/InputFormatRealBrasileiro";
import { OptionsStyle } from "../../../Paginas_Sistemas_Planejamento/Cadastros/NaturezaDaReceita/NaturezaDaReceita";
import HistoricoAba from "./HistoricoAba";
import FonteDeRecursosAba from "./FonteDeRecursosAba";

const CColNumeroAndEVB = styled(CCol)`
  @media (min-width: 768px) {
    width: 10%;
  }
`;

const CColRemessa = styled(CCol)`
  @media (min-width: 768px) {
    width: 20%;
  }
`;

const ReceitaOrcamentaria = () => {
  const [abaSelecionada, setAbaSelecionada] = useState(0);

  return (
    <ContainerToModal
      id={"pags_responsividade_padding_geral"}
      fluid
      className={"container_conteudo conteudo_pagina"}
    >
      <HeaderCadastros
        NomePaginaTitulo={"Orçamentário"}
        PaginaSubtitulo={"Receita"}
        RowTitle={"Receita Orçamentária"}
      />

      <RowToModal className="row_form mt-1">
        <CColNumeroAndEVB md={1}>
          <FloatingLabelInput placeholder="Número" label="Número" disabled />
        </CColNumeroAndEVB>
        <CColNumeroAndEVB md={1}>
          <MyAutoComplete
            labelInput={"EVB"}
            options={[{ label: "Teste", value: "teste" }]}
            labelFormat={(option) => `${option.label} - ${option.value}`}
            style={{ height: "38px" }}
          />
        </CColNumeroAndEVB>
        <CCol>
          <MyAutoComplete
            labelInput={"Natureza da Receita"}
            options={[{ label: "Teste", value: "teste" }]}
            labelFormat={(option) => `${option.label} - ${option.value}`}
            style={{ height: "38px" }}
          />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CColRemessa md={1}>
          <FloatingLabelInput placeholder="Remessa" label="Remessa" disabled />
        </CColRemessa>
        <CCol md={7}>
          <FloatingLabelInput
            placeholder="Tipo de lançamento"
            new
            tipo="input_select"
            labe="Tipo de lançamento"
            options={[{ label: "teste", value: "teste" }]}
          />
        </CCol>
        <CCol>
          <InputData />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CCol>
          <MyAutoComplete
            labelInput={"Devedor"}
            options={[{ label: "Teste", value: "teste" }]}
            labelFormat={(option) => `${option.label} - ${option.value}`}
            style={{ height: "38px" }}
          />
        </CCol>
      </RowToModal>
      <RowToModal className="row_form" style={{ marginTop: "-15px" }}>
        <CCol md={8}>
          <MyAutoComplete
            labelInput={"Caixa e Equivalente de Caixa"}
            options={[{ label: "Teste", value: "teste" }]}
            labelFormat={(option) => `${option.label} - ${option.value}`}
            style={{ height: "38px" }}
          />
        </CCol>
        <CCol>
          <FloatingLabelInput
            placeholder="Doc Financeiro"
            label="Doc Financeiro"
            options={[{ label: "teste", value: "teste" }]}
          />
        </CCol>
        <CCol md={2}>
          <InputFormatRealBrasileiro />
        </CCol>
      </RowToModal>

      <RowToModal
        style={{ backgroundColor: "#fff", borderRadius: "0px 0px 5px 5px" }}
        className={"mt-1"}
      >
        <CCol style={{ display: "flex", alignItems: "center", padding: "0px" }}>
          <OptionsStyle
            selecionado={abaSelecionada === 0}
            onClick={() => setAbaSelecionada(0)}
          >
            Histórico
          </OptionsStyle>
          <OptionsStyle
            selecionado={abaSelecionada === 1}
            onClick={() => setAbaSelecionada(1)}
          >
            Fonte de Recursos
          </OptionsStyle>
        </CCol>
      </RowToModal>

      {abaSelecionada === 0 && <HistoricoAba />}
      {abaSelecionada === 1 && <FonteDeRecursosAba />}
    </ContainerToModal>
  );
};

export default ReceitaOrcamentaria;
