
import api from "../../../../../../utils/api";

export const getDespesaCatEconOrgao = async (clienteId) => {
  try {
    const response = await api.get(
      `qdr/relatorio/${clienteId}/2024`
    );
    console.log("response.data", response.data);
    return processAllData(response.data);
  } catch (error) {
    console.error("Erro na requisição:", error);
  }
};

export const processAllData = (dataQddRelatorioCatEconOrgao) => {
  

  const processItem = (item) => {
    const seccoes = item.nr.split(".").filter((secao) => parseInt(secao) !== 0);
    

    const resultado = [
      {
        codigo: item.nr,
        especificacao: item.especificacao,
        desdobramento: seccoes.length > 2 ? item.valor : null,
        grupoNatureza: seccoes.length === 2 ? item.valor : null,
        catEcon: seccoes.length < 2 ? item.valor : null,
        
      },
    ];

    if (
      item.qdr &&
      item.qdr.fontesDeRecursos &&
      item.qdr.fontesDeRecursos.length > 0
    ) {
      const recursos = item.qdr.fontesDeRecursos
        .map((recurso) => {
          
            return {
              codigo: recurso.conta,
              especificacao: recurso.titulo,
              desdobramento: recurso.valorPrevisto,
              grupoNatureza: null,
              catEcon: null,
            };
          
        })
      resultado.push(...recursos);
    }

    return resultado;
  };

  const dadosFiltrados = dataQddRelatorioCatEconOrgao.naturezas.map(natureza => processItem(natureza)).flat()
  console.log(dataQddRelatorioCatEconOrgao);
  
console.log(dadosFiltrados);

  return {
    data: dadosFiltrados,
    sum: 10,
  };
};
