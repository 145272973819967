import React, { useState, useContext, useEffect, useCallback } from "react";
import HeaderCadastros from "../../../../components/HeaderCadastros/HeaderCadastros";
import {
  CCol,
  ContainerToModal,
  RowToModal,
} from "../../../../components/Grid/CCol";
import FloatingLabelInput from "../../../../components/Grid/FloatingLabelInput";
import InputData from "../../../../components/Grid/InputData";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import {
  FormatCep,
  FormatCepValue,
} from "../../../../utils/FormatacaoDeDados/FormatCEP";
import api from "../../../../utils/api";
import { useParams } from "react-router-dom";
import { FormatDateToBR } from "../../../../utils/FormatacaoDeDados/FormatDateToBR";
import ButtonSaveModalAutoComplete from "../../../../components/Modais/ButtonSaveModalAutoComplete";
import { DefinirRequisicaoDoModalComoConluida } from "../../../../utils/Gerais/DefinirRequisicaoDoModalComoConcluida";
import { OpenedModalsContext } from "../../../../contexts/OpenedModalsContext";

const CadastroDiretriz = ({ isAModalPage }) => {
  const { currentClient } = useContext(CurrentClientContext);
  const { openedModals, setOpenedModals } = useContext(OpenedModalsContext);
  const [teclaPressionada, setTeclaPressionada] = useState(null);
  const [values, setValues] = useState({
    clienteId: 0,
    codigo: 0,
    titulo: "",
    audiencia: "",
    descricaoAudiencia: "",
    cep: 0,
    bairro: "",
    cidade: "",
    exercicio: "",
    dataDiretriz: "",
  });
  const { id: paramsId } = useParams();

  const maiorNumero = (array) => {
    let maior = array[0];
    for (let i = 1; i < array.length; i++) {
      if (array[i] > maior) {
        maior = array[i];
      }
    }
    return maior;
  };

  const fetchDiretrizMaxNumber = useCallback(() => {
    api
      .get(`diretrizPlanejamento/por-cliente/${currentClient.clienteId}`)
      .then((resp) => {
        if (resp.data.length) {
          const arrayCodigosDiretriz = resp.data.map((item) => item.codigo);
          const maxCodigoDiretriz = maiorNumero(arrayCodigosDiretriz);
          setValues((prevValues) => ({
            ...prevValues,
            codigo: maxCodigoDiretriz + 1,
          }));
        } else {
          setValues((prevValues) => ({
            ...prevValues,
            codigo: 1,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Ocorreu um erro ao tentar buscar o maior codigo de registro, porem, voce pode continuar a cadastrar uma diretriz normalmente."
        );
      });
  }, [currentClient.clienteId]);

  useEffect(() => {
    if (!paramsId) {
      fetchDiretrizMaxNumber();
    }
  }, [fetchDiretrizMaxNumber, paramsId]);

  let optionsAudiencia = [
    { label: "Audiência", value: null },
    { label: "Plano de Governo", value: "Plano de Governo" },
    { label: "PPA", value: "PPA" },
    { label: "LDO", value: "LDO" },
    { label: "LOA", value: "LOA" },
  ];

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      clienteId: currentClient.clienteId,
    }));
  }, [currentClient]);

  const fetchBairro = (cep) => {
    axios.get(`https://brasilapi.com.br/api/cep/v1/${cep}`).then((resp) => {
      console.log(resp.data);
      setValues((prevValues) => ({
        ...prevValues,
        bairro: resp.data.neighborhood,
        cidade: resp.data.city,
      }));
    });
  };

  const cadastraDiretriz = () => {
    const [DD, MM, YYYY] = values.dataDiretriz.split("/");
    const valueFormatted = { ...values, dataDiretriz: `${YYYY}-${MM}-${DD}` };
    console.log("Values to send: ", valueFormatted);
    api
      .post("diretrizPlanejamento", valueFormatted)
      .then((resp) => {
        console.log(resp.data);
        toast.success("Diretriz cadastrada com sucesso!");
        setValues((prevValues) => ({
          ...prevValues,
          codigo: values.codigo + 1,
          titulo: "",
          audiencia: "",
          descricaoAudiencia: "",
          cep: 0,
          bairro: "",
          cidade: "",
          exercicio: "",
          dataDiretriz: "",
        }));

        if (isAModalPage) {
          DefinirRequisicaoDoModalComoConluida(
            openedModals,
            setOpenedModals,
            "Diretriz"
          );
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Algo deu errado. Por favor, tente mais tarde.");
      });
  };

  const atualizarDiretriz = () => {
    let valueFormatted;
    const [DD, MM, YYYY] = values.dataDiretriz.split("/");
    //Adicionei esse if pois sem ele, quando ia fazer um put ele dava erro
    //O dado vinha como DD-MM-YYYY, o spli não dividia e atribuia tudo a DD
    if (DD && MM && YYYY) {
      valueFormatted = { ...values, dataDiretriz: `${YYYY}-${MM}-${DD}` };
    } else {
      valueFormatted = {
        ...values,
        dataDiretriz: values.dataDiretriz.substring(0, 10),
      };
    }
    console.log("Values to send: ", valueFormatted);
    api
      .put(
        `diretrizPlanejamento/cliente/${currentClient.clienteId}/atualiza/${paramsId}`,
        valueFormatted
      )
      .then((resp) => {
        console.log(resp);
        toast.success("Dados atualizados com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Algo deu errado ao atualizar os dados");
      });
  };

  const getDiretriz = () => {
    api
      .get(`diretrizPlanejamento/por-id/${paramsId}`)
      .then((resp) => {
        const data = resp.data[0];
        setValues((prevValues) => ({
          ...prevValues,
          codigo: data.codigo,
          titulo: data.titulo,
          audiencia: data.audiencia,
          descricaoAudiencia: data.descricaoAudiencia,
          cep: data.cep,
          bairro: data.bairro,
          cidade: data.cidade,
          exercicio: data.exercicio,
          dataDiretriz: data.dataDiretriz,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (paramsId && !isAModalPage) {
      getDiretriz();
    }

    //eslint-disable-next-line
  }, [paramsId, isAModalPage]);

  return (
    <ContainerToModal
      id={isAModalPage ? undefined : "pags_responsividade_padding_geral"}
      isAModalPage={isAModalPage}
      fluid
      className={
        isAModalPage ? undefined : "container_conteudo conteudo_pagina"
      }
    >
      {!isAModalPage && <ToastContainer />}

      {!isAModalPage && (
        <HeaderCadastros
          PaginaConsulta={"/planejamento/cadastro/diretriz/consulta"}
          PaginaRelatorio={"/planejamento/cadastro/diretriz/relatorio"}
          NomePaginaTitulo={"Diretriz"}
          PaginaSubtitulo={"Cadastro"}
          ButtonSaveFunction={paramsId ? atualizarDiretriz : cadastraDiretriz}
        />
      )}

      {isAModalPage && (
        <ButtonSaveModalAutoComplete
          SaveFunction={cadastraDiretriz}
          pageTitle={"Diretriz"}
        />
      )}

      <RowToModal isAModalPage={isAModalPage} className="row_form mt-1">
        {/* <button onClick={() => console.log(values)}>console</button> */}
        <CCol md={2}>
          <FloatingLabelInput
            placeholder="N° Diretriz"
            label="N° Diretriz"
            maxLength="2"
            disabled={paramsId}
            value={values.codigo || ""}
            onChange={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
              setValues((prevValues) => ({
                ...prevValues,
                codigo: e.target.value,
              }));
            }}
          />
        </CCol>
        <CCol md={10}>
          <FloatingLabelInput
            placeholder="Título"
            label="Título"
            value={values.titulo}
            onChange={(e) => {
              setValues((prevValues) => ({
                ...prevValues,
                titulo: e.target.value,
              }));
            }}
          />
        </CCol>
      </RowToModal>

      <RowToModal isAModalPage={isAModalPage} className="row_form_2 mt-1">
        <CCol md={3}>
          <FloatingLabelInput
            new={true}
            tipo="input_select"
            placeholder="Audiência"
            defaultValue=""
            value={values.audiencia}
            options={optionsAudiencia}
            label="Audiência"
            onSelect={(option) =>
              setValues((prevValues) => ({
                ...prevValues,
                audiencia: option.value,
              }))
            }
            onChange={(e) => {
              setValues((prevValues) => ({
                ...prevValues,
                audiencia: e.target.value,
              }));
            }}
          />
        </CCol>
        <CCol md={9}>
          <FloatingLabelInput
            label="Descrição Audiência"
            placeholder="Descrição Audiência"
            value={values.descricaoAudiencia}
            onChange={(e) => {
              setValues((prevValues) => ({
                ...prevValues,
                descricaoAudiencia: e.target.value,
              }));
            }}
          />
        </CCol>
        <div className="pt-3"></div>
        {/* ------------------------- */}
        <CCol md={2}>
          <InputData
            maxLength="10"
            placeholder="Data"
            externalValue={values.dataDiretriz.substring(0, 10)}
            onChange={(e) => {
              setValues((prevValues) => ({
                ...prevValues,
                dataDiretriz: FormatDateToBR(e.target.value),
              }));
            }}
          />
        </CCol>
        <CCol md={2}>
          <div className="input_icon_search">
            <FloatingLabelInput
              style={{ paddingLeft: "32px" }}
              placeholder="CEP"
              maxLength="9"
              label="CEP"
              value={values.cep ? FormatCepValue(String(values.cep)) : ""}
              onKeyDown={(e) => {
                setTeclaPressionada(e.keyCode);
              }}
              onChange={(e) => {
                let cleanedValue = e.target.value.replace(/[^0-9]/g, "");

                if (teclaPressionada !== 8) {
                  FormatCep(e.target);
                }

                if (cleanedValue.length === 8) {
                  console.log("igualou a oito");
                  fetchBairro(cleanedValue);
                }

                setValues((prevValues) => ({
                  ...prevValues,
                  cep: cleanedValue,
                }));
              }}
            />
          </div>
        </CCol>
        <CCol md={4}>
          <FloatingLabelInput
            placeholder="Bairro"
            label="Bairro"
            value={values.bairro || ""}
            disabled={true}
          />
        </CCol>
        <CCol md={3}>
          <FloatingLabelInput
            placeholder="Cidade"
            label="Cidade"
            value={values.cidade || ""}
            disabled={true}
          />
        </CCol>
        <CCol md={1}>
          <FloatingLabelInput
            placeholder="Exercício"
            label="Exercício"
            value={values.exercicio}
            onChange={(e) => {
              setValues((prevValues) => ({
                ...prevValues,
                exercicio: e.target.value,
              }));
            }}
          />
        </CCol>
      </RowToModal>
    </ContainerToModal>
  );
};

export default CadastroDiretriz;
