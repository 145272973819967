import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import HeaderCadastros from "../../../components/HeaderCadastros/HeaderCadastros";
import { CCol, RowAninhada } from "../../../components/Grid/CCol";
import UsuarioPerfil from "../../../assets/NavBars/user.png";
import FloatingLabelInput from "../../../components/Grid/FloatingLabelInput";
import styled from "styled-components";
import RowSelecaoAbasInferior from "../../../components/Grid/RowSelecaoAbasInferior";
import CadastroListaClientes from "./CadastroListaClientes";
import { toast, ToastContainer } from "react-toastify";
import { FormatCpfPaste } from "../../../utils/FormatacaoDeDados/FormtCpfPaste";
import { useParams } from "react-router-dom";
import AbaDocumentosUsuario from "./AbaDocumentosUsuario";
import { LabelSelectFileImgUser } from "../../../styles/StyledComponents/InputsPersonalizados";
import AbaClientesDoUsuario from "./AbaClientesDoUsuario";
import api from "../../../utils/api";
import { ModulosDoSistema } from "../../../utils/DadosGerais/ModulosDoSistema";
import useOptions from "./hooks/useOptions";
import usePagContaPerfil from "./hooks/usePagCadsatroDeUsuario";
import useSelectAbas from "./hooks/useSelectAbas";
import MyAutoComplete from "../../../components/Inputs/MyAutoComplete";
import {
  ContentModalAlterarSenha,
  LabelCheckStyled,
} from "./StyledComponentsContaPerfil";
import InputEmail from "../../../components/Inputs/InputEmail";
import { CurrentClientContext } from "../../../contexts/CurrentClientContext";
import ModalComponent from "../../../components/Modais/ModalComponent/ModalComponent";
import { tiposDeUsuario } from "./data/dataPagCadatroDeUsuario";

export const CColImgUsuarioPagConta = styled(CCol)`
  @media (min-width: 768px) {
    width: 12%;
  }
`;

// const useClientes = () => {};

const DivCheckboxUsuarioAtivo = styled.label`
  background-color: ${({ $checked }) => ($checked ? "#89c45454" : "#E8E8E8")};
  height: 100%;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const CheckboxUsuarioAtivo = ({ valuesUser, setValuesUser }) => {
  return (
    <DivCheckboxUsuarioAtivo $checked={valuesUser.usuarioAtivo}>
      <LabelCheckStyled
        onChange={() =>
          setValuesUser((prev) => ({
            ...prev,
            usuarioAtivo: !prev.usuarioAtivo,
          }))
        }
        checked={valuesUser.usuarioAtivo}
        label={"Usuário Ativo"}
      />
    </DivCheckboxUsuarioAtivo>
  );
};

const PagCadastroDeUsuario = ({ moduloDoSistema }) => {
  const listaDeSistemas = ModulosDoSistema;
  const { optionsClientes, optionsUsuarios } = useOptions();
  const { currentClient } = useContext(CurrentClientContext);

  const {
    valuesUser,
    setValuesUser,
    modulosSelecionadosDosClientes,
    setModulosSelecionadosDosClientes,
    handleImageChange,
    handleImageUpdate,
    cadastraNovoUsuario,
    handleAutocompleteChange,
  } = usePagContaPerfil();

  const [documentoAutorizativo, setDocumentoAutorizativo] = useState("");
  const [modalAlterarSenhaOpened, setModalAlterarSenhaOpened] = useState(false);

  const { abaSelecionada, setAbaSelecionada } = useSelectAbas();

  const [nomeDoUsuarioECpf, setnomeDoUsuarioECpf] = useState("");
  const { id: idDoUsuario } = useParams();

  const [valoresParaAdicionarCliente, setValoresParaAdicionarCliente] =
    useState({
      clienteId: "",
      tipoDeUsuario: 5,
      openedOptions: true,
    });

  const [inputAutocompleteClientes, setInputAutocompleteClientes] =
    useState("");
  const [inputAutocompleteUsers, setInputAutocompleteUsers] = useState("");
  // const [indexDoClienteAtual, setIndexDoClienteAtual] = useState(-1);

  const getTipoUsuarioId = (tipoUsuario) => {
    switch (tipoUsuario.toLowerCase()) {
      case "master":
        return 1;
      case "gestor":
        return 2;
      case "suporte":
        return 3;
      case "administrador":
        return 4;
      case "usuário":
        return 5;
      case "externo":
        return 6;
      default:
        return;
    }
  };

  const preencheDadosDaVizualizacaoDoUsuario = () => {
    api
      .get(`/usuario/${idDoUsuario}`)
      .then((resp) => {
        const data = resp.data[0];
        console.log(data);
        //--------- REORGANIZANDO O ARRAY DE CLIENTES DO USUARIO -------------------

        let newArrayClientesDoUsuario = data.clientes
          .filter((cliente) => cliente.clienteId)
          .map((cliente) => ({
            clienteId: cliente.clienteId,
            clienteAtivo: cliente.clienteAtivo,
            usuarioTipoId: getTipoUsuarioId(cliente.tipoUsuario),
            autorizacaoAnexo: cliente.autorizacaoAnexo,
            nomeArq: cliente.nomeArq,
            permissoes: cliente.permissoes.sistemas.map((sistema) => ({
              sistema: sistema.sistema,
              sistemaAtivo: sistema.sistemaAtivo === 1 ? true : false,
              menus: sistema.menus.map((menu) => ({
                menu: menu.menu,
                menuAtivo: menu.menuAtivo === 1 ? true : false,
                acoes: menu.acoes
                  .filter((item) => item.acao !== menu.menu)
                  .map((acao) => ({
                    acao: acao.acao,
                    acaoAtiva: acao.acaoAtiva === 1 ? true : false,
                  })),
              })),
            })),
          }));

        console.log(newArrayClientesDoUsuario);

        //---------- SETANDO ESTADOS PARA SEREM EXIBIDOS NA PAG DE VIZUALIZACAO  ----------------
        setnomeDoUsuarioECpf(`${data.nome} - ${FormatCpfPaste(data.cpf)}`);

        // ----------- PREENCHENDO COM OS SISTEMAS QUE O USUARIO NAO TEM PRA O CLIENTE -----------

        let arrayDeClientesComAlteracoes = newArrayClientesDoUsuario.map(
          (cliente) => {
            console.log(cliente);
            const permissoesAtivasNoCliente = cliente.permissoes.map(
              (permissao) => {
                const menusAtivos = [...permissao.menus]; //aqui ficam os menus que vem como ativos do get

                console.log(menusAtivos);

                const sistemaCompleto = listaDeSistemas.find(
                  (sistema) => sistema.sistema === permissao.sistema
                );
                console.log(sistemaCompleto);

                const menusFormatados = menusAtivos.map((menu) => {
                  const acoesDoMenuAtivas = menu.acoes.map((acao) => acao.acao);

                  const menuDoSistemaCompleto = sistemaCompleto.menus.find(
                    (obj) => obj.menu === menu.menu
                  );
                  console.log(menuDoSistemaCompleto);
                  const todasAcoesDoMenu = menuDoSistemaCompleto.acoes;

                  const acoesRestantes = todasAcoesDoMenu.filter(
                    (obj) => !acoesDoMenuAtivas.includes(obj.acao)
                  );

                  return {
                    ...menu,
                    acoes: [...menu.acoes, ...acoesRestantes],
                  };
                });

                const menusNoSistema = menusAtivos.map((item) => item.menu);

                console.log(menusNoSistema);

                const menusRestantes = sistemaCompleto.menus.filter(
                  (item) => !menusNoSistema.includes(item.menu)
                );

                console.log(menusRestantes);

                return {
                  ...permissao,
                  menus: [...menusFormatados, ...menusRestantes],
                };
              }
            );

            const arraySistemasAtivos = permissoesAtivasNoCliente.map(
              (item) => item.sistema
            );
            const permissoesNaoAtivasNoCliente = listaDeSistemas.filter(
              (item) => !arraySistemasAtivos.includes(item.sistema)
            );

            return {
              ...cliente,
              permissoes: [
                ...permissoesAtivasNoCliente,
                ...permissoesNaoAtivasNoCliente,
              ],
            };
          }
        );

        console.log(arrayDeClientesComAlteracoes);

        // ----------------------------------------------------------

        setValuesUser((prev) => ({
          ...prev,
          pessoaFisicaId: data.pessoaFisicaId,
          userId: data.userId,
          email: data.email,
          fotoPerfil: data.fotoPerfil,
          clientes: [...arrayDeClientesComAlteracoes],
        }));

        const arrayPreenchimentoModulosSelecionados =
          arrayDeClientesComAlteracoes.map((item) => ({
            clienteId: item.clienteId,
            moduloSelecionado: item.permissoes.filter(
              (obj) => obj.sistemaAtivo
            )[0].sistema,
          }));

        console.log(arrayPreenchimentoModulosSelecionados);

        setModulosSelecionadosDosClientes(
          arrayPreenchimentoModulosSelecionados
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (idDoUsuario && listaDeSistemas.length > 0) {
      console.log(`id do usuario: ${idDoUsuario}`);
      preencheDadosDaVizualizacaoDoUsuario();
    }
    //eslint-disable-next-line
  }, [idDoUsuario, listaDeSistemas]);

  useEffect(() => {
    if (moduloDoSistema !== "Gerenciador") {
      setValoresParaAdicionarCliente((prev) => ({
        ...prev,
        clienteId: currentClient.clienteId,
      }));
    }
  }, [currentClient, moduloDoSistema]);

  const handleDisableClient = (clienteId) => {
    api
      .put(`usuario/situacao-cliente/${idDoUsuario}/clientes/${clienteId}`)
      .then((resp) => {
        toast.success("Permissão ao cliente alterada com sucesso!");

        const newArray = [...valuesUser.clientes];
        const cliente = newArray.find(
          (cliente) => cliente.clienteId === clienteId
        );
        cliente.clienteAtivo = cliente.clienteAtivo === 0 ? 1 : 0;

        console.log(newArray);

        setValuesUser((prev) => ({
          ...prev,
          clientes: [...newArray],
        }));
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Algo deu errado ao alterar o estado desse cliente no usuário."
        );
      });
  };

  const atualizaUsuario = () => {
    // .map((cliente) => ({
    //   clienteId: cliente.clienteId,
    //   clienteAtivo: cliente.clienteAtivo,
    //   usuarioTipoId: getTipoUsuarioId(cliente.tipoUsuario),
    //   autorizacaoAnexo: cliente.autorizacaoAnexo,
    //   nomeArq: cliente.nomeArq,
    //   permissoes: cliente.permissoes.sistemas.map((sistema) => ({
    //     sistema: sistema.sistema,
    //     sistemaAtivo: sistema.sistemaAtivo === 1 ? true : false,
    //     menus: sistema.menus.map((menu) => ({
    //       menu: menu.menu,
    //       menuAtivo: menu.menuAtivo === 1 ? true : false,
    //       acoes: menu.acoes
    //         .filter((item) => item.acao !== menu.menu)
    //         .map((acao) => ({
    //           acao: acao.acao,
    //           acaoAtiva: acao.acaoAtiva === 1 ? true : false,
    //         })),
    //     })),

    const mappedClientes = valuesUser.clientes.map((cliente) => {
      const { clienteAtivo, openedOptions, ...resto } = cliente;

      return {
        ...resto,
        autorizacaoAnexo: "padrão",
        permissoes: resto.permissoes
          .filter((permissao) => permissao.sistemaAtivo)
          .map((permissao) => ({
            ...permissao,
            sistemaAtivo: 1,
            menus: permissao.menus
              .filter((menu) => menu.menuAtivo)
              .map((menu) => ({
                ...menu,
                menuAtivo: 1,
                acoes: menu.acoes
                  .filter((acao) => acao.acaoAtiva)
                  .map((acao) => ({
                    ...acao,
                    acaoAtiva: 1,
                  })),
              })),
          })),
      };
    });

    console.log(mappedClientes);

    api
      .put(`usuario/${idDoUsuario}/clientes`, { clientes: mappedClientes })
      .then((resp) => {
        console.log(resp);
        toast.success("Permissões do usuário atualizadas com sucesso!");
      })
      .catch((error) => {
        console.log(error);

        toast.error("Erro ao atualizar as permissões do usuário.");
      });
  };

  return (
    <Container
      id="pags_responsividade_padding_geral"
      fluid
      style={{ color: "#515C70" }}
      className="container_conteudo conteudo_pagina"
    >
      <ToastContainer />
      <HeaderCadastros
        NomePaginaTitulo={"Conta do Usuário"}
        PaginaSubtitulo={"Cadastro de Novo Usuário"}
        PaginaConsulta={
          moduloDoSistema === "Gerenciador" &&
          "/acesso-interno/usuarios/consulta"
        }
        ButtonSaveFunction={idDoUsuario ? atualizaUsuario : cadastraNovoUsuario}
      />
      <ModalComponent modalOpened={modalAlterarSenhaOpened}>
        <ContentModalAlterarSenha>
          <Button onClick={() => setModalAlterarSenhaOpened(false)}>Sim</Button>
        </ContentModalAlterarSenha>
      </ModalComponent>
      {/* <button onClick={() => console.log(valuesUser)}>console</button>
      <button onClick={() => console.log(listaDeSistemas)}>
        lista de sistemas
      </button> */}
      <Row className="row_form mt-1">
        <CColImgUsuarioPagConta
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LabelSelectFileImgUser>
            <input
              id="imgUser"
              type="file"
              accept="image/*"
              onChange={
                !idDoUsuario
                  ? handleImageChange
                  : (e) => handleImageUpdate(e, idDoUsuario)
              }
            />
            <div>
              <img
                src={
                  valuesUser.fotoPerfil !== ""
                    ? valuesUser.fotoPerfil
                    : UsuarioPerfil
                }
                alt="Foto do Usuário"
              />
            </div>
          </LabelSelectFileImgUser>
        </CColImgUsuarioPagConta>

        <CCol>
          <RowAninhada>
            <CCol>
              {idDoUsuario ? (
                <>
                  <FloatingLabelInput
                    label={"Nome - CPF"}
                    value={nomeDoUsuarioECpf}
                    disabled
                  />
                </>
              ) : (
                <>
                  <MyAutoComplete
                    inputValue={inputAutocompleteUsers}
                    setInputValue={setInputAutocompleteUsers}
                    labelInput={"Nome ou CPF do usuário"}
                    style={{ height: "38px" }}
                    options={optionsUsuarios}
                    labelFormat={(option) =>
                      `${option.nome} - ${FormatCpfPaste(option.cpf)}`
                    }
                    onOptionSelect={handleAutocompleteChange}
                  />
                </>
              )}
            </CCol>
            <CCol md={3}>
              <CheckboxUsuarioAtivo
                valuesUser={valuesUser}
                setValuesUser={setValuesUser}
              />
            </CCol>
          </RowAninhada>
          <RowAninhada style={{ marginTop: "16px" }}>
            <CCol>
              <InputEmail
                label={"@ E-mail"}
                placeholder="@ E-mail"
                value={valuesUser.email}
                disabled={idDoUsuario}
                onChange={(e) => {
                  setValuesUser((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                }}
              />
            </CCol>
            <CCol md={3}>
              <Button
                style={{ padding: "0px", height: "100%", width: "100%" }}
                variant="success"
                onClick={() => setModalAlterarSenhaOpened(true)}
              >
                Alterar Senha
              </Button>
            </CCol>
          </RowAninhada>
        </CCol>
      </Row>

      <RowSelecaoAbasInferior
        abaSelecionada={abaSelecionada}
        setAbaSelecionada={setAbaSelecionada}
        abas={["Cadastros", "Clientes"]}
      />
      {abaSelecionada.numeroAba === 0 && (
        <CadastroListaClientes
          inputAutocompleteClientes={inputAutocompleteClientes}
          setInputAutocompleteClientes={setInputAutocompleteClientes}
          optionsClientes={optionsClientes}
          valoresParaAdicionarCliente={valoresParaAdicionarCliente}
          setValoresParaAdicionarCliente={setValoresParaAdicionarCliente}
          valuesUser={valuesUser}
          setValuesUser={setValuesUser}
          modulosSelecionadosDosClientes={modulosSelecionadosDosClientes}
          setModulosSelecionadosDosClientes={setModulosSelecionadosDosClientes}
          moduloDoSistema={moduloDoSistema}
          documentoAutorizativo={documentoAutorizativo}
          setDocumentoAutorizativo={setDocumentoAutorizativo}
          currentClient={currentClient}
        />
      )}
      {abaSelecionada.numeroAba === 1 && (
        <AbaClientesDoUsuario
          idDoUsuario={idDoUsuario}
          handleDisableClient={handleDisableClient}
          clientesDoUsuario={valuesUser.clientes.map((cliente) => ({
            clienteId: cliente.clienteId,
            nomeFantasia: optionsClientes.find(
              (option) => cliente.clienteId === option.id
            ).nome,
            docAutorizativo: cliente.autorizacaoAnexo,
            tipoDeUsuario: tiposDeUsuario.find(
              (tipo) => tipo.value === cliente.usuarioTipoId
            ).label,
            sistemas: cliente.permissoes.map((permissao) => ({
              sistema: permissao.sistema,
              sistemaAtivo: permissao.sistemaAtivo,
            })),
            clienteAtivo: cliente.clienteAtivo,
          }))}
        />
      )}
      {abaSelecionada.numeroAba === 2 && (
        <AbaDocumentosUsuario
          setValuesUser={setValuesUser}
          valuesUser={valuesUser}
        />
      )}
      <div className="p-3"></div>
    </Container>
  );
};

export default PagCadastroDeUsuario;
