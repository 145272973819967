import { useState } from "react";

import * as RS from "../../../../../components/ComponenteDeRelatorio/StyledCompsRelatorio";
import { Col, Row } from "react-bootstrap";

import AbaOpcoesRelatorio from "../../../../../components/ComponenteDeRelatorio/AbaOpcoesRelatorio";
import InputSearch from "../../../../../components/Inputs/InputSearch";
import { InputDateRelatorio } from "../../../../../components/Inputs/InputDateRelatorio";
import { OptionsRelatorio } from "./OptionsRelatorio";
import FloatingLabelInput from "../../../../../components/Grid/FloatingLabelInput";
import { ReturnDataAtual } from "../../../../../utils/Gerais/ReturnDataAtual";
import { LabelCheckLOA } from "../../../../../components/ComponenteDeRelatorio/StyledCompsRelatorio";

const months = [
  { label: "Janeiro", value: "01" },
  { label: "Fevereiro", value: "02" },
  { label: "Março", value: "03" },
  { label: "Abril", value: "04" },
  { label: "Maio", value: "05" },
  { label: "Junho", value: "06" },
  { label: "Julho", value: "07" },
  { label: "Agosto", value: "08" },
  { label: "Setembro", value: "09" },
  { label: "Outubro", value: "10" },
  { label: "Novembro", value: "11" },
  { label: "Dezembro", value: "12" },
];

export const PagRelatorio = ({
  principalOptions = [],
  labelsCheckAbaPrincipal = [],
  setLabelsCheckAbaPrincipal = [],
  labelsCheckAbaOpcoes = [],
  setLabelsCheckAbaOpcoes,
  entidadesGestorasDoCliente = [],
  setEntidadesGestorasDoCliente,
  showOpcoesButton = false,
  showOutrosButton = false,
}) => {
  const [abaSelecionada, setAbaSelecionada] = useState(0);
  const [month, setMonth] = useState("");

  const capitalizeExcept = (str) => {
    const exceptions = ["STTrans"];
    return str
      .split(" ")
      .map((word) => {
        const exception = exceptions.find(
          (ex) => ex.toLowerCase() === word.toLowerCase()
        );
        if (exception) {
          return exception;
        }

        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  const checkAllBoxes = () => {
    setEntidadesGestorasDoCliente((prev) => {
      return prev.map((option) => {
        const anyFalse = option.checkBoxes.some(
          (checkbox) => !checkbox.selected
        );

        return {
          ...option,
          checkBoxes: option.checkBoxes.map((checkbox) => ({
            ...checkbox,
            selected: anyFalse ? true : false,
          })),
        };
      });
    });
  };

  const toggleCheckBox = (index) => {
    setEntidadesGestorasDoCliente((prevEntidades) =>
      prevEntidades.map((group) => ({
        ...group,
        checkBoxes: group.checkBoxes.map((item, i) =>
          i === index ? { ...item, selected: !item.selected } : item
        ),
      }))
    );
  };

  return (
    <>
      <Row
        className="row_form mt-1"
        style={{
          borderRadius: "0 0 5px 5px",
          height: "auto",
          padding: "0px",
          paddingTop: "10px",
          paddingBottom: "15px",
        }}
      >
        {/* <div className="pt-3"></div> */}
        <RS.ColMaiorEsquerda lg={8} sm={12}>
          {abaSelecionada === 0 && (
            <OptionsRelatorio
              principalOptions={principalOptions}
              labelsCheckAbaPrincipal={labelsCheckAbaPrincipal}
              setLabelsCheckAbaPrincipal={setLabelsCheckAbaPrincipal}
              abaSelecionada={abaSelecionada}
              setAbaSelecionada={setAbaSelecionada}
            />
          )}

          {abaSelecionada === 1 && (
            <AbaOpcoesRelatorio
              setLabelsCheckAbaOpcoes={setLabelsCheckAbaOpcoes}
              labelsCheckAbaOpcoes={labelsCheckAbaOpcoes}
            />
          )}
        </RS.ColMaiorEsquerda>
        <Col lg={4} sm={12} style={{ paddingTop: "10px" }}>
          <Row>
            <Col md={4} style={{ width: "50%" }}>
              <FloatingLabelInput
                new={true}
                style={{ color: "#515c70" }}
                tipo="input_select"
                placeholder="Mês"
                onChange={(e) => {
                  console.log(e.target.value);
                  setMonth(e.target.value);
                }}
                value={month}
                options={months}
                onSelect={(option) => {
                  console.log(option.value);

                  setMonth(option.value);
                }}
              />
            </Col>
            <Col md={4} style={{ width: "50%" }}>
              <FloatingLabelInput
                new
                style={{ color: "#515c70" }}
                tipo="input_select"
                placeholder={ReturnDataAtual().split("/")[2]}
                onSelect={(option) => {
                  console.log(option.value);
                }}
                options={[
                  {
                    label: parseInt(ReturnDataAtual().split("/")[2]),
                    value: parseInt(ReturnDataAtual().split("/")[2]),
                  },
                  {
                    label: parseInt(ReturnDataAtual().split("/")[2]) + 1,
                    value: parseInt(ReturnDataAtual().split("/")[2]) + 1,
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <RS.ColMenorDireita className="mt-3" md={12}>
              <RS.ContainerMenorDireita>
                <RS.TituloColDir style={{ paddingTop: "0px" }}>
                  <span>Selecionado</span>
                </RS.TituloColDir>
                <RS.LabelsContMaiorDir className="pt-3">
                  <LabelCheckLOA
                    label={"Consolidação Geral"}
                    checked={entidadesGestorasDoCliente[0]?.checkBoxes?.every(
                      (checkBox) => checkBox.selected === true
                    )}
                    onChange={(e) => {
                      checkAllBoxes();
                    }}
                  />
                  {entidadesGestorasDoCliente[0]?.checkBoxes?.map(
                    (item, index) => (
                      <LabelCheckLOA
                        label={capitalizeExcept(item.dados.nomeFantasia)}
                        checked={item.selected}
                        onChange={(e) => {
                          toggleCheckBox(index);
                        }}
                      />
                    )
                  )}
                </RS.LabelsContMaiorDir>
              </RS.ContainerMenorDireita>
              <RS.ParteInferiorContDirMaior>
                <p>{`0 itens`}</p>
              </RS.ParteInferiorContDirMaior>

              <RS.ContainerMenorDireita
                style={{
                  marginTop: "18px",
                  borderBottom: "1px",
                  borderStyle: "solid",
                  borderColor: "#ced4da",
                }}
              >
                <RS.TituloColDir style={{ paddingTop: "0px" }}>
                  <span>Assinaturas</span>
                </RS.TituloColDir>
                <RS.LabelsContMaiorDir className="pt-3">
                  <RS.LabelCheckLoa2 label="Prefeito" />
                  <RS.LabelCheckLoa2 label="Ordenadores" />
                  <RS.LabelCheckLoa2 label="Contador" />
                  <RS.LabelCheckLoa2 label="Diretor Financeiro" />
                </RS.LabelsContMaiorDir>
              </RS.ContainerMenorDireita>
              {/* <RS.ParteInferiorContDirMenor>
                <RS.ButtonContDirMenor>
                  Solicitar assinatura
                  <Icon width={27} icon="mdi:contract-sign" color="#515c70" />
                </RS.ButtonContDirMenor>
              </RS.ParteInferiorContDirMenor> */}

              <RS.ContainerMenorDireita style={{ marginTop: "18px" }}>
                <RS.TituloColDir style={{ paddingTop: "0px" }}>
                  <span>Publicação</span>
                </RS.TituloColDir>
                <RS.LabelsContMaiorDir className="pt-3">
                  <RS.LabelCheckLoa2 label="Portal da Transarência" />
                  <RS.LabelCheckLoa2 label="Jornal Oficial do Município" />
                </RS.LabelsContMaiorDir>
                <Row style={{ padding: "12px" }}>
                  <Col md={7}>
                    <InputSearch />
                  </Col>
                  <Col md={5}>
                    <InputDateRelatorio placeholder="Ano" disabled />
                  </Col>
                </Row>
              </RS.ContainerMenorDireita>
              <RS.ParteInferiorContDirMenor>
                <RS.ButtonContDirMenor>Publicar</RS.ButtonContDirMenor>
              </RS.ParteInferiorContDirMenor>
            </RS.ColMenorDireita>
          </Row>
        </Col>
      </Row>
    </>
  );
};
