import React, { useContext, useEffect, useState } from "react";
import {
  CCol,
  CColButtonsAcoes,
  RowToModal,
} from "../../../../components/Grid/CCol";
import {
  FormControlListaDeInputs,
  PTitulosInputs,
} from "../../../../styles/StyledComponents/ListaDeInputs";
import {
  BoxCinzaTexto,
  RowInterna,
} from "../../../../styles/StyledComponents/LayoutGerais";
import { Icon } from "@iconify/react";
import MyAutoComplete from "../../../../components/Inputs/MyAutoComplete";
import { useFetchData } from "../../../../hooks/useFetchData";
import { CurrentClientContext } from "../../../../contexts/CurrentClientContext";
import { ReturnDataAtual } from "../../../../utils/Gerais/ReturnDataAtual";
import { toast } from "react-toastify";
import ModalDeleteData from "../../../../components/ModalDeleteData/ModalDeleteData";
import api from "../../../../utils/api";

function Orientacoes({
  valuesInfosComplementares,
  arrayReceitas,
  setArrayReceitas,
  valuesFonteRecursos,
  setValuesFonteRecursos,
  orientacoesAtivo,
  isAModalPage,
}) {
  const { currentClient } = useContext(CurrentClientContext);
  const [optionsNaturezaDaReceita, setOptionsNaturezaDaReceita] = useState([]);
  const [receitaParaAdicionar, setReceitaParaAdicionar] = useState({
    codigo: "",
    titulo: "",
    id: 0,
  });
  const [modalOpened, setModalOpened] = useState(false);
  const [dataDelete, setDataDelete] = useState({});
  const [inputNatRec, setInputNatRec] = useState("");
  const ano = parseInt(ReturnDataAtual().split("/")[2]);
  const { data: dataNaturezasDaReceita } = useFetchData(
    `naturezaDaReceita/por-cliente/${currentClient.clienteId}/${ano}/filtro?codigo=`
  );

  const padroes = [
    /^[1-9]\.[1-9]\.[1-9]\.0\.00\.0\.0$/,
    /^[1-9]\.[1-9]\.[1-9]\.[1-9]\.00\.0\.0$/,
    /^[1-9]\.[1-9]\.[1-9]\.[1-9]\.[1-9]{2}\.0\.0$/,
    /^[1-9]\.[1-9]\.[1-9]\.[1-9]\.0[1-9]\.0\.0$/,
    /^[1-9]\.[1-9]\.[1-9]\.[1-9]\.[1-9]0\.0\.0$/,
  ];

  const filterNaturezas = (item) => {
    const itemNatureza = item.naturezaDaReceita;
    //1.1.1.1.01.0.1

    return padroes.some((padrao) => padrao.test(itemNatureza.nr));
    // return (
    //   itemNatureza.nr[4] !== "0" &&
    //   (itemNatureza.nr[8] !== "0" || itemNatureza.nr[9] !== "0") &&
    //   itemNatureza.nr[11] === "0" &&
    //   itemNatureza.nr[13] === "0"
    // );
  };

  useEffect(() => {
    if (dataNaturezasDaReceita) {
      console.log(
        dataNaturezasDaReceita
          .filter(filterNaturezas)
          .map((item) => ({
            codigo: item.naturezaDaReceita.nr,
            titulo: item.naturezaDaReceita.especificacao,
            id: item.naturezaDaReceita.id,
          }))
          .map((item) => {
            const pos = item.codigo.search(/\.0\.|\.00\./);
            return { ...item, codigo: item.codigo.substring(0, pos) };
          })
      );

      setOptionsNaturezaDaReceita(
        dataNaturezasDaReceita
          .filter(filterNaturezas)
          .map((item) => ({
            codigo: item.naturezaDaReceita.nr,
            titulo: item.naturezaDaReceita.especificacao,
            id: item.naturezaDaReceita.id,
          }))
          .map((item) => {
            const pos = item.codigo.search(/\.0\.|\.00\./);
            return { ...item, codigo: item.codigo.slice(0, pos) };
          })
      );
    }

    //eslint-disable-next-line
  }, [dataNaturezasDaReceita]);

  const handleOptionSelect = (item) => {
    console.log(item.id);
    setReceitaParaAdicionar((prev) => ({
      ...prev,
      codigo: item.codigo,
      titulo: item.titulo,
      id: item.id,
    }));
  };

  const adicionaNaturezaDaReceita = () => {
    if (
      !arrayReceitas.some((item) => item.id === receitaParaAdicionar.id) &&
      receitaParaAdicionar.id !== 0
    ) {
      setArrayReceitas((prev) => [...prev, receitaParaAdicionar]);
      setInputNatRec("");
    } else if (
      arrayReceitas.some((item) => item.id === receitaParaAdicionar.id)
    ) {
      toast.warning("Esse dado já faz parte da lista.");
    } else {
      toast.warning(
        "Preencha o campo corretamente para adicionar mais um dado a lista."
      );
    }
  };

  const deletaNaturezaDaReceita = () => {
    api
      .delete(
        `fonteDeRecurso/delete-vinculo/${valuesFonteRecursos.fonteId}/${dataDelete.codigo}`
      )
      .then((resp) => {
        toast.success("Registro deletado com sucesso!");
        console.log(resp);
        setArrayReceitas((prev) =>
          [...prev].filter((item) => item.codigo !== dataDelete.codigo)
        );
      })
      .catch((error) => {
        toast.error(
          "Algo deu errado ao deletar esse registro. Por favor, tente mais tarde ou entre em contato com nosso suporte."
        );
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("useEffect 2");
    setValuesFonteRecursos((prev) => ({
      ...prev,
      naturezaPrefixos: [...arrayReceitas].map((item) => item.codigo),
    }));

    //eslint-disable-next-line
  }, [arrayReceitas]);

  const openModal = () => {
    setModalOpened(true);
  };

  const closeModal = () => {
    setModalOpened(false);
  };

  return (
    <div>
      <ModalDeleteData
        modalOpened={modalOpened}
        closeModal={closeModal}
        setDataDelete={setDataDelete}
        deleteDataFunction={deletaNaturezaDaReceita}
      />
      <RowToModal isAModalPage={isAModalPage}>
        {/* <button onClick={() => console.log(arrayReceitas)}>console</button> */}
        <CCol
          className="d-flex align-items-center p-2 mt-4 row_endereco_conteudo"
          md={12}
        >
          <span className="mx-4">Orientações</span>
        </CCol>
      </RowToModal>
      <RowToModal isAModalPage={isAModalPage} className="row_form_2 mt-1">
        <CCol style={{ marginBottom: "10px" }}>
          <BoxCinzaTexto>
            Orientações para utilização Fontes ou Destinações de Recursos -
            Exclusivas
          </BoxCinzaTexto>
        </CCol>

        {orientacoesAtivo && (
          <>
            <RowInterna>
              <CCol>
                <PTitulosInputs style={{ paddingLeft: "10px" }}>
                  Natureza da Receita de C - Espécie até EE - Desdobramento para
                  identificação de peculiaridades
                </PTitulosInputs>
              </CCol>
              <CColButtonsAcoes md={1}>
                <PTitulosInputs>Ação</PTitulosInputs>
              </CColButtonsAcoes>
            </RowInterna>
            <RowInterna>
              <CCol>
                <MyAutoComplete
                  inputValue={inputNatRec}
                  setInputValue={setInputNatRec}
                  radiusborder={
                    arrayReceitas.length > 0 ? "5px 5px 0px 0px" : "5px"
                  }
                  options={optionsNaturezaDaReceita}
                  labelFormat={(option) =>
                    `${option.codigo} - ${option.titulo}`
                  }
                  onOptionSelect={handleOptionSelect}
                />
              </CCol>
              <CColButtonsAcoes md={1} style={{ paddingLeft: "5px" }}>
                <Icon
                  style={{ cursor: "pointer" }}
                  icon="ic:baseline-add-box"
                  color="#105200"
                  height="28"
                  onClick={adicionaNaturezaDaReceita}
                />
              </CColButtonsAcoes>
            </RowInterna>

            {arrayReceitas.map((item, index, selfArray) => (
              <RowInterna key={index}>
                <CCol>
                  <FormControlListaDeInputs
                    title={`${item.codigo} - ${item.titulo}`}
                    value={`${item.codigo} - ${item.titulo}`}
                    radiusborder={
                      selfArray.length - 1 === index ? "0px 0px 5px 5px" : "0px"
                    }
                    disabled
                  />
                </CCol>
                <CColButtonsAcoes md={1} style={{ paddingLeft: "5px" }}>
                  <Icon
                    icon="ic:baseline-delete"
                    style={{ cursor: "pointer" }}
                    color="#E79900"
                    height="28"
                    onClick={() => {
                      openModal();
                      setDataDelete({ codigo: item.codigo });
                    }}
                  />
                </CColButtonsAcoes>
              </RowInterna>
            ))}
          </>
        )}
      </RowToModal>
    </div>
  );
}

export default Orientacoes;
